import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { store } from "..";
import { TOKEN_EXPIRED, UNAUTHORIZED } from "../constants/axios";
import { SERVICE_URL } from "../constants/env";
import { AuthActions } from "../store/auth/types";

export interface IFormatError {
  message: string;
  status?: number;
  code?: string;
  extra?: any[];
  extraType?: string;
}

class Api {
  private req!: AxiosInstance;

  constructor(service?: boolean) {
    if (service) {
      this.req = axios.create({
        baseURL: SERVICE_URL,
        // timeout: 5000,
      });
      this.req.interceptors.request.use((config) => {
        const token = localStorage.getItem("jwt");
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        config.headers["Content-Type"] = "application/json";
        return config;
      });

      this.req.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response?.data?.statusCode === 401) {
            store.dispatch({ type: AuthActions.EXPIRED_SESSION });
            return Promise.reject(error);
          }

          if (error.response?.data?.code === UNAUTHORIZED) {
            window.location.assign("/");
            return Promise.reject(error);
          }

          return Promise.reject(error);
        }
      );
      return;
    }

    this.req = axios.create();
    // { timeout: 5000 });
  }

  public async get<T>(
    url: string,
    params: AxiosRequestConfig = {}
  ): Promise<T> {
    return this.req.get(url, { ...params });
  }

  public post<T>(
    url: string,
    body: any,
    params: AxiosRequestConfig = {}
  ): Promise<T> {
    return this.req.post(url, body, params);
  }

  public put<T>(
    url: string,
    body: any,
    params: AxiosRequestConfig = {}
  ): Promise<T> {
    return this.req.put(url, body, params);
  }

  public patch<T>(
    url: string,
    body: any,
    params: AxiosRequestConfig = {}
  ): Promise<T> {
    return this.req.patch(url, body, params);
  }

  public delete<T>(url: string, config: AxiosRequestConfig = {}): Promise<T> {
    return this.req.delete(url, config);
  }

  public errorHandler(error: any): IFormatError {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */

      return {
        message:
          error.response.data.reasons?.[0]?.message ||
          error.response.data.message,
        status: error.response.data.statusCode,
        code: error.response.data.code,
        extra: error.response.data.dependants,
        extraType: error.response.data.dependantsType,
      };
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      return {
        message: error.request.statusText,
      };
    }
    // Something happened in setting up the request and triggered an Error
    return {
      message: error.message,
    };
  }
}

export const DashboardApi = new Api(true);
export const ExternalApi = new Api(false);
