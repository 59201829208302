import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { icons } from "../../../assets/icons/icons";
import Button from "../../../components/button/button";
import Footer from "../../../components/cross/footer/footer";
import InputController from "../../../components/input/input-controller";
import useQuery from "../../../hooks/useQuery";
import useReactGa from "../../../hooks/useReactGa";
import useTimer from "../../../hooks/useTimer";
import { routes } from "../../../routes/routes";
import { DashboardApi } from "../../../services/rest-client";
import { loaderActions } from "../../../store/loader/actions";
import { getErrorMessage } from "../../../utils/errorMessage";
import { PurchaseConfirmStyle } from "./purchase-confirm-style";

const PurchaseConfirm: React.FC = () => {
  const { t, i18n } = useTranslation(["market", "validations"]);
  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();

  const [reject] = useState(query.get("action") === "reject");

  const { timer, setTimer, handleStart, formatTime, isPaused } = useTimer(0);

  useReactGa(
    window.location.pathname,
    query.get("action") === "confirm" ? "Confirmar Compra" : "Rechazar Compra"
  );

  const { control, handleSubmit, errors } = useForm<any>({
    defaultValues: {
      verificationCode: "",
    },
    mode: "onBlur",
    criteriaMode: "all",
  });

  const [finish, setFinish] = useState(false);
  const numberIntent = useRef<number>(0);
  const numberIntentBefore = useRef<number>(0);

  useEffect(() => {
    handleVerifyCode();
  }, []);

  useEffect(() => {
    if (isPaused && timer > 0) {
      handleStart();
    }
  }, [timer, isPaused]);

  useEffect(() => {
    const token = query.get("token");
    if (!token) {
      history.push(routes.root);
    }
  }, [history]);

  const handleVerifyCode = async () => {
    try {
      dispatch(loaderActions.loading());
      const token = query.get("token");

      await DashboardApi.post(
        "/market/request-verify-code",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
          params: { locale: i18n.language },
        }
      );
      const temp = numberIntent.current;
      numberIntent.current += numberIntentBefore.current || 1;
      numberIntentBefore.current = temp;
      setTimer(numberIntent.current * 60);
      toast.success(t("market:SMS enviado"));
    } catch (err) {
      const error = DashboardApi.errorHandler(err);
      toast.error(error.message);
    } finally {
      dispatch(loaderActions.loaded());
    }
  };

  const handleConfirmPurchase = async (data: any) => {
    try {
      dispatch(loaderActions.loading());
      const token = query.get("token");

      await DashboardApi.post(
        "/market/confirm-order",
        {
          ...data,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
          params: { locale: i18n.language },
        }
      );
      setFinish(true);
      if (reject) {
        toast.success(t("market:Compra rechazada"));
      } else {
        toast.success(t("market:Compra confirmada"));
      }
    } catch (err) {
      const error = DashboardApi.errorHandler(err);
      toast.error(error.message);
    } finally {
      dispatch(loaderActions.loaded());
    }
  };

  return (
    <>
      <PurchaseConfirmStyle>
        <div className="purchase-confirm-main">
          <div className="purchase-confirm-main__logo">
            <img
              className="tablet"
              src={icons.fullLogoWhite}
              alt="Sanza logo"
            />
            <img className="other" src={icons.fullLogo} alt="Sanza logo" />
          </div>

          <div className="purchase-confirm-main__container">
            <div className="purchase-confirm-main__title">
              {!finish && <p>{t("market:Introduce tu código SMS")}</p>}
              {finish && (
                <p>
                  {reject
                    ? t(
                        "market:La orden de compra ha sido rechazada correctamente."
                      )
                    : t(
                        "market:La orden de compra ha sido confirmada con éxito."
                      )}
                </p>
              )}
            </div>
            <div className="purchase-confirm-main__text">
              {!finish && (
                <p>
                  {reject
                    ? "Introduce el código SMS para rechazar la compra."
                    : "Introduce el código SMS para aprobar la compra."}
                </p>
              )}
              {finish && (
                <p>
                  {reject
                    ? t(
                        "market:Has rechazado la orden de compra, para cualquier consulta contacta con Sanza."
                      )
                    : t(
                        "market:Has confirmado la orden de compra, para cualquier consulta contacta con Sanza."
                      )}
                </p>
              )}
            </div>
            {!finish && (
              <div className="purchase-confirm-form">
                <div className="form-row">
                  <InputController
                    inputProps={{
                      required: true,
                      placeholder: t("market:Introduce el código"),
                      type: "text",
                      label: t("market:Código SMS código"),
                      error: getErrorMessage(t, errors.verificationCode?.type),
                    }}
                    controllerProps={{
                      control,
                      name: "verificationCode",
                      rules: {
                        required: true,
                      },
                    }}
                  />
                </div>
                <div className="purchase-confirm-main__buttons">
                  <Button
                    className="purchase-confirm-main__buttons__item"
                    type="primary"
                    size="xxl"
                    text={t("market:Enviar")}
                    onClick={handleSubmit(handleConfirmPurchase)}
                  />
                  <Button
                    className="purchase-confirm-main__buttons__item"
                    type="ghost"
                    disabled={!!timer}
                    text={`${t("market:Reenviar código SMS")}${
                      timer ? ` (${formatTime(timer)})` : ""
                    }`}
                    onClick={() => handleVerifyCode()}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="purchase-confirm-side">
          <picture>
            <source
              srcSet={icons.purchaseConfirmBg}
              media="(min-width: 1100px)"
            />
            <img src={icons.purchaseConfirmBg} alt="" />
          </picture>
        </div>
      </PurchaseConfirmStyle>
      <Footer />
    </>
  );
};

export default PurchaseConfirm;
