import React from "react";
import { ReactSVG } from "react-svg";
import styled, { css } from "styled-components";

interface ISVGIcon {
  icon: string;
  color?: string;
  strokeColor?: string;
  size?: "16px" | "24px" | "32px" | "48px" | "64px" | string;
  className?: string;
}

interface ISVGIconStyle {
  color?: string;
  strokeColor?: string;
  size?: "16px" | "24px" | "32px" | "48px" | "64px" | string;
}

const SVGIconStyle = styled.div<ISVGIconStyle>`
  svg {
    width: 100%;
    height: auto;
  }
  ${(props) =>
    props.color &&
    css`
      path {
        fill: ${props.color};
      }
    `}

  ${(props) =>
    props.strokeColor &&
    css`
      path {
        stroke: ${props.strokeColor};
      }
    `}

	${(props) =>
    props.size &&
    css`
      width: ${props.size};
      min-width: ${props.size};
      height: ${props.size};

      svg {
        width: ${props.size};
        min-width: ${props.size};
        height: ${props.size};
      }
    `}
`;

const SVGIcon: React.FC<ISVGIcon> = (props: ISVGIcon) => {
  const { icon, color, strokeColor, size, className } = props;
  return (
    <SVGIconStyle
      color={color}
      size={size}
      strokeColor={strokeColor}
      className={className}
    >
      <ReactSVG src={icon} />
    </SVGIconStyle>
  );
};

export default SVGIcon;
