import classnames from "classnames";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { icons } from "../../assets/icons/icons";
import { PRIVATE_CONTACT_EMAIL } from "../../constants/contact";
import useOutsideClick from "../../hooks/useOutSideClick";
import SVGIcon from "../svg-icon/svg-icon";
import { MainMenuStyle } from "./main-menu-style";
export interface ILink {
  text: string;
  src: string;
  icon: string;
  active?: boolean;
  path: string;
}

export interface IMainMenuProps {
  links?: ILink[];
  setModal: (value: string) => void;
  defaultState?: boolean;
  className?: string;
  withContact?: boolean;
}

const MainMenu: React.FC<IMainMenuProps> = ({
  links,
  setModal,
  defaultState,
  className,
  withContact,
}) => {
  const { t } = useTranslation("header");

  const [open, setOpen] = useState(
    defaultState !== undefined ? defaultState : false
  );

  const toggleContainer = useRef(null);

  const onClickOutsideHandler = () => {
    if (open) {
      setOpen(false);
    }
  };

  useOutsideClick(toggleContainer, onClickOutsideHandler);

  return (
    <MainMenuStyle
      className={classnames(className, {
        open: !!open,
      })}
    >
      <div ref={toggleContainer} className="main-menu-container">
        <div className="main-menu-toggle" onClick={() => setOpen(!open)}>
          <SVGIcon icon={icons.arrowRightSmall} size="18px" />
        </div>
        <div className="main-menu-links">
          {links?.map((link: ILink, index: number) => (
            <NavLink
              exact={link.path === "/"}
              to={link.path}
              key={`main-menu-link-${index}`}
              className={classnames("main-menu-link", {
                active: link?.active,
              })}
            >
              <div className="main-menu-link__icon">
                <SVGIcon icon={link.icon} size="22px" />
              </div>
              <div className="main-menu-link__text">
                <p>{link.text}</p>
              </div>
            </NavLink>
          ))}
        </div>
        <div className="main-menu-spacer" />
        <div className="main-menu-footer">
          {withContact && (
            <div
              className={classnames("main-menu-link")}
              onClick={() => window.open(`mailto:${PRIVATE_CONTACT_EMAIL}`)}
            >
              <div className="main-menu-link__icon">
                <SVGIcon icon={icons.mail} size="22px" />
              </div>
              <div className="main-menu-link__text">
                <p>{t("header:Contacto")}</p>
              </div>
            </div>
          )}
          <div className="main-menu-link" onClick={() => setModal("logout")}>
            <div className="main-menu-link__icon icon-stroke">
              <SVGIcon icon={icons.logout} size="22px" />
            </div>
            <div className="main-menu-link__text">
              <p>{t("header:Cerrar sesión")}</p>
            </div>
          </div>
        </div>
      </div>
    </MainMenuStyle>
  );
};

export default MainMenu;
