export const getErrorMessage = (t: any, key: string | undefined) => {
  const errorMessages: any = {
    required: t("validation:El campo esta vacio"),
    isDocument: t("validation:Documento incorrecto"),
    isEmail: t("validation:El email es incorrecto"),
    isPhone: t("validation:El teléfono es incorrecto"),
    isMobile: t("validation:El móvil es incorrecto"),
    isWeb: t("validation:La web es incorrecto"),
    fileSize: t("validation:Los archivos no pueden superar los 3MB"),
    validExtension: t("validation:Formato del archivo no valido"),
    isValidPercentage: t(
      "validation:Porcentaje invalido (de 0 a 100 con solo 2 dígitos)"
    ),
    min: t("validation:Caractéres mínimo%n"),
    max: t("validation:Caractéres máximo%n"),
    less: t("validation:Tiene que ser menor a%n"),
    greaterEmpty: t("validation:Tiene que ser mayor o igual a%n"),
    greater: t("validation:Tiene que ser mayor a%n"),
    isValidDate: t("validation:Fecha inválida"),
    isFutureDate: t("validation:Fecha tiene que ser futura"),
    isEqualPasswords2: t("validation:Las contraseñas no coinciden"),
    dateMoreThan: t("validation:La fecha tiene que ser mayor a%n"),
    isInteger: t("validation:Tiene que ser un número entero"),
    isEqualPasswords: "",
    isMinLength: "",
    hasUppercase: "",
    hasDigits: "",
    hasLowercase: "",
    hasSymbol: "",
    checked: "",
  };

  let errorMessage = "";
  if (key !== undefined) {
    if (
      typeof key === "string" &&
      (key.indexOf("min-") > -1 ||
        key.indexOf("max-") > -1 ||
        key.indexOf("greater-") > -1 ||
        key.indexOf("greaterEmpty-") > -1 ||
        key.indexOf("dateMoreThan-") > -1 ||
        key.indexOf("less-") > -1)
    ) {
      const [keyAux, num] = key.split("-");
      errorMessage = errorMessages[keyAux].replace("%n", `: ${num}`);
    } else {
      errorMessage =
        errorMessages[key] !== undefined
          ? errorMessages[key]
          : t("validation:Error");
    }
  }

  return errorMessage;
};
