import React from "react";
import { useSelector } from "react-redux";
import useCan from "../../hooks/useCan";
import { authSelector } from "../../store/auth/selectors";

interface ICan {
  perform: string;
  yes: () => void;
  no: () => void;
}

const Can: React.FC<ICan> = ({ perform, yes, no }) => {
  const { role } = useSelector(authSelector.getUser());

  const { check } = useCan();

  return <>{check(perform) ? yes() : no()}</>;
};

export default Can;
