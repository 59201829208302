import { IBaseAction } from "../base-action.interface";

export enum RecaptchaActions {
  SET_ID = "SET_ID",
}

export interface RecaptchaState {
  readonly id: number;
}

interface SetIdAction extends IBaseAction {
  type: RecaptchaActions.SET_ID;
  payload: number;
}

export type RecaptchaActionsTypes = SetIdAction;
