import produce from "immer";
import { UsersActions, UsersActionsTypes, UsersState } from "./types";

export const initialState: UsersState = {
  user: null,
  qualification: null,
  identities: null,
  documents: null,
  multipoint: null,
};

const reducer = (state: UsersState = initialState, action: UsersActionsTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case UsersActions.SET_USER:
        draft.user = action.payload;
        break;

      case UsersActions.CLEAN_USER:
        draft.user = null;
        break;

      case UsersActions.SET_QUALIFICATION:
        draft.qualification = action.payload;
        break;

      case UsersActions.CLEAN_QUALIFICATION:
        draft.qualification = null;
        break;

      case UsersActions.SET_IDENTITIES:
        draft.identities = action.payload;
        break;

      case UsersActions.CLEAN_IDENTITIES:
        draft.identities = null;
        break;

      case UsersActions.SET_DOCUMENTS:
        draft.documents = action.payload;
        break;

      case UsersActions.CLEAN_DOCUMENTS:
        draft.documents = null;
        break;

      case UsersActions.SET_MULTIPOINT:
        draft.multipoint = action.payload;
        break;

      case UsersActions.CLEAN_MULTIPOINT:
        draft.multipoint = null;
        break;

      default:
        break;
    }
  });

export default reducer;
