import { MainEntity } from "../common/main.entiity";
import { FileEntity } from "../files/file.entity";
import {
  USER_VALIDATION_ANNUAL_CONSOLIDATE_ACCOUNTS,
  USER_VALIDATION_ANNUAL_ELECTRICAL_ENERGY_CONSUMPTION,
  USER_VALIDATION_ANNUAL_EXPENDITURE_ELECTRICITY,
  USER_VALIDATION_CONSUMER_COMPANY,
  USER_VALIDATION_COVER_WITH_PPA,
  USER_VALIDATION_CUPS,
  USER_VALIDATION_MULTIPOINT_TABLE,
  USER_VALIDATION_SAVINGS_SIMULATION,
  USER_VALIDATION_STATUS,
  USER_VALIDATION_USER,
} from "../users/user.constants";
import { UserEntity } from "../users/users.entities";

export enum QualificationStatusEnum {
  DRAFT = "draft",
  VALIDATING = "validating",
  NEEDS_MORE_INFO = "needs_more_info",
  APPROVED = "approved",
  REJECTED = "rejected",
}

export interface QualificationEntity extends MainEntity {
  [USER_VALIDATION_CONSUMER_COMPANY]: string;
  [USER_VALIDATION_ANNUAL_CONSOLIDATE_ACCOUNTS]?: FileEntity;
  [USER_VALIDATION_CUPS]?: string;
  [USER_VALIDATION_MULTIPOINT_TABLE]?: FileEntity;
  [USER_VALIDATION_SAVINGS_SIMULATION]?: FileEntity;
  [USER_VALIDATION_ANNUAL_ELECTRICAL_ENERGY_CONSUMPTION]?: string;
  [USER_VALIDATION_ANNUAL_EXPENDITURE_ELECTRICITY]?: string;
  [USER_VALIDATION_COVER_WITH_PPA]: string;
  [USER_VALIDATION_STATUS]: QualificationStatusEnum;
  [USER_VALIDATION_USER]: UserEntity;
}
