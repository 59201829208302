import styled from 'styled-components';
import { colors } from '../../../assets/colors/colors';
import { FormRowMixin } from '../../../components/style-components/form-row';
import { BodyDefaultMixin } from '../../../components/text-components/body-text';
import { Heading1Mixin, Heading4Mixin } from '../../../components/text-components/heading-text';

export const PurchaseConfirmStyle = styled.div`
  display: flex;
  align-items: stretch;
  min-height: 100vh;
  width: 100%;
  position: relative;

  .purchase-confirm-main {
    flex: 1;
    padding: 44px 20px;
    padding-left: 130px;
    display: flex;
    flex-flow: column;
    /* align-items: center;
    align-self: center; */

    &__logo {
      width: 182px;
      margin-bottom: 62px;
      /* top: 48px;
      left: 120px; */
      position: relative;

      .other {
        display: block;
      }

      .tablet {
        display: none;
      }
    }

    &__container {
      display: flex;
      flex-flow: column;
      max-width: 426px;
      position: relative;
    }

    &__title {
      ${Heading1Mixin};
      color: ${colors['neutrals-100']};
      margin-bottom: 8px;
      user-select: none;
    }

    &__text {
      ${BodyDefaultMixin};
      color: ${colors['neutrals-50']};
      margin-bottom: 48px;
      user-select: none;
    }

    &__buttons {
      margin-top: 48px;
      display: flex;
      flex-flow: column;

      &__item {
        width: 100%;

        &:not(:first-child) {
          margin-top: 24px;
        }

        &:last-child {
          width: auto;
          align-self: center;
        }
      }
    }
  }

  ${FormRowMixin};

  .purchase-confirm-side {
    /* max-height: 100vh; */
    max-width: 38%;
    flex: 1;

    picture {
      width: 100%;
      height: 100%;
      display: flex;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 150px 0px 0px 0px;
    }
  }

  @media (max-width: 1100px) {
    flex-flow: column;
    align-items: stretch;
    min-height: 0;

    .purchase-confirm-main {
      padding: 0 20px 60px;
      align-items: center;

      &__container {
        padding-top: 60px;
      }

      &__logo {
        position: absolute;
        left: 54px;
        top: 60px;

        .other {
          display: none;
        }

        .tablet {
          display: block;
        }
      }

      &__container {
        max-width: 540px;
      }
    }

    .purchase-confirm-form-info {
      position: relative;
      top: initial;
      left: initial;
      transform: translateY(0);
      width: 100%;
    }

    .purchase-confirm-side {
      max-height: 300px;
      max-width: none;
      flex: none;
      order: -1;

      img {
        max-height: 300px;
        border-radius: 0px 0px 150px 0px;
      }
    }
  }

  @media (max-width: 768px) {
    .purchase-confirm-main {
      justify-content: flex-start;
      padding: 44px 20px 26px;
      align-self: stretch;
      align-items: stretch;

      &__container {
        padding-top: 0px;
        max-width: none;
      }

      &__title {
        ${Heading4Mixin};
      }

      &__text {
        margin-bottom: 36px;
      }

      &__logo {
        left: initial;
        top: initial;
        position: relative;
        margin-bottom: 32px;
        width: 130px;

        .other {
          display: block;
        }

        .tablet {
          display: none;
        }
      }
    }

    .purchase-confirm-side {
      display: none;
    }
  }
`;