import styled from 'styled-components';
import { colors } from '../../assets/colors/colors';



export const ProgressBarStyle = styled.div`
  background: ${colors['neutrals-5']};
  border-radius: 12px;
  height: 6px;
  overflow: hidden;
  width: 100%;
  
  .progress {
    background: #45BE84;
    display: block;
    height: 100%;
    border-radius: 20px;
  }
`;