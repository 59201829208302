import classnames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { colors } from "../../assets/colors/colors";
import { icons } from "../../assets/icons/icons";
import { PRIVATE_CONTACT_EMAIL } from "../../constants/contact";
import HeaderNotifications from "../../containers/internal/notifications/header-notifications/header-notifications";
import useNotification from "../../hooks/useNotification";
import { authActions } from "../../store/auth/actions";
import { authSelector } from "../../store/auth/selectors";
import { notificationsSelector } from "../../store/notifications/selectors";
import { UserRolesEnum } from "../../types/users/users.enums";
import Button from "../button/button";
import MainMenu, { ILink } from "../main-menu/main-menu";
import {
  getAdiviserLinks,
  getBuyerAndGeneratorLinks,
  getSanzaManagerLinks,
} from "../main-menu/main-menu-constants";
import Modal from "../modal/modal";
import SVGIcon from "../svg-icon/svg-icon";
import { MainHeaderStyle } from "./main-header-style";

export interface IMainHeaderProps {
  defaultState?: boolean;
  withContact?: boolean;
  withoutActions?: boolean;
}

const MainHeader: React.FC<IMainHeaderProps> = ({
  defaultState,
  withoutActions,
}) => {
  const { t, i18n } = useTranslation("header");
  const dispatch = useDispatch();
  const { role }: any = useSelector(authSelector.getUser());
  const requests = useSelector(notificationsSelector.getRequestsHeaders());
  const notifications = useSelector(
    notificationsSelector.getNotifciationsHeaders()
  );

  const changeLanguage = (lng: "es" | "en") => {
    localStorage.setItem("language", i18n.language);
    i18n.changeLanguage(lng);
  };

  const [open, setOpen] = useState<boolean>(defaultState || false);
  const [openAlerts, setOpenAlerts] = useState<boolean>(defaultState || false);
  const [modal, setModal] = useState("");

  const {
    hasNotifications,
    markedReadNotification,
    markedReadRequest,
  } = useNotification(true);

  const sanzaManagerLinks = React.useMemo(() => getSanzaManagerLinks(t), [
    i18n.language,
  ]);
  const buyerAndGeneratorLinks = React.useMemo(
    () => getBuyerAndGeneratorLinks(t),
    [i18n.language]
  );
  const adviserLinks = React.useMemo(() => getAdiviserLinks(t), [
    i18n.language,
  ]);

  const linksCallbacks: any = {
    [UserRolesEnum.MANAGER]: sanzaManagerLinks,
    [UserRolesEnum.BUYER]: buyerAndGeneratorLinks,
    [UserRolesEnum.GENERATOR]: buyerAndGeneratorLinks,
    [UserRolesEnum.ADVISER]: adviserLinks,
  };

  return (
    <>
      <MainHeaderStyle
        className={classnames({
          open: !!open,
        })}
      >
        <div className="main-header-bar">
          <div className="main-header-bar__small-logo">
            <SVGIcon icon={icons.logo} size="36px" />
          </div>
          <div className="main-header-bar__logo">
            <SVGIcon icon={icons.fullLogo} size="100px" />
          </div>
          <div className="main-header-bar__spacer" />
          {!withoutActions && (
            <>
              <div className="main-header-bar__language">
                {i18n.language === "es" && (
                  <Button
                    type="ghost"
                    text="EN"
                    onClick={() => changeLanguage("en")}
                  />
                )}
                {i18n.language === "en" && (
                  <Button
                    type="ghost"
                    text="ES"
                    onClick={() => changeLanguage("es")}
                  />
                )}
              </div>
              <div className="main-header-bar__notifications">
                <Button
                  className={classnames({
                    alert: hasNotifications,
                    active: openAlerts,
                  })}
                  type="icon-primary"
                  icon={icons.notification2}
                  iconSize="22px"
                  onClick={() => setOpenAlerts(!openAlerts)}
                />
                <HeaderNotifications
                  notifications={notifications}
                  markedReadNotification={markedReadNotification}
                  requests={requests}
                  markedReadRequest={markedReadRequest}
                  open={openAlerts}
                  setOpen={setOpenAlerts}
                />
              </div>
              <Button
                type="icon-primary"
                className="main-header-bar__toggle"
                icon={open ? icons.menuClose : icons.menuToggle}
                iconColor={open ? "" : colors["brand-1-100"]}
                iconStrokeColor={open ? colors["brand-1-100"] : ""}
                onClick={() => setOpen(!open)}
              />
            </>
          )}
        </div>
        {!withoutActions && (
          <div className="main-header-menu">
            <div className="main-header-links">
              {linksCallbacks[role]?.map((link: ILink, index: number) => (
                <NavLink
                  exact={link.path === "/"}
                  to={link.path}
                  key={`main-header-link-${index}`}
                  onClick={() => setOpen(false)}
                  className={classnames("main-header-link", {
                    active: link?.active,
                  })}
                >
                  <div className="main-header-link__icon">
                    <SVGIcon icon={link.icon} size="22px" />
                  </div>
                  <div className="main-header-link__text">
                    <p>{link.text}</p>
                  </div>
                </NavLink>
              ))}
            </div>
            <div className="main-header-spacer" />
            <div className="main-header-links">
              {role !== UserRolesEnum.MANAGER && (
                <div
                  className="main-header-link"
                  onClick={() => window.open(`mailto:${PRIVATE_CONTACT_EMAIL}`)}
                >
                  <div className="main-header-link__icon icon-stroke">
                    <SVGIcon icon={icons.mail} size="16px" />
                  </div>
                  <div className="main-header-link__text">
                    <p>{t("header:Contacto")}</p>
                  </div>
                </div>
              )}
              <div
                className="main-header-link"
                onClick={() => setModal("logout")}
              >
                <div className="main-header-link__icon icon-stroke">
                  <SVGIcon icon={icons.logout} size="16px" />
                </div>
                <div className="main-header-link__text">
                  <p>{t("header:Cerrar sesión")}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </MainHeaderStyle>
      {!withoutActions && (
        <MainMenu
          setModal={setModal}
          links={linksCallbacks[role]}
          className="main-header-menu"
          withContact={role !== UserRolesEnum.MANAGER}
        />
      )}
      <Modal
        active={modal === "logout"}
        title={t("header:Cerrar sesión")}
        text={t("header:¿Estás seguro que quieres cerrar sesión?")}
        onClose={() => setModal("")}
        buttons={[
          {
            type: "secondary",
            text: t("header:Cancelar"),
            onClick: () => setModal(""),
          },
          {
            type: "primary",
            error: true,
            text: t("header:Cerrar sesión"),
            onClick: () => {
              dispatch(authActions.logout());
              setModal("");
            },
          },
        ]}
      />
    </>
  );
};

export default MainHeader;
