export interface IActions {
  static: string[];
  dynamic?: any;
}

export interface IRules {
  buyer: IActions;
  manager: IActions;
  generator: IActions;
  adviser: IActions;
}

// Roles Prismic
export const ROLES: any = {
  buyer: "Comprador",
  manager: "Gestor",
  generator: "Generador",
  adviser: "Revisor",
};

export const rules: IRules = {
  manager: {
    static: [
      "notifications:create",
      "notifications:read",
      "requests:read",
      "users:read",
      "users:edit",
    ],
    dynamic: {},
  },
  buyer: {
    static: ["notifications:read"],
  },
  generator: {
    static: ["notifications:read", "projects:create"],
  },
  adviser: {
    static: ["notifications:read", "users:read"],
  },
};
