import { combineReducers } from "redux";
import authReducer from "./auth/reducer";
import loaderReducer from "./loader/reducer";
import notificationsReducer from "./notifications/reducer";
import projectsReducer from "./projects/reducer";
import recaptchaReducer from "./recaptcha/reducer";
import usersReducer from "./users/reducer";

export default combineReducers({
  load: loaderReducer,
  auth: authReducer,
  notifications: notificationsReducer,
  recaptcha: recaptchaReducer,
  projects: projectsReducer,
  users: usersReducer,
});
