import classnames from 'classnames';
import React, { ReactNode } from 'react';
import { ToggleStyle } from './toggle-style';


interface IToggleProps {
  name?: string;
  error?: boolean | string;
  disabled?: boolean;
  required?: boolean;
  tooltip?: string;
  checked?: boolean;
  label?: string | ReactNode;
  className?: string;
  onChange?: any;
  onBlur?: any;
  onFocus?: any;
  onKeyDown?: any;
}

const Toggle: React.FC<IToggleProps> = (props: IToggleProps) => {
  const handleChange = (ev: any) => {
    if (props.onChange) {
      props.onChange(ev.target.checked);
    }
  };

  return (
    <ToggleStyle
      className={classnames(props.className || '', {
        error: !!props.error,
        disabled: !!props.disabled,
        required: !!props.required,
        active: !!props.checked,
      })}
    >
      <label className="toggle-container">
        <input
          type="checkbox"
          name={props.name}
          disabled={props.disabled}
          required={props.required}
          checked={props.checked}
          onChange={(ev: any) => !props.disabled && handleChange(ev)}
          onFocus={(ev: any) => !props.disabled && props.onFocus && props.onFocus(ev)}
          onBlur={(ev: any) => !props.disabled && props.onBlur && props.onBlur(ev)}
          onKeyDown={(ev: any) => !props.disabled && props.onKeyDown && props.onKeyDown(ev)}
        />
        {props.label && <div className="toggle-label">{props.label}</div>}
        <div className="toggle-bottom">
          {props.error && typeof props.error === 'string' && (
            <div className="toggle-bottom-error">
              <p>{props.error}</p>
            </div>
          )}
          <div className="toggle-item">
            <div className="toggle-item-check" />
          </div>
        </div>
      </label>
    </ToggleStyle>
  );
};

export default Toggle;
