import styled from "styled-components";
import { colors } from "../../../assets/colors/colors";
import { FormRowMixin } from "../../../components/style-components/form-row";
import { BodyDefaultMixin } from "../../../components/text-components/body-text";
import {
  Heading1Mixin,
  Heading4Mixin,
} from "../../../components/text-components/heading-text";

export const LoginStyle = styled.div`
  display: flex;
  align-items: stretch;
  min-height: calc(100vh - 60px);
  width: 100%;
  position: relative;

  .login-main {
    flex: 1;
    padding: 44px 20px;
    padding-bottom: 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    align-self: center;
    margin-left: 48%;

    &__logo {
      margin-bottom: 40px;
      width: 100%;
      max-width: 426px;
      img {
        width: 182px;
      }
      .mobile {
        display: none;
      }
    }

    &__container {
      display: flex;
      flex-flow: column;
      max-width: 426px;
    }

    &__title {
      ${Heading1Mixin};
      color: ${colors["neutrals-100"]};
      margin-bottom: 8px;
      user-select: none;
    }

    &__text {
      ${BodyDefaultMixin};
      color: ${colors["neutrals-50"]};
      margin-bottom: 48px;
      user-select: none;
    }

    &__buttons {
      margin-top: 48px;

      &__item {
        width: 100%;
        user-select: none;

        span {
          cursor: pointer;
          text-decoration: underline;
          color: ${colors["brand-1-100"]};
        }

        &:not(:first-child) {
          margin-top: 24px;
        }
      }
    }
  }

  ${FormRowMixin};

  .login-recover {
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;
  }

  .login-side {
    height: 100vh;
    width: 48%;
    flex: 1;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    /* background: colors['neutrals-80'] */

    picture {
      width: 100%;
      height: 100%;
      display: flex;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 0px 150px 0px 0px;
    }
  }

  .input-bottom {
    position: absolute;
  }

  @media (max-width: 1100px) {
    min-height: 0;
    .login-main {
      padding: 56px 54px;
      margin-left: 0px;
      min-height: unset;


      &__container {
        max-width: 540px;
      }
    }

    /* .login-side {
      position: relative;
      max-height: 300px;
      width: 100%;
      img {
        border-radius: 0px 0px 0px 0px;
      }
    } */
    .login-side {
      display: none;
    }
  }

  @media (max-width: 768px) {
    /* min-height: 0; */

    .login-main {
      justify-content: flex-start;
      padding: 40px 20px;
      align-self: stretch;
      align-items: stretch;

      &__container {
        /* max-width: none; */
      }

      &__title {
        ${Heading4Mixin};
      }

      &__text {
        margin-bottom: 36px;
      }

      &__logo {
        left: initial;
        top: initial;
        position: relative;
        margin-bottom: 40px;
        width: 130px;

        .other {
          display: none;
        }

        .mobile {
          display: block;
        }
      }
    }

    .login-side {
      display: none;
    }
  }
`;