import classnames from "classnames";
import React, { ReactNode } from "react";
import { colors } from "../../assets/colors/colors";
import { icons } from "../../assets/icons/icons";
import SVGIcon from "../svg-icon/svg-icon";
import { CheckboxStyle } from "./checkbox-style";

export interface CheckboxProps {
  disabled?: boolean;
  required?: boolean;
  label?: string | ReactNode;
  error?: boolean | string;
  tooltip?: string;
  name?: string;
  checked?: boolean;
  className?: string;
  isRadio?: boolean;
  value?: any;
  indeterminated?: boolean;
  inSelect?: boolean;
  onChange?: any;
  onBlur?: any;
  onFocus?: any;
  onKeyDown?: any;
}

// States: disable | active | error

const Checkbox: React.FC<CheckboxProps> = (props: CheckboxProps) => {
  const [checked, setChecked] = React.useState<boolean>(false);

  const handleChange = (ev: any) => {
    if (props.onChange) {
      props.onChange(ev);
    } else if (props.checked === undefined) {
      setChecked(ev.target.checked);
    }
  };

  const check = props.checked || checked;

  return (
    <CheckboxStyle
      className={classnames(props.className || "", {
        error: !!props.error,
        disabled: !!props.disabled,
        required: !!props.required,
        active: !!check,
        radio: props.isRadio,
        indeterminated: props.indeterminated,
        "in-select": props.inSelect,
      })}
    >
      <label className="checkbox-container">
        <input
          type={props.isRadio ? "radio" : "checkbox"}
          name={props.name}
          disabled={props.disabled}
          required={props.required}
          value={props.value}
          checked={props.checked || checked}
          onChange={(ev: any) => !props.disabled && handleChange(ev)}
          onFocus={(ev: any) =>
            !props.disabled && props.onFocus && props.onFocus(ev)
          }
          onBlur={(ev: any) =>
            !props.disabled && props.onBlur && props.onBlur(ev)
          }
          onKeyDown={(ev: any) =>
            !props.disabled && props.onKeyDown && props.onKeyDown(ev)
          }
        />
        <div className="checkbox-item">
          {!props.isRadio ? (
            <div className="checkbox-item__icon">
              <SVGIcon
                icon={icons.check}
                color={colors["neutrals-0"]}
                size="16px"
              />
            </div>
          ) : (
            <div className="checkbox-item__circle" />
          )}
        </div>
        {props.label && (
          <div className="checkbox-label">
            <p>{props.label}</p>
          </div>
        )}
      </label>
    </CheckboxStyle>
  );
};

export default Checkbox;
