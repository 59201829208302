import styled from "styled-components";
import { colors } from "../../assets/colors/colors";
import { BodyDefaultMixin } from "../text-components/body-text";

export const CheckboxStyle = styled.div`
  border: 1px solid ${colors["neutrals-10"]};
  border-radius: 12px;
  transition: 0.3s ease-in-out all;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .checkbox-container {
    display: flex;
    align-items: center;
    position: relative;
    padding: 8px 24px;
    cursor: pointer;
    transition: 0.3s ease-in-out all;

    &:hover {
      .checkbox-item {
        border-color: ${colors["brand-1-100"]};
      }
    }
  }

  input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;
  }

  .checkbox-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 16px;
    max-width: 16px;
    min-height: 16px;
    max-height: 16px;
    border: 1px solid ${colors["neutrals-50"]};
    transition: 0.3s ease-in-out all;
    border-radius: 4px;
  }

  .checkbox-label {
    ${BodyDefaultMixin};
    color: ${colors["neutrals-50"]};
    margin-left: 8px;
    transition: 0.3s ease-in-out all;

    span,
    a {
      text-decoration: underline;
    }
  }

  &.active {
    .checkbox-container {
      .checkbox-item {
        border-color: ${colors["brand-1-100"]};
        background: ${colors["brand-1-100"]};
      }
    }

    .checkbox-label {
      color: ${colors["brand-1-100"]};
    }
  }

  &.disabled {
    pointer-events: none;

    .checkbox-item {
      border-color: ${colors["neutrals-30"]};
      background: ${colors["neutrals-30"]};
    }

    .checkbox-label {
      color: ${colors["neutrals-30"]};
    }
  }

  &.radio {
    .checkbox-item {
      border-width: 1px;
      border-radius: 100%;

      &__circle  {
        width: 14px;
        border: 2px solid #ffffff;
        height: 14px;
        border-radius: 100%;
        opacity: 0;
        visibility: hidden;
        transition: 0.3s ease-in-out all;
      }
    }

    &.active {
      .checkbox-container {
        .checkbox-item {
          border: 1px solid ${colors["brand-1-100"]};
          color: ${colors["brand-1-100"]};

          &__circle {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }

  &.error {
    .checkbox-container {
      .checkbox-label {
        color: ${colors["error-100"]};
      }

      .checkbox-item {
        border: 1px solid ${colors["error-100"]};

        svg {
          rect {
            fill: ${colors["error-100"]};
          }
        }
      }
    }
  }

  &:hover {
    border: 1px solid ${colors["brand-1-100"]};

    &.radio .checkbox-item {
      border: 1px solid ${colors["brand-1-100"]};
      color: ${colors["brand-1-100"]};
    }

    .checkbox-label {
      color: ${colors["brand-1-100"]};
    }
  }

  &.indeterminated {
    .checkbox-container {
      .checkbox-item {
        border-color: ${colors["brand-1-100"]};
        background: ${colors["brand-1-100"]};
      }
    }

    &.active {
      .checkbox-container {
        .checkbox-item {
          border-color: ${colors["brand-1-100"]};
          background: ${colors["brand-1-100"]};
        }
      }
    }
  }

  &.in-select {
    border: none;

    .checkbox-container {
      padding: 0;
    }
  }
`;
