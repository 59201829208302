import { ITab, TYPE_TAB } from "../../../../components/tab/tab";
import { ProjectStatusEnum } from "../../../../constants/options/projects";
import { AuctionStatusEnum } from "../../../../types/auctions/auction.enums";

export enum RenderEnum {
  MAIN = "main",
  REGISTER_PROJECT = "register-project",
  EDIT_TECHNICAL_INFORMATION = "edit-technical-information",
  EDIT_FINNACIAL_INFORMATION = "edit-finnacial-information",
  ADD_DOCUMENT = "add-document",
  ADD_COMMENT = "add-comment",
  HIRING_SANZA = "hiring-sanza",
  CREATE_AUCTION = "create-auction",
  CREATE_EDIT_DESCRIPTION = "create-edit-description",
  CONDICTIONS_AUCTION = "condictions-auction",
  IDENTITY_GENERATOR = "identity-generator",
  IDENTITY_GUARANTOR = "identity-guarantor",
  ADD_CONSENT = "add-consent",
  AUCTION = "auction",
  PROJECT_DEVELOPMENT = "project-development",
  PPA = "ppa",
  INFO_PROJECT = "info-project",
  DETAIL_TICKET = "detail-ticket",
  EDIT_TICKET = "edit-ticket",
  UPLOAD_ANNEX = "upload-annex",
}

export type CREATE_STEPS =
  | RenderEnum.MAIN
  | RenderEnum.ADD_DOCUMENT
  | RenderEnum.ADD_COMMENT;

export type RENDER =
  | RenderEnum.REGISTER_PROJECT
  | RenderEnum.EDIT_TECHNICAL_INFORMATION
  | RenderEnum.EDIT_FINNACIAL_INFORMATION
  | RenderEnum.ADD_DOCUMENT
  | RenderEnum.ADD_COMMENT
  | RenderEnum.HIRING_SANZA
  | RenderEnum.CREATE_AUCTION
  | RenderEnum.CONDICTIONS_AUCTION
  | RenderEnum.CREATE_EDIT_DESCRIPTION
  | RenderEnum.IDENTITY_GENERATOR
  | RenderEnum.IDENTITY_GUARANTOR
  | RenderEnum.ADD_CONSENT
  | RenderEnum.AUCTION
  | RenderEnum.PROJECT_DEVELOPMENT
  | RenderEnum.PPA
  | RenderEnum.INFO_PROJECT
  | RenderEnum.DETAIL_TICKET
  | RenderEnum.EDIT_TICKET
  | RenderEnum.UPLOAD_ANNEX;

const getTypeRegisterProject = (
  status: ProjectStatusEnum | undefined
): TYPE_TAB | undefined => {
  if (status === ProjectStatusEnum.DRAFT) {
    return "pending";
  }
  if (status === ProjectStatusEnum.CREATION_REJECTED) {
    return "rejected";
  }
  if (status === ProjectStatusEnum.CREATION_VALIDATING) {
    return "analyzing";
  }
  if (status === ProjectStatusEnum.CREATION_NEEDS_MORE_INFO) {
    return "needs-more-info";
  }
  return "approved";
};
const getTypeHiringSanza = (
  status: ProjectStatusEnum | undefined,
  hiringDisabled: boolean
) => {
  if (hiringDisabled || status === ProjectStatusEnum.HIRING_PENDING) {
    return "pending";
  }
  if (status === ProjectStatusEnum.HIRING_REJECTED) {
    return "rejected";
  }
  return "approved";
};
const getTypeCreateAuction = (
  status: ProjectStatusEnum | undefined,
  hiringDisabled: boolean,
  createAuctionDisabled: boolean
) => {
  if (
    hiringDisabled ||
    createAuctionDisabled ||
    status === ProjectStatusEnum.AUCTION_PENDING
  ) {
    return "pending";
  }
  if (status === ProjectStatusEnum.AUCTION_REJECTED) {
    return "rejected";
  }
  if (status === ProjectStatusEnum.AUCTION_VALIDATING) {
    return "analyzing";
  }
  if (status === ProjectStatusEnum.AUCTION_NEEDS_MORE_INFO) {
    return "needs-more-info";
  }
  return "approved";
};
const getTypeAuction = (
  hiringDisabled: boolean,
  createAuctionDisabled: boolean,
  auctionDisabled: boolean,
  auctionStatus: AuctionStatusEnum | undefined
) => {
  if (auctionStatus) {
    return auctionStatus.toLowerCase();
  }
  if (hiringDisabled || createAuctionDisabled || auctionDisabled) {
    return "pending";
  }
  return "auction";
};

export const TABS_GENERATOR = (
  t: any,
  render: RENDER,
  setRender: (type: RENDER) => void,
  status: ProjectStatusEnum | undefined,
  auctionStatus: AuctionStatusEnum | undefined
): ITab[] => {
  const hiringDisabled =
    !!status &&
    (status.indexOf("creation_") > -1 || status === ProjectStatusEnum.DRAFT);
  const createAuctionDisabled = !!status && status.indexOf("hiring_") > -1;
  const auctionDisabled = status !== ProjectStatusEnum.AUCTION_APPROVED;
  return [
    {
      options: {
        text: t("projects:Alta Proyecto"),
        onClick: () => setRender(RenderEnum.REGISTER_PROJECT),
        active: render === RenderEnum.REGISTER_PROJECT,
        disabled: false,
      },
      type: getTypeRegisterProject(status),
      headerTabs: true,
    },
    {
      options: {
        text: t("projects:Contratación Sanza"),
        onClick: () => setRender(RenderEnum.HIRING_SANZA),
        active: render === RenderEnum.HIRING_SANZA,
        disabled: hiringDisabled,
      },
      type: getTypeHiringSanza(status, hiringDisabled),
      headerTabs: true,
    },
    {
      options: {
        text: t("projects:Crear Subasta"),
        onClick: () => setRender(RenderEnum.CREATE_AUCTION),
        active: render === RenderEnum.CREATE_AUCTION,
        disabled: hiringDisabled, //  || createAuctionDisabled
      },
      type: getTypeCreateAuction(status, hiringDisabled, createAuctionDisabled),
      headerTabs: true,
    },
    {
      options: {
        text: t("projects:Subasta"),
        onClick: () => setRender(RenderEnum.AUCTION),
        active: render === RenderEnum.AUCTION,
        disabled: hiringDisabled || createAuctionDisabled || auctionDisabled,
      },
      type: getTypeAuction(
        hiringDisabled,
        createAuctionDisabled,
        auctionDisabled,
        auctionStatus
      ),
      headerTabs: true,
    },
    {
      options: {
        text: t("projects:Proyecto en desarrollo"),
        onClick: () => setRender(RenderEnum.PROJECT_DEVELOPMENT),
        active: render === RenderEnum.PROJECT_DEVELOPMENT,
        disabled: false,
      },
      type: "pending",
      headerTabs: true,
    },
    {
      options: {
        text: t("projects:PPA en Vigor"),
        onClick: () => setRender(RenderEnum.PPA),
        active: render === RenderEnum.PPA,
        disabled: false,
      },
      type: "pending",
      headerTabs: true,
    },
  ];
};
export const TABS_ADVISER = (
  t: any,
  render: RENDER,
  setRender: (type: RENDER) => void,
  status: ProjectStatusEnum | undefined,
  auctionStatus: AuctionStatusEnum | undefined
): ITab[] => {
  const hiringDisabled =
    !!status &&
    (status.indexOf("creation_") > -1 || status === ProjectStatusEnum.DRAFT);
  const createAuctionDisabled = !!status && status.indexOf("hiring_") > -1;
  const auctionDisabled = status !== ProjectStatusEnum.AUCTION_APPROVED;
  return [
    {
      options: {
        text: t("projects:Alta proyecto"),
        onClick: () => setRender(RenderEnum.REGISTER_PROJECT),
        active: render === RenderEnum.REGISTER_PROJECT,
      },
      type: getTypeRegisterProject(status),
    },
    {
      options: {
        text: t("projects:Crear subasta"),
        onClick: () => setRender(RenderEnum.CREATE_AUCTION),
        active: render === RenderEnum.CREATE_AUCTION,
      },
      type: getTypeCreateAuction(status, hiringDisabled, createAuctionDisabled),
    },
    {
      options: {
        text: t("projects:Subasta"),
        onClick: () => setRender(RenderEnum.AUCTION),
        active: render === RenderEnum.AUCTION,
      },
      type: getTypeAuction(
        hiringDisabled,
        createAuctionDisabled,
        auctionDisabled,
        auctionStatus
      ),
    },
  ];
};

export const TABS_MANAGER = (
  t: any,
  render: RENDER,
  setRender: (type: RENDER) => void,
  status: ProjectStatusEnum | undefined,
  auctionStatus: AuctionStatusEnum | undefined
): ITab[] => {
  const hiringDisabled =
    !!status &&
    (status.indexOf("creation_") > -1 || status === ProjectStatusEnum.DRAFT);
  const createAuctionDisabled = !!status && status.indexOf("hiring_") > -1;
  const auctionDisabled = status !== ProjectStatusEnum.AUCTION_APPROVED;
  return [
    {
      options: {
        text: t("projects:Alta proyecto"),
        onClick: () => setRender(RenderEnum.REGISTER_PROJECT),
        active: render === RenderEnum.REGISTER_PROJECT,
      },
      type: getTypeRegisterProject(status),
    },
    {
      options: {
        text: t("projects:Contratación Sanza"),
        onClick: () => setRender(RenderEnum.HIRING_SANZA),
        active: render === RenderEnum.HIRING_SANZA,
      },
      type: getTypeHiringSanza(status, hiringDisabled),
    },
    {
      options: {
        text: t("projects:Crear subasta"),
        onClick: () => setRender(RenderEnum.CREATE_AUCTION),
        active: render === RenderEnum.CREATE_AUCTION,
      },
      type: getTypeCreateAuction(status, hiringDisabled, createAuctionDisabled),
    },
    {
      options: {
        text: t("projects:Subasta"),
        onClick: () => setRender(RenderEnum.AUCTION),
        active: render === RenderEnum.AUCTION,
      },
      type: getTypeAuction(
        hiringDisabled,
        createAuctionDisabled,
        auctionDisabled,
        auctionStatus
      ),
    },
  ];
};

export const TABS_BUYER = (
  t: any,
  render: RENDER,
  setRender: (type: RENDER) => void,
  auctionStatus: AuctionStatusEnum | undefined
): ITab[] => {
  return [
    {
      options: {
        text: t("projects:Info. Proyecto"),
        onClick: () => setRender(RenderEnum.INFO_PROJECT),
        active: render === RenderEnum.INFO_PROJECT,
      },
      type: "approved",
    },
    {
      options: {
        text: t("projects:Subasta"),
        onClick: () => setRender(RenderEnum.AUCTION),
        active: render === RenderEnum.AUCTION,
      },
      type: getTypeAuction(false, false, false, auctionStatus),
    },
    {
      options: {
        text: t("projects:Proyecto en desarrollo"),
        onClick: () => setRender(RenderEnum.PROJECT_DEVELOPMENT),
        active: render === RenderEnum.PROJECT_DEVELOPMENT,
      },
      type: "pending",
    },
    {
      options: {
        text: t("projects:PPA Vigor"),
        onClick: () => setRender(RenderEnum.PPA),
        active: render === RenderEnum.PPA,
      },
      type: "pending",
    },
  ];
};
