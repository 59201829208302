import { TFunction } from "i18next";

export const REQUEST_BUYER_TYPES = (t: TFunction) => {
  return {
    register: t("common:Registro"),
    qualification: t("common:Validación Sanza"),
    identity: t("common:Alta de Apoderados"),
  };
};

export const REQUEST_GENERATOR_TYPES = (t: TFunction) => {
  return {
    register: t("common:Registro"),
    project: t("common:Alta proyecto"),
    auction: t("common:Crear subasta"),
  };
};

export const REQUEST_TYPES = (t: TFunction) => {
  return {
    ...REQUEST_BUYER_TYPES(t),
    ...REQUEST_GENERATOR_TYPES(t),
  };
};
