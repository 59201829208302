import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { icons } from "../../../assets/icons/icons";
import {
  CONTACT_EMAIL,
  LINKEDIN_URL,
  PRIVATE_CONTACT_EMAIL,
  TWITTER_UL,
} from "../../../constants/contact";
import {LEGAL_LINKS_REGISTER} from "../../../constants/legal-links";
import { routes } from "../../../routes/routes";
import { authSelector } from "../../../store/auth/selectors";
import Button from "../../button/button";
import { FooterStyle } from "./footer-style";

interface IFooterProps {
  inAuth?: boolean;
}

const Footer: React.FC<IFooterProps> = ({ inAuth }) => {
  const { t, i18n } = useTranslation("footer");
  const isAuth = useSelector(authSelector.isAuth());

  const changeLanguage = (lng: "es" | "en") => {
    localStorage.setItem("language", i18n.language);
    i18n.changeLanguage(lng);
  };

  return (
    <FooterStyle>
      <div id="footer-addon" />
      <div className="footer-links">
        <Button
          className="footer-links__item"
          type="ghost"
          text={t("footer:Contacto")}
          onClick={() =>
            window.open(
              isAuth
                ? `mailto:${PRIVATE_CONTACT_EMAIL}`
                : `mailto:${CONTACT_EMAIL}`
            )
          }
        />
        <Button
          className="footer-links__item"
          type="ghost"
          text={t("footer:Política de Privacidad")}
          onClick={() => {
            localStorage.setItem("language", i18n.language);
            window.open(routes.privacy);
          }}
        />
        <Button
          className="footer-links__item"
          type="ghost"
          text={t("footer:Política de Cookies")}
          onClick={() => {
            localStorage.setItem("language", i18n.language);
            window.open(routes.cookies);
          }}
        />
        <Button
          className="footer-links__item"
          type="ghost"
          text={t("footer:Términos de uso")}
          onClick={() => {
            window.open(LEGAL_LINKS_REGISTER[i18n.language]);
          }}
        />
      </div>
      <div className="footer-spacer" />
      <div className="footer-other">
        {inAuth && i18n.language === "es" && (
          <Button
            type="ghost"
            text="EN"
            onClick={() => changeLanguage("en")}
            className="language-btn"
          />
        )}
        {inAuth && i18n.language === "en" && (
          <Button
            type="ghost"
            text="ES"
            onClick={() => changeLanguage("es")}
            className="language-btn"
          />
        )}
        {/* <Button
          className="footer-other__item"
          type="icon-secondary"
          icon={icons.instagram}
          onClick={() => window.open(INSTAGRAM_URL)}
        /> */}
        <Button
          className="footer-other__item"
          type="icon-secondary"
          icon={icons.linkedin}
          onClick={() => window.open(LINKEDIN_URL)}
        />
        <Button
          className="footer-other__item"
          type="icon-secondary"
          icon={icons.twitter}
          onClick={() => window.open(TWITTER_UL)}
        />
      </div>
    </FooterStyle>
  );
};

export default Footer;
