import { css } from "styled-components";
import { colors } from "../../assets/colors/colors";

export const FormRowMixin = css`
  .form-line {
    height: 1px;
    background: ${colors["neutrals-10"]};
    width: 100%;
    margin: 16px 0px 16px 0px;
  }

  .form-row {
    display: flex;
    margin-bottom: 12px;
    .center {
      display: flex;
      align-items: center;
    }

    &:not(:last-child):not(:last-child:first-child) {
      margin-bottom: 28px;
    }

    & > * {
      flex: 1;

      &:not(:last-child) {
        margin-right: 24px;
      }
    }

    &.responsive {
      flex-flow: wrap;
      margin: -12px;

      & > * {
        flex: none;
        width: calc(33.33% - 24px);
        max-width: calc(33.33% - 24px);
        margin: 12px;

        &.full-width {
          width: calc(100% - 24px);
          max-width: calc(100% - 24px);
        }
      }
    }

    .item {
      display: inline-flex;
      align-items: center;
      transition: all 0.3s ease-in-out;
      .item-icon {
        margin-right: 4px;

        svg {
          path {
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }

    @media (max-width: 1100px) {
      &.responsive {
        flex-flow: wrap;

        & > * {
          width: calc(50% - 24px);
          max-width: calc(50% - 24px);

          &.spacer-m {
            display: none;
          }

          &.full-width {
            width: calc(50% - 24px);
            max-width: calc(50% - 24px);
          }
        }
      }
    }

    @media (max-width: 768px) {
      flex-flow: column;

      & > * {
        &:not(:last-child) {
          margin-right: 0;
          margin-bottom: 24px;
        }
      }

      &.responsive {
        flex-flow: column nowrap;

        & > * {
          width: calc(100% - 24px);
          max-width: calc(100% - 24px);

          &.spacer-s {
            display: none;
          }

          &.full-width {
            width: calc(100% - 24px);
            max-width: calc(100% - 24px);
          }
        }
      }
    }

    &.small {
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
`;
