import { TFunction } from "i18next";
import { UserRolesEnum } from "../../../../types/users/users.enums";

export enum RenderEnum {
  REGISTER = "register",
  PROFILE = "profile",
  PROFILE_ADVISER = "profile-adviser",
  PROJECTS = "projects",
  EDIT_ADVISER = "edit-adviser",
  EDIT_COMPANY = "edit-compnay",
  EDIT_REPRESENTATIVE = "edit-representative",
  EDIT_VALIDATION = "edit-validation",
  EDIT_IDENTITY = "edit-identity",
  EDIT_DOCUMENT = "edit-document",
  ADD_COMMENT = "add-comment",
  EDIT_CONTACT = "edit-contact",
  DOCUMENTS_MANAGER = "documents-manager",
  EDIT_DOCUMENTS_MANAGER = "edit-documents-manager",
}
export type RENDER =
  | RenderEnum.REGISTER
  | RenderEnum.PROJECTS
  | RenderEnum.PROFILE_ADVISER
  | RenderEnum.PROFILE
  | RenderEnum.EDIT_ADVISER
  | RenderEnum.EDIT_COMPANY
  | RenderEnum.EDIT_REPRESENTATIVE
  | RenderEnum.EDIT_IDENTITY
  | RenderEnum.EDIT_VALIDATION
  | RenderEnum.EDIT_DOCUMENT
  | RenderEnum.ADD_COMMENT
  | RenderEnum.EDIT_CONTACT
  | RenderEnum.DOCUMENTS_MANAGER
  | RenderEnum.EDIT_DOCUMENTS_MANAGER
  | null;
export type RENDER_MODAL = "change-status" | "save-changes" | "";
export type BUYER_STATUS = "approved" | "rejected" | "on_hold";

export const getEditModeTitles = (t: TFunction) => ({
  validation: t("users:Validación Sanza"),
  verify: t("users:Alta de Apoderados"),
  newDoc: t("users:Documentos y solicitudes de información"),
  company: t("users:Datos empresa comprador"),
  representative: t(
    "users:Datos de representante del Comprador dentro de Sanza"
  ),
});

export const TABS_MANAGER = (
  t: TFunction,
  role: UserRolesEnum | "",
  render: RENDER,
  setRender: (type: RENDER) => void
) => {
  if (role === UserRolesEnum.ADVISER) {
    return [];
  }
  const tabs = [
    {
      options: {
        text: t("users:Registro"),
        onClick: () => setRender(RenderEnum.REGISTER),
        active: render === RenderEnum.REGISTER,
      },
    },
    {
      options: {
        text: t("users:Mi Perfil"),
        onClick: () => setRender(RenderEnum.PROFILE),
        active: render === RenderEnum.PROFILE,
      },
    },
    {
      options: {
        text: t("users:Proyectos"),
        onClick: () => setRender(RenderEnum.PROJECTS),
        active: render === RenderEnum.PROJECTS,
      },
    },
  ];
  if (role === UserRolesEnum.GENERATOR) {
    tabs.splice(1, 1);
    tabs[0].options.text = t("users:Registro / Mi perfil");
  }
  return tabs;
};

export const TABS_ADVISER = (
  t: TFunction,
  role: UserRolesEnum | "",
  render: RENDER,
  setRender: (type: RENDER) => void
) => {
  const tabs = [
    {
      options: {
        text: t("users:Mi Perfil"),
        onClick: () => setRender(RenderEnum.PROFILE),
        active: render === RenderEnum.PROFILE,
      },
    },
    {
      options: {
        text: t("users:Proyectos"),
        onClick: () => setRender(RenderEnum.PROJECTS),
        active: render === RenderEnum.PROJECTS,
      },
    },
  ];
  if (role === UserRolesEnum.GENERATOR) {
    tabs[0].options.onClick = () => setRender(RenderEnum.REGISTER);
    tabs[0].options.active = render === RenderEnum.REGISTER;
  }
  return tabs;
};
