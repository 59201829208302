import { DocumentEntity } from "../../types/documents/document.entity";
import {
  IDENTITY_GENERATOR,
  IDENTITY_GUARANTOR,
} from "../../types/identities/identity-constants";
import { IdentityEntity } from "../../types/identities/identity.entity";
import { QualificationEntity } from "../../types/qualifications/qualification.entity";
import { UserEntity } from "../../types/users/users.entities";
import { IBaseAction } from "../base-action.interface";

export enum UsersActions {
  SET_USER = "SET_USER",
  SET_QUALIFICATION = "SET_QUALIFICATION",
  SET_IDENTITIES = "SET_IDENTITIES",
  SET_DOCUMENTS = "SET_DOCUMENTS",
  SET_MULTIPOINT = "SET_MULTIPOINT",
  CLEAN_QUALIFICATION = "CLEAN_QUALIFICATION",
  CLEAN_IDENTITIES = "CLEAN_IDENTITIES",
  CLEAN_DOCUMENTS = "CLEAN_DOCUMENTS",
  CLEAN_USER = "CLEAN_USER",
  CLEAN_MULTIPOINT = "CLEAN_MULTIPOINT",
}

export interface UsersState {
  readonly user: UserEntity | null;
  readonly qualification: QualificationEntity | null;
  readonly identities: {
    [IDENTITY_GENERATOR]: IdentityEntity;
    [IDENTITY_GUARANTOR]: IdentityEntity;
  } | null;
  readonly documents: DocumentEntity[] | null;
  readonly multipoint: any;
}

interface SetUser extends IBaseAction {
  type: UsersActions.SET_USER;
  payload: UserEntity | null;
}

interface CleanUser extends IBaseAction {
  type: UsersActions.CLEAN_USER;
}

interface SetQualification extends IBaseAction {
  type: UsersActions.SET_QUALIFICATION;
  payload: QualificationEntity | null;
}

interface CleanQualification extends IBaseAction {
  type: UsersActions.CLEAN_QUALIFICATION;
}

interface SetIdentities extends IBaseAction {
  type: UsersActions.SET_IDENTITIES;
  payload: any | null;
}

interface CleanIdentities extends IBaseAction {
  type: UsersActions.CLEAN_IDENTITIES;
}

interface SetDocuments extends IBaseAction {
  type: UsersActions.SET_DOCUMENTS;
  payload: DocumentEntity[] | null;
}

interface CleanDocuments extends IBaseAction {
  type: UsersActions.CLEAN_DOCUMENTS;
}

interface SetMultiPoint extends IBaseAction {
  type: UsersActions.SET_MULTIPOINT;
  payload: any;
}

interface CleMultiPoint extends IBaseAction {
  type: UsersActions.CLEAN_MULTIPOINT;
}

export type UsersActionsTypes =
  | SetUser
  | CleanUser
  | SetQualification
  | CleanQualification
  | SetIdentities
  | CleanIdentities
  | SetDocuments
  | CleanDocuments
  | SetMultiPoint
  | CleMultiPoint;
