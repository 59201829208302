import classnames from "classnames";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/button/button";
import Modal from "../../../../components/modal/modal";
import useTranslate from "../../../../hooks/useTranslate";
import { CardNotificationDetailStyle } from "./card-notification-detail-style";

// interface IDetailProps {
//   fromSanza?: boolean;
//   to?: string | ReactNode;
//   title?: string | ReactNode;
//   message?: string | ReactNode;
//   extra?: string[];
//   onClick?: () => void;
// }

interface ICardNotificationDetailProps {
  active: boolean;
  notification: any;
  onClose: () => void;
  onClickDetail?: () => void;
  className?: string;
}

const CardNotificationDetail: React.FC<ICardNotificationDetailProps> = ({
  active,
  onClose,
  onClickDetail,
  notification,
  className,
}) => {
  const { t, i18n } = useTranslation("notifications");
  const { getCopyLanguage } = useTranslate(i18n);

  return (
    <Modal active={active} onClose={onClose}>
      <CardNotificationDetailStyle
        className={classnames(className, {
          "to-user": !!notification.user,
        })}
      >
        <div className="card-notification-detail">
          <div className="card-notification-detail__to">
            <p>
              {`${t("notifications:De")}:`}{" "}
              <span>{getCopyLanguage(notification.sender)}</span>
            </p>
          </div>

          {notification.title && (
            <div className="card-notification-detail__title">
              <p>{getCopyLanguage(notification.title)}</p>
            </div>
          )}
          {notification.message && (
            <div className="card-notification-detail__message">
              <p>{getCopyLanguage(notification.message)}</p>
            </div>
          )}
          {notification?.extra?.length && (
            <div className="card-notification-detail-container">
              {notification.extra.map(
                (item: string, index: number): ReactElement => (
                  <div
                    key={`detail-extra-${index}`}
                    className="card-notification-detail__extra"
                  >
                    <p>{item}</p>
                  </div>
                )
              )}
            </div>
          )}
          {onClickDetail && (
            <div className="card-notification-detail__more">
              <Button
                type="ghost"
                onClick={() => onClickDetail()}
                text={t("notifications:Ver más detalles")}
              />
            </div>
          )}
        </div>
      </CardNotificationDetailStyle>
    </Modal>
  );
};

export default CardNotificationDetail;
