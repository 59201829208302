import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { CONTACT_EMAIL } from "../../../constants/contact";
import useReactGa from "../../../hooks/useReactGa";
import { PrivacyStyle } from "./privacy-style";

const Privacy: React.FC<any> = (props: any) => {
  const { t } = useTranslation("legal");
  useReactGa(window.location.pathname, "Privacidad");

  return (
    <PrivacyStyle>
      <Helmet>
        <title>{t("common:app-title")} - Privacidad</title>
      </Helmet>
      <main className="main-container__content">
        <div className="privacy-title">
          <p style={{ textAlign: "center" }}>
            {t("legal:Política de Privacidad")}
          </p>
        </div>
        <div className="privacy-text">
          <p style={{ textAlign: "center" }}>{t("legal:PORTAL SANZA")}</p>
          <p>
            <span>
              {t(
                "legal:El tratamiento de datos personales que en su caso faciliten los Usuario a través de"
              )}
            </span>
            <a href="http://www.sanzaenergy.com">
              <span>www.sanzaenergy.com</span>
            </a>
            <span>
              {t(
                "legal:, de esta página de internet o de cualquier otro Website de Sanza Renewables, S.L. (en adelante, el “Portal”) o de cualquiera de sus filiales, será regulado por lo dispuesto en esta política de Privacidad (en adelante la “Política” o la “Política de Privacidad”)"
              )}
              .
            </span>
          </p>
          <p>
            <span>
              {t(
                "legal:Por favor, lea detenidamente la presente Política de Privacidad que es parte integrante de los"
              )}
            </span>
            <b> {t("legal:Términos de Uso,")}</b>
            <span> {t("legal:accesibles desde el Portal.")}</span>
          </p>
          <h4>
            <span>{t("legal:¿Quién tratará sus datos personales?")}</span>
          </h4>
          <p>
            <span>
              {t(
                "legal:El responsable del tratamiento es Sanza Renewables, S.L., (“Sanza”)."
              )}
            </span>
          </p>
          <h4>
            <span>{t("legal:¿Para qué se tratarán sus datos y por qué?")}</span>
          </h4>
          <p>
            <span>
              {t(
                "legal:Los datos de carácter personal que en su caso proporcione el Usuario serán utilizados para las siguientes finalidades"
              )}
              :
            </span>
          </p>
          <ul>
            <li>
              <span>
                {t("legal:Permitir la navegación a través del Portal.")}
              </span>
            </li>
            <li>
              <span>{t("legal:En caso de que acepte nuestra")} </span>
              <b>{t("legal:Política de Cookies")}</b>
              <span>
                :
                {t(
                  "legal:Analizar su navegación para optimizar la estructura y diseño del Portal (p.ej. ver qué espacios de la web son más visitados, cuál es el tiempo de estancia medio…) y realizar publicidad comportamental y/o personalizada."
                )}
              </span>
            </li>
            <li>
              <span>
                {t(
                  "legal:En caso de que lo acepte el Usuario, enviarle información relacionada con Sanza y sus servicios."
                )}
              </span>
            </li>
          </ul>
          <h4>
            <span>{t("legal:¿Qué datos serán tratados?")}</span>
          </h4>
          <p>
            <b>{t("legal:Sanza")} </b>
            <span>
              {t(
                "legal:tratará los datos personales que obtenga a través de su navegación por nuestra página web, incluyendo"
              )}
              :
            </span>
          </p>
          <ol>
            <li>
              <span>
                {t(
                  "legal:Datos facilitados de forma directa por el Usuario (ciertas funcionalidades de nuestra página web exigen que facilite información a través del correspondiente formulario tales como su nombre y apellidos y dirección de correo electrónico y/o teléfono de contacto y empresa a la que pertenece),"
                )}
              </span>
            </li>
            <li>
              <span>
                {t(
                  "legal:Datos que nuestros servidores web almacenan de forma estándar, incluyendo la dirección IP y el dominio desde el que se obtiene el acceso, la fecha y la hora de la visita, etc..Salvo que expresamente se indique lo contrario, todos los campos recogidos en el formulario serán obligatorios, por lo que la falta de información impedirá la tramitación de su solicitud."
                )}
              </span>
            </li>
            <li>
              <span>
                {t(
                  "legal:Datos facilitados de forma indirecta por el Usuario. A través de su navegación, pueden instalarse en su dispositivo distintas cookies de conformidad con lo establecido en nuestra"
                )}
              </span>
              <b>{t("legal:Política de Cookies.")}</b>
            </li>
          </ol>
          <h4>
            <span>{t("legal:¿Se comunicarán sus datos a terceros?")}</span>
          </h4>
          <p>
            <span>
              {t(
                "legal:Con carácter general no se prevé que sus datos sean comunicados a otras personas distintas de nuestros colaboradores expresamente identificados en el Portal para la prestación u oferta de los servicios que dichos colaboradores ofrecen a través del Portal. Además, de manera excepcional para cumplir con la ley Sanza"
              )}
            </span>
            <span>
              {t(
                "legal:puede asimismo verse obligada a tener que comunicar sus datos a otros terceros como Administraciones Públicas o tribunales."
              )}
            </span>
          </p>
          <h4>
            <span>
              {t("legal:¿Por cuánto tiempo serán tratados sus datos?")}
            </span>
          </h4>
          <p>
            <span>
              {t(
                "legal:Los datos personales serán conservados exclusivamente durante el plazo necesario para permitir su navegación por la página web, analizar su navegación (en caso de aceptación de la política de cookies), tramitar sus solicitudes de servicio a través de la página web o prestar servicios solicitados a través del Portal (incluyendo, cuando así lo hubiese solicitado el Usuario, la tramitación, alta y mantenimiento de un perfil de usuario registrado en el Portal)."
              )}
            </span>
          </p>
          <p>
            <span>
              {t(
                "legal:En el momento en que dejen de ser necesarios, los datos serán suprimidos conforme a lo dispuesto en la normativa de protección de datos lo que implica su bloqueo, estando disponibles tan solo a solicitud de Jueces y Tribunales, el Ministerio Fiscal o las Administraciones Públicas competentes durante el plazo de prescripción de las acciones que pudieran derivar y, transcurrido el período de bloqueo, su completa eliminación. A efectos ejemplificativos, en los casos más habituales se estará al plazo de prescripción de 3 años derivado de la normativa de protección de datos."
              )}
            </span>
          </p>
          <p>
            <span>
              {t(
                "legal:En cualquier caso, si al finalizar la relación contractual existieran litigios pendientes, los datos podrán conservarse durante su tramitación procesal y exclusivamente con fines probatorios, en tanto no recaiga resolución definitiva, momento en el cual se procederá a su bloqueo y posterior borrado."
              )}
            </span>
          </p>
          <h4>
            <span>{t("legal:¿Cuáles son sus derechos?")}</span>
          </h4>
          <p>
            <span>
              {t(
                "legal:En la medida en que estén reconocidos en la normativa de protección de datos aplicable en cada momento, el Usuario podrá ejercer los siguientes derechos en relación con el tratamiento de sus datos"
              )}
              :
            </span>
          </p>
          <p>
            <span>– </span>
            <b>{t("legal:Derecho de acceso")}</b>
            <span>
              :{" "}
              {t(
                "legal:si ejerce este derecho, podrá conocer qué tipo de datos estamos tratando y las características del tratamiento que estamos llevando a cabo."
              )}
            </span>
          </p>
          <p>
            <span>– </span>
            <b>{t("legal:Derecho de rectificación")}</b>
            <span>
              :{" "}
              {t(
                "legal:si ejerce este derecho, podrá solicitar la modificación de sus datos por ser éstos inexactos o no veraces."
              )}
            </span>
          </p>
          <p>
            <span>– </span>
            <b>{"legal:Derecho de portabilidad"}</b>
            <span>
              :{" "}
              {t(
                "legal:si ejerce este derecho, podrá obtener una copia en un formato interoperable de los datos que estén siendo tratados."
              )}
            </span>
          </p>
          <p>
            <span>– </span>
            <b>
              {t("legal:Derecho a la limitación en el tratamiento de datos")}
            </b>
            <span>
              :{" "}
              {t(
                "legal:si ejerce este derecho, podrá limitar el tratamiento de sus datos en los casos recogidos en la Ley."
              )}
            </span>
          </p>
          <p>
            <span>– </span>
            <b>{t("legal:Derecho de oposición")}</b>
            <span>
              :{" "}
              {t(
                "legal:si ejerce este derecho, podrá oponerse al tratamiento de sus datos y solicitar el cese en el envío de comunicaciones comerciales."
              )}
            </span>
          </p>
          <p>
            <span>– </span>
            <b>{t("legal:Derecho de supresión")}</b>
            <span>
              :{" "}
              {t(
                "legal:si ejerce este derecho, podrá solicitar la supresión de sus datos cuando el tratamiento ya no resulte necesario."
              )}
            </span>
          </p>
          <p>
            <span>– </span>
            <b>{t("legal:Derecho a revocar el consentimiento prestado")}</b>
            <span>.</span>
          </p>
          <p>
            <span>
              {t(
                "legal:Puede ejercitar sus derechos a través de alguno de los siguientes canales, indicando el derecho a ejercitar y acompañando copia del Documento Nacional de Identidad o documento equivalente además de cualquier otra documentación que considere oportuna"
              )}
              :
            </span>
          </p>
          <ol>
            <li>
              <span>
                {t("legal:Dirección Postal")}:{" "}
                {t(
                  "legal:Calle Serrano número 6, planta 2 puerta 3, 28001 Madrid"
                )}
              </span>
            </li>
            <li>
              <span>
                {t("legal:Correo Electrónico")}:{CONTACT_EMAIL}
              </span>
            </li>
          </ol>
          <p>
            <span>
              {t(
                "legal:En la página web de la Agencia Española de Protección de Datos (AEPD) puede encontrar una serie de modelos que le ayudarán en el ejercicio de sus derechos. Asimismo, le informamos tiene derecho a interponer una reclamación ante la autoridad de control (en España, la AEPD) en caso de que considere infringidos sus derechos."
              )}
            </span>
          </p>
        </div>
      </main>
    </PrivacyStyle>
  );
};

export default Privacy;
