import produce from "immer";
import { LoadingActions, LoadingActionsTypes, LoadingState } from "./types";

const query = new URLSearchParams(window.location.search);

export const initialState: LoadingState = {
  loading: !!localStorage.getItem("jwt") && !query.get("token") ? [true] : [],
};

const reducer = (
  state: LoadingState = initialState,
  action: LoadingActionsTypes
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LoadingActions.LOADING:
        draft.loading.push(true);
        break;

      case LoadingActions.LOADED:
        draft.loading.pop();
        break;

      default:
    }
  });

export default reducer;
