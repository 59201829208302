import { NotificationsState } from "./types";

export const notificationsSelector = {
  getNotifciations: () => (state: { notifications: NotificationsState }) =>
    state.notifications.notifications,
  getRequests: () => (state: { notifications: NotificationsState }) =>
    state.notifications.requests,
  getNotifciationsTotal: () => (state: { notifications: NotificationsState }) =>
    state.notifications.notificationsTotal,
  getRequestsTotal: () => (state: { notifications: NotificationsState }) =>
    state.notifications.requestsTotal,
  getNotifciationsHeaders: () => (state: {
    notifications: NotificationsState;
  }) => state.notifications.notificationsHeader,
  getRequestsHeaders: () => (state: { notifications: NotificationsState }) =>
    state.notifications.requestsHeader,
};
