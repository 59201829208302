import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { colors } from "../../../../assets/colors/colors";
import { icons } from "../../../../assets/icons/icons";
import SVGIcon from "../../../../components/svg-icon/svg-icon";
import useTranslate from "../../../../hooks/useTranslate";
import { routes } from "../../../../routes/routes";
import { RenderEnum } from "../../projects/constants-project/project-detail.constants";
import CardNotificationDetail from "../card-notification-detail/card-notification-detail";
import { CardNotificationStyle } from "./card-notification-style";

interface ICardNotificationProps {
  notification: any;
  className?: string;
  markedReadNotification: any;
  isHeader?: boolean;
  closeNotification?: () => void;
}

const CardNotification: React.FC<ICardNotificationProps> = ({
  notification,
  className,
  markedReadNotification,
  isHeader,
  closeNotification,
}) => {
  const { t, i18n } = useTranslation("notifications");
  const [renderModal, setRenderModal] = useState("");

  const { getCopyLanguage } = useTranslate(i18n);
  const history = useHistory();
  return (
    <>
      <CardNotificationStyle
        className={className}
        onClick={(ev: any) => {
          ev.preventDefault();
          if (!notification.wasRead) {
            markedReadNotification(notification.id, isHeader);
          }
          setRenderModal("notification-detail");
        }}
      >
        <div className="card-notification">
          <div className="card-notification__date">
            <div className="card-notification__date__icon">
              <SVGIcon
                icon={icons.inboxLine}
                size="20px"
                color={
                  !notification.wasRead
                    ? colors["brand-1-100"]
                    : colors["neutrals-50"]
                }
              />
            </div>
            <div className="card-notification__date__text">
              <p>{moment(notification.createdAt).format("LLL")}</p>
            </div>
          </div>
          <div className="card-notification__text">
            <p>
              {t("notifications:De")}:{" "}
              <span>{notification.sender || "TODO: NO back"}</span>
            </p>
          </div>
          {notification.user ? (
            <div className="card-notification__text">
              <p>
                {t("notifications:A")}:{" "}
                <span>{`${notification.user.name} ${notification.user.lastname}`}</span>
              </p>
            </div>
          ) : undefined}
          <div className="card-notification__text">
            <p>
              {t("notifications:Asunto")}:{" "}
              <span>{getCopyLanguage(notification.title)}</span>
            </p>
          </div>
          {notification.message && (
            <div className="card-notification__detail">
              <p>
                {t("notifications:Detalle")}:{" "}
                <span>{getCopyLanguage(notification.message)}</span>
              </p>
            </div>
          )}
        </div>
      </CardNotificationStyle>
      <CardNotificationDetail
        onClose={() => setRenderModal("")}
        onClickDetail={
          notification?.projectId
            ? () => {
                setRenderModal("");
                if (closeNotification) {
                  closeNotification();
                }
                history.push(routes.editProject(notification?.projectId), {
                  render: RenderEnum.AUCTION,
                });
              }
            : undefined
        }
        active={renderModal === "notification-detail"}
        notification={notification}
      />
    </>
  );
};

export default CardNotification;
