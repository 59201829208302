import styled, { css } from "styled-components";

interface ITypography {
  weight?: 300 | 400 | 500;
  color?: string;
}

export const ButtonMixin = css`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  /* text-transform: uppercase; */
`;

export const ButtonText = styled.p<ITypography>`
  ${ButtonMixin};
`;

export const ButtonSmallMixin = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  /* text-transform: capitalize; */
`;

export const ButtonSmallText = styled.p<ITypography>`
  ${ButtonSmallMixin};
`;

export const BreadcrumbMixin = css`
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

export const BreadcrumbText = styled.p<ITypography>`
  ${BreadcrumbMixin};
`;

export const LinkMixin = css`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-decoration-line: underline;
  /* text-transform: capitalize; */
`;

export const LinkText = styled.p<ITypography>`
  ${LinkMixin};
`;

export const TagMixin = css`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  /* text-transform: capitalize; */
`;

export const TagText = styled.p<ITypography>`
  ${TagMixin};
`;