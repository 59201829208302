import { Dispatch } from 'redux';

import { LoadingActions, LoadingActionsTypes } from './types'

const loading = () => async (dispatch: Dispatch<LoadingActionsTypes>) => {
  dispatch({ type: LoadingActions.LOADING });
};

const loaded = () => async (dispatch: Dispatch<LoadingActionsTypes>) => {
  dispatch({ type: LoadingActions.LOADED });
};

export const loaderActions = {
  loading,
  loaded,
};
