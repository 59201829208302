export const TECHNICAL_INFORMATION_NAME = "technicalInformationName";
export const TECHNICAL_INFORMATION_LOCATION = "technicalInformationLocation";
export const TECHNICAL_INFORMATION_TECHNOLOGY =
  "technicalInformationTechnology";
export const TECHNICAL_INFORMATION_INSTALLED_POWER =
  "technicalInformationInstalledPower";
export const TECHNICAL_INFORMATION_PROJECT_SOCIETY =
  "technicalInformationProjectSociety";
export const TECHNICAL_INFORMATION_ESTIMATED_TOTAL_ANNUAL_PRODUCTION =
  "technicalInformationEstimatedTotalAnnualProduction";
export const TECHNICAL_INFORMATION_PROJECT_STATUS =
  "technicalInformationProjectStatus";
export const TECHNICAL_INFORMATION_ESTIMATED_PRODUCTION_START_DATE =
  "technicalInformationEstimatedProductionStartDate";
export const TECHNICAL_INFORMATION_REPORT_SANZA = "technicalReportSanza";
export const FINANCIAL_INFORMATION_PARENT_COMPANY =
  "financialInformationParentCompany";
export const FINANCIAL_INFORMATION_SPONSOR_COMPANY =
  "financialInformationSponsorCompany";
export const FINANCIAL_INFORMATION_CURRENT_RENEWABLE_GENERATION_PORTFOLIO =
  "financialInformationCurrentRenewableGenerationPortfolio";
export const FINANCIAL_INFORMATION_CURRENT_RENEWABLE_GENERATION_PORTFOLIO_ID =
  "financialInformationCurrentRenewableGenerationPortfolioId";
export const FINANCIAL_INFORMATION_INSTALLED_POWER =
  "financialInformationInstalledPower";
export const FINANCIAL_INFORMATION_TOTAL_ANNUAL_PRODUCTION =
  "financialInformationTotalAnnualProduction";
export const DOCUMENTS = "documents";
export const STATUS = "status";
export const NEW_STATUS = "newStatus";
export const USER = "user";
export const CONTRACTUAL_DOCUMENTS = "contractualDocuments";

// TODO: BACKEND
export const PROJECT_USER_GENERATOR = "generator";
