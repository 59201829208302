import { TFunction } from "i18next";
import { routes } from "../../routes/routes";
import { closeGA, initializedGA } from "../../utils/analytics";

export const MODAL_COOKIES_TEXT = (t: TFunction) => ({
  cookiesText: t(
    "cookies:En Sanza utilizamos cookies propias y de terceros para analizar nuestros servicios y mostrarte contenido relacionado con tus preferencias. Puedes configurar su uso y rechazar "
  ),
  cookiesText2: t(
    "cookies:. Para saber más, consulta nuestra "
  ),
  cookiesButtonConfig: {
    text: t("cookies: aquí"),
    type: "ghost ghost-cookies",
    onClick: () => window.open(routes.cookies),
  },
  cookiesButtonText: {
    text: t("cookies:Política de Cookies."),
    type: "ghost ghost-cookies",
    onClick: () => window.open(routes.cookies),
  },
  cookiesButtonTextSettings: t("cookies:Opciones"),
  cookiesButtonTextAccept: t("cookies:Aceptar"),
  titleSettings: t("cookies:¡Bienvenidos!"),
  textSettings: [
    t(
      "cookies:Este sitio web utiliza cookies para mejorar tu experiencia mientras navegas por él. Las cookies que clasificamos como “necesarias” se almacenan en su navegador por el mero hecho de visitar nuestro sitio web, ya que son esenciales para el funcionamiento básico del sitio web. También utilizamos otros tipos de cookies (analíticas, de publicidad,...), que clasificamos como “no necesarias” que nos ayudan a comprender cómo utilizas este sitio web y mejorar tu experiencia. Puedes conceder o denegar  tu consentimiento para las “no necesarias” en el menú de configuración a continuación."
    ),
  ],
  titleOptions: t("cookies:USTED PERMITE"),
  optionsItems: [
    {
      text: t("cookies:Necesarias"),
      cookieKey: t("cookies:functional"),
      options: [
        t(
          "cookies:Las cookies necesarias son absolutamente esenciales para que el sitio web funcione correctamente. Estas cookies no almacenan ninguna información personal."
        ),
      ],
      // value: true,
      required: true,
    },
    {
      text: t("cookies:No Necesarias"),
      cookieKey: "acceptAnalytics",
      options: [
        t(
          "cookies:Las cookies que pueden no ser particularmente necesarias para el funcionamiento del sitio web y que se utilizan específicamente para recopilar datos personales del usuario a través de análisis, anuncios y otros contenidos integrados se denominan cookies no necesarias."
        ),
      ],
      // value: false,
      required: false,
      setCookies: initializedGA,
      clearCookies: closeGA,
    },
  ],
  optionRequiredText: t("cookies:Siempre activado"),
  optionAcceptText: t("cookies:Aceptado"),
  optionRejectText: t("cookies:Rechazado"),
  buttonAccept: t("cookies:Aceptar y guardar"),
  buttonCancel: t("cookies:Cancelar"),
  textFinal: t(
    "cookies:De conformidad con el artículo 22 de la Ley de Servicios de la Sociedad de la Información y Comercio Electrónico así como en el Considerando (30) RGPD la presente Página Web recaba cookies tanto propias como de terceros con diversas finalidades, siendo la principal la personalización de la experiencia de nuestros usuarios."
  ),
});
