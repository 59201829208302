import styled from 'styled-components';
import { colors } from '../../assets/colors/colors';
import { BodySmallMixin } from '../text-components/body-text';

export const ToggleStyle = styled.div`
  .toggle-container {
    display: flex;
    position: relative;
    cursor: pointer;
    height: auto;
    align-items: center;
    transition: 0.3s ease-in-out all;
    width: max-content;
  }

  input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;

    /* Tambien se define en active */
    &:checked ~ .toggle-item {
    }
  }

  .toggle-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 24px;
    border-radius: 16px;
    transition: 0.3s ease-in-out all;
    position: relative;
    background: ${colors['neutrals-10']};
  }

  .toggle-item-check {
    position: absolute;
    width: 28px;
    height: 28px;
    top: -2px;
    left: 0px;
    border-radius: 100%;
    background: ${colors['neutrals-30']};
    transition: 0.3s all ease-in-out;
  }

  .toggle-label {
    ${BodySmallMixin};
    margin-right: 8px;
    color: ${colors['neutrals-50']};
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.6;

    .toggle-label {
      color: #F4A3A3;
    }

    .toggle-item-check {
      background: ${colors['neutrals-10']};
    }

    .toggle-item {
      background: #F8F8F8;
    }
  }

  &.active {
    &.disabled {
      .toggle-item-check {
        background: ${colors['success-100']};
      }
    }
    .toggle-container {
      .toggle-item {
        background: ${colors['success-30']};

        .toggle-item-check {
          background: ${colors['success-100']};
          left: calc(100% - 28px);
        }
      }
    }
  }

  &.error {
    .toggle-label {
      color: #ff0000;
    }
    .toggle-item {
    }
  }
`;
