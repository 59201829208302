import {
  EstimatedDatePpaEnum,
  InstalledPowerPpaEnum,
  ValueAsAConsumerCompanyEnum,
} from "../../types/users/users.enums";

interface IObject {
  [key: string]: string;
}

interface ISelectItem {
  [key: string]: any;
  value: string;
  label: string;
  icon?: any;
}

export const getInstalledPowerPPAObject = (t: any) => {
  const INSTALLATED_POWER_PPA: IObject = {
    [InstalledPowerPpaEnum.ZERO_TO_FIFTY]: t("users:De 0 a 50 MW"),
    [InstalledPowerPpaEnum.FIFTY_TO_TWO_HUNDRED]: t("users:De 50 a 200 MW"),
    [InstalledPowerPpaEnum.TWO_HUNDRED_TO_FIVE_HUNDRED]: t(
      "users:De 200 a 500 MW"
    ),
    [InstalledPowerPpaEnum.MORE_THAN_FIVE_HUNDRED]: t("users:Más de 500 MW"),
  };

  return INSTALLATED_POWER_PPA;
};

export const getInstalledPowerPPAArray = (t: any) => {
  const INSTALLATED_POWER_PPA: ISelectItem[] = [
    {
      value: InstalledPowerPpaEnum.ZERO_TO_FIFTY,
      label: t("users:De 0 a 50 MW"),
    },
    {
      value: InstalledPowerPpaEnum.FIFTY_TO_TWO_HUNDRED,
      label: t("users:De 50 a 200 MW"),
    },
    {
      value: InstalledPowerPpaEnum.TWO_HUNDRED_TO_FIVE_HUNDRED,
      label: t("users:De 200 a 500 MW"),
    },
    {
      value: InstalledPowerPpaEnum.MORE_THAN_FIVE_HUNDRED,
      label: t("users:Más de 500 MW"),
    },
  ];

  return INSTALLATED_POWER_PPA;
};

export const getEstimatedDatePPAObject = (t: any) => {
  const ESTIMATED_DATE_PPA: IObject = {
    [EstimatedDatePpaEnum.ONE_TO_THREE_MONTHS]: t("users:De 1 a 3 meses"),
    [EstimatedDatePpaEnum.THREE_TO_SIX_MONTHS]: t("users:De 3 a 6 meses"),
    [EstimatedDatePpaEnum.SIX_TO_TWELVE_MONTHS]: t("users:De 6 a 12 meses"),
    [EstimatedDatePpaEnum.MORE_THAN_TWELVE_MONTHS]: t("users:Mas de 12 meses"),
  };

  return ESTIMATED_DATE_PPA;
};

export const getEstimatedDatePPAArray = (t: any) => {
  const ESTIMATED_DATE_PPA: ISelectItem[] = [
    {
      value: EstimatedDatePpaEnum.ONE_TO_THREE_MONTHS,
      label: t("users:De 1 a 3 meses"),
    },
    {
      value: EstimatedDatePpaEnum.THREE_TO_SIX_MONTHS,
      label: t("users:De 3 a 6 meses"),
    },
    {
      value: EstimatedDatePpaEnum.SIX_TO_TWELVE_MONTHS,
      label: t("users:De 6 a 12 meses"),
    },
    {
      value: EstimatedDatePpaEnum.MORE_THAN_TWELVE_MONTHS,
      label: t("users:Más de 12 meses"),
    },
  ];

  return ESTIMATED_DATE_PPA;
};

export const getValueAsAConsumerCompanyObject = (t: any) => {
  const ESTIMATED_DATE_PPA: IObject = {
    [ValueAsAConsumerCompanyEnum.DIRECT]: t("users:Comprador Directo"),
    [ValueAsAConsumerCompanyEnum.GUARANTOR]: t(
      "users:Garante de otra sociedad compradora"
    ),
  };

  return ESTIMATED_DATE_PPA;
};
