import React from "react";
import Footer from "../cross/footer/footer";
import MainHeader from "../main-header/main-header";
import { MainContainerStyle } from "./main-container-style";

interface IMainContainerProps {
  children?: any;
  className?: any;
  withoutActions?: boolean;
}

const MainContainer: React.FC<IMainContainerProps> = ({
  children,
  className,
  withoutActions,
}) => {
  return (
    <MainContainerStyle className={className}>
      <MainHeader withoutActions={withoutActions} />
      <div className="main-container">
        <div className="main-container-children">
          {children}
        </div>
        <div className="footer-container">
          <Footer inAuth/>
        </div>
      </div>
    </MainContainerStyle>
  );
};

export default MainContainer;
