import { TFunction } from "i18next";
import { RenderEnum } from "../../containers/internal/projects/constants-project/project-detail.constants";

export enum ProjectStatusEnum {
  DRAFT = "draft",
  CREATION_VALIDATING = "creation_validating",
  CREATION_REJECTED = "creation_rejected",
  CREATION_NEEDS_MORE_INFO = "creation_needs_more_info",
  HIRING_PENDING = "hiring_pending",
  HIRING_REJECTED = "hiring_rejected",
  AUCTION_PENDING = "auction_pending",
  AUCTION_VALIDATING = "auction_validating",
  AUCTION_NEEDS_MORE_INFO = "auction_needs_more_info",
  AUCTION_APPROVED = "auction_approved",
  AUCTION_REJECTED = "auction_rejected",
}

export const getStatus = (t: TFunction): any => {
  return {
    [ProjectStatusEnum.DRAFT]: t("projects:Pendiente Solicitar Alta Proyecto"),
    [ProjectStatusEnum.CREATION_VALIDATING]: t(
      "projects:Sanza Analizando Alta Proyecto"
    ),
    [ProjectStatusEnum.CREATION_REJECTED]: t(
      "projects:Alta Proyecto Rechazada"
    ),
    [ProjectStatusEnum.CREATION_NEEDS_MORE_INFO]: t(
      "projects:Info adicional pendiente en Alta Proyecto"
    ),
    [ProjectStatusEnum.HIRING_PENDING]: t(
      "projects:Pendiente Contratación Sanza"
    ),
    [ProjectStatusEnum.HIRING_REJECTED]: t(
      "projects:Rechazada Contratación Sanza"
    ),
    [ProjectStatusEnum.AUCTION_PENDING]: t(
      "projects:Pendiente solicitar Crear Subasta"
    ),
    [ProjectStatusEnum.AUCTION_VALIDATING]: t(
      "projects:Sanza Analizando Crear Subasta"
    ),
    [ProjectStatusEnum.AUCTION_NEEDS_MORE_INFO]: t(
      "projects:Info adicional pendiente en Crear Subasta"
    ),
    [ProjectStatusEnum.AUCTION_APPROVED]: t("projects:Crear Subasta Aprobada"),
    [ProjectStatusEnum.AUCTION_REJECTED]: t("projects:Crear Subasta Rechazada"),
  };
};

export const getStatusOptions = (t: TFunction, render: RenderEnum) => {
  switch (render) {
    case RenderEnum.REGISTER_PROJECT:
      return [
        {
          value: ProjectStatusEnum.CREATION_VALIDATING,
          label: t("projects:Sanza Analizando"),
        },
        {
          value: ProjectStatusEnum.CREATION_REJECTED,
          label: t("projects:Rechazada"),
        },
        {
          value: ProjectStatusEnum.CREATION_NEEDS_MORE_INFO,
          label: t("projects:Mas Información"),
        },
        {
          value: ProjectStatusEnum.HIRING_PENDING,
          label: t("projects:Aceptado"),
        },
      ];
    case RenderEnum.HIRING_SANZA:
      return [
        {
          value: ProjectStatusEnum.HIRING_PENDING,
          label: t("projects:Pendiente contratar a Sanza"),
        },
        {
          value: ProjectStatusEnum.HIRING_REJECTED,
          label: t("projects:Contratación con Sanza cancelada"),
        },
        {
          value: ProjectStatusEnum.AUCTION_PENDING,
          label: t("projects:Contrato con Sanza firmado"),
        },
      ];
    case RenderEnum.CREATE_AUCTION:
      return [
        // {
        //   value: ProjectStatusEnum.AUCTION_PENDING,
        //   label: t("projects:Pendiente"),
        // },
        {
          value: ProjectStatusEnum.AUCTION_VALIDATING,
          label: t("projects:Sanza Analizando"),
        },
        {
          value: ProjectStatusEnum.AUCTION_NEEDS_MORE_INFO,
          label: t("projects:Más información"),
        },
        {
          value: ProjectStatusEnum.AUCTION_APPROVED,
          label: t("projects:Aprobada"),
        },
        {
          value: ProjectStatusEnum.AUCTION_REJECTED,
          label: t("projects:Rechazada"),
        },
      ];
    default:
      return [];
  }
};

// ----------------------------------

export enum TechnicalInformationProjectStatusEnum {
  DEVELOPMENT = "development",
  BUILDING = "building",
  OPERATION = "operation",
}

export const getTechnicalInformationProjectStatus = (t: TFunction): any => {
  return {
    [TechnicalInformationProjectStatusEnum.DEVELOPMENT]: t(
      "projects:Desarrollo"
    ),
    [TechnicalInformationProjectStatusEnum.BUILDING]: t(
      "projects:Construcción"
    ),
    [TechnicalInformationProjectStatusEnum.OPERATION]: t("projects:Operación"),
  };
};

export const getTechnicalInformationProjectStatusOptions = (
  t: TFunction
): any => {
  return [
    {
      value: TechnicalInformationProjectStatusEnum.DEVELOPMENT,
      label: t("projects:Desarrollo"),
    },
    {
      value: TechnicalInformationProjectStatusEnum.BUILDING,
      label: t("projects:Construcción"),
    },
    {
      value: TechnicalInformationProjectStatusEnum.OPERATION,
      label: t("projects:Operación"),
    },
  ];
};

// ----------------------------------

export enum TechnologiesEnum {
  PHOTOVOLTAIC_SOLAR = "photovoltaicSolar",
  ONSHORE_WIND = "onshoreWind",
  OFFSHORE_WIND = "offshoreWind",
  THERMOELECTRIC_SOLAR = "thermoelectricSolar",
  HYDROELECTRIC = "hydroelectric",
  OTHERS = "others",
}

export const getTechnologies = (t: TFunction): any => ({
  [TechnologiesEnum.PHOTOVOLTAIC_SOLAR]: t("projects:Solar Fotovoltaico"),
  [TechnologiesEnum.ONSHORE_WIND]: t("projects:Eólica Onshore"),
  [TechnologiesEnum.OFFSHORE_WIND]: t("projects:Eólica Offshore"),
  [TechnologiesEnum.THERMOELECTRIC_SOLAR]: t("projects:Solar Termoeléctrica"),
  [TechnologiesEnum.HYDROELECTRIC]: t("projects:Hidroeléctrica"),
  [TechnologiesEnum.OTHERS]: t("projects:Otros"),
});

export const getTechnologiesOptions = (t: TFunction): any => [
  {
    value: TechnologiesEnum.PHOTOVOLTAIC_SOLAR,
    label: t("projects:Solar Fotovoltaico"),
  },
  {
    value: TechnologiesEnum.ONSHORE_WIND,
    label: t("projects:Eólica Onshore"),
  },
  {
    value: TechnologiesEnum.OFFSHORE_WIND,
    label: t("projects:Eólica Offshore"),
  },
  {
    value: TechnologiesEnum.THERMOELECTRIC_SOLAR,
    label: t("projects:Solar Termoeléctrica"),
  },
  {
    value: TechnologiesEnum.HYDROELECTRIC,
    label: t("projects:Hidroeléctrica"),
  },
  { value: TechnologiesEnum.OTHERS, label: t("projects:Otros") },
];

// ----------------------------------

export enum HasGuarantorEnum {
  YES = "true",
  NO = "false",
}

export const getHasGuarantor = (t: TFunction): any => ({
  [HasGuarantorEnum.YES]: t("projects:Sí"),
  [HasGuarantorEnum.NO]: t("projects:No"),
});

export const getHasGuarantorOptions = (t: TFunction): any => [
  {
    value: HasGuarantorEnum.YES,
    label: t("projects:Sí"),
  },
  {
    value: HasGuarantorEnum.NO,
    label: t("projects:No"),
  },
];
