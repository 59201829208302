import classnames from "classnames";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { icons } from "../../assets/icons/icons";
import Button, { IButtonProps } from "../button/button";
import { ModalStyle } from "./modal-style";

export interface IModalProps {
  title?: string;
  text?: string | ReactNode;
  children?: any;
  buttons?: IButtonProps[];
  extraButtons?: IButtonProps[];
  onClose?: () => void;
  active: boolean;
  className?: string;
}

const Modal: React.FC<IModalProps> = (props: IModalProps) => {
  const {
    title,
    text,
    children,
    buttons,
    extraButtons,
    onClose,
    active,
    className,
  } = props;

  const [modalLarge, setModalLarge] = useState(false);
  const modalContentRef: any = useRef(null);

  useEffect(() => {
    document.body.style.overflow = active ? "hidden" : "visible";
  }, [active]);

  useEffect(() => {
    return function cleanup() {
      document.body.style.overflow = "visible";
    };
  }, []);

  const modalPositionListener = () => {
    if (
      modalContentRef &&
      modalContentRef.current &&
      modalContentRef.current.offsetHeight > window.innerHeight - 200
    ) {
      setModalLarge(true);
    } else setModalLarge(false);
  };

  useEffect(() => {
    modalPositionListener();
    window.addEventListener("resize", () => modalPositionListener());
    return window.removeEventListener("resize", () => modalPositionListener());
  }, []);

  return (
    <ModalStyle
      className={classnames(className, {
        large: !!modalLarge,
        active: !!active,
        "only-children": !!children && !text && !title,
      })}
    >
      <div className="modal-content" ref={modalContentRef}>
        <div
          className="modal-content-close"
          onClick={() => {
            if (onClose) {
              onClose();
            }
          }}
        >
          <Button
            className="custom-icon-color"
            iconColor="#444A51"
            type="icon-secondary"
            icon={icons.closeFill}
          />
        </div>
        <div className="modal-content-main">
          <div className="modal-content-main-body">
            {title && (
              <div className="modal-content-main-body__title">
                <p>{title}</p>
              </div>
            )}
            {text && (
              <div className="modal-content-main-body__text">
                <p>{text}</p>
              </div>
            )}

            {children && (
              <div className="modal-content-main-body__children">
                {children}
              </div>
            )}
          </div>
        </div>
        {/* Actions */}
        <div className="modal-content-bottom">
          {extraButtons && (
            <div className="modal-content-actions extra">
              {extraButtons.map((action: IButtonProps, index: number) => (
                <div
                  key={`modal-extra-action-${index}`}
                  className="modal-content-actions__item"
                >
                  <Button {...action} type="ghost" size="m" />
                </div>
              ))}
            </div>
          )}
          {buttons && (
            <div className="modal-content-actions">
              {buttons.map((action: IButtonProps, index: number) => (
                <div
                  key={`modal-action-${index}`}
                  className="modal-content-actions__item"
                >
                  <Button className="full-width" {...action} size="xl" />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </ModalStyle>
  );
};

export default Modal;
