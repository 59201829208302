import { Dispatch } from "redux";
import { AuthActions, AuthActionsTypes } from "./types";

const logging = (user: any) => async (dispatch: Dispatch<AuthActionsTypes>) =>
  dispatch({ type: AuthActions.LOGGING, payload: user });

const logout = () => async (dispatch: Dispatch<AuthActionsTypes>) =>
  dispatch({ type: AuthActions.LOGOUT });

export const authActions = {
  logging,
  logout,
};
