import classnames from "classnames";
import React, { ReactNode, useEffect, useRef } from "react";
import SVGIcon from "../svg-icon/svg-icon";
import { ButtonStyle } from "./button-style";

export interface IButtonProps {
  onClick?: (ev?: any) => void;
  text?: string | ReactNode;
  size?: "xxl" | "xl" | "l" | "m" | "icon-l" | "icon-m";
  className?: string;
  disabled?: boolean;
  type?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "ghost"
    | "ghost-cookies"
    | "table-action"
    | "icon-primary"
    | "icon-secondary"
    | "icon-tertiary"
    | "tab";
  error?: boolean;
  icon?: string;
  iconColor?: string;
  iconSize?: string;
  iconStrokeColor?: string;
}

const Button: React.FC<IButtonProps> = ({
  text,
  type,
  onClick,
  disabled,
  className,
  size,
  icon,
  iconColor,
  iconSize,
  iconStrokeColor,
  error,
}) => {
  const mount = useRef<boolean>(false);
  const button = useRef<any>(null);

  useEffect(() => {
    mount.current = true;
    return () => {
      mount.current = false;
    };
  }, []);

  const handleClick = (ev: any) => {
    if (!disabled && onClick) onClick(ev);

    if (button.current) {
      button.current.style.pointerEvents = "none";
      setTimeout(() => {
        if (button.current && mount.current) {
          button.current.style = "";
        }
      }, 600);
    }
  };

  return (
    <ButtonStyle
      ref={button}
      data-testid="button"
      className={classnames(className, type, size, {
        disabled: !!disabled,
        error: !!error,
        icon:
          !!icon &&
          !!!text &&
          (type === "icon-primary" ||
            type === "icon-secondary" ||
            type === "icon-tertiary"),
        "icon-text": !!icon && !!text,
      })}
      onClick={(ev: any) => handleClick(ev)}
    >
      {icon && (
        <div className="button-icon">
          <SVGIcon
            size={iconSize ? iconSize : "18px"}
            icon={icon}
            color={iconColor ? iconColor : ""}
            strokeColor={iconStrokeColor ? iconStrokeColor : ""}
          />
        </div>
      )}
      {text && (
        <div className="button-text">
          <p>{text}</p>
        </div>
      )}
    </ButtonStyle>
  );
};

export default Button;
