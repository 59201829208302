import styled from "styled-components";
import {
  BodyDefaultBoldMixin,
  BodyDefaultMixin,
  BodySmallMixin,
} from "../text-components/body-text";
import { LinkMixin } from "../text-components/other-text";
import { colors } from "./../../assets/colors/colors";

export const ButtonStyle = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  border-radius: 12px;

  .button-icon {
    svg {
      path {
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .button-text {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    user-select: none;
  }

  /* SIZE */

  &.xxl {
    padding: 0 32px;
    height: 48px;
  }

  &.xl {
    padding: 0 24px;
    height: 44px;
  }

  &.l {
    padding: 0 16px;
    height: 36px;
  }

  &.m {
    padding: 4px 12px;
  }

  /* TYPE */

  &.primary {
    background: ${colors["brand-1-100"]};
    color: ${colors["neutrals-0"]};
    ${BodyDefaultBoldMixin};

    .button-icon {
      svg {
        path {
          fill: #ffffff;
        }
      }
    }

    &:hover:not(:active) {
      background: #39996b;
    }

    &:active {
      background: ${colors["brand-1-100"]};
    }

    &.error {
      background: ${colors["error-100"]};

      &:hover:not(:active) {
        background: #d1252f;
      }

      &:active {
        background: ${colors["error-100"]};
      }
    }

    &.disabled {
      background: ${colors["neutrals-30"]};
    }
  }

  &.secondary {
    background: ${colors["neutrals-10"]};
    color: ${colors["neutrals-100"]};
    ${BodyDefaultMixin};

    .button-icon {
      svg {
        path {
          fill: ${colors["neutrals-100"]};
        }
      }
    }

    &:hover:not(:active) {
      background: ${colors["neutrals-30"]};
    }

    &:active {
      background: ${colors["neutrals-10"]};
    }

    &.error {
      background: ${colors["error-10"]};
      color: ${colors["error-100"]};

      &:hover:not(:active) {
        background: ${colors["error-30"]};
      }

      &:active {
        background: ${colors["error-10"]};
      }
    }

    &.disabled {
      background: ${colors["neutrals-30"]};
      color: ${colors["neutrals-0"]};
      pointer-events: none;

      .button-icon {
        svg {
          path {
            fill: ${colors["neutrals-0"]};
          }
        }
      }
    }
  }

  &.tertiary {
    background: ${colors["secondary-3-10"]};
    color: ${colors["secondary-3-100"]};
    border: 1px solid ${colors["secondary-3-10"]};
    ${BodyDefaultMixin};

    .button-icon {
      svg {
        path {
          fill: ${colors["secondary-3-100"]};
        }
      }
    }

    &:hover:not(:active) {
      background: ${colors["secondary-3-30"]};
    }

    &:active {
      background: ${colors["neutrals-10"]};
    }

    &.error {
      background: ${colors["error-10"]};
      color: ${colors["error-100"]};

      &:hover:not(:active) {
        background: ${colors["error-30"]};
      }

      &:active {
        background: ${colors["error-10"]};
      }
    }

    &.disabled {
      background: ${colors["neutrals-5"]};
      color: ${colors["secondary-3-30"]};
      border: 1px solid ${colors["secondary-3-30"]};

      .button-icon {
        svg {
          path {
            fill: ${colors["neutrals-0"]};
          }
        }
      }
    }
  }

  /* &.icon-text {
    padding: 0 16px;
    height: 36px;

    .button-icon {
      margin-right: 8px;
    }

    .button-text {
      
    }
  } */

  &.icon-primary {
    background: transparent;
    border: 1px solid transparent;

    .button-icon {
      svg {
        path {
          transition: all 0.3s ease-in-out;
          fill: ${colors["brand-1-100"]};
        }
      }
    }

    &:hover:not(:active):not(.active),
    &:focus:not(:active) {
      background: ${colors["brand-1-10"]};
    }

    &:active,
    &.active {
      background: ${colors["neutrals-0"]};
      border-color: ${colors["brand-1-100"]};
    }
  }

  &.icon-secondary {
    background: transparent;
    border: 1px solid transparent;

    &:not(.custom-icon-color) {
      .button-icon {
        svg {
          path {
            transition: all 0.3s ease-in-out;
            fill: ${colors["neutrals-50"]};
          }
        }
      }
    }

    &:hover:not(:active),
    &:focus:not(:active) {
      background: ${colors["neutrals-10"]};
    }

    &:active {
      background: ${colors["neutrals-0"]};
      border-color: ${colors["neutrals-50"]};
    }
  }

  &.icon-tertiary {
    background: ${colors["brand-1-100"]};

    .button-icon {
      svg {
        path {
          transition: all 0.3s ease-in-out;
          fill: ${colors["neutrals-0"]};
        }
      }
    }

    &:hover:not(:active):not(.active),
    &:focus:not(:active) {
      background: #39996b;
    }

    &:active,
    &.active {
      background: ${colors["brand-1-100"]};
      border-color: ${colors["brand-1-100"]};
    }
  }

  &.icon {
    height: 46px;
    width: 46px;

    &.disabled {
      border: none;
      background: transparent;

      .button-icon {
        svg {
          path {
            transition: all 0.3s ease-in-out;
            fill: ${colors["neutrals-30"]};
          }
        }
      }
    }
  }

  &.alert {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      right: 10px;
      top: 10px;
      border-radius: 50%;
      height: 12px;
      width: 12px;
      border: 0.5px solid ${colors["neutrals-0"]};
      background: ${colors["alert-100"]};
    }
  }

  &.ghost {
    background: transparent;
    color: ${colors["brand-1-100"]};
    padding: 0;
    height: 28px;
    border: 1px solid transparent;
    &.table-files-wrap {
      .button-text {
        p {
          white-space: pre-wrap;
        }
      }
    }

    &.ghost-cookies {
      .button-text {
        ${BodySmallMixin}
      }
    }

    .button-icon {
      svg {
        path {
          fill: ${colors["brand-1-100"]};
        }
      }
    }

    .button-text {
      ${LinkMixin};
      font-weight: 400;
    }

    &:hover:not(:active) {
      background: none;
      color: #39996b;

      .button-icon {
        svg {
          path {
            fill: #39996b;
          }
        }
      }
    }

    &:active {
      border-color: transparent;
    }

    &.error {
      background: ${colors["neutrals-0"]};
      color: ${colors["error-100"]};

      &:hover:not(:active) {
        background: ${colors["error-10"]};
        color: #d1252f;
      }

      &:active {
        border-color: ${colors["error-100"]};
      }
    }

    &.disabled {
      background: ${colors["neutrals-0"]};
      color: ${colors["neutrals-30"]};

      .button-icon {
        svg {
          path {
            fill: ${colors["neutrals-30"]};
          }
        }
      }
    }
  }

  &.tab {
    padding: 0 12px 8px;
    height: 30px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: transparent;
      transition: all 0.3s ease-in-out;
    }

    .button-icon {
      margin-right: 8px;

      svg {
        path {
          fill: ${colors["neutrals-80"]};
        }
      }
    }

    &.icon-stroke {
      .button-icon {
        svg {
          path {
            fill: transparent;
            stroke: ${colors["neutrals-80"]};
          }
        }
      }
    }

    .button-text {
      ${BodyDefaultMixin};
      /* text-transform: none; */
    }

    &.disabled {
      color: ${colors["neutrals-30"]};

      &::after {
        background: ${colors["neutrals-30"]};
      }

      .button-icon {
        svg {
          path {
            fill: ${colors["neutrals-30"]};
          }
        }
      }

      &.icon-stroke {
        .button-icon {
          svg {
            path {
              fill: transparent;
              stroke: ${colors["neutrals-30"]};
            }
          }
        }
      }
    }

    &.active {
      position: relative;

      .button-text {
        font-weight: 400;
      }

      &:after {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 2px;
        width: 100%;
        background-color: ${colors["brand-1-100"]};
      }
    }

    &.scheduled {
      color: ${colors["neutrals-50"]};

      &.active {
        &::after {
          background: ${colors["neutrals-50"]};
        }
      }

      .button-icon {
        svg {
          path {
            fill: ${colors["neutrals-50"]};
          }
        }
      }

      &.icon-stroke {
        .button-icon {
          svg {
            path {
              fill: transparent;
              stroke: ${colors["neutrals-50"]};
            }
          }
        }
      }
    }

    &.pending {
      color: ${colors["neutrals-80"]};

      &.active {
        &::after {
          background: ${colors["neutrals-80"]};
        }
      }

      .button-icon {
        svg {
          path {
            fill: ${colors["neutrals-80"]};
          }
        }
      }

      &.icon-stroke {
        .button-icon {
          svg {
            path {
              fill: transparent;
              stroke: ${colors["neutrals-80"]};
            }
          }
        }
      }
    }

    &.rejected,
    &.canceled {
      color: ${colors["error-100"]};

      &.active {
        &::after {
          background: ${colors["error-100"]};
        }
      }

      .button-icon {
        svg {
          path {
            fill: ${colors["error-100"]};
          }
        }
      }

      &.icon-stroke {
        .button-icon {
          svg {
            path {
              fill: transparent;
              stroke: ${colors["error-100"]};
            }
          }
        }
      }
    }

    &.approved,
    &.closed,
    &.success {
      color: #39996b;

      &.active {
        &::after {
          background: #39996b;
        }
      }

      .button-icon {
        svg {
          path {
            fill: #39996b;
          }
        }
      }

      &.icon-stroke {
        .button-icon {
          svg {
            path {
              fill: transparent;
              stroke: #39996b;
            }
          }
        }
      }
    }

    &.analyzing,
    &.open,
    &.auction {
      color: #134074;

      &.active {
        &::after {
          background: #134074;
        }
      }

      .button-icon {
        svg {
          path {
            fill: #134074;
          }
        }
      }

      &.icon-stroke {
        .button-icon {
          svg {
            path {
              fill: transparent;
              stroke: #134074;
            }
          }
        }
      }
    }

    &.deserted {
      color: ${colors["error-100"]};

      &.active {
        &::after {
          background: ${colors["error-100"]};
        }
      }

      .button-icon {
        svg {
          path {
            fill: ${colors["error-100"]};
          }
        }
      }

      &.icon-stroke {
        .button-icon {
          svg {
            path {
              fill: transparent;
              stroke: ${colors["error-100"]};
            }
          }
        }
      }
    }

    &.needs-more-info {
      color: ${colors["alert-100"]};

      &.active {
        &::after {
          background: ${colors["alert-100"]};
        }
      }

      .button-icon {
        svg {
          path {
            fill: ${colors["alert-100"]};
          }
        }
      }

      &.icon-stroke {
        .button-icon {
          svg {
            path {
              fill: transparent;
              stroke: ${colors["alert-100"]};
            }
          }
        }
      }
    }
  }

  &.table-action {
    padding: 4px 12px;
    color: #536b60;
    height: 28px;
    position: relative;
    background: ${colors["neutrals-10"]};

    .button-icon {
      svg {
        path {
          fill: #536b60;
        }
      }
    }

    &:hover:not(:active) {
      background: ${colors["neutrals-30"]};
    }

    &:active {
      background: ${colors["neutrals-10"]};
    }

    &.error {
      background: ${colors["error-10"]};
      color: ${colors["error-100"]};

      &:hover:not(:active) {
        background: ${colors["error-30"]};
      }

      &:active {
        background: ${colors["error-10"]};
      }
    }

    &.disabled {
      background: ${colors["neutrals-30"]};
      color: ${colors["neutrals-0"]};

      .button-icon {
        svg {
          path {
            fill: ${colors["neutrals-0"]};
          }
        }
      }
    }
  }

  &.full-width {
    width: 100%;
  }

  &.disabled {
    pointer-events: none;
  }

  &.no-bg {
    background: transparent;
  }
`;
