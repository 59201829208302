import React from "react";
import { Control, DeepMap, FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InputController from "../../../../../components/input/input-controller";
import InputPhoneController from "../../../../../components/input/input-phone-controller";
import useReactGa from "../../../../../hooks/useReactGa";
import {
  USER_EMAIL,
  USER_JOB,
  USER_LASTNAME,
  USER_NAME,
  USER_PHONE,
} from "../../../../../types/users/user.constants";
import { getErrorMessage } from "../../../../../utils/errorMessage";
import {
  isMaxLengthEmpty,
  isMinLengthEmpty,
  isValidEmail,
  isValidPhone,
} from "../../../../../utils/validation";

interface IRegisterContact {
  control: Control<any>;
  errors: DeepMap<any, FieldError>;
}

const RegisterContact: React.FC<IRegisterContact> = ({ control, errors }) => {
  const { t } = useTranslation(["auth", "validation"]);
  useReactGa(window.location.pathname, "Registro - Datos Contacto");

  return (
    <div className="card-register-contact">
      <div className="card-register-contact__label">
        <p>{t("auth:Datos de Contacto")}</p>
      </div>
      <div className="card-register-contact__form">
        <div className="form-row">
          <InputController
            inputProps={{
              required: true,
              placeholder: t("auth:Introduce tu nombre"),
              type: "text",
              label: t("auth:Nombre"),
              error: getErrorMessage(t, errors[USER_NAME]?.type),
            }}
            controllerProps={{
              control,
              name: USER_NAME,
              rules: {
                required: true,
                validate: {
                  "min-3": (value: string) => isMinLengthEmpty(value, 3),
                  "max-50": (value: string) => isMaxLengthEmpty(value, 50),
                },
              },
            }}
          />
          <InputController
            inputProps={{
              required: true,
              placeholder: t("auth:Introduce tus apellidos"),
              type: "text",
              label: t("auth:Apellidos"),
              error: getErrorMessage(t, errors[USER_LASTNAME]?.type),
            }}
            controllerProps={{
              control,
              name: USER_LASTNAME,
              rules: {
                required: true,
                validate: {
                  "min-3": (value: string) => isMinLengthEmpty(value, 3),
                  "max-70": (value: string) => isMaxLengthEmpty(value, 70),
                },
              },
            }}
          />
        </div>
        <div className="form-row">
          <InputController
            inputProps={{
              required: true,
              placeholder: t("auth:Ej - hola@gmail.com"),
              type: "text",
              label: t("auth:Correo electrónico"),
              error: getErrorMessage(t, errors[USER_EMAIL]?.type),
            }}
            controllerProps={{
              control,
              name: USER_EMAIL,
              rules: {
                required: true,
                validate: {
                  isEmail: isValidEmail,
                },
              },
            }}
          />
        </div>
        <div className="form-row">
          {/* <InputPhone
            required={true}
            placeholderSelect={t("auth:Prefijo")}
            placeholderInput={t("auth:Teléfono")}
            value="+34621312312"
            label={t("auth:Teléfono")}
            error={getErrorMessage(t, errors[USER_PHONE]?.type)}
          /> */}
          <InputPhoneController
            inputPhoneProps={{
              required: true,
              placeholderSelect: t("auth:Prefijo"),
              placeholderInput: t("auth:Teléfono"),
              label: t("auth:Teléfono"),
              error: getErrorMessage(t, errors[USER_PHONE]?.type),
            }}
            controllerProps={{
              control,
              name: USER_PHONE,
              rules: {
                required: true,
                validate: {
                  isPhone: (value: any) => {
                    if(value)
                      return isValidPhone(value)
                  },
                },
              },
            }}
          />
          <InputController
            inputProps={{
              placeholder: t("auth:Ej - Director general"),
              type: "text",
              label: t("auth:Cargo en la Empresa"),
              error: getErrorMessage(t, errors[USER_JOB]?.type),
            }}
            controllerProps={{
              control,
              name: USER_JOB,
              rules: {
                validate: {
                  "min-3": (value: string) => isMinLengthEmpty(value, 3),
                  "max-30": (value: string) => isMaxLengthEmpty(value, 30),
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RegisterContact;
