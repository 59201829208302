import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../../../assets/colors/colors";
import { icons } from "../../../../assets/icons/icons";
import SVGIcon from "../../../../components/svg-icon/svg-icon";
import { REQUEST_TYPES } from "../../../../constants/requests";
import { ROLES } from "../../../../constants/roles";
import { IRequest } from "../../../../types/common/interfaces/notifications-interfaces";
import { CardRequestStyle } from "./card-request-style";

interface ICardRequestProps {
  request: IRequest;
  className?: string;
  markedReadRequest: any;
  isHeader: boolean;
}

const CardRequest: React.FC<ICardRequestProps> = ({
  request,
  className,
  markedReadRequest,
  isHeader,
}) => {
  const { t } = useTranslation("common");

  const requestTypes = useMemo(() => REQUEST_TYPES(t), [t]);

  return (
    <CardRequestStyle
      className={className}
      onClick={() => {
        if (!request.wasRead) {
          markedReadRequest(request.id, isHeader);
        }
      }}
    >
      <div className="card-request">
        <div className="card-request__user">
          <div className="card-request__user__icon">
            <SVGIcon
              icon={icons.flag}
              size="20px"
              color={
                !request.wasRead ? colors["brand-1-100"] : colors["neutrals-50"]
              }
            />
          </div>
          <div className="card-request__user__text">
            <p>{`Usuario ${request.user?.name}`}</p>
          </div>
        </div>
        <div className="card-request__type">
          <p>{`Validación ${requestTypes[request.type]}`}</p>
        </div>
        <div className="card-request__user">
          <p>{ROLES[request.user?.role]}</p>
        </div>
      </div>
    </CardRequestStyle>
  );
};

export default CardRequest;
