import classnames from "classnames";
import React, { useState } from "react";
import { RadioStyle } from "./radio-style";

interface IRadioOptions {
  value: string;
  label: string;
  name: string;
}

export interface IRadioProps {
  options: IRadioOptions[];
  disabled?: boolean;
  required?: boolean;
  error?: boolean | string;
  tooltip?: string;
  value?: string;
  className?: string;
  onChange?: any;
  onBlur?: any;
  onFocus?: any;
  onKeyDown?: any;
  label?: string;
}

const Radio: React.FC<IRadioProps> = (props: IRadioProps) => {
  const [value, setValue] = useState<string>("value1");
  const val = props.value || value;

  const handleChange = (ev: any) => {
    if (props.onChange) {
      props.onChange(ev);
    } else if (props.value === undefined) {
      setValue(ev.target.value);
    }
  };

  return (
    <RadioStyle
      className={classnames(props.className || "", {
        error: !!props.error,
        disabled: !!props.disabled,
        required: !!props.required,
      })}
    >
      {props.label && <div className="radio-label">{props.label}</div>}
      <div className="radio-option-container">
        {props.options.map((item: IRadioOptions, index: number) => (
          <div
            className={classnames("radio-option", {
              active: item.value === val,
            })}
            key={index}
          >
            <input
              type="radio"
              name={item.name}
              value={item.value}
              checked={val === item.value}
              disabled={props.disabled}
              required={props.required}
              onChange={(ev: any) => handleChange(ev)}
              onFocus={(ev: any) => props.onFocus && props.onFocus(ev)}
              onBlur={(ev: any) => props.onBlur && props.onBlur(ev)}
              onKeyDown={(ev: any) => props.onKeyDown && props.onKeyDown(ev)}
            />
            <div className="radio-item">
              <div className="radio-item__circle" />
            </div>
            <div className="radio-option__label">{item.label}</div>
          </div>
        ))}
      </div>
    </RadioStyle>
  );
};

export default Radio;
