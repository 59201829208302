import React from "react";
import { FlexRowStyle } from "./flex-row-style";

interface IFlexRow {
  className?: string;
  pattern?: string;
  uniquePattern?: string;
  uniquePatternResT?: string;
  uniquePatternResM?: string;
  rowGap?: string;
  rowGapRes?: string;
  colGap?: string;
  colGapRes?: string;
  responsive?: string;
  justifyContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "initial";
  alignItems?: "normal" | "stretch" | "flex-start" | "flex-end" | "center";
  children: any;
}

const FlexRow: React.FC<IFlexRow> = ({
  className,
  pattern,
  uniquePattern,
  uniquePatternResT,
  uniquePatternResM,
  rowGap,
  rowGapRes,
  colGap,
  colGapRes,
  justifyContent,
  alignItems,
  children,
}) => {
  return (
    <FlexRowStyle
      className={className}
      pattern={pattern}
      uniquePattern={uniquePattern}
      uniquePatternResT={uniquePatternResT}
      uniquePatternResM={uniquePatternResM}
      rowGap={rowGap}
      rowGapRes={rowGapRes}
      colGap={colGap}
      colGapRes={colGapRes}
      justifyContent={justifyContent}
      alignItems={alignItems}
    >
      <div className="flex-row-container">{children}</div>
    </FlexRowStyle>
  );
};

export default FlexRow;
