
export const initGA = () => {
  window[`ga-disable-${"G-0JKSS687YB"}`] = false;
  logPageView();
};
export const closeGA = () => {
  window[`ga-disable-${"G-0JKSS687YB"}`] = true;
};
export const logPageView = (title) => {
  window.gtag('config', "G-0JKSS687YB", {
    title,
    page_path: window.location.pathname,
  });
};
export const logEvent = (category = '', action = '') => {
  window.gtag('event', action, {
    event_category: category,
  });
};

export const initializedGA = () => {
  if (!window.GA_INITIALIZED) {
    initGA();
    window.GA_INITIALIZED = true;
  }
};
