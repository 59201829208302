import styled from 'styled-components';
import { colors } from '../../../assets/colors/colors';
import { Heading1Mixin } from './../../../components/text-components/heading-text';

export const LegalStyle = styled.div`
  font-weight: 300;
  color: ${colors['neutrals-80']};
  .legal-title {
    ${Heading1Mixin};
    color: ${colors['neutrals-100']};
    margin-bottom: 48px;
    text-align: center;
  }

  b {
    font-weight: 500;
  }

  li {
    display: list-item;
  }

  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 80px;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  .legal-text {
    font-size: 16px;
    line-height: 22px;
    & > p {
      &:not(:first-child) {
        margin-top: 24px;
      }
    }
  }

  @media (max-width: 1100px) {
  }

  @media (max-width: 768px) {
  }
`;
