import styled from 'styled-components';
import { colors } from '../../assets/colors/colors';
import { BodyDefaultMixin, BodySmallMixin } from '../text-components/body-text';
import { Heading5Mixin, Heading6Mixin } from '../text-components/heading-text';

export const ModalStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  /* Character/Primary .65 */
  background: rgba(34, 34, 34, 0.2);
  transition: 0.3s ease-in-out all;
  z-index: 99999;
  padding: 40px 0;
  user-select: none;

  &.active {
    visibility: visible;
    opacity: 1;
  }

  &.large  {
    overflow-y: scroll;
    align-items: flex-start;
  }

  .modal-content {
    position: relative;
    background: ${colors['neutrals-0']};
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    max-width: 412px;
    width: 100%;
    min-width: 280px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .modal-content-close {
    position: relative;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    width: 100%;
  }

  .modal-content-main {
    display: flex;
    align-items: flex-start;
    width: 100%;
  }

  .modal-content-bottom {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .modal-content-actions {
    display: flex;
    justify-content: center;

    & > .modal-content-actions__item {
      min-width: 170px;
      flex: 1;

      &:not(:first-child) {
        margin-left: 24px;
      }
    }
  }

  .modal-content-main-body {
    flex: 1;
    min-width: 294px;

    &__title {
      text-align: center;
      ${Heading5Mixin};
      color: ${colors['neutrals-100']};
      margin-bottom: 24px;
    }

    &__text {
      text-align: center;
      ${BodyDefaultMixin};
      color: ${colors['neutrals-100']};
      margin-bottom: 36px;
    }

    &__children {
      width: 100%;
    }
  }

  &.only-children {
    .modal-content {
      max-width: 886px;
    }
    
    .modal-content-close {
      top: 24px;
      right: 24px;
    }
  }

  &.wide-actions {
    .modal-content-actions {
      width: 100%;

      & > .modal-content-actions__item {
        &:not(:first-child) {
          margin-left: 8px;
        }
      }
    }
  }

  .radio-option-container {
    display: flex;
    flex-direction: column;

    .radio-option {
      margin-top: 0;
      width: 100%;
    }
  }

  @media (max-width: 1100px) {
    &.only-children {
      .modal-content {
        padding: 68px 18px 24px;
        max-width: 660px;
      }

      .modal-content-close {
        right: 18px;
      }
    }
  }

  @media (max-width: 768px) {
    .modal-content {
      padding-top: 48px;
    }

    .modal-content-main-body {
      min-width: 0;

      &__title {
        ${Heading6Mixin};
      }

      &__text {
        ${BodySmallMixin};
      }
    }

    &.only-children {
      .modal-content {
        padding-top: 56px;
        padding: 56px 12px 12px;
        max-width: calc(100% - 16px);
      }

      .modal-content-close {
        top: 12;
        right: 12;
      }
    }

    .modal-content {
      max-width: 280px;
    }

    .modal-content-bottom {
      flex-flow: column;
    }

    .modal-content-actions {
      flex-flow: column;
      align-items: stretch;
      width: 100%;

      & > .modal-content-actions__item {
        width: 100%;
        flex: 1;

        &:not(:first-child) {
          margin-left: 0;
          margin-top: 12px;
        }
      }

      &.extra {
        margin-bottom: 8px;
      }
    }

    &.wide-actions {
      .modal-content-actions {
        & > .modal-content-actions__item {
          &:not(:first-child) {
            margin-left: 0;
            margin-top: 12px;
          }
        }
      }
    }
  }
`;
