import { IBaseAction } from "../base-action.interface";

export enum AuthActions {
  LOGGING = "LOGGING",
  LOGOUT = "LOGOUT",
  EXPIRED_SESSION = "EXPIRED_SESSION",
}

export interface AuthState {
  readonly isAuth: boolean;
  readonly user: any;
}

interface LoggingAction extends IBaseAction {
  type: AuthActions.LOGGING;
  payload: any;
}

interface LogoutAction extends IBaseAction {
  type: AuthActions.LOGOUT;
}

interface ExpiredSessionAction extends IBaseAction {
  type: AuthActions.EXPIRED_SESSION;
}

export type AuthActionsTypes =
  | LoggingAction
  | LogoutAction
  | ExpiredSessionAction;
