import styled from "styled-components";
import { colors } from "../../assets/colors/colors";
import {
  BodyDefaultMixin,
  BodySmallMixin,
  BodyXSmallMixin,
} from "../text-components/body-text";

export const InputStyle = styled.div`
  /* .svg-hidden {
    display: none !important;
  } */
  /* TOP */

  min-width: 0;
  width: 100%;

  .input-body__select {
    width: 100px;
  }

  .input-top {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    min-height: 16px;

    &__tooltip {
      cursor: pointer;
      margin-right: 8px;
      transition: all 0.3s ease-in-out;
    }

    &__label {
      user-select: none;
      display: flex;
      flex: 1;
      ${BodyXSmallMixin};
      color: ${colors["neutrals-50"]};
      transition: all 0.3s ease-in-out;

      span {
        color: #ec952f;
      }
    }
  }

  .input-bottom {
    margin-top: 4px;

    &__error {
      color: ${colors["error-100"]};
      ${BodyXSmallMixin};
    }

    &__message {
      color: ${colors["neutrals-30"]};
      ${BodyXSmallMixin};
    }

    &__length {
      color: ${colors["neutrals-50"]};
      ${BodyXSmallMixin};
      margin-top: 12px;
      text-align: right;
    }
  }

  .input-action {
    margin-top: 4px;

    & > div {
      padding: 0;
    }

    .button-text {
      font-weight: 300;
    }
  }

  /* BODY */

  .input-body-wrapper {
    width: 100%;
    display: flex;
    align-items: center;

    .fileIcon {
      margin-right: 12px;
    }
  }

  .input-body__selected {
    width: calc(100% - 90px);
  }

  .selected-item  {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .input-body {
    min-width: 240px;
    height: 46px;
    padding: 0 12px;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 12px;
    border: 1px solid ${colors["neutrals-10"]};
    color: ${colors["neutrals-50"]};
    transition: 0.3s ease-in-out all;
    background: ${colors["neutrals-0"]};
    transition: all 0.3s ease-in-out;
    min-width: 0;

    &__select {
      .input-top,
      .input-bottom {
        display: none;
      }

      &__selected {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        border: 1px solid red;

        .selected-item {
          width: calc(100% - 67px);
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .input-body {
        min-width: 36px;
        height: 24px;
        padding: 6px 3px;
        border-radius: unset;
        border: none;

        & > div:nth-child(2) {
          width: max-content;
        }

        /* position: relative;
        display: flex;
        align-items: center;
        color: ${colors["neutrals-50"]};
        transition: 0.3s ease-in-out all;
        background: ${colors["neutrals-0"]};
        transition: all 0.3s ease-in-out; */
      }
    }

    &__icon {
      margin-right: 12px;

      &.clickable {
        cursor: pointer;
      }

      svg * {
        transition: all 0.3s ease-in-out;
        fill: ${colors["neutrals-50"]};
      }
    }

    &__detail {
      ${BodyDefaultMixin};
      white-space: nowrap;
      color: ${colors["neutrals-50"]};
      transition: all 0.3s ease-in-out;
    }

    &__iconRight {
      margin-left: 12px;

      &.clickable {
        cursor: pointer;
      }

      svg * {
        transition: all 0.3s ease-in-out;
        fill: ${colors["neutrals-50"]};
      }
    }

    &:hover {
      border-color: #39996b;
    }
  }

  /* &.complete {
    .input-body {
      &:hover {
        background: ${colors["neutrals-0"]};
      }
    }
  } */

  input {
    flex: 1;
    ${BodyDefaultMixin};
    caret-color: ${colors["neutrals-80"]};
    color: ${colors["neutrals-80"]};
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 0;
    width: 100%;
    &::placeholder {
      color: ${colors["neutrals-50"]};
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &:focus  {
      outline: none;
    }
  }

  textarea {
    background: none;
    border: none;
    resize: none;
    width: 100%;
    ${BodyDefaultMixin};
    color: ${colors["neutrals-50"]};

    &:focus  {
      outline: none;
    }
  }

  /* .input-tooltip {
    background: #426690;
    padding: 12px !important;
    color: colors['neutrals-0']} !important;
    font-weight: 300 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    border-radius: 12px !important;
    opacity: 1 !important;
    max-width: 433px !important;
    width: 433px !important;

    &.place-top {
      bottom: 200px !important;
      &:after {
        border-top-color: #426690 !important;
        border-top-style: solid !important;
        border-top-width: 6px !important;
      }
    }

    &.place-bottom {
      &:after {
        border-bottom-color: #426690 !important;
        border-bottom-style: solid !important;
        border-bottom-width: 6px !important;
      }
    }

    &.place-left {
      &:after {
        top: 20px;
        border-left-color: #426690 !important;
        border-left-style: solid !important;
        border-left-width: 6px !important;
      }
    }

    &.place-right {
      &:after {
        border-right-color: #426690 !important;
        border-right-style: solid !important;
        border-right-width: 6px !important;
      }
    }
  } */

  &.complete {
    .input-body {
      border-color: ${colors["neutrals-10"]};
      background: ${colors["neutrals-0"]};

      &__detail {
        color: ${colors["neutrals-100"]};
      }
    }

    input,
    textarea {
      color: ${colors["neutrals-100"]};
    }
  }

  &.focus {
    .input-body {
      border-color: ${colors["brand-1-100"]};

      &__detail {
        color: ${colors["neutrals-80"]};
      }

      &__icon {
        &.select {
          svg * {
            transition: all 0.3s ease-in-out;
            fill: ${colors["brand-1-100"]};
          }
        }
      }
    }
  }

  &.error {
    .input-top {
      &__icon {
        fill: ${colors["error-100"]};
      }
    }

    .input-body {
      background: ${colors["neutrals-0"]};
      border-color: ${colors["error-100"]};
    }

    input,
    textarea {
      color: ${colors["error-100"]};
    }
  }

  &.success {
    .input-body {
      border-color: ${colors["success-100"]};
    }
  }

  &.disabled {
    .input-top {
      &__label {
        user-select: none;
        color: ${colors["neutrals-30"]};
      }

      .input-top {
        &__icon {
          fill: ${colors["neutrals-30"]};
        }
      }
    }

    .input-body {
      border-color: ${colors["neutrals-5"]};

      &__detail {
        color: ${colors["neutrals-30"]};
      }
    }

    input,
    textarea {
      color: ${colors["neutrals-30"]};
      -webkit-text-fill-color: ${colors["neutrals-30"]};
    }
  }

  &.textarea {
    .input-body {
      padding: 12px;
      height: auto;
      min-height: 46px;
      height: auto;
    }
  }

  &.readonly {
    .input-top {
      &__label {
        ${BodySmallMixin};
        color: ${colors["neutrals-80"]};
      }
    }

    .input-body {
      padding: 0;
      height: 24px;
      border: none;
      /* pointer-events: none; */

      input {
        font-weight: 400;
        pointer-events: none;
      }

      &__icon {
        &.select {
          display: none;
        }
      }
    }

    input,
    textarea {
      color: #000000;
      padding: 0;
      font-weight: 400;
    }

    .input-bottom {
      display: none;
    }
  }

  &.viewonly {
    .input-top {
      &__label {
        ${BodySmallMixin};
        color: ${colors["neutrals-80"]};
      }
    }

    .input-body {
      padding: 0;
      height: 24px;
      border: none;
      /* pointer-events: none; */

      input {
        font-weight: 400;
        pointer-events: none;
      }

      &__icon {
        &.select {
          display: none;
        }
      }
    }

    input,
    textarea {
      color: #000000;
      padding: 0;
    }

    .input-bottom {
      display: none;
    }
  }

  &.responsive-label {
    .input-top {
      &__label {
        height: 40px;
        margin-top: -20px;
        display: flex;
        align-items: flex-end;
      }
    }
  }

  &.responsive-label-margin {
    .input-top {
      &__label {
        height: 40px;
        /* margin-top: -20px; */
        display: flex;
        align-items: flex-end;
      }
    }
  }

  &.on-focus {
    .input-body__selected {
      opacity: 0;
    }
  }

  &.hide-top {
    .input-top {
      display: none;
    }
  }

  &.hide-bottom {
    .input-bottom {
      display: none;
    }
  }

  &.input-file-viewonly {
    cursor: pointer;
    &.inline {
      width: auto;
    } 
    .input-top,
    .input-bottom {
      display: none;
    }

    .input-body {
      &__icon {
        svg path {
          fill: ${colors["brand-1-100"]};
        }
      }

      input {
        color: ${colors["brand-1-100"]};
        text-decoration: underline;
      }
    }

    &.disabled {
      .input-body {
        &__icon {
          svg path {
            fill: ${colors["neutrals-30"]};
          }
        }

        input {
          color: ${colors["neutrals-30"]};
          text-decoration: underline;
        }

        &__iconRight {
          svg path {
            fill: ${colors["neutrals-30"]};
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .input-body {
      min-width: 0;
    }

    input {
      width: 100%;
    }
  }
`;

export const SelectStyle = styled.div`
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
  border-radius: 8px;
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.04));
  max-height: 144px;
  width: 100%;
  z-index: 11;

  &.open {
    opacity: 1;
    visibility: visible;
  }

  .select-item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 12px;
    transition: 0.3s ease-in-out;
    background: ${colors["neutrals-0"]};
    color: ${colors["neutrals-80"]};
    height: 48px;
    user-select: none;
    ${BodyDefaultMixin};
    cursor: pointer;

    &:hover {
      background: ${colors["brand-1-10"]};
      color: #39996b;
    }

    .input-body-option-checkbox {
      pointer-events: none;
      padding-right: 12px;
    }

    .input-body-option-icon {
      svg * {
        /* fill: colors.background_40}; */
      }
    }

    &:not(:last-child) {
      /* border-bottom: 1px solid colors.background_30; */
    }

    &:first-child {
      border-radius: 3px 3px 0 0;
    }

    &:last-child {
      border-radius: 0 0 3px 3px;
    }
  }
`;

export const InputCalendarStyle = styled.div`
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  opacity: 0;
  visibility: hidden;
  background: #ffffff;
  border-radius: 8px;

  &.open {
    opacity: 1;
    visibility: visible;
  }
`;

export const SelectContainerStyle = styled.div`
  position: relative;

  .input-body {
    &__selected {
      user-select: none;
      position: absolute;
      ${BodyDefaultMixin};
      color: ${colors["neutrals-100"]};
      transition: 0.3s ease-in-out all;
      pointer-events: none;
    }
  }

  input {
    flex: 1;
    width: 0px;
  }

  .on-focus {
    .input-body-selected {
      visibility: hidden;
      opacity: 0;
    }
  }
`;

export const InputPhoneContainerStyle = styled.div`
  /* RESET */
  .input-body {
    min-width: initial;
    height: initial;
    border-radius: initial;
    border: initial;
    color: initial;
    background: initial;
  }

  .input-top {
    display: initial;
    align-items: initial;
    margin-bottom: initial;
    min-height: initial;
  }

  .input-body__selected {
    width: initial;
  }

  .input-bottom {
    margin-top: initial;
  }

  .input-action {
    margin-top: initial;
  }

  /* ------- */

  .input-phone-body {
    display: flex;
    height: 46px;
    padding: 0 12px;
    min-width: 240px;
    align-items: center;
    border-radius: 12px;
    border: 1px solid ${colors["neutrals-10"]};
    color: ${colors["neutrals-50"]};
    transition: 0.3s ease-in-out all;
    background: ${colors["neutrals-0"]};
    transition: all 0.3s ease-in-out;
    :hover {
      border-color: #39996b;
    }
    .input-select {
      .input-body {
        width: 90px;
        .input-body-wrapper {
          width: 90px;
        }
      }
    }
    .input-select {
      width: initial;
    }
    .input-body {
      padding: 0;
      width: 100%;
    }
  }

  &.error {
    .input-phone-top {
      &__icon {
        fill: ${colors["error-100"]};
      }
    }

    .input-phone-body {
      background: ${colors["neutrals-0"]};
      border-color: ${colors["error-100"]};
    }

    input {
      color: ${colors["error-100"]};
    }
  }

  .input-phone-top {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    min-height: 16px;

    &__tooltip {
      cursor: pointer;
      margin-right: 8px;
      transition: all 0.3s ease-in-out;
    }

    &__label {
      user-select: none;
      flex: 1;
      ${BodyXSmallMixin};
      color: ${colors["neutrals-50"]};
      transition: all 0.3s ease-in-out;

      span {
        color: #ec952f;
      }
    }
  }

  .input-phone-bottom {
    margin-top: 4px;

    &__error {
      color: ${colors["error-100"]};
      ${BodyXSmallMixin};
    }

    &__message {
      color: ${colors["neutrals-30"]};
      ${BodyXSmallMixin};
    }

    &__length {
      color: ${colors["neutrals-50"]};
      ${BodyXSmallMixin};
      margin-top: 12px;
      text-align: right;
    }
  }

  .input-phone-action {
    margin-top: 4px;

    & > div {
      padding: 0;
    }

    .button-text {
      font-weight: 300;
    }
  }

  .input-select {
    width: 115px;
    input {
      width: 100px;
    }
  }
`;
