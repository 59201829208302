interface IContries {
  [key: string]: string;
}

interface IContriesItem {
  [key: string]: any;
  value: string;
  label: string;
  icon?: any;
}

export const getCountriesObject = (t: any) => {
  const COUNTRIES: IContries = {
    af: t("country:Afganistán"),
    ax: t("country:Åland"),
    al: t("country:Albania"),
    de: t("country:Alemania"),
    ad: t("country:Andorra"),
    ao: t("country:Angola"),
    ai: t("country:Anguila"),
    aq: t("country:Antártida"),
    ag: t("country:Antigua y Barbuda"),
    sa: t("country:Arabia Saudita"),
    dz: t("country:Argelia"),
    ar: t("country:Argentina"),
    am: t("country:Armenia"),
    aw: t("country:Aruba"),
    au: t("country:Australia"),
    at: t("country:Austria"),
    az: t("country:Azerbaiyán"),
    bs: t("country:Bahamas"),
    bd: t("country:Bangladés"),
    bb: t("country:Barbados"),
    bh: t("country:Baréin"),
    be: t("country:Bélgica"),
    bz: t("country:Belice"),
    bj: t("country:Benín"),
    bm: t("country:Bermudas"),
    by: t("country:Bielorrusia"),
    bo: t("country:Bolivia"),
    bq: t("country:Bonaire"),
    ba: t("country:Bosnia y Herzegovina"),
    bw: t("country:Botsuana"),
    br: t("country:Brasil"),
    bn: t("country:Brunéi"),
    bg: t("country:Bulgaria"),
    bf: t("country:Burkina Faso"),
    bi: t("country:Burundi"),
    bt: t("country:Bután"),
    cv: t("country:Cabo Verde"),
    kh: t("country:Camboya"),
    cm: t("country:Camerún"),
    ca: t("country:Canadá"),
    qa: t("country:Catar"),
    td: t("country:Chad"),
    cl: t("country:Chile"),
    cn: t("country:China"),
    cy: t("country:Chipre"),
    co: t("country:Colombia"),
    km: t("country:Comoras"),
    kp: t("country:Corea del Norte"),
    kr: t("country:Corea del Sur"),
    ci: t("country:Costa de Marfil"),
    cr: t("country:Costa Rica"),
    hr: t("country:Croacia"),
    cu: t("country:Cuba"),
    cw: t("country:Curazao"),
    dk: t("country:Dinamarca"),
    dm: t("country:Dominica"),
    ec: t("country:Ecuador"),
    eg: t("country:Egipto"),
    sv: t("country:El Salvador"),
    ae: t("country:Emiratos Árabes Unidos"),
    er: t("country:Eritrea"),
    sk: t("country:Eslovaquia"),
    si: t("country:Eslovenia"),
    es: t("country:España"),
    us: t("country:Estados Unidos"),
    ee: t("country:Estonia"),
    et: t("country:Etiopía"),
    ph: t("country:Filipinas"),
    fi: t("country:Finlandia"),
    fj: t("country:Fiyi"),
    fr: t("country:Francia"),
    ga: t("country:Gabón"),
    gm: t("country:Gambia"),
    ge: t("country:Georgia"),
    gh: t("country:Ghana"),
    gi: t("country:Gibraltar"),
    gd: t("country:Granada"),
    gr: t("country:Grecia"),
    gl: t("country:Groenlandia"),
    gp: t("country:Guadalupe"),
    gu: t("country:Guam"),
    gt: t("country:Guatemala"),
    gf: t("country:Guayana Francesa"),
    gg: t("country:Guernsey"),
    gn: t("country:Guinea"),
    gw: t("country:Guinea-Bisáu"),
    gq: t("country:Guinea Ecuatorial"),
    gy: t("country:Guyana"),
    ht: t("country:Haití"),
    hn: t("country:Honduras"),
    hk: t("country:Hong Kong"),
    hu: t("country:Hungría"),
    in: t("country:India"),
    id: t("country:Indonesia"),
    iq: t("country:Irak"),
    ir: t("country:Irán"),
    ie: t("country:Irlanda"),
    bv: t("country:Isla Bouvet"),
    im: t("country:Isla de Man"),
    cx: t("country:Isla de Navidad"),
    is: t("country:Islandia"),
    ky: t("country:Islas Caimán"),
    cc: t("country:Islas Cocos"),
    ck: t("country:Islas Cook"),
    fo: t("country:Islas Feroe"),
    gs: t("country:Islas Georgias del Sur y Sandwich del Sur"),
    hm: t("country:Islas Heard y McDonald"),
    fk: t("country:Islas Malvinas"),
    mp: t("country:Islas Marianas del Norte"),
    mh: t("country:Islas Marshall"),
    pn: t("country:Islas Pitcairn"),
    sb: t("country:Islas Salomón"),
    tc: t("country:Islas Turcas y Caicos"),
    um: t("country:Islas ultramarinas de Estados Unidos"),
    vg: t("country:Islas Vírgenes Británicas"),
    vi: t("country:Islas Vírgenes de los Estados Unidos"),
    il: t("country:Israel"),
    it: t("country:Italia"),
    jm: t("country:Jamaica"),
    jp: t("country:Japón"),
    je: t("country:Jersey"),
    jo: t("country:Jordania"),
    kz: t("country:Kazajistán"),
    ke: t("country:Kenia"),
    kg: t("country:Kirguistán"),
    ki: t("country:Kiribati"),
    kw: t("country:Kuwait"),
    la: t("country:Laos"),
    ls: t("country:Lesoto"),
    lv: t("country:Letonia"),
    lb: t("country:Líbano"),
    lr: t("country:Liberia"),
    ly: t("country:Libia"),
    li: t("country:Liechtenstein"),
    lt: t("country:Lituania"),
    lu: t("country:Luxemburgo"),
    mo: t("country:Macao"),
    mk: t("country:Macedonia"),
    mg: t("country:Madagascar"),
    my: t("country:Malasia"),
    mw: t("country:Malaui"),
    mv: t("country:Maldivas"),
    ml: t("country:Malí"),
    mt: t("country:Malta"),
    ma: t("country:Marruecos"),
    mq: t("country:Martinica"),
    mu: t("country:Mauricio"),
    mr: t("country:Mauritania"),
    yt: t("country:Mayotte"),
    mx: t("country:México"),
    fm: t("country:Micronesia"),
    md: t("country:Moldavia"),
    mc: t("country:Mónaco"),
    mn: t("country:Mongolia"),
    me: t("country:Montenegro"),
    ms: t("country:Montserrat"),
    mz: t("country:Mozambique"),
    mm: t("country:Myanmar"),
    na: t("country:Namibia"),
    nr: t("country:Nauru"),
    np: t("country:Nepal"),
    ni: t("country:Nicaragua"),
    ne: t("country:Níger"),
    ng: t("country:Nigeria"),
    nu: t("country:Niue"),
    nf: t("country:Norfolk"),
    no: t("country:Noruega"),
    nc: t("country:Nueva Caledonia"),
    nz: t("country:Nueva Zelanda"),
    om: t("country:Omán"),
    nl: t("country:Países Bajos"),
    pk: t("country:Pakistán"),
    pw: t("country:Palaos"),
    ps: t("country:Palestina"),
    pa: t("country:Panamá"),
    pg: t("country:Papúa Nueva Guinea"),
    py: t("country:Paraguay"),
    pe: t("country:Perú"),
    pf: t("country:Polinesia Francesa"),
    pl: t("country:Polonia"),
    pt: t("country:Portugal"),
    pr: t("country:Puerto Rico"),
    gb: t("country:Reino Unido"),
    eh: t("country:República Árabe Saharaui Democrática"),
    cf: t("country:República Centroafricana"),
    cz: t("country:República Checa"),
    cg: t("country:República del Congo"),
    cd: t("country:República Democrática del Congo"),
    do: t("country:República Dominicana"),
    re: t("country:Reunión"),
    rw: t("country:Ruanda"),
    ro: t("country:Rumania"),
    ru: t("country:Rusia"),
    ws: t("country:Samoa"),
    as: t("country:Samoa Americana"),
    bl: t("country:San Bartolomé"),
    kn: t("country:San Cristóbal y Nieves"),
    sm: t("country:San Marino"),
    mf: t("country:San Martín"),
    pm: t("country:San Pedro y Miquelón"),
    vc: t("country:San Vicente y las Granadinas"),
    sh: t("country:Santa Elena"),
    lc: t("country:Santa Lucía"),
    st: t("country:Santo Tomé y Príncipe"),
    sn: t("country:Senegal"),
    rs: t("country:Serbia"),
    sc: t("country:Seychelles"),
    sl: t("country:Sierra Leona"),
    sg: t("country:Singapur"),
    sx: t("country:Sint Maarten"),
    sy: t("country:Siria"),
    so: t("country:Somalia"),
    lk: t("country:Sri Lanka"),
    sz: t("country:Suazilandia"),
    za: t("country:Sudáfrica"),
    sd: t("country:Sudán"),
    ss: t("country:Sudán del Sur"),
    se: t("country:Suecia"),
    ch: t("country:Suiza"),
    sr: t("country:Surinam"),
    sj: t("country:Svalbard y Jan Mayen"),
    th: t("country:Tailandia"),
    tw: t("country:Taiwán (República de China)"),
    tz: t("country:Tanzania"),
    tj: t("country:Tayikistán"),
    io: t("country:Territorio Británico del Océano Índico"),
    tf: t("country:Tierras Australes y Antárticas Francesas"),
    tl: t("country:Timor Oriental"),
    tg: t("country:Togo"),
    tk: t("country:Tokelau"),
    to: t("country:Tonga"),
    tt: t("country:Trinidad y Tobago"),
    tn: t("country:Túnez"),
    tm: t("country:Turkmenistán"),
    tr: t("country:Turquía"),
    tv: t("country:Tuvalu"),
    ua: t("country:Ucrania"),
    ug: t("country:Uganda"),
    uy: t("country:Uruguay"),
    uz: t("country:Uzbekistán"),
    vu: t("country:Vanuatu"),
    va: t("country:Vaticano"),
    ve: t("country:Venezuela"),
    vn: t("country:Vietnam"),
    wf: t("country:Wallis y Futuna"),
    ye: t("country:Yemen"),
    dj: t("country:Yibuti"),
    zm: t("country:Zambia"),
    zw: t("country:Zimbabue"),
  };

  return COUNTRIES;
};

export const getCountriesArray = (t: any) => {
  const COUNTRIES: IContriesItem[] = [
    { value: "af", label: t("country:Afganistán") },
    { value: "ax", label: t("country:Åland") },
    { value: "al", label: t("country:Albania") },
    { value: "de", label: t("country:Alemania") },
    { value: "ad", label: t("country:Andorra") },
    { value: "ao", label: t("country:Angola") },
    { value: "ai", label: t("country:Anguila") },
    { value: "aq", label: t("country:Antártida") },
    { value: "ag", label: t("country:Antigua y Barbuda") },
    { value: "sa", label: t("country:Arabia Saudita") },
    { value: "dz", label: t("country:Argelia") },
    { value: "ar", label: t("country:Argentina") },
    { value: "am", label: t("country:Armenia") },
    { value: "aw", label: t("country:Aruba") },
    { value: "au", label: t("country:Australia") },
    { value: "at", label: t("country:Austria") },
    { value: "az", label: t("country:Azerbaiyán") },
    { value: "bs", label: t("country:Bahamas") },
    { value: "bd", label: t("country:Bangladés") },
    { value: "bb", label: t("country:Barbados") },
    { value: "bh", label: t("country:Baréin") },
    { value: "be", label: t("country:Bélgica") },
    { value: "bz", label: t("country:Belice") },
    { value: "bj", label: t("country:Benín") },
    { value: "bm", label: t("country:Bermudas") },
    { value: "by", label: t("country:Bielorrusia") },
    { value: "bo", label: t("country:Bolivia") },
    { value: "bq", label: t("country:Bonaire") },
    { value: "ba", label: t("country:Bosnia y Herzegovina") },
    { value: "bw", label: t("country:Botsuana") },
    { value: "br", label: t("country:Brasil") },
    { value: "bn", label: t("country:Brunéi") },
    { value: "bg", label: t("country:Bulgaria") },
    { value: "bf", label: t("country:Burkina Faso") },
    { value: "bi", label: t("country:Burundi") },
    { value: "bt", label: t("country:Bután") },
    { value: "cv", label: t("country:Cabo Verde") },
    { value: "kh", label: t("country:Camboya") },
    { value: "cm", label: t("country:Camerún") },
    { value: "ca", label: t("country:Canadá") },
    { value: "qa", label: t("country:Catar") },
    { value: "td", label: t("country:Chad") },
    { value: "cl", label: t("country:Chile") },
    { value: "cn", label: t("country:China") },
    { value: "cy", label: t("country:Chipre") },
    { value: "co", label: t("country:Colombia") },
    { value: "km", label: t("country:Comoras") },
    { value: "kp", label: t("country:Corea del Norte") },
    { value: "kr", label: t("country:Corea del Sur") },
    { value: "ci", label: t("country:Costa de Marfil") },
    { value: "cr", label: t("country:Costa Rica") },
    { value: "hr", label: t("country:Croacia") },
    { value: "cu", label: t("country:Cuba") },
    { value: "cw", label: t("country:Curazao") },
    { value: "dk", label: t("country:Dinamarca") },
    { value: "dm", label: t("country:Dominica") },
    { value: "ec", label: t("country:Ecuador") },
    { value: "eg", label: t("country:Egipto") },
    { value: "sv", label: t("country:El Salvador") },
    { value: "ae", label: t("country:Emiratos Árabes Unidos") },
    { value: "er", label: t("country:Eritrea") },
    { value: "sk", label: t("country:Eslovaquia") },
    { value: "si", label: t("country:Eslovenia") },
    { value: "es", label: t("country:España") },
    { value: "us", label: t("country:Estados Unidos") },
    { value: "ee", label: t("country:Estonia") },
    { value: "et", label: t("country:Etiopía") },
    { value: "ph", label: t("country:Filipinas") },
    { value: "fi", label: t("country:Finlandia") },
    { value: "fj", label: t("country:Fiyi") },
    { value: "fr", label: t("country:Francia") },
    { value: "ga", label: t("country:Gabón") },
    { value: "gm", label: t("country:Gambia") },
    { value: "ge", label: t("country:Georgia") },
    { value: "gh", label: t("country:Ghana") },
    { value: "gi", label: t("country:Gibraltar") },
    { value: "gd", label: t("country:Granada") },
    { value: "gr", label: t("country:Grecia") },
    { value: "gl", label: t("country:Groenlandia") },
    { value: "gp", label: t("country:Guadalupe") },
    { value: "gu", label: t("country:Guam") },
    { value: "gt", label: t("country:Guatemala") },
    { value: "gf", label: t("country:Guayana Francesa") },
    { value: "gg", label: t("country:Guernsey") },
    { value: "gn", label: t("country:Guinea") },
    { value: "gw", label: t("country:Guinea-Bisáu") },
    { value: "gq", label: t("country:Guinea Ecuatorial") },
    { value: "gy", label: t("country:Guyana") },
    { value: "ht", label: t("country:Haití") },
    { value: "hn", label: t("country:Honduras") },
    { value: "hk", label: t("country:Hong Kong") },
    { value: "hu", label: t("country:Hungría") },
    { value: "in", label: t("country:India") },
    { value: "id", label: t("country:Indonesia") },
    { value: "iq", label: t("country:Irak") },
    { value: "ir", label: t("country:Irán") },
    { value: "ie", label: t("country:Irlanda") },
    { value: "bv", label: t("country:Isla Bouvet") },
    { value: "im", label: t("country:Isla de Man") },
    { value: "cx", label: t("country:Isla de Navidad") },
    { value: "is", label: t("country:Islandia") },
    { value: "ky", label: t("country:Islas Caimán") },
    { value: "cc", label: t("country:Islas Cocos") },
    { value: "ck", label: t("country:Islas Cook") },
    { value: "fo", label: t("country:Islas Feroe") },
    {
      value: "gs",
      label: t("country:Islas Georgias del Sur y Sandwich del Sur"),
    },
    { value: "hm", label: t("country:Islas Heard y McDonald") },
    { value: "fk", label: t("country:Islas Malvinas") },
    { value: "mp", label: t("country:Islas Marianas del Norte") },
    { value: "mh", label: t("country:Islas Marshall") },
    { value: "pn", label: t("country:Islas Pitcairn") },
    { value: "sb", label: t("country:Islas Salomón") },
    { value: "tc", label: t("country:Islas Turcas y Caicos") },
    { value: "um", label: t("country:Islas ultramarinas de Estados Unidos") },
    { value: "vg", label: t("country:Islas Vírgenes Británicas") },
    { value: "vi", label: t("country:Islas Vírgenes de los Estados Unidos") },
    { value: "il", label: t("country:Israel") },
    { value: "it", label: t("country:Italia") },
    { value: "jm", label: t("country:Jamaica") },
    { value: "jp", label: t("country:Japón") },
    { value: "je", label: t("country:Jersey") },
    { value: "jo", label: t("country:Jordania") },
    { value: "kz", label: t("country:Kazajistán") },
    { value: "ke", label: t("country:Kenia") },
    { value: "kg", label: t("country:Kirguistán") },
    { value: "ki", label: t("country:Kiribati") },
    { value: "kw", label: t("country:Kuwait") },
    { value: "la", label: t("country:Laos") },
    { value: "ls", label: t("country:Lesoto") },
    { value: "lv", label: t("country:Letonia") },
    { value: "lb", label: t("country:Líbano") },
    { value: "lr", label: t("country:Liberia") },
    { value: "ly", label: t("country:Libia") },
    { value: "li", label: t("country:Liechtenstein") },
    { value: "lt", label: t("country:Lituania") },
    { value: "lu", label: t("country:Luxemburgo") },
    { value: "mo", label: t("country:Macao") },
    { value: "mk", label: t("country:Macedonia") },
    { value: "mg", label: t("country:Madagascar") },
    { value: "my", label: t("country:Malasia") },
    { value: "mw", label: t("country:Malaui") },
    { value: "mv", label: t("country:Maldivas") },
    { value: "ml", label: t("country:Malí") },
    { value: "mt", label: t("country:Malta") },
    { value: "ma", label: t("country:Marruecos") },
    { value: "mq", label: t("country:Martinica") },
    { value: "mu", label: t("country:Mauricio") },
    { value: "mr", label: t("country:Mauritania") },
    { value: "yt", label: t("country:Mayotte") },
    { value: "mx", label: t("country:México") },
    { value: "fm", label: t("country:Micronesia") },
    { value: "md", label: t("country:Moldavia") },
    { value: "mc", label: t("country:Mónaco") },
    { value: "mn", label: t("country:Mongolia") },
    { value: "me", label: t("country:Montenegro") },
    { value: "ms", label: t("country:Montserrat") },
    { value: "mz", label: t("country:Mozambique") },
    { value: "mm", label: t("country:Myanmar") },
    { value: "na", label: t("country:Namibia") },
    { value: "nr", label: t("country:Nauru") },
    { value: "np", label: t("country:Nepal") },
    { value: "ni", label: t("country:Nicaragua") },
    { value: "ne", label: t("country:Níger") },
    { value: "ng", label: t("country:Nigeria") },
    { value: "nu", label: t("country:Niue") },
    { value: "nf", label: t("country:Norfolk") },
    { value: "no", label: t("country:Noruega") },
    { value: "nc", label: t("country:Nueva Caledonia") },
    { value: "nz", label: t("country:Nueva Zelanda") },
    { value: "om", label: t("country:Omán") },
    { value: "nl", label: t("country:Países Bajos") },
    { value: "pk", label: t("country:Pakistán") },
    { value: "pw", label: t("country:Palaos") },
    { value: "ps", label: t("country:Palestina") },
    { value: "pa", label: t("country:Panamá") },
    { value: "pg", label: t("country:Papúa Nueva Guinea") },
    { value: "py", label: t("country:Paraguay") },
    { value: "pe", label: t("country:Perú") },
    { value: "pf", label: t("country:Polinesia Francesa") },
    { value: "pl", label: t("country:Polonia") },
    { value: "pt", label: t("country:Portugal") },
    { value: "pr", label: t("country:Puerto Rico") },
    { value: "gb", label: t("country:Reino Unido") },
    { value: "eh", label: t("country:República Árabe Saharaui Democrática") },
    { value: "cf", label: t("country:República Centroafricana") },
    { value: "cz", label: t("country:República Checa") },
    { value: "cg", label: t("country:República del Congo") },
    { value: "cd", label: t("country:República Democrática del Congo") },
    { value: "do", label: t("country:República Dominicana") },
    { value: "re", label: t("country:Reunión") },
    { value: "rw", label: t("country:Ruanda") },
    { value: "ro", label: t("country:Rumania") },
    { value: "ru", label: t("country:Rusia") },
    { value: "ws", label: t("country:Samoa") },
    { value: "as", label: t("country:Samoa Americana") },
    { value: "bl", label: t("country:San Bartolomé") },
    { value: "kn", label: t("country:San Cristóbal y Nieves") },
    { value: "sm", label: t("country:San Marino") },
    { value: "mf", label: t("country:San Martín") },
    { value: "pm", label: t("country:San Pedro y Miquelón") },
    { value: "vc", label: t("country:San Vicente y las Granadinas") },
    { value: "sh", label: t("country:Santa Elena") },
    { value: "lc", label: t("country:Santa Lucía") },
    { value: "st", label: t("country:Santo Tomé y Príncipe") },
    { value: "sn", label: t("country:Senegal") },
    { value: "rs", label: t("country:Serbia") },
    { value: "sc", label: t("country:Seychelles") },
    { value: "sl", label: t("country:Sierra Leona") },
    { value: "sg", label: t("country:Singapur") },
    { value: "sx", label: t("country:Sint Maarten") },
    { value: "sy", label: t("country:Siria") },
    { value: "so", label: t("country:Somalia") },
    { value: "lk", label: t("country:Sri Lanka") },
    { value: "sz", label: t("country:Suazilandia") },
    { value: "za", label: t("country:Sudáfrica") },
    { value: "sd", label: t("country:Sudán") },
    { value: "ss", label: t("country:Sudán del Sur") },
    { value: "se", label: t("country:Suecia") },
    { value: "ch", label: t("country:Suiza") },
    { value: "sr", label: t("country:Surinam") },
    { value: "sj", label: t("country:Svalbard y Jan Mayen") },
    { value: "th", label: t("country:Tailandia") },
    { value: "tw", label: t("country:Taiwán (República de China)") },
    { value: "tz", label: t("country:Tanzania") },
    { value: "tj", label: t("country:Tayikistán") },
    { value: "io", label: t("country:Territorio Británico del Océano Índico") },
    {
      value: "tf",
      label: t("country:Tierras Australes y Antárticas Francesas"),
    },
    { value: "tl", label: t("country:Timor Oriental") },
    { value: "tg", label: t("country:Togo") },
    { value: "tk", label: t("country:Tokelau") },
    { value: "to", label: t("country:Tonga") },
    { value: "tt", label: t("country:Trinidad y Tobago") },
    { value: "tn", label: t("country:Túnez") },
    { value: "tm", label: t("country:Turkmenistán") },
    { value: "tr", label: t("country:Turquía") },
    { value: "tv", label: t("country:Tuvalu") },
    { value: "ua", label: t("country:Ucrania") },
    { value: "ug", label: t("country:Uganda") },
    { value: "uy", label: t("country:Uruguay") },
    { value: "uz", label: t("country:Uzbekistán") },
    { value: "vu", label: t("country:Vanuatu") },
    { value: "va", label: t("country:Vaticano") },
    { value: "ve", label: t("country:Venezuela") },
    { value: "vn", label: t("country:Vietnam") },
    { value: "wf", label: t("country:Wallis y Futuna") },
    { value: "ye", label: t("country:Yemen") },
    { value: "dj", label: t("country:Yibuti") },
    { value: "zm", label: t("country:Zambia") },
    { value: "zw", label: t("country:Zimbabue") },
  ];

  return COUNTRIES;
};
