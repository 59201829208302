import styled from "styled-components";
import { colors } from "../../../assets/colors/colors";
import { FormRowMixin } from "../../../components/style-components/form-row";
import {
  BodyDefaultMixin,
  BodyLargeBoldMixin,
} from "../../../components/text-components/body-text";
import {
  Heading1Mixin,
  Heading4Mixin,
  Heading6Mixin,
} from "../../../components/text-components/heading-text";

export const RegisterStyle = styled.div`
  display: flex;
  align-items: stretch;
  min-height: calc(100vh - 60px);
  width: 100%;
  position: relative;

  .register-main {
    flex: 1;
    padding: 56px 120px;
    width: 540px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 48%;
    margin-bottom: 60px;
    &__logo {
      width: 164px;
      margin-bottom: 12px;
    }

    &__title {
      ${Heading1Mixin};
      color: ${colors["neutrals-100"]};
      margin-bottom: 24px;
      user-select: none;
    }

    &__progress {
      margin-bottom: 24px;
      width: 100%;
    }

    &__section {
      width: 100%;

      .card-register-contact {
        &__form {
          .checkbox {
            border: none;

            .checkbox-container {
              padding-left: 0;
              padding-right: 0;
            }
          }
        }

        .terms-modal {
          .modal-content {
            padding: 48px 56px;
            max-height: 800px;
            overflow-y: auto;
            max-width: 648px;

            &-main {
              padding: 30px 0;

              &-body > * {
                text-align: left;
              }

              &-body {
                &__title {
                  ${Heading4Mixin};
                }

                &__text {
                  ${BodyDefaultMixin};
                  color: ${colors["neutrals-80"]};
                }
              }
            }
          }
        }
      }
    }

    &__buttons {
      flex-grow: 5;
      display: flex;
      align-items: center;
      margin-top: 32px;
      align-self: flex-end;
      /* position: absolute;
      top: 56px;
      right: 122px; */

      &__item {
        &:not(:first-child) {
          margin-left: 24px;
        }
      }
    }
  }

  .register-side {
    height: 100vh;
    width: 48%;
    flex: 1;
    position: fixed;
    top: 0;
    right: 0;
    z-index: -1;

    picture {
      width: 100%;
      height: 100%;
      display: flex;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 150px 0px 0px 0px;
    }
  }

  .radio-option__label {
    white-space: nowrap;
    overflow: hidden;
  }

  .input-body__selected {
    min-width: 64px;
  }

  .input-bottom {
    position: absolute;
  }

  @media (max-width: 1100px) {
    min-height: calc(100vh - 200px);
    .register-main {
      margin-bottom: 172px;
      padding: 56px 54px;
      margin-right: 0%;

      &__buttons {
        display: flex;
        flex-flow: row nowrap;
        justify-content: stretch;
        position: relative;
        right: initial;
        top: initial;
        margin-top: 36px;

        &__item {
          flex-grow: 1;
          &:not(:first-child) {
            margin-left: 12px;
          }
        }
      }
    }

    .register-side {
      display: none;
    }
  }

  @media (max-width: 768px) {
    .register-main {
      margin-bottom: 260px;
      &__buttons {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }

    .radio-option-container {
      flex-direction: column;
      .radio-option {
        width: 100%;
      }
    }
  }

  /* Sections for cards */

  .card-register-contact {
    &__label {
      ${BodyLargeBoldMixin};
      margin-bottom: 24px;
    }

    &__form {
      ${FormRowMixin};
    }
  }

  .card-register-success {
    &__label {
      display: flex;
      align-items: flex-start;
      margin-bottom: 20px;

      &__text {
        margin-left: 12px;
        margin-top: 6px;
        ${Heading6Mixin};
        color: ${colors["neutrals-100"]};

        @media (max-width: 768px) {
          ${BodyLargeBoldMixin};
        }
      }
    }

    &__message {
      ${BodyDefaultMixin};
      color: ${colors["neutrals-50"]};
    }
  }
`;
