import { createGlobalStyle } from "styled-components";
import promptItalicWoff from '../../assets/fonts/prompt-font/prompt-italic-webfont.woff';
import promptItalicWoff2 from '../../assets/fonts/prompt-font/prompt-italic-webfont.woff2';
import promptLightWoff from '../../assets/fonts/prompt-font/prompt-light-webfont.woff';
import promptLightWoff2 from '../../assets/fonts/prompt-font/prompt-light-webfont.woff2';
import promptLightItalicWoff from '../../assets/fonts/prompt-font/prompt-lightitalic-webfont.woff';
import promptLightItalicWoff2 from '../../assets/fonts/prompt-font/prompt-lightitalic-webfont.woff2';
import promptMediumWoff from '../../assets/fonts/prompt-font/prompt-medium-webfont.woff';
import promptMediumWoff2 from '../../assets/fonts/prompt-font/prompt-medium-webfont.woff2';
import promptMediumItalicWoff from '../../assets/fonts/prompt-font/prompt-mediumitalic-webfont.woff';
import promptMediumItalicWoff2 from '../../assets/fonts/prompt-font/prompt-mediumitalic-webfont.woff2';
import promptRegularWoff from '../../assets/fonts/prompt-font/prompt-regular-webfont.woff';
import promptRegularWoff2 from '../../assets/fonts/prompt-font/prompt-regular-webfont.woff2';

export default createGlobalStyle`
  @font-face {
    font-family: 'Prompt Font';
    src: url(${promptLightWoff2}) format('woff2'),
          url(${promptLightWoff}) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'Prompt Font';
    src: url(${promptLightItalicWoff2}) format('woff2'),
          url(${promptLightItalicWoff}) format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: fallback;
  }

  @font-face {
    font-family: 'Prompt Font';
    src: url(${promptRegularWoff2}) format('woff2'),
          url(${promptRegularWoff}) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'Prompt Font';
    src: url(${promptItalicWoff2}) format('woff2'),
          url(${promptItalicWoff}) format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: fallback;
  }

  @font-face {
    font-family: 'Prompt Font';
    src: url(${promptMediumWoff2}) format('woff2'),
          url(${promptMediumWoff}) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'Prompt Font';
    src: url(${promptMediumItalicWoff2}) format('woff2'),
          url(${promptMediumItalicWoff}) format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: fallback;
  }
`;