import styled from 'styled-components';
import { colors } from '../../../assets/colors/colors';
import { FormRowMixin } from '../../../components/style-components/form-row';
import { BodyDefaultMixin } from '../../../components/text-components/body-text';
import { Heading1Mixin, Heading4Mixin } from '../../../components/text-components/heading-text';

export const PassRecoveryStyle = styled.div`
  display: flex;
  align-items: stretch;
  min-height: calc(100vh - 60px);
  width: 100%;
  position: relative;

  .pass-recovery-main {
    flex: 1;
    padding: 44px 20px;
    padding-left: 120px;
    display: flex;
    flex-flow: column;
    margin-right: 48%;
    &__logo {
      width: 182px;
      margin-bottom: 62px;
      position: relative;

      .other {
        display: block;
      }

      .tablet {
        display: none;
      }
    }

    &__container {
      display: flex;
      flex-flow: column;
      max-width: 430px;
    }

    &__title {
      ${Heading1Mixin};
      color: ${colors['neutrals-100']};
      margin-bottom: 8px;
      user-select: none;
    }

    &__text {
      ${BodyDefaultMixin};
      color: ${colors['neutrals-50']};
      margin-bottom: 48px;
      user-select: none;
    }

    &__buttons {
      margin-top: 48px;
      display: flex;
      justify-content: flex-end;
      &__item {
        /* width: 100%; */

        &:not(:first-child) {
          margin-left: 12px;
        }
      }
    }
  }

  ${FormRowMixin};

  .pass-recovery-form-info {
    background: #FFFFFF;
    box-shadow: 0px 40px 80px rgba(37, 40, 43, 0.05);
    border-radius: 12px;
    padding: 24px;
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    right: 28%;
    width: 306px;

    &__item {
      display: flex;
      align-items: center;
      max-width: 100%;

      &__icon {
        margin-right: 12px;
      }

      &__text {
        ${BodyDefaultMixin};
        color: ${colors['neutrals-80']};
      }

      &:not(:first-child) {
        margin-top: 24px;
      }
    }
  }

  .pass-recovery-side {
    height: 100vh;
    width: 48%;
    flex: 1;
    position: fixed;
    top: 0;
    right: 0;
    z-index: -1;

    picture {
      width: 100%;
      height: 100%;
      display: flex;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 150px 0px 0px 0px;
    }
  }

  @media (max-width: 1100px) {
    flex-flow: column;
    align-items: stretch;
    min-height: 0;

    .pass-recovery-main {
      padding: 0 20px 60px;
      align-items: center;
      margin-right: 0px;
      min-height: unset;

      &__container {
        padding-top: 60px;
      }

      &__logo {
        position: absolute;
        left: 54px;
        top: 60px;

        .other {
          display: none;
        }

        .tablet {
          display: block;
        }
      }

      &__container {
        max-width: 540px;
      }
    }

    .pass-recovery-form-info {
      position: relative;
      top: initial;
      right: initial;
      transform: translateY(0);
      width: 100%;
    }

    .pass-recovery-side {
      max-height: 300px;
      max-width: none;
      width: 100%;
      flex: none;
      order: -1;
      position: relative;

      img {
        max-height: 300px;
        border-radius: 0px 0px 0px 0px;
      }
    }
  }

  @media (max-width: 768px) {
    .pass-recovery-main {
      justify-content: flex-start;
      padding: 44px 20px 26px;
      align-self: stretch;
      align-items: stretch;

      &__container {
        padding-top: 0px;
        max-width: none;
      }

      &__title {
        ${Heading4Mixin};
      }

      &__text {
        margin-bottom: 36px;
      }

      &__logo {
        left: initial;
        top: initial;
        position: relative;
        margin-bottom: 32px;
        width: 130px;

        .other {
          display: block;
        }

        .tablet {
          display: none;
        }
      }
    }

    .pass-recovery-side {
      display: none;
    }
  }
`;