import { ProjectsState } from "./types";

export const projectsSelector = {
  getProject: () => (state: { projects: ProjectsState }) =>
    state.projects.project,
  getContracts: () => (state: { projects: ProjectsState }) =>
    state.projects.contracts,
  getInvoices: () => (state: { projects: ProjectsState }) =>
    state.projects.invoices,
  getAuction: () => (state: { projects: ProjectsState }) =>
    state.projects.auction,
  getTickets: () => (state: { projects: ProjectsState }) =>
    state.projects.tickets,
  getUser: () => (state: { projects: ProjectsState }) => state.projects.user,
  getDocument: () => (state: { projects: ProjectsState }) =>
    state.projects.documentEdit,
  getTicket: () => (state: { projects: ProjectsState }) =>
    state.projects.ticket,
  getPurchaseOrderEdit: () => (state: { projects: ProjectsState }) =>
    state.projects.purchaseOrderEdit,
};
