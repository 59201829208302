import React, { useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Button from "../../../components/button/button";
import ModalCookies from "../../../components/modal-cookies/modal-cookies";
import { MODAL_COOKIES_TEXT } from "../../../components/modal-cookies/modal-cookies-text";
import useReactGa from "../../../hooks/useReactGa";
import { CookiesStyle } from "./cookies-style";

const Cookies: React.FC<any> = (props: any) => {
  const { t, i18n } = useTranslation(["legal", "cookies"]);
  const [showConfig, setShowConfig] = useState(false);

  useReactGa(window.location.pathname, "Cookies");

  const modalText: any = useMemo(() => MODAL_COOKIES_TEXT(t), [i18n.language]);

  return (
    <CookiesStyle>
      <Helmet>
        <title>{t("common:app-title")} - Cookies</title>
      </Helmet>
      <main className="main-container__content">
        <div className="cookies-title">
          <p style={{ textAlign: "center" }}>
            {t("legal:Política de Cookies")}
          </p>
        </div>
        <div className="cookies-text">
          <h4>
            <strong>{t("legal:1. ¿Qué son las cookies?")}</strong>
          </h4>
          <p />
          <p>
            <span>
              {t(
                "legal:Una “cookie” consiste en un fichero enviado desde los servidores del sitio web visitado al dispositivo (PC, Portátil, móvil, tablet…) desde el que accedes a dicha web. Este fichero será almacenado en tu propio navegador utilizado para acceder a la web. El objetivo de este fichero es registrar información de interés para el editor/propietario del sitio web para mejorar tus siguientes visitas."
              )}
            </span>
          </p>
          <p>
            <span>
              {t(
                "legal:La finalidad de una “cookie” es ayudar al editor/propietario del sitio web pues le permite ajustar contenido, diseños y servicios de su sitio de forma personalizada en base a esa información almacenada en tu navegador. Cuando vuelvas a visitar dicho sitio web, gracias a este fichero, el sitio dispondrá de información respecto a tu tráfico web generado previamente, pudiendo reconocer tus preferencias (idioma, país de procedencia, productos previamente consultados,…) y darte un mejor servicio personalizado que resultará en una mejor experiencia de navegación para ti."
              )}
            </span>
          </p>
          <p>
            <span>
              {t(
                "legal:La identificación de visitantes y la asociación de información a estos mediante el uso de cookies está “anonimizada”. Los sitios web no suelen almacenar datos sensibles como nombre, apellidos, DNI, tarjetas bancarias,… sino que realmente identifica a tu navegador en particular y se almacenan datos técnicos y preferencias de usuario. Cada sitio web dispone de una configuración de cookies determinada donde define esta información de interés almacenada respecto al visitante."
              )}
            </span>
          </p>
          <p>
            <span>
              {t(
                "legal:Por otra parte, es importante tener en cuenta también que un sitio web puede disponer de contenidos y servicios dentro de su web de terceros (por ejemplo, banners de publicidad de otro producto) que a su vez tengan sus propias cookies asociadas en base los intereses de información del tercero y no del editor del sitio web en sí."
              )}
            </span>
          </p>
          <p>
            <span>{t("legal:Nuestra web")} </span>
            <a href="http://www.sanzaenergy.com">
              <span>www.sanzaenergy.com</span>
            </a>
            <span>
              {" "}
              {t(
                "legal:también emplea cookies con el fin de mejorar la experiencia y servicio de nuestros visitantes. En la sección 4 te detallamos cuales y su finalidad en particular."
              )}
            </span>
          </p>
          <p />
          <h4>
            <strong>{t("legal:2. ¿Por qué son tan importantes?")}</strong>
          </h4>
          <p />
          <p>
            <span>
              {t(
                "legal:Las Cookies son útiles por varios motivos. Desde un punto de vista técnico, permiten que las páginas web funcionen de forma más ágil y adaptada a las preferencias del Usuario, como por ejemplo almacenar su idioma o la moneda de su país. Además, ayudan a los responsables de los sitios web a mejorar los servicios que ofrecen, gracias a la información estadística que recogen a través de ellas. Finalmente, sirven para hacer más eficiente la publicidad, en caso de utilizarla, al dirigirla de forma más personalizada."
              )}
            </span>
          </p>
          <p />
          <h4>
            <strong>{t("legal:3. Tipos de cookies")}</strong>
          </h4>
          <p />
          <p>
            <span>
              {t(
                "legal:Hoy en día existen muchas variantes de cookies en función de su objetivo. A continuación detallamos algunas de las más utilizadas en función de su tipología"
              )}
            </span>
          </p>
          <p />
          <p>
            <span>{t("legal:Por entidad gestora del dominio")}</span>
          </p>
          <ul>
            <li>
              <span>{t("legal:Cookies propias")}</span>
            </li>
            <li>
              <span>{t("legal:Cookies de terceros")}</span>
            </li>
          </ul>
          <p>
            <span>{t("legal:Por su tiempo de permanencia")}</span>
          </p>
          <ul>
            <li>
              <span>{t("legal:Cookies de sesión")}</span>
            </li>
            <li>
              <span>{t("legal:Cookies persistentes")}</span>
            </li>
          </ul>
          <p>
            <span>{t("legal:Por su finalidad")}</span>
          </p>
          <ul>
            <li>
              <span>{t("legal:Cookies técnicas")}</span>
            </li>
            <li>
              <span>{t("legal:Cookies de personalización")}</span>
            </li>
            <li>
              <span>{t("legal:Cookies de análisis")}</span>
            </li>
            <li>
              <span>{t("legal:Cookies de publicidad")}</span>
            </li>
            <li>
              <span>{t("legal:Cookies de publicidad comportamental")}</span>
            </li>
            <li>
              <span>{t("legal:Cookies de redes sociales")}</span>
            </li>
          </ul>
          <h4>
            <strong>
              {t(
                "legal:4. ¿Qué tipos de cookies utiliza este sitio web y cuálesson sus finalidades?"
              )}
            </strong>
          </h4>
          <p />
          <ul>
            <li>
              <strong>{t("legal:Cookies de análisis")}</strong>
              <span>
                :{" "}
                {t(
                  "legal:Son aquéllas que bien tratadas por nosotros o por terceros, nos permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios del servicio ofertado. Para ello se analiza su navegación en nuestra página web con el fin de mejorar la oferta de productos o servicios que le ofrecemos."
                )}
              </span>
            </li>
          </ul>
          <ul>
            <li>
              <strong>{t("legal:Cookies técnicas")}: </strong>
              <span>
                {t(
                  "legal:Son aquellas que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan, incluyendo aquellas que el editor utiliza para permitir la gestión y operativa de la página web y habilitar sus funciones y servicios, como, por ejemplo, controlar el tráfico y la comunicación de datos, identificar la sesión, acceder a partes de acceso restringido, etc."
                )}
              </span>
            </li>
          </ul>
          <h4 style={{ textAlign: "left" }}>
            <strong>
              {t("legal:Relación de cookies por emisor / entidad")}
            </strong>
          </h4>
          <p />
          <h5 style={{ paddingLeft: "80px" }}>
            <strong>{t("legal:Cookies propias de Sanza")}</strong>
          </h5>
          <table style={{ marginLeft: "40px" }}>
            <tbody style={{ paddingLeft: "40px" }}>
              <tr style={{ paddingLeft: "40px" }}>
                <td style={{ paddingLeft: "40px" }}>
                  <ul>
                    <li style={{ listStyleType: "none" }}>
                      <ul>
                        <li>
                          <span>{t("legal:Nombre")}: </span>
                          <strong>cookielawinfo-checkbox-necessary</strong>
                        </li>
                        <li>
                          <span>{t("legal:Origen")}: www.sanzaenergy.com</span>
                        </li>
                        <li>
                          <span>
                            {t("legal:Uso")}:{" "}
                            {t(
                              "legal:Utilizada para registrar la aceptación de cookies necesarias"
                            )}
                          </span>
                        </li>
                        <li>
                          <span>
                            {t(
                              "legal:Temporalidad: La sesión, expiran al cerrar el navegador"
                            )}
                          </span>
                        </li>
                        <li>
                          <span>
                            {t("legal:Tipo")}: {t("legal:Cookie técnica")}
                          </span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <p />
                  <ul>
                    <li style={{ listStyleType: "none" }}>
                      <ul>
                        <li>
                          <span>{t("legal:Nombre")}: </span>
                          <strong>cookielawinfo-checkbox-non-necessary</strong>
                        </li>
                        <li>
                          <span>{t("legal:Origen")}: www.sanzaenergy.com</span>
                        </li>
                        <li>
                          <span>
                            {t("legal:Uso")}:{" "}
                            {t(
                              "legal:Utilizada para registrar la aceptación de cookies no necesarias"
                            )}
                          </span>
                        </li>
                        <li>
                          <span>
                            {t("legal:Temporalidad")}:{" "}
                            {t(
                              "legal:La sesión, expiran al cerrar el navegador"
                            )}
                          </span>
                        </li>
                        <li>
                          <span>
                            {t("legal:Tipo")}: {t("legal:Cookie técnica")}
                          </span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <p />
                  <ul>
                    <li style={{ listStyleType: "none" }}>
                      <ul>
                        <li>
                          <span>{t("legal:Nombre")}: </span>
                          <strong>pll_language</strong>
                        </li>
                        <li>
                          <span>{t("legal:Origen")}: www.sanzaenergy.com</span>
                        </li>
                        <li>
                          <span>
                            {t("legal:Uso")}:{" "}
                            {t(
                              "legal:Es una cookie persistente y de personalización. Pertenece al plugin de WordPress Polylang y permite guardar el idioma en que se mostrará el site al usuario."
                            )}
                          </span>
                        </li>
                        <li>
                          <span>{t("legal:Temporalidad")}:</span>
                        </li>
                        <li>
                          <span>
                            {t("legal:Tipo")}:{" "}
                            {t("legal:Cookie técnica / personalización")}
                          </span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <p />
                  <h5>
                    <strong>{t("legal:Cookies de terceros")}</strong>
                  </h5>
                  <ul>
                    <li style={{ listStyleType: "none" }}>
                      <ul>
                        <li>
                          <span>{t("legal:Nombre")}: _ga</span>
                        </li>
                        <li>
                          <span>{t("legal:Origen")}: google.com</span>
                        </li>
                        <li>
                          <span>
                            {t("legal:Uso")}:{" "}
                            {t(
                              "legal:Se utiliza para distinguir usuarios únicos mediante la asignación de un número generado aleatoriamente como un identificador de cliente. Se incluye en cada solicitud de página en un sitio y se utiliza para calcular los datos de visitantes, sesiones y campañas para los informes analíticos de los sitios."
                            )}
                          </span>
                        </li>
                        <li>
                          <span>
                            {t("legal:Temporalidad")}: {t("legal:2 años")}
                          </span>
                        </li>
                        <li>
                          <span>
                            {t("legal:Tipo")}: {t("legal:Análisis")}
                          </span>
                        </li>
                        <li>
                          <span>
                            {t("legal:Política de cookies")}:{" "}
                            {t("legal:Más información en")}{" "}
                          </span>
                          <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">
                            <span>{t("legal:política")}</span>
                          </a>
                          <span> {t("legal:de Google Analytics")}.</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <p />
                  <ul>
                    <li style={{ listStyleType: "none" }}>
                      <ul>
                        <li>
                          <span>{t("legal:Nombre")}: _gid</span>
                        </li>
                        <li>
                          <span>{t("legal:Origen")}: google.com</span>
                        </li>
                        <li>
                          <span>
                            {t("legal:Uso")}:{" "}
                            {t(
                              "legal:Es responsable de rastrear el comportamiento del usuario. Caduca después de 24 horas de inactividad. El contenido de esta cookie también es muy similar a la cookie _ga."
                            )}
                          </span>
                        </li>
                        <li>
                          <span>
                            {t("legal:Temporalidad")}: {t("legal:24h")}
                          </span>
                        </li>
                        <li>
                          <span>
                            {t("legal:Tipo")}: {t("legal:Análisis")}
                          </span>
                        </li>
                        <li>
                          <span>
                            {t("legal:Política de cookies")}:{" "}
                            {t("legal:Más información en")}{" "}
                          </span>
                          <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">
                            <span>{t("legal:política")}</span>
                          </a>
                          <span> {t("legal:de Google Analytics.")}</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
          <h4>
            <strong>
              {t(
                "legal:5. ¿Quiénes son los destinatarios de la información¿ ¿Dónde se encuentran?"
              )}
            </strong>
          </h4>
          <p />
          <p>
            <span>
              {t(
                "legal:La información recogida a través de las Cookies puede ser utilizada tanto por Sanza como por terceras empresas colaboradoras, tales como operadores y agentes intervinientes de las redes publicitarias y/o anunciantes, con las finalidades descritas en el apartado anterior."
              )}
            </span>
          </p>
          <p />
          <p>
            <span>
              {t(
                "legal:Algunos de dichos terceros pueden encontrarse ubicados en terceros países cuya legislación no ofrece un nivel de protección de datos equivalente al europeo; si bien el Usuario puede consultar las garantías adecuadas que ofrecen para garantizar que las transferencias internacionales de los datos se realizan teniendo en cuenta los derechos y libertades de los interesados."
              )}
            </span>
          </p>
          <p />
          <p>
            <span>
              {t(
                "legal:El Usuario puede conocer más información acerca de dichos terceros, así como de los tipos de Cookies que instalan, las finalidades para las cuales tratan los datos, las garantías ofrecidas para realizar las transferencias internacionales, los plazos de conservación, y cómo pueden configurarse las preferencias con respecto a los mismos, a través de las políticas de privacidad disponibles en la tabla incluida en el apartado anterior. En cualquier caso, Sanza no se hace responsable de la veracidad y contenido de las políticas de privacidad ofrecidas por dichos terceros en sus sitios web."
              )}
            </span>
          </p>
          <p />
          <h4>
            <strong>{t("legal:6. Gestión de las cookies")}</strong>
          </h4>
          <p />
          <p>
            <span>
              {t(
                "legal:Teniendo en cuenta la información facilitada a través de la presente Política de cookies, a continuación, te ofrecemos información sobre cómo puedes gestionar las cookies utilizadas enla weba través de las diferentes opciones que ofrecen los navegadores más comunes (Explorer, Firefox, Chrome, Safari y Opera).En la función Ayuda de la mayoría de los navegadores se indica cómo configurar su navegador para que no acepte «cookies», para que le notifique cada vez que reciba una nueva «cookie», así como para desactivar completamente todas las «cookies»."
              )}
            </span>
          </p>
          <p>
            <span>
              {t(
                "legal:Así, por ejemplo, puede encontrar información sobre cómo gestionar sus preferencias sobre Cookies en los navegadores más habituales a través de los enlaces siguientes"
              )}
              :
            </span>
          </p>
          <ul>
            <li>
              <span>{t("legal:Firefox desde")} </span>
              <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&amp;redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we">
                <span>{t("legal:aquí")}</span>
              </a>
              <span>.</span>
            </li>
            <li>
              <span>{t("legal:Chrome desde")} </span>
              <a href="https://support.google.com/chrome/answer/95647?hl=es">
                <span>{t("legal:aquí")}</span>
              </a>
              <span>.</span>
            </li>
            <li>
              <span>{t("legal:Explorer desde")} </span>
              <a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies">
                <span>{t("legal:aquí")}</span>
              </a>
              <span>.</span>
            </li>
            <li>
              <span>{t("legal:Safari desde")} </span>
              <a href="https://support.apple.com/kb/ph5042?locale=es_ES">
                <span>{t("legal:aquí")}</span>
              </a>
              <span>.</span>
            </li>
            <li>
              <span>{t("legal:Opera desde")} </span>
              <a href="https://help.opera.com/en/latest/security-and-privacy/">
                <span>{t("legal:aquí")}</span>
              </a>
              <span>.</span>
            </li>
          </ul>
          <p>
            <span>
              {t(
                "legal:Si tu navegador no se encuentra entre los que te hemos listado, consulta la documentación específica de tu navegador."
              )}
            </span>
          </p>
          <p>
            <span>
              {t(
                "legal:Si se impide la instalación de todas las Cookies de la página web, algunas funcionalidades de la misma, así como algunos contenidos pueden verse afectados."
              )}
            </span>
          </p>
          <p>
            <strong />
          </p>
          <h4>
            <strong>{t("legal:7. Información adicional")}</strong>
          </h4>
          <p />
          <p>
            <span>
              {t(
                "legal:En la medida en que la información tratada se encuentre relacionada únicamente con códigos o identificadores únicos de dispositivos o navegadores utilizados por el Usuario, y no esté relacionada con datos que identifiquen que forma directa al mismo, los anteriores derechos no serán aplicables salvo que el Usuario facilite información adicional que permita su identificación."
              )}
            </span>
          </p>
          <p>
            <span>
              {t(
                "legal:Para información adicional acerca del tratamiento de datos, pueden consultarse las Políticas de Privacidad de Sanza o contactar directamente con nosotros."
              )}
            </span>
          </p>
        </div>

        <Button
          type="ghost"
          text={t("legal:Administrar cookies")}
          onClick={() => setShowConfig(true)}
        />
      </main>
      <ModalCookies
        showConfig={showConfig}
        setShowConfig={(value: boolean) => setShowConfig(value)}
        {...modalText}
      />
    </CookiesStyle>
  );
};

export default Cookies;
