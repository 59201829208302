import classnames from "classnames";
import Cleave from "cleave.js/react";
import React from "react";
import { colors } from "../../assets/colors/colors";
import { icons } from "../../assets/icons/icons";
import Button, { IButtonProps } from "../button/button";
import SVGIcon from "../svg-icon/svg-icon";
import Tooltip from "../tooltip/tooltip";
import { InputStyle } from "./input-style";
import Select, { SelectProps } from "./select";

interface IIcon {
  color?: string;
  strokeColor?: string;
  size?: "16px" | "24px" | "32px" | "48px" | "64px" | string;
  src?: string;
  inputFn?: "showPassword" | "clearInput";
  customFn?: () => void;
  classNames?: string;
}

export interface InputProps {
  bottomAction?: IButtonProps;
  className?: string;
  cleaveFormat?: any;
  detail?: any;
  disabled?: boolean;
  error?: boolean | string;
  label?: string;
  leftPhone?: SelectProps;
  maxLength?: number;
  message?: string;
  name?: string;
  onBlur?: any;
  onChange?: any;
  onClick?: any;
  onFocus?: any;
  onKeyDown?: any;
  pattern?: string;
  placeholder?: string;
  range?: { max: number; min: number };
  readonly?: boolean;
  required?: boolean;
  size?: "small" | "medium" | "large";
  success?: boolean | string;
  tooltip?: string;
  type?: "text" | "password" | "number";
  value?: string;
  viewonly?: boolean;
  icon?: IIcon;
  iconRight?: IIcon;
}

const Input: React.FC<InputProps> = (props: InputProps) => {
  const [isShowPass, setIsShowPass] = React.useState<boolean>(false);
  const [focus, setFocus] = React.useState<boolean>(false);

  const runInputFunction = (type: "showPassword" | "clearInput") => {
    switch (type) {
      case "showPassword":
        showPassword();
        break;
      case "clearInput":
        clearInput();
        break;
    }
  };

  const showPassword = () => {
    setIsShowPass(!isShowPass);
  };

  const clearInput = () => {
    if (props.onChange) {
      props.onChange({ target: { value: "" } });
    }
  };

  const getType = (): string => {
    if (props.type === "password" && isShowPass) {
      return "text";
    }
    return props.type || "text";
  };

  const handleChange = (ev: any) => {
    if (
      // pattern
      (((props.pattern && new RegExp(props.pattern).test(ev.target.value)) ||
        !props.pattern) &&
        // maxLength
        ((props.maxLength && ev.target.value.length <= props.maxLength) ||
          !props.maxLength) &&
        // range
        ((props.range &&
          parseFloat(ev.target.value) >= props.range.min &&
          ev.target.value.length <= props.range.max.toString().length) ||
          !props.range)) ||
      ev.target.value === ""
    ) {
      if (props.onChange) {
        props.onChange(ev);
      }
    }
  };

  const getIcon = (): string | void => {
    if (!props.icon) return;

    if (props.icon.inputFn === "showPassword") {
      return isShowPass ? icons.eye : icons.eyeOffLine;
    }

    return props.icon?.src;
  };

  return (
    <InputStyle
      className={classnames(
        props.className || "",
        props.size ? props.size : "",
        {
          error: !!props.error,
          success: !!props.success,
          disabled: !!props.disabled,
          required: !!props.required,
          readonly: !!props.readonly,
          viewonly: !!props.viewonly,
          complete: props.value && props.value.length > 0,
          focus,
        }
      )}
    >
      <div className="input-top">
        {props.tooltip && (
          <Tooltip
            place="bottom"
            content={props.tooltip}
            className="input-top__tooltip"
          >
            <SVGIcon
              icon={icons.information}
              size="16px"
              color={colors["neutrals-50"]}
            />
          </Tooltip>
        )}
        {props.label && (
          <label className="input-top__label" htmlFor={props.name}>
            <p>
              {props.label}
              {props.required && <span>*</span>}
            </p>
          </label>
        )}
      </div>
      <div
        className="input-body"
        onClick={(ev: any) => props.onClick && props.onClick(ev)}
      >
        {props.leftPhone && (
          <Select className="input-body__select" {...props.leftPhone} />
        )}

        {props.icon && (
          <div
            className={classnames("input-body__icon", {
              clickable: !!props.icon.customFn || !!props.icon.inputFn,
            })}
            onClick={(ev: any) => {
              if (props.icon?.inputFn) {
                ev.preventDefault();
                ev.stopPropagation();
                runInputFunction(props.icon.inputFn);
              } else if (props.icon?.customFn) {
                ev.preventDefault();
                ev.stopPropagation();
                props.icon.customFn();
              }
            }}
          >
            <SVGIcon
              icon={getIcon() || ""}
              size={props.icon.size || "16px"}
              color={props.icon.color}
              strokeColor={props.icon.strokeColor}
            />
          </div>
        )}
        {props.cleaveFormat ? (
          <Cleave
            id={props.name}
            type={getType()}
            name={props.name}
            placeholder={props.placeholder}
            value={props.value}
            disabled={props.disabled || props.readonly || props.viewonly}
            required={props.required}
            onChange={(ev: any) => handleChange(ev)}
            onFocus={(ev: any) => props.onFocus && props.onFocus(ev)}
            onBlur={(ev: any) => props.onBlur && props.onBlur(ev)}
            onKeyDown={(ev: any) => props.onKeyDown && props.onKeyDown(ev)}
            options={props.cleaveFormat}
          />
        ) : (
          <input
            type={getType()}
            name={props.name}
            id={props.name}
            placeholder={props.placeholder}
            value={props.value}
            disabled={props.disabled || props.readonly || props.viewonly}
            required={props.required}
            onChange={(ev: any) =>
              !props.disabled && !props.readonly && handleChange(ev)
            }
            onFocus={(ev: any) => {
              if (props.disabled || props.readonly) {
                ev.preventDefault();
                return;
              }
              setFocus(true);
              return props.onFocus && props.onFocus(ev);
            }}
            onBlur={(ev: any) => {
              if (props.disabled || props.readonly) {
                ev.preventDefault();
                return;
              }
              setFocus(false);
              return props.onBlur && props.onBlur(ev);
            }}
            onKeyDown={(ev: any) =>
              !props.disabled &&
              !props.readonly &&
              props.onKeyDown &&
              props.onKeyDown(ev)
            }
          />
        )}
        {props.success && (
          <div className="input-body__icon">
            <SVGIcon
              icon={icons.check}
              size="16px"
              color={colors["success-100"]}
            />
          </div>
        )}
        {props.detail && (
          <div className="input-body__detail">
            <p>{props.detail}</p>
          </div>
        )}
        {props.iconRight && (
          <div
            className={classnames(
              "input-body__iconRight",
              props.iconRight?.classNames || "",
              {
                clickable:
                  !!props.iconRight.customFn || !!props.iconRight.inputFn,
              }
            )}
            onClick={() => {
              if (props.iconRight?.inputFn) {
                runInputFunction(props.iconRight.inputFn);
              } else if (props.iconRight?.customFn) {
                props.iconRight.customFn();
              }
            }}
          >
            <SVGIcon
              icon={props.iconRight.src || ""}
              size={props.iconRight.size || "16px"}
              color={props.iconRight.color}
              strokeColor={props.iconRight.strokeColor}
            />
          </div>
        )}
      </div>
      <div className="input-bottom">
        {props.error && typeof props.error === "string" ? (
          <div className="input-bottom__error">
            <p>{props.error}</p>
          </div>
        ) : (
          props.success &&
          typeof props.success === "string" && (
            <div className="input-bottom__success">
              <p>{props.success}</p>
            </div>
          )
        )}
        {props.message && (
          <div className="input-bottom__message">
            <p>{props.message}</p>
          </div>
        )}
        {props.maxLength && (
          <div className="input-bottom__length">
            <p>
              {props.value?.length} / {props.maxLength}
            </p>
          </div>
        )}
      </div>
      {props.bottomAction && (
        <div className="input-action">
          <Button {...props.bottomAction} />
        </div>
      )}
    </InputStyle>
  );
};

export default Input;
