export const USER_ID = "id";
export const USER_NAME = "name";
export const USER_LASTNAME = "lastname";
export const USER_EMAIL = "email";
export const USER_PHONE = "phone";
export const USER_JOB = "job";
export const USER_COMPANY_NAME = "companyName";
export const USER_COUNTRY = "country";
export const USER_INDUSTRY = "industry";
export const USER_ANNUAL_ELECTRICAL_ENERGY_CONSUMPTION =
  "annualElectricalEnergyConsumption";
export const USER_ANNUAL_EXPENDITURE_ON_ELECTRICITY =
  "annualExpenditureOnElectricity";
export const USER_ESTIMATED_DATE_PPA = "estimatedDatePPA";
export const USER_INSTALLED_POWER_PPA = "installedPowerPPA";
export const USER_STATUS = "userState";
export const USER_ROLE = "role";
export const USER_HAS_PASSWORD = "hasPassword";
export const USER_PASSWORD = "password";
export const USER_PASSWORD_DATE = "passwordDate";
export const USER_IS_ACTIVE = "isActive";
export const USER_IDENTITY_STATUS = "identityStatus";
export const USER_IDENTITY_BUYER = "identityBuyer";
export const USER_IDENTITY_GUARANTOR = "identityGuarantor";
export const USER_DOCUMENTS = "documents";
export const USER_QUALIFICATION = "qualification";
export const USER_QUALIFICATION_STATUS = "status";

export const USER_VALIDATION_CONSUMER_COMPANY = "companyName";
export const USER_VALIDATION_ANNUAL_CONSOLIDATE_ACCOUNTS =
  "annualConsolidatedAccounts";
export const USER_VALIDATION_ANNUAL_ELECTRICAL_ENERGY_CONSUMPTION =
  "annualElectricalEnergyConsumption";
export const USER_VALIDATION_ANNUAL_EXPENDITURE_ELECTRICITY =
  "annualExpenditureOnElectricity";
export const USER_VALIDATION_COVER_WITH_PPA = "howMuchDoYouWantToCoverWithPpa";
export const USER_VALIDATION_CUPS = "cups";
export const USER_VALIDATION_MULTIPOINT_TABLE = "multipointTable";
export const USER_VALIDATION_SAVINGS_SIMULATION = "simulationOfSavings";
export const USER_VALIDATION_STATUS = "status";
export const USER_VALIDATION_USER = "user";
export const USER_TERMS = "legalTermsAccepted";
