import { useEffect, useRef, useState } from "react";

const useTimer = (initialState = 0) => {
  const [timer, setTimer] = useState(initialState);
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const countRef = useRef<any>(null);
  const timerRef = useRef<any>(initialState);

  useEffect(() => {
    if (timer !== timerRef.current) {
      timerRef.current = timer;
    }
    if (timer === 0) {
      handlePause();
    }
  }, [timer]);

  const handleStart = () => {
    setIsActive(true);
    setIsPaused(false);
    countRef.current = setInterval(() => {
      if (timerRef.current > 0) {
        setTimer((value) => value - 1);
      } else {
        handlePause();
      }
    }, 1000);
  };

  const handlePause = () => {
    clearInterval(countRef.current);
    setIsPaused(true);
  };

  const handleResume = () => {
    setIsPaused(false);
    countRef.current = setInterval(() => {
      if (timerRef.current > 0) {
        setTimer((value) => value - 1);
      } else {
        handlePause();
      }
    }, 1000);
  };

  const handleReset = () => {
    clearInterval(countRef.current);
    setIsActive(false);
    setIsPaused(true);
    setTimer(initialState);
    timerRef.current = initialState;
  };

  const formatTime = (value: number) => {
    const getSeconds = `0${value % 60}`.slice(-2);
    const minutes = `${Math.floor(value / 60)}`;
    const getMinutes = `0${parseInt(minutes, 10) % 60}`.slice(-2);
    const getHours = `0${Math.floor(value / 3600)}`.slice(-2);

    return `${getHours} : ${getMinutes} : ${getSeconds}`;
  };

  return {
    timer,
    setTimer,
    isActive,
    isPaused,
    handleStart,
    handlePause,
    handleResume,
    handleReset,
    formatTime,
  };
};

export default useTimer;
