import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";
import { icons } from "../../../assets/icons/icons";
import Button from "../../../components/button/button";
import Footer from "../../../components/cross/footer/footer";
import InputController from "../../../components/input/input-controller";
import ValidationsPassword from "../../../components/validations-password/validations-password";
import useQuery from "../../../hooks/useQuery";
import useReactGa from "../../../hooks/useReactGa";
import { routes } from "../../../routes/routes";
import { DashboardApi } from "../../../services/rest-client";
import { loaderActions } from "../../../store/loader/actions";
import {
  hasDigits,
  hasLowercase,
  hasSymbol,
  hasUppercase,
  isMinLength,
  isPasswordsEqual,
} from "../../../utils/validation";
import { CredentialsStyle } from "./credentials-style";

const Credentials: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const { t, i18n } = useTranslation(["auth", "validations"]);
  const dispatch = useDispatch();
  useReactGa(
    window.location.pathname,
    window.location.pathname.indexOf("change-password") > -1
      ? "Cambiar contraseña"
      : "Generar Contraseña"
  );

  const {
    control,
    handleSubmit,
    errors,
    watch,
    setError: setErrorForm,
  } = useForm<any>({
    defaultValues: {
      // oldPassword: "",
      password: "",
      repeatPassword: "",
    },
    mode: "onChange",
    criteriaMode: "all",
  });

  const allFields = watch();

  const recovery = React.useMemo(
    () => location.pathname.indexOf("change-password") > -1,
    [location.pathname]
  );

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const token = query.get("token");
    if (!token) {
      history.push(routes.root);
    }
  }, [history]);

  const handleChangePassword = async (data: any) => {
    try {
      dispatch(loaderActions.loading());
      const token = query.get("token");

      await DashboardApi.patch(
        "/users/update-password",
        {
          ...data,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
          params: { locale: i18n.language },
        }
      );
      setDisabled(true);
      toast.success(t("auth:Contraseña cambiada"));
    } catch (err) {
      const error = DashboardApi.errorHandler(err);
      toast.error(error.message);
    } finally {
      dispatch(loaderActions.loaded());
    }
  };

  return (
    <>
      <Helmet>
        {recovery ? (
          <title>{t("common:app-title")} - Change Password</title>
        ) : (
          <title>{t("common:app-title")} - Generate Password</title>
        )}
      </Helmet>
      <CredentialsStyle>
        <div className="credentials-main">
          <div className="credentials-main__logo">
            <img
              className="tablet"
              src={icons.fullLogoWhite}
              alt="Sanza logo"
            />
            <img className="other" src={icons.fullLogo} alt="Sanza logo" />
          </div>

          <div className="credentials-main__container">
            <div className="credentials-main__title">
              <p>
                {recovery
                  ? t("auth:Restablecer contraseña")
                  : t("auth:Generar credenciales")}
              </p>
            </div>
            <div className="credentials-main__text">
              <p>
                {recovery
                  ? t("auth:Crea tu nueva contraseña")
                  : t(
                      "auth:Crea tu propia contraseña para acceder a la plataforma"
                    )}
              </p>
            </div>
            <form onSubmit={handleSubmit(handleChangePassword)}>
              <div className="credentials-form">
                <div className="form-row">
                  <InputController
                    inputProps={{
                      required: true,
                      placeholder: t("auth:Introduce tu contraseña"),
                      label: t("auth:Nueva Contraseña"),
                      type: "password",
                      icon: {
                        inputFn: "showPassword",
                      },
                      error: !!errors.password,
                    }}
                    controllerProps={{
                      control,
                      name: "password",
                      rules: {
                        required: true,
                        validate: {
                          isMinLength,
                          hasUppercase,
                          hasDigits,
                          hasLowercase,
                          hasSymbol,
                        },
                      },
                      onChange: (ev: any) => {
                        if (
                          ev.target.value &&
                          allFields.repeatPassword &&
                          !isPasswordsEqual(
                            ev.target.value,
                            allFields.repeatPassword
                          )
                        ) {
                          setErrorForm("repeatPassword", {
                            type: "isEqualPasswords",
                          });
                        } else {
                          setErrorForm("repeatPassword", {});
                        }
                      },
                    }}
                  />
                </div>
                <div className="form-row">
                  <InputController
                    inputProps={{
                      required: true,
                      placeholder: t("auth:Repetir contraseña"),
                      label: t("auth:Repite tu contraseña"),
                      type: "password",
                      icon: {
                        inputFn: "showPassword",
                      },
                      error: !!errors.repeatPassword?.type,
                    }}
                    controllerProps={{
                      control,
                      name: "repeatPassword",
                      rules: {
                        required: true,
                        validate: {
                          isEqualPasswords: (value: string) =>
                            !allFields.password ||
                            isPasswordsEqual(allFields.password, value),
                        },
                      },
                    }}
                  />
                </div>
                <div className="credentials-form-info">
                  <ValidationsPassword
                    validations={[
                      {
                        text: t("auth:Mínimo de 8 caracteres"),
                        error: !isMinLength(allFields.password),
                      },
                      {
                        text: t("auth:Al menos una letra minúscula"),
                        error: !hasLowercase(allFields.password),
                      },
                      {
                        text: t("auth:Al menos una letra mayúscula"),
                        error: !hasUppercase(allFields.password),
                      },
                      {
                        text: t("auth:Requiere ser alfanumérica"),
                        error: !hasDigits(allFields.password),
                      },
                      {
                        text: t(
                          "auth:Requiere un carácter especial ($%&()/.,)"
                        ),
                        error: !hasSymbol(allFields.password),
                      },
                      {
                        text: t("auth:Ambas contraseñas coinciden"),
                        error: !isPasswordsEqual(
                          allFields.password,
                          allFields.repeatPassword
                        ),
                      },
                    ]}
                  />
                </div>
                <div className="credentials-main__buttons">
                  <Button
                    className="credentials-main__buttons__item"
                    type="secondary"
                    size="xxl"
                    text={t("auth:Ir a login")}
                    onClick={() => history.push(routes.root)}
                  />
                  <Button
                    className="credentials-main__buttons__item"
                    type="primary"
                    size="xxl"
                    text={t("auth:Generar credenciales")}
                    onClick={handleSubmit(handleChangePassword)}
                    disabled={disabled}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="credentials-side">
          <picture>
            <source
              srcSet={
                recovery
                  ? icons.credentialsRecoveryBgBig
                  : icons.credentialsBgBig
              }
              media="(min-width: 1100px)"
            />
            <img
              src={
                recovery
                  ? icons.credentialsRecoveryBgSmall
                  : icons.credentialsBgSmall
              }
              alt=""
            />
          </picture>
        </div>
      </CredentialsStyle>
      <Footer />
    </>
  );
};

export default Credentials;
