import styled from "styled-components";
import { colors } from "../../assets/colors/colors";
import { BodyDefaultMixin } from "../text-components/body-text";

export const RadioStyle = styled.div`
  background: #ffffff;
  display: flex;
  flex-flow: column;

  .radio-label {
    ${BodyDefaultMixin};
    color: #222222;
    margin-bottom: 12px;
  }

  .radio-option-container {
    display: flex;
    align-items: center;
    margin: -12px;
  }

  .radio-item {
    pointer-events: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border: 1px solid ${colors["neutrals-30"]};
    transition: 0.3s ease-in-out all;
    border-radius: 50%;

    &__circle  {
      position: absolute;
      left: 50%;
      top: 50%;
      background: ${colors["brand-1-100"]};
      width: 10px;
      height: 10px;
      transform: translate(-50%, -50%) scale(0);
      border-radius: 50%;
      transition: 0.3s ease-in-out all;
    }
  }

  .radio-option {
    padding-left: 32px;
    padding-right: 24px;
    margin: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.3s ease-in-out all;
    position: relative;

    input {
      margin: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }

    &__label {
      margin-left: 8px;
      ${BodyDefaultMixin};
      transition: 0.3s ease-in-out all;
      color: ${colors["neutrals-30"]};
      user-select: none;
    }

    &:hover {
      .radio-item {
        border-color: #a7a7a7;
      }

      .radio-option__label {
        color: #a7a7a7;
      }
    }

    &.active {
      .radio-item {
        border-color: ${colors["brand-1-100"]};

        &__circle {
          transform: translate(-50%, -50%) scale(1);
        }
      }

      .radio-option {
        &__label {
          color: ${colors["brand-1-100"]};
        }
      }
    }
  }

  &.disabled {
    .radio-option {
      &__label {
        color: ${colors["neutrals-30"]};
      }
    }

    .radio-item {
      border-color: ${colors["neutrals-30"]};
    }
  }

  &.card {
    .radio-option {
      border-radius: 12px;
      background: ${colors["neutrals-0"]};
      border: 1px solid ${colors["neutrals-10"]};
      padding: 15px 32px;
      padding-right: 24px;
      height: 46px;

      &:hover {
        border-color: #39996b;

        .radio-item {
          border-color: #39996b;
        }

        .radio-option__label {
          color: #39996b;
        }
      }

      &.active {
        border-color: ${colors["brand-1-100"]};

        .radio-item {
          border-color: ${colors["brand-1-100"]};
        }

        .radio-option__label {
          color: ${colors["brand-1-100"]};
        }
      }
    }

    &.error {
      .radio-option {
        border-color: ${colors["error-100"]};
      }
    }

    &.disabled {
      .radio-option {
        border-color: ${colors["neutrals-10"]};
      }
    }
  }
`;
