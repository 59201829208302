import classnames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { colors } from "../../assets/colors/colors";
import { icons } from "../../assets/icons/icons";
import { PREFIX_SELECT } from "../../constants/options/prefix-phone";
import Button, { IButtonProps } from "../button/button";
import SVGIcon from "../svg-icon/svg-icon";
import Tooltip from "../tooltip/tooltip";
import Input from "./input";
import { InputPhoneContainerStyle } from "./input-style";
import Select from "./select";

interface IIcon {
  color?: string;
  strokeColor?: string;
  size?: "16px" | "24px" | "32px" | "48px" | "64px" | string;
  src?: string;
  inputFn?: "showPassword" | "clearInput";
  customFn?: () => void;
  classNames?: string;
}

export interface InputPhoneProps {
  bottomAction?: IButtonProps;
  className?: string;
  disabled?: boolean;
  error?: boolean | string;
  label?: string;
  maxLength?: number;
  message?: string;
  name?: string;
  onBlur?: any;
  onChange?: any;
  onClick?: any;
  placeholderSelect?: string;
  placeholderInput?: string;
  required?: boolean;
  size?: "small" | "medium" | "large";
  success?: boolean | string;
  tooltip?: string;
  value?: string;
  icon?: IIcon;
  iconRight?: IIcon;
}

const InputPhone: React.FC<InputPhoneProps> = (props: InputPhoneProps) => {
  const [valueSelect, setValueSelect] = useState<any>("");
  const [valueInput, setValueInput] = useState<string>("");

  const arrayPrefix = useMemo(
    () => PREFIX_SELECT.map((item: any) => item.value),
    []
  );

  const first = useRef(true);

  useEffect(() => {
    if (props.value && first.current) {
      first.current = false;
      handleValue();
    }
  }, [props.value]);

  const handleValue = () => {
    let result = null;
    for (const prefix of arrayPrefix) {
      const re = new RegExp(`\\S*(\\${prefix})(\\d+)`, "g");
      result = re.exec(props.value || "");
      if (result) break;
    }
    if (result) {
      setValueSelect({ label: result[1], value: result[1] });
      setValueInput(result[2]);
    }
  };

  const onChange = (val: string, type: "select" | "input") => {
    first.current = false;
    if (props.onChange) {
      let valFinal = "";
      if (type === "select") {
        valFinal = `${val || ""}${valueInput || ""}`;
      } else {
        valFinal = `${valueSelect?.value || ""}${val || ""}`;
      }
      props.onChange(valFinal);
    }
  };

  const onBlur = () => {
    first.current = false;
    if (props.onChange) {
      const val = `${valueSelect?.value || ""}${valueInput || ""}`;
      props.onBlur(val);
    }
  };

  const handleValuePrefix = (val: { label: string; value: string }) => {
    setValueSelect({ ...val });
    onChange(val.value, "select");
  };

  const handleValueInput = (val: string) => {
    setValueInput(val);
    onChange(val, "input");
  };

  return (
    <InputPhoneContainerStyle
      className={classnames(
        props.className || "",
        props.size ? props.size : "",
        {
          error: !!props.error,
          success: !!props.success,
          disabled: !!props.disabled,
          required: !!props.required,
          complete: props.value && props.value.length > 0,
        }
      )}
    >
      <div className="input-phone-top">
        {props.tooltip && (
          <Tooltip
            place="bottom"
            content={props.tooltip}
            className="input-phone-top__tooltip"
          >
            <SVGIcon
              icon={icons.information}
              size="16px"
              color={colors["neutrals-50"]}
            />
          </Tooltip>
        )}
        {props.label && (
          <label className="input-phone-top__label" htmlFor={props.name}>
            <p>
              {props.label}
              {props.required && <span>*</span>}
            </p>
          </label>
        )}
      </div>
      <div
        className="input-phone-body"
        onClick={(ev: any) => props.onClick && props.onClick(ev)}
      >
        <Select
          className="input-select"
          placeholder={props.placeholderSelect}
          options={PREFIX_SELECT}
          value={valueSelect}
          onChange={(val: any) => handleValuePrefix(val)}
          onBlur={onBlur}
        />
        <Input
          placeholder={props.placeholderInput}
          value={valueInput}
          onChange={(ev: any) => handleValueInput(ev.target.value)}
          onBlur={onBlur}
        />
      </div>
      <div className="input-phone-bottom">
        {props.error && typeof props.error === "string" ? (
          <div className="input-phone-bottom__error">
            <p>{props.error}</p>
          </div>
        ) : (
          props.success &&
          typeof props.success === "string" && (
            <div className="input-phone-bottom__success">
              <p>{props.success}</p>
            </div>
          )
        )}
        {props.message && (
          <div className="input-phone-bottom__message">
            <p>{props.message}</p>
          </div>
        )}
        {props.maxLength && (
          <div className="input-phone-bottom__length">
            <p>
              {props.value?.length} / {props.maxLength}
            </p>
          </div>
        )}
      </div>
      {props.bottomAction && (
        <div className="input-phone-action">
          <Button {...props.bottomAction} />
        </div>
      )}
    </InputPhoneContainerStyle>
  );
};

export default InputPhone;
