import React from 'react'
import { ProgressBarStyle } from './progress-bar-style'

interface IProgressBar {
  percentage: number;
}

const ProgressBar: React.FC<IProgressBar> = ({ percentage }) => {
  return (
    <ProgressBarStyle>
      <div className="progress" style={{ width: `${percentage}%` }}/>
    </ProgressBarStyle>
  )
}

export default ProgressBar