export enum InstalledPowerPpaEnum {
  ZERO_TO_FIFTY = "zero_to_fifty",
  FIFTY_TO_TWO_HUNDRED = "fifty_to_two_hundred",
  TWO_HUNDRED_TO_FIVE_HUNDRED = "two_hundred_to_five_hundred",
  MORE_THAN_FIVE_HUNDRED = "more_than_five_hundred",
}

export enum EstimatedDatePpaEnum {
  ONE_TO_THREE_MONTHS = "one_to_three_months",
  THREE_TO_SIX_MONTHS = "three_to_six_months",
  SIX_TO_TWELVE_MONTHS = "six_to_twelve_months",
  MORE_THAN_TWELVE_MONTHS = "more_than_twelve_months",
}

export enum UserRolesEnum {
  BUYER = "buyer",
  MANAGER = "manager",
  GENERATOR = "generator",
  ADVISER = "adviser",
}

export enum UserStateEnum {
  APPROVED = "approved",
  REJECTED = "rejected",
  ON_HOLD = "on_hold",
}

export enum UserIdentityStatusEnum {
  DRAFT = "draft",
  VALIDATING = "validating",
  NEEDS_MORE_INFO = "needs_more_info",
  APPROVED = "approved",
  REJECTED = "rejected",
}

export enum UserQualificationStatusEnum {
  DRAFT = "draft",
  VALIDATING = "validating",
  NEEDS_MORE_INFO = "needs_more_info",
  APPROVED = "approved",
  REJECTED = "rejected",
}

export enum UserStatusEnum {
  APPROVED = "approved",
  REJECTED = "rejected",
  ON_HOLD = "on_hold",
}

export enum UserStateActiveEnum {
  ACTIVE = "true",
  BLOCKED = "false",
}

export enum ValueAsAConsumerCompanyEnum {
  DIRECT = "direct",
  GUARANTOR = "guarantor",
}
