import classnames from 'classnames';
import React from "react";
import { colors } from "../../assets/colors/colors";
import { icons } from "../../assets/icons/icons";
import SVGIcon from "../svg-icon/svg-icon";
import { ValidationsPasswordStyle } from "./validations-password-style";

interface Validations {
  text: string;
  error: boolean;
}

interface IValidationsPassword {
  validations: Validations[];
  className?: string;
}

const ValidationsPassword: React.FC<IValidationsPassword> = ({
  validations,
  className,
}) => {
  return (
    <ValidationsPasswordStyle className={classnames(className, 'validation-password')}>
      {validations.map((validation: Validations, index: number) => (
        <div
          key={`validation-${index}`}
          className="validation-password-item"
        >
          <div className="validation-password-item__icon">
            <SVGIcon
              icon={
                validation.error ? icons.credentialsX : icons.credentialsCheck
              }
              size="16px"
              color={
                validation.error ? colors["error-100"] : colors["success-100"]
              }
            />
          </div>
          <div className="validation-password-item__text">
            <p>{validation.text}</p>
          </div>
        </div>
      ))}
    </ValidationsPasswordStyle>
  );
};

export default ValidationsPassword;
