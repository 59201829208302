import styled from "styled-components";
import { colors } from "../../assets/colors/colors";

export const ModalContainerStyle = styled.div`
  .box-modal-cookies.off {
    display: none;
  }

  .box-modal-cookies {
    font-family: 'Proxima Nova', sans-serif;
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(255, 255, 255);
    width: 100%;
    z-index: 999999;
    padding: 40px 80px;
    box-shadow: 0 -1px 10px 0 rgb(172 171 171 / 30%);
    border-radius: 2px;
  }

  .box-modal-cookies-buttons {
    display: flex;
    flex-flow: column;
    margin-left: 40px;
    &__item {
      width: 200px;
      transition: all 0.3s ease-in-out;
      &:not(:first-child) {
        margin-top: 16px;
      }
    }
  }

  .btn-ok-cookies {
    padding: 16px 24px;
    background-color:  ${colors["brand-1-100"]};
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    border-radius: 12px;
    color: #ffffff;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    width: 100%;
    border: none;
    height: 48px;

    &:hover {
      opacity: 0.8;
    }
  }

  .btn-options-cookies {
    padding: 16px 24px;
    border: 1px solid white;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    border-radius: 12px;
    color: ${colors["brand-1-100"]};
    font-weight: 400;
    transition: all 0.3s ease-in-out;
    width: 100%;
    cursor: pointer;
    background: white;
    height: 48px;

    &:hover {
      opacity: 0.8;
      border: 1px solid${colors["brand-1-100"]};
    }
  }

  .modal-description {
    align-self: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #091e42;
    font-family: 'Proxima Nova', sans-serif;
    flex: 1;
  }

  .modal-cookies-settings {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 126;
    opacity: 0;
    visibility: hidden;
    overflow-y: hidden;
    display: none;
    background: rgba(17, 17, 17, 0.83);

    &.active {
      overflow-y: scroll;
      display: block;
    }
  }

  .modal-cookies-settings-container {
    position: relative;
    padding: 80px;
    background: #ffffff;
    width: calc(100% - 160px);
    max-width: 846px;
    z-index: 1;
    margin: 80px auto;
  }

  .modal-cookies-settings-header {
    display: flex;
  }

  .modal-cookies-settings-header-title {
    font-size: 20px;
    color: rgb(114, 114, 114);
  }

  .modal-cookies-settings-close {
    position: absolute;
    cursor: pointer;
    width: 30px;
    height: 24px;
    top: 30px;
    right: 30px;
  }

  .modal-cookies-settings-close-top,
  .modal-cookies-settings-close-bottom {
    width: 20px;
    height: 2px;
    background: ${colors["brand-1-100"]};
    transition: all 0.3s ease-in-out;
    position: absolute;
  }

  .modal-cookies-settings-close-top {
    top: 10px;
    right: 7px;
    transform: rotate(45deg);
  }

  .modal-cookies-settings-close-bottom {
    top: 10px;
    right: 7px;
    transform: rotate(-45deg);
  }

  .modal-cookies-settings-text {
    color: #111111;
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Proxima Nova', sans-serif;
  }

  .modal-cookies-settings-final-text {
    color: #111111;
    margin-top: 40px;
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Proxima Nova', sans-serif;
  }

  .modal-cookies-settings-options-container {
    margin-bottom: 40px;
  }
  
  .modal-cookies-settings-options-title {
    color: #2a2a2a;
    margin-bottom: 16px;
    flex: auto;
    font-family: 'Proxima Nova', sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
  }

  .modal-cookies-settings-options-item {
    display: flex;
    flex: 1;
    cursor: pointer;
    user-select: none;
  }

  .modal-cookies-settings-drop-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #111111;
    position: relative;
    margin-left: 8px;
    font-family: 'Proxima Nova', sans-serif;
    transition: all 0.3s ease-in-out;
  }

  .modal-cookies-settings-options-more-info {
    padding-top: 4px;
  }

  .modal-cookies-settings-options-more-info-icon {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    min-width: 15px;
    min-height: 15px;
    border-radius: 100%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  .modal-cookies-settings-options-more-info-text {
    font-size: 12px;
    font-weight: 700;
    color: #000000;
    padding: 1px;
  }

  .modal-cookies-settings-options-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #111111;
    margin-left: 24px;
    margin-right: 50px;
    opacity: 0;
    visibility: hidden;
    max-height: 0px;
    font-family: 'Proxima Nova', sans-serif;
    transition: all 0.6s ease-in-out;

    &.active {
      max-height: 1000px;
      padding-top: 8px;
    }

    p:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .settings-footer {
    display: flex;
    padding: 10px;
    justify-content: center;
  }

  .modal-cookies-settings-save {
    text-align: center;
    display: flex;
    cursor: pointer;
    font-family: 'Proxima Nova', sans-serif;

    & > * {
      flex-basis: calc(50% - 12px);

      &:not(:first-child) {
        margin-left: 24px;
      }
    }

    &__success-button {
      background: ${colors["brand-1-100"]};
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      width: 200px;
      padding: 16px 24px;
      transition: all 0.3s ease-in-out;

      &:hover {
        opacity: 0.8;
      }
    }

    &__reject-button {
      padding: 16px 24px;
      border: 1px solid ${colors["brand-1-100"]};
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      border-radius: 12px;
      color: ${colors["brand-1-100"]};
      font-weight: 400;
      transition: all 0.3s ease-in-out;
      width: 100%;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .active {
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease-in-out;
  }

  .modal-cookies-settings-options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${colors["neutral-5"]};
    padding: 8px 0;

    &:not(:first-child) {
      margin-top: 8px;
    }

    &.open {
      .modal-cookies-settings-drop-title {
        color: ${colors["brand-1-100"]};
      }

      .modal-cookies-settings-options-more-info-icon {
        transform: rotate(180deg);
      }
    }
  }

  @media (max-width: 1024px) {
    .box-modal-cookies {
      padding: 40px 48px;
    }

    .modal-cookies-pop-buttons {
      flex-direction: column;
    }

    .modal-cookies-pop-buttons-secondary {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 768px) {
    .modal-cookies-settings-close {
      top: 18px;
      right: 18px;
    }

    .box-modal-cookies {
      padding: 24px;
      flex-flow: column;
    }

    .box-modal-cookies-buttons {
      margin-left: 0;
      margin-top: 24px;

      &__item {
        width: 100%;
      }
    }

    .modal-cookies-settings-container {
      width: auto;
      max-width: unset;
      margin: 40px 20px;
      padding: 56px 24px;
    }

    .modal-cookies-pop-container {
      flex-direction: column;
    }

    .modal-cookies-pop-buttons {
      flex-direction: row;
    }

    .modal-cookies-pop-buttons-secondary {
      margin-bottom: 0px;
      margin-left: 0;
    }

    .modal-cookies-pop-text {
      margin-bottom: 20px;
    }

    .modal-cookies-settings-options {
      flex-direction: column;

      &:not(:first-child) {
        margin-top: 32px;
      }
    }

    .modal-cookies-settings-options-info {
      margin-bottom: 12px;
    }

    .modal-cookies-settings-option-buttons {
      margin-left: 0;
    }

    .modal-cookies-settings-save {
      flex-flow: wrap;

      & > * {
        flex-basis: 100%;

        &:not(:first-child) {
          margin-left: 0;
        }
      }

      &__success-button {
        order: -1;
        margin-bottom: 16px;
      }
    }
  }

  p[id*="toggle-input-option-text-"] {
    min-width: fit-content;
    align-self: flex-start;
    margin-top: 6px;
    margin-right: 8px;
  }
`;
