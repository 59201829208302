import classnames from "classnames";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-media";
import { useHistory, useLocation } from "react-router";
import Button from "../../../../components/button/button";
import Can from "../../../../components/can/can";
import Tab from "../../../../components/tab/tab";
import useOutsideClick from "../../../../hooks/useOutSideClick";
import { routes } from "../../../../routes/routes";
import {
  INotification,
  IRequest,
} from "../../../../types/common/interfaces/notifications-interfaces";
import CardNotification from "../card-notification/card-notification";
import CardRequest from "../card-request/card-request";
import { HeaderNotificationsStyle } from "./header-notifications-style";

interface IHeaderNotificationsProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  markedReadNotification: (id: string, isHeader?: boolean) => void;
  markedReadRequest: (id: string, isHeader?: boolean) => void;
  notifications: INotification[];
  requests: IRequest[];
}

const HeaderNotifications: React.FC<IHeaderNotificationsProps> = ({
  open,
  setOpen,
  notifications,
  requests,
  markedReadRequest,
  markedReadNotification,
}) => {
  const { t } = useTranslation("notifications");
  const history = useHistory();
  const location = useLocation();

  const isMobile = useMedia({ query: "(max-width: 768px)" });

  const [render, setRender] = useState<"notifications" | "requests">(
    "notifications"
  );
  const toggleContainer = useRef(null);

  const onClickOutsideHandler = () => {
    if (open) {
      setOpen(false);
    }
  };

  useOutsideClick(toggleContainer, onClickOutsideHandler);

  const renderList = () => {
    const items = render === "notifications" ? notifications : requests;
    if (items.length === 0) {
      return <p>{t("notifications:No hay resultados")}</p>;
    }
    if (render === "notifications") {
      return notifications
        .slice(0, 5)
        .map((notification: INotification) => (
          <CardNotification
            key={`card-notification-in-header-${notification.id}`}
            notification={notification}
            markedReadNotification={markedReadNotification}
            closeNotification={() => setOpen(false)}
            isHeader
            className="in-header"
          />
        ));
    }
    return requests
      .slice(0, 5)
      .map((request: IRequest) => (
        <CardRequest
          key={`card-request-in-header-${request.id}`}
          request={request}
          markedReadRequest={markedReadRequest}
          isHeader
          className="in-header"
        />
      ));
  };

  return (
    <HeaderNotificationsStyle>
      <div
        ref={toggleContainer}
        className={classnames("header-notifications", {
          open,
        })}
      >
        <div className="header-notifications__tabs">
          <Tab
            options={{
              text: t("notifications:Notificaciones"),
              active: render === "notifications",
              onClick: () =>
                render === "requests" ? setRender("notifications") : "",
            }}
          />
          <Can
            perform={"requests:read"}
            yes={() => (
              <Tab
                options={{
                  text: t("notifications:Solicitudes"),
                  active: render === "requests",
                  onClick: () =>
                    render === "notifications" ? setRender("requests") : "",
                }}
              />
            )}
            no={() => null}
          />
        </div>

        <div className="header-notifications__list">{renderList()}</div>

        <div className="header-notifications__spacer" />
        <div className="header-notifications__actions">
          <Button
            type="secondary"
            text={t("notifications:Ver todo")}
            size={isMobile ? "l" : "xxl"}
            onClick={() => {
              history.push(routes.notifications, { render });
              setOpen(false);
            }}
          />
          {render === "notifications" ? (
            <Can
              perform={"notifications:create"}
              yes={() => (
                <Button
                  type="primary"
                  text={t("notifications:Crear nueva")}
                  size={isMobile ? "l" : "xxl"}
                  onClick={() => {
                    history.push(routes.createNotification, {
                      pathname: location.pathname,
                    });
                    setOpen(false);
                  }}
                />
              )}
              no={() => null}
            />
          ) : null}
        </div>
      </div>
    </HeaderNotificationsStyle>
  );
};

export default HeaderNotifications;
