import { IBaseAction } from "../base-action.interface";

export enum NotificationsActions {
  UPDATE_NOTIFICATIONS_HEADER = "UPDATE_NOTIFICATIONS_HEADER",
  UPDATE_REQUESTS_HEADER = "UPDATE_REQUESTS_HEADER",
  UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS",
  UPDATE_REQUESTS = "UPDATE_REQUESTS",
  UPDATE_NOTIFICATIONS_TOTAL = "UPDATE_NOTIFICATIONS_TOTAL",
  UPDATE_REQUESTS_TOTAL = "UPDATE_REQUESTS_TOTAL",
}

export interface NotificationsState {
  readonly notifications: any[];
  readonly requests: any[];
  readonly notificationsTotal: number;
  readonly requestsTotal: number;
  readonly notificationsHeader: any[];
  readonly requestsHeader: any[];
}

interface NotificationsHeaderAction extends IBaseAction {
  type: NotificationsActions.UPDATE_NOTIFICATIONS_HEADER;
  payload: any;
}
interface RequestsHeaderAction extends IBaseAction {
  type: NotificationsActions.UPDATE_REQUESTS_HEADER;
  payload: any;
}

interface NotificationsAction extends IBaseAction {
  type: NotificationsActions.UPDATE_NOTIFICATIONS;
  payload: any;
}
interface RequestsAction extends IBaseAction {
  type: NotificationsActions.UPDATE_REQUESTS;
  payload: any;
}

interface NotificationsTotalsAction extends IBaseAction {
  type: NotificationsActions.UPDATE_NOTIFICATIONS_TOTAL;
  payload: any;
}
interface RequestsTotalsAction extends IBaseAction {
  type: NotificationsActions.UPDATE_REQUESTS_TOTAL;
  payload: any;
}

export type NotificationsActionsTypes =
  | NotificationsHeaderAction
  | RequestsHeaderAction
  | NotificationsAction
  | RequestsAction
  | NotificationsTotalsAction
  | RequestsTotalsAction;
