import styled from "styled-components";
import { colors } from "../../../../assets/colors/colors";

export const HeaderNotificationsStyle = styled.div`
  .header-notifications {
    background: ${colors["neutrals-0"]};
    padding: 18px 24px;
    border-radius: 12px;
    box-shadow: 0px 40px 80px rgba(37, 40, 43, 0.05);
    border-radius: 0px 0px 12px 12px;
    position: fixed;
    right: 38px;
    top: 64px;
    z-index: 100;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.3s, opacity 0.3s ease-in-out;

    /* .header-notifications__title {
      BodyDefaultMixin;
      margin-bottom: 16px;
      color: ${colors["neutrals-100"]};
      user-select: none;
    } */

    &__tabs {
      margin-bottom: 16px;
      color: ${colors["neutrals-100"]};
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;

      & > * {
        &:not(:first-child) {
          margin-left: 16px;
        }
      }
    }

    &__list {
      overflow-y: auto;
      max-height: 60vh;

      & > * {
        flex: 1;

        &:not(:first-child) {
          margin-top: 8px;
        }
      }
    }

    &__spacer {
      display: block;
      margin: 16px 0;
      height: 1px;
      width: 100%;
      background: ${colors["neutrals-10"]};
    }

    &__actions {
      display: flex;
      justify-content: center;

      & > * {
        &:not(:first-child) {
          margin-left: 8px;
        }
      }
    }

    &.open {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s, opacity 0.3s ease-in-out;
    }

    @media (max-width: 1100px) {
      top: 56px;
      right: 56px;
    }

    @media (max-width: 768px) {
      width: calc(100% - 40px);
      right: 20px;
      padding: 18px 20px;
    }
  }
`;
