import React from "react";
import { Controller } from "react-hook-form";
import { IController } from "../../types/controller-interface";
import Checkbox, { CheckboxProps } from "./checkbox";

interface CheckboxControllerProps {
  checkboxProps?: CheckboxProps;
  controllerProps: IController;
}

const CheckboxController: React.FC<CheckboxControllerProps> = ({
  checkboxProps,
  controllerProps,
}) => {
  return (
    <Controller
      render={({ value, onChange, onBlur, name }) => {
        return (
          <Checkbox
            {...checkboxProps}
            onChange={(event: any) => {
              if (controllerProps.onChange) {
                controllerProps.onChange(event);
              }
              if (checkboxProps?.value) {
                onChange(event.currentTarget.value);
              } else {
                onChange(event.target.checked);
              }
            }}
            onBlur={(event: any) => {
              if (controllerProps.onBlur) {
                controllerProps.onBlur(event);
              }
              onBlur();
            }}
            checked={
              checkboxProps?.checked !== undefined
                ? checkboxProps?.checked
                : !!value
            }
            name={name}
          />
        );
      }}
      {...controllerProps}
    />
  );
};

export default CheckboxController;
