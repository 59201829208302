import moment from "moment";
import "moment/locale/en-gb";
import "moment/locale/es.js";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Loader from "./components/loader/loader";
import ModalCookies from "./components/modal-cookies/modal-cookies";
import { MODAL_COOKIES_TEXT } from "./components/modal-cookies/modal-cookies-text";
import Fonts from "./constants/global-styles/fonts";
import ResetStyles from "./constants/global-styles/reset";
import useQuery from "./hooks/useQuery";
import AuthRoutes from "./routes/auth.routes";
import InternalRoutes from "./routes/internal.routes";
import { DashboardApi } from "./services/rest-client";
import { authActions } from "./store/auth/actions";
import { authSelector } from "./store/auth/selectors";
import { loaderActions } from "./store/loader/actions";
import { loadSelector } from "./store/loader/selectors";

const App = () => {
  const loader = useSelector(loadSelector.isLoading());
  const isAuth = useSelector(authSelector.isAuth());
  const [showConfig, setShowConfig] = useState(false);
  const dispatch = useDispatch();
  const query = useQuery();

  const { i18n, t } = useTranslation(["auth", "cookies"]);

  const modalText: any = useMemo(() => MODAL_COOKIES_TEXT(t), [i18n.language]);

  useEffect(() => {
    const lng = localStorage.getItem("language");
    if (lng !== "es") {
      i18n.changeLanguage(lng || "es");
    }
  }, []);

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (query.get("token")) {
      localStorage.removeItem("jwt");
    }
    if (localStorage.getItem("jwt")) {
      validateToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateToken = async () => {
    try {
      const res: any = await DashboardApi.get("/verify-token", {
        params: {
          locale: i18n.language,
        },
      });

      dispatch(authActions.logging(res.data));
    } catch (err) {
      dispatch(authActions.logout());
      toast.error(t("auth:Se caducó la sesión"));
    } finally {
      dispatch(loaderActions.loaded());
    }
  };

  return (
    <div className="App">
      <Fonts />
      <ResetStyles />
      {loader.length > 0 && <Loader />}
      <Router>{isAuth ? <InternalRoutes /> : <AuthRoutes />}</Router>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        closeButton={false}
      />
      <ModalCookies
        showConfig={showConfig}
        setShowConfig={(value: boolean) => setShowConfig(value)}
        {...modalText}
      />
    </div>
  );
};

export default App;
