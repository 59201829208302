import styled from "styled-components";

const LoaderContainer = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader-holder {
    position: absolute;
    z-index: 99999;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    transform: translate(-50%, -50%);
  }
`;

export default LoaderContainer;
