import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import MainContainer from "../components/main-container/main-container";
import Credentials from "../containers/auth/credentials/credentials";
import Login from "../containers/auth/login/login";
import PassRecovery from "../containers/auth/pass-recovery/pass-recovery";
import Register from "../containers/auth/register/register";
import Cookies from "../containers/internal/cookies/cookies";
import Legal from "../containers/internal/legal/legal";
import Privacy from "../containers/internal/privacy/privacy";
import PurchaseConfirm from "../containers/internal/purchase-confirm/purchase-confirm";
import { authRoutes, commonRoutes } from "./routes";

const AuthRoutes: React.FC = () => {
  const history = useHistory();

  history.listen(() => window.scrollTo(0, 0));

  return (
    <Switch>
      <Route exact path={authRoutes.generatePassword} component={Credentials} />
      <Route exact path={authRoutes.changePassword} component={Credentials} />
      <Route exact path={authRoutes.forgotPassword} component={PassRecovery} />
      <Route exact path={authRoutes.register} component={Register} />
      <Route
        exact
        path={commonRoutes.privacy}
        render={() => (
          <MainContainer withoutActions className="is-legal">
            <Privacy />
          </MainContainer>
        )}
      />
      <Route
        exact
        path={commonRoutes.cookies}
        render={() => (
          <MainContainer withoutActions className="is-legal">
            <Cookies />
          </MainContainer>
        )}
      />
      <Route
        exact
        path={commonRoutes.legal}
        render={() => (
          <MainContainer withoutActions className="is-legal">
            <Legal />
          </MainContainer>
        )}
      />
      <Route exact path={authRoutes.orderConfirm} component={PurchaseConfirm} />
      <Route exact path={commonRoutes.root} component={Login} />
      <Redirect to={commonRoutes.root} />
    </Switch>
  );
};

export default AuthRoutes;
