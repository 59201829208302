import { IBaseAction } from "../base-action.interface";

export enum LoadingActions {
  LOADING = "LOADING",
  LOADED = "LOADED",
}

export interface LoadingState {
  readonly loading: boolean[];
}

interface LoadingAction extends IBaseAction {
  type: LoadingActions.LOADING;
}

interface LoadedAction extends IBaseAction {
  type: LoadingActions.LOADED;
}

export type LoadingActionsTypes = LoadingAction | LoadedAction;
