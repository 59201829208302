import styled from 'styled-components';
import { colors } from '../../../assets/colors/colors';
import { Heading1Mixin } from '../../../components/text-components/heading-text';

export const CookiesStyle = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  color: ${colors['neutrals-80']};
  .cookies-title {
    ${Heading1Mixin};
    color: ${colors['neutrals-100']};
    margin-bottom: 48px;
  }

  strong {
    font-weight: 600;
  }

  h4 {
    font-size: 24px;
    line-height: 28px;
  }

  li {
    ul {
      list-style-type: circle;
    }
    strong {
      font-weight: 500;
    }
  }

  a span {
    color: ${colors['brand-1-100']};
    text-decoration: underline;
    letter-spacing: 0.5px;
    flex-shrink: 0;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  .cookies-text {
    & > p {

      &:not(:first-child) {
        margin-top: 24px;
      }
    }
  }

  @media (max-width: 1100px) {
  }

  @media (max-width: 768px) {
  }
`;