const useTranslate = (i18n: any) => {
  const getCopyLanguage = (text: string) => {
    const split = text.split("--");
    if (split.length > 1) {
      if (i18n.language === "es") {
        return split[0];
      }
      return split[1];
    }
    return text;
  };

  return {
    getCopyLanguage,
  };
};

export default useTranslate;
