import { Dispatch } from "redux";
import { NotificationsActions, NotificationsActionsTypes } from "./types";

const setRequests = (items: any) => async (
  dispatch: Dispatch<NotificationsActionsTypes>
) => dispatch({ type: NotificationsActions.UPDATE_REQUESTS, payload: items });

const setNotifications = (items: any) => async (
  dispatch: Dispatch<NotificationsActionsTypes>
) =>
  dispatch({ type: NotificationsActions.UPDATE_NOTIFICATIONS, payload: items });

const setRequestsTotal = (total: any) => async (
  dispatch: Dispatch<NotificationsActionsTypes>
) =>
  dispatch({
    type: NotificationsActions.UPDATE_REQUESTS_TOTAL,
    payload: total,
  });

const setNotificationsTotal = (total: any) => async (
  dispatch: Dispatch<NotificationsActionsTypes>
) =>
  dispatch({
    type: NotificationsActions.UPDATE_NOTIFICATIONS_TOTAL,
    payload: total,
  });

const setRequestsHeader = (items: any) => async (
  dispatch: Dispatch<NotificationsActionsTypes>
) =>
  dispatch({
    type: NotificationsActions.UPDATE_REQUESTS_HEADER,
    payload: items,
  });

const setNotificationsHeader = (items: any) => async (
  dispatch: Dispatch<NotificationsActionsTypes>
) =>
  dispatch({
    type: NotificationsActions.UPDATE_NOTIFICATIONS_HEADER,
    payload: items,
  });

export const notificationsActions = {
  setRequests,
  setNotifications,
  setRequestsTotal,
  setNotificationsTotal,
  setRequestsHeader,
  setNotificationsHeader,
};
