/* eslint-disable @typescript-eslint/no-unused-vars */
import { isEmail, isPhoneNumber } from "class-validator";
import moment from "moment";
import PasswordValidator from "password-validator";
// EMPTY
export const isEmpty = (value: any) =>
  value === null || value === undefined || value === "";

export const arrayIsNotEmpty = (array: any[]) =>
  array && Array.isArray(array) && array.length > 0;

// EMAIL
export const isValidEmail = (mail: string) => {
  if (!mail) return true;
  return isEmail(mail);
};

// isEqualPassword
export const isPasswordsEqual = (password: string, passwordRepeat: string) => {
  return password === passwordRepeat;
};

// DATE
export const isValidDate = (date: string) => {
  if (!date) return true;
  return /^([0-2][0-9]|(3)[0-1])\/(((0)[0-9])|((1)[0-2]))\/\d{4}$/.test(date);
};

export const isFutureDate = (date: string) => {
  if (!isValidDate(date)) return true;
  const dateMoment = moment(date, "DD/MM/YYYY");
  const today = moment();
  return dateMoment.isAfter(today);
};

export const dateMoreThan = (date: string, dateCompare: string) => {
  if (!date || !dateCompare) {
    return true;
  }
  return moment(date).isAfter(dateCompare);
};

export const isValidNumber = (number: string) => /^\d+(\.\d+)?$/.test(number);
export const isValidInteger = (number: string) => /^\d+?$/.test(number);

// Documento de Identidad (DNI/NIF, NIE, Passport)
export const isValidDocumentID = (text: string, elemID: string) => {
  if (["NIF", "NIE", "DNI"].includes(elemID)) {
    const validChars = "TRWAGMYFPDXBNJZSQVHLCKET";
    const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
    const nieRexp = /^[XYZ012][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
    const str = text.toString().toUpperCase();

    if (elemID === "NIF" && !nifRexp.test(str)) return false;
    if (elemID === "NIE" && !nieRexp.test(str)) return false;

    const nie = str
      .replace(/^[X]/, "0")
      .replace(/^[Y]/, "1")
      .replace(/^[Z]/, "2");

    const letter = str.substr(-1);
    // tslint:disable-next-line: radix
    const charIndex = parseInt(nie.substr(0, 8)) % 23;

    if (validChars.charAt(charIndex) === letter) return true;

    return false;
  }
  if (elemID === "PASP") {
    // tslint:disable-next-line: variable-name
    const pass_esp = /^[a-z]{3}[0-9]{6}[a-z]?$/i;
    // tslint:disable-next-line: variable-name
    const pass_world = /^[A-Za-z0-9]{9}$/i;
    return pass_esp.test(text) || pass_world.test(text);
  }
  if (elemID === "NIFP") {
    return true;
  }
  return false;
};

// PHONE (ES)
// tslint:disable-next-line:variable-name
export const isValidPhone_ESP = (phone: string) =>
  phone ? /^\d{9}$/.test(phone) : true;

// PHONE INTERNATIONAL
export const isValidPhone = (number: string) => {
  return number ? isPhoneNumber(number, "ZZ") : true;
  // /^\w+([\.\+\-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(mail)
};

// CARD
export const isValidCard = (text: string) => {
  if (!text || text.length < 18) return false;

  const number = text.split(" ").join("");

  if (number.length < 15 || number.length > 16) return false;

  if (
    number.length === 15 &&
    number.substr(0, 2) !== "34" &&
    number.substr(0, 2) !== "37"
  ) {
    return false;
  }

  if (number.length === 16) {
    const firstDigits = parseInt(number.substr(0, 2), 10);

    if (number.substr(0, 1) !== "4" && (firstDigits < 51 || firstDigits > 55)) {
      return false;
    }
  }

  return number.match(/[0-9]/);
};

// CIF
export const isValidCif = (text: string) => {
  if (!text || text.length !== 9) return false;

  const letters = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "J",
    "N",
    "P",
    "Q",
    "R",
    "S",
    "U",
    "V",
    "W",
  ];
  const letter = text.substr(0, 1);
  const province = text.substr(1, 2);
  const control = text.substr(text.length - 1);

  if (letters.indexOf(letter) === -1) return false;

  if (
    (["P", "Q", "R", "S", "W"].indexOf(letter) !== -1 || province === "00") &&
    !control.match(/[A-Z]/)
  ) {
    return false;
  }

  if (["A", "B", "E", "H"].indexOf(letter) !== -1 && !control.match(/[0-9]/)) {
    return false;
  }

  const central = text.substr(1, 7);

  if (Number.isNaN(parseInt(central, 10))) return false;

  let sum = 0;

  central.split("").forEach((value, index) => {
    if ((index + 1) % 2 === 0) {
      sum += parseInt(value, 10);
    } else {
      let doubleValue = parseInt(value, 10) * 2;
      if (doubleValue > 9) doubleValue -= 9;
      sum += doubleValue;
    }
  });

  const units = sum % 10;
  const digit = units === 0 ? 0 : 10 - units;
  const relation = ["J", "A", "B", "C", "D", "E", "F", "G", "H", "I"];

  if (control !== digit.toString() && control !== relation[digit]) return false;

  return true;
};

export const hasNumber = (text: string) => {
  return /\d/.test(text);
};

export const isNumeric = (text: string) => {
  return /^\d+$/.test(text);
};

// PASSWORD
export const getPasswordValidator = (value: string) => {
  const validator = new PasswordValidator();
  validator.is().min(8).has().uppercase().has().lowercase().has().digits();

  return validator.validate(value);
};

export const isGreater = (value: string, max?: number) => {
  if (!value) return true;
  return parseFloat(value) > (max || 0);
};

export const isGreaterEmpty = (value: string, max?: number) => {
  if (!value) return true;
  return parseFloat(value) >= (max || 0);
};

export const isLessEmpty = (value: string, min?: number) => {
  if (!value) return true;
  return parseFloat(value) <= (min || 0);
};

export const isMaxLengthEmpty = (value: string, max?: number) => {
  if (!value) return true;
  const validator = new PasswordValidator();
  validator.is().max(max || 30);

  return validator.validate(value);
};

export const isMinLengthEmpty = (value: string, min?: number) => {
  if (!value) return true;
  const validator = new PasswordValidator();
  validator.is().min(min || 8);
  return validator.validate(value);
};

export const isMaxLength = (value: string, max?: number) => {
  const validator = new PasswordValidator();
  validator.is().max(max || 30);

  return validator.validate(value);
};

export const isMinLength = (value: string, min?: number) => {
  const validator = new PasswordValidator();
  validator.is().min(min || 8);
  return validator.validate(value);
};

export const hasSymbol = (value: string) => {
  const validator = new PasswordValidator();
  validator.has().symbols();

  return validator.validate(value);
};

export const hasUppercase = (value: string) => {
  const validator = new PasswordValidator();
  validator.has().uppercase();

  return validator.validate(value);
};

export const hasLowercase = (value: string) => {
  const validator = new PasswordValidator();
  validator.has().lowercase();

  return validator.validate(value);
};

export const hasDigits = (value: string) => {
  const validator = new PasswordValidator();
  validator.has().digits();

  return validator.validate(value);
};
