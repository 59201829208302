/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Control, DeepMap, FieldError } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import CheckboxController from "../../../../../components/checkbox/checbox-controller";
import FlexRow from "../../../../../components/flex-row/flex-row";
import InputController from "../../../../../components/input/input-controller";
import SelectController from "../../../../../components/input/select-controller";
import Modal from "../../../../../components/modal/modal";
import {LEGAL_LINKS_REGISTER} from "../../../../../constants/legal-links";
import { getCountriesArray } from "../../../../../constants/options/countries";
import { getIndustriesArray } from "../../../../../constants/options/industries";
import useReactGa from "../../../../../hooks/useReactGa";
import {
  USER_ANNUAL_ELECTRICAL_ENERGY_CONSUMPTION,
  USER_ANNUAL_EXPENDITURE_ON_ELECTRICITY,
  USER_COMPANY_NAME,
  USER_COUNTRY,
  USER_INDUSTRY,
  USER_TERMS,
} from "../../../../../types/users/user.constants";
import { getErrorMessage } from "../../../../../utils/errorMessage";
import {
  isMaxLengthEmpty,
  isMinLengthEmpty,
} from "../../../../../utils/validation";
interface IRegisterBuyer {
  control: Control<any>;
  errors: DeepMap<any, FieldError>;
}

const RegisterBuyer: React.FC<IRegisterBuyer> = ({ control, errors }) => {
  const { t, i18n } = useTranslation(["auth", "validation", "country", "legal"]);
  const { language } = i18n;
  useReactGa(window.location.pathname, "Registro - Datos Comprador");
  const [modal, setModal] = useState("");

  const countriesArray = React.useMemo(() => getCountriesArray(t), []);
  const industriesArray = React.useMemo(() => getIndustriesArray(t), []);

  return (
    <div className="card-register-contact">
      <div className="card-register-contact__label">
        <p>{t("auth:Datos de la Empresa")}</p>
      </div>
      <div className="card-register-contact__form">
        <FlexRow rowGap="44px" colGap="24px" uniquePattern="100%">
          <InputController
            inputProps={{
              required: true,
              placeholder: t("auth:Introduce el nombre de la empresa"),
              type: "text",
              label: t("auth:Nombre de la empresa"),
              error: getErrorMessage(t, errors[USER_COMPANY_NAME]?.type),
            }}
            controllerProps={{
              control,
              name: USER_COMPANY_NAME,
              rules: {
                required: true,
                validate: {
                  "min-3": (value: string) => isMinLengthEmpty(value, 3),
                  "max-50": (value: string) => isMaxLengthEmpty(value, 50),
                },
              },
            }}
          />
        </FlexRow>
        <FlexRow
          rowGap="44px"
          colGap="24px"
          uniquePattern="50%"
          uniquePatternResM="100%"
        >
          <SelectController
            selectProps={{
              required: true,
              placeholder: t("auth:Sector/Industria"),
              label: t("auth:Sector/Industria"),
              error: getErrorMessage(t, errors[USER_INDUSTRY]?.type),
              options: industriesArray,
            }}
            controllerProps={{
              control,
              name: USER_INDUSTRY,
              rules: {
                required: true,
              },
            }}
          />
          <SelectController
            selectProps={{
              required: true,
              placeholder: t("auth:País"),
              label: t("auth:País"),
              error: getErrorMessage(t, errors[USER_COUNTRY]?.type),
              options: countriesArray,
            }}
            controllerProps={{
              control,
              name: USER_COUNTRY,
              rules: {
                required: true,
              },
            }}
          />
        </FlexRow>
        <FlexRow
          rowGap="44px"
          colGap="24px"
          uniquePattern="50%"
          uniquePatternResM="100%"
        >
          <InputController
            inputProps={{
              placeholder: t("auth:Consumo anual"),
              type: "number",
              label: t("auth:Cons. anual energía eléctrica (MWh/año)"),
              detail: t("auth:MWh/año"),
              error: getErrorMessage(
                t,
                errors[USER_ANNUAL_ELECTRICAL_ENERGY_CONSUMPTION]?.type
              ),
            }}
            controllerProps={{
              control,
              name: USER_ANNUAL_ELECTRICAL_ENERGY_CONSUMPTION,
            }}
          />
          <InputController
            inputProps={{
              placeholder: t("auth:Gasto anual"),
              type: "number",
              label: t("auth:Gasto anual energía eléctrica (€)"),
              detail: t("auth:€"),
              error: getErrorMessage(
                t,
                errors[USER_ANNUAL_EXPENDITURE_ON_ELECTRICITY]?.type
              ),
            }}
            controllerProps={{
              control,
              name: USER_ANNUAL_EXPENDITURE_ON_ELECTRICITY,
            }}
          />
        </FlexRow>
        <FlexRow rowGap="44px" colGap="24px">
          <CheckboxController
            checkboxProps={{
              className: "checkbox",
              required: true,
              label: (
                <>
                  {/* {t("auth:Aceptar")}{" "}
                  <a
                    onClick={(ev: any) => {
                      ev.preventDefault();
                      ev.stopPropagation();
                      setModal("terms");
                    }}
                  >
                    {t("auth:términos y condiciones")}
                  </a> */}
                  <Trans i18nKey="legal:register-buyer-terms">
                    Aceptar
                    <a
                      target="_blank"
                      href={LEGAL_LINKS_REGISTER[language]}
                    >
                      Condiciones legales de uso de la plataforma
                    </a>
                  </Trans>
                </>
              ),
              error: !!errors[USER_TERMS]?.type,
            }}
            controllerProps={{
              control,
              name: USER_TERMS,
              rules: {
                validate: {
                  checked: (value: boolean) => !!value,
                },
              },
            }}
          />
        </FlexRow>
      </div>
      <Modal
        className="terms-modal"
        active={modal === "terms"}
        title={t("auth:Términos y condiciones")}
        text={t(
          "auth:Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse mattis vehicula ipsum vel ultrices. Nulla feugiat ex ac odio placerat vehicula. Etiam at risus felis. Proin egestas congue elit, vel vehicula orci eleifend eu. Fusce nec metus ut arcu faucibus porttitor sit amet eu ipsum. Vestibulum elementum ullamcorper consequat. Nam lorem mauris, viverra sed vehicula vitae, porta scelerisque tellus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Sed faucibus risus lectus, sit amet euismod nisi rhoncus non. In sollicitudin lobortis augue, ut cursus eros eleifend vel. Donec ante risus, pellentesque sit amet convallis a, fringilla iaculis neque. Morbi ut urna vulputate, imperdiet ante in, ornare lacus. Proin dictum urna et diam dignissim, et ultrices augue dignissim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse mattis vehicula ipsum vel ultrices. Nulla feugiat ex ac odio placerat vehicula. Etiam at risus felis. Proin egestas congue elit, vel vehicula orci eleifend eu. Fusce nec metus ut arcu faucibus porttitor sit amet eu ipsum. Vestibulum elementum ullamcorperLorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse mattis vehicula ipsum vel ultrices. Nulla feugiat ex ac odio placerat vehicula. Etiam at risus felis. Proin egestas congue elit, vel vehicula orci eleifend ."
        )}
        onClose={() => setModal("")}
      />
    </div>
  );
};

export default RegisterBuyer;
