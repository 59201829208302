interface IIndustries {
  [key: string]: string;
}

interface IIndustriesItem {
  [key: string]: any;
  value: string;
  label: string;
  icon?: any;
}

export const getIndustriesObject = (t: any) => {
  const INDUSTIRES: IIndustries = {
    aerospace_defence: t("industries:Aeroespacial / Defensa"),
    automotive: t("industries:Automoción"),
    chemicals: t("industries:Químico"),
    construction_homebuilding: t("industries:Construcción"),
    consumer_products: t("industries:Gran consumo"),
    healthcare: t("industries:Salud"),
    hotels_leisure: t("industries:Hotelero y ocio"),
    manufacturing: t("industries:Fabricación"),
    media_publishing: t("industries:Medios y Publicidad"),
    metals_mining: t("industries:Metalúrgico y Minería"),
    oil_gas: t("industries:Petróleo y Gas"),
    pharmaceuticals: t("industries:Farmacéutico"),
    real_estate: t("industries:Inmobiliario"),
    retail_business_products: t("industries:Comercio Minorista"),
    technology: t("industries:Tecnología"),
    telecommunications: t("industries:Telecomunicaciones"),
    transportation_logistics: t("industries:Transporte y logística"),
    professional_services: t("industries:Servicios Profesionales"),
    energy: t("industries:Energía"),
    utilities: t(
      "industries:Empresas de servicios públicos (agua, luz, gas,...)"
    ),
    "governance_public institutions": t(
      "industries:Entidades e instituciones públicas"
    ),
    finance_institutions: t(
      "industries:Entidades financieras (Bancos, Brokers)"
    ),
    infrastructure: t("industries:Infraestructuras "),
    others: t("industries:Otros"),
  };

  return INDUSTIRES;
};

export const getIndustriesArray = (t: any) => {
  const INDUSTIRES: IIndustriesItem[] = [
    {
      value: "aerospace_defence",
      label: t("industries:Aeroespacial / Defensa"),
    },
    { value: "automotive", label: t("industries:Automoción") },
    { value: "chemicals", label: t("industries:Químico") },
    { value: "construction_homebuilding", label: t("industries:Construcción") },
    { value: "consumer_products", label: t("industries:Gran consumo") },
    { value: "healthcare", label: t("industries:Salud") },
    { value: "hotels_leisure", label: t("industries:Hotelero y ocio") },
    { value: "manufacturing", label: t("industries:Fabricación") },
    { value: "media_publishing", label: t("industries:Medios y Publicidad") },
    { value: "metals_mining", label: t("industries:Metalúrgico y Minería") },
    { value: "oil_gas", label: t("industries:Petróleo y Gas") },
    { value: "pharmaceuticals", label: t("industries:Farmacéutico") },
    { value: "real_estate", label: t("industries:Inmobiliario") },
    {
      value: "retail_business_products",
      label: t("industries:Comercio Minorista"),
    },
    { value: "technology", label: t("industries:Tecnología") },
    { value: "telecommunications", label: t("industries:Telecomunicaciones") },
    {
      value: "transportation_logistics",
      label: t("industries:Transporte y logística"),
    },
    {
      value: "professional_services",
      label: t("industries:Servicios Profesionales"),
    },
    { value: "energy", label: t("industries:Energía") },
    {
      value: "utilities",
      label: t(
        "industries:Empresas de servicios públicos (agua, luz, gas,...)"
      ),
    },
    {
      value: "governance_public institutions",
      label: t("industries:Entidades e instituciones públicas"),
    },
    {
      value: "finance_institutions",
      label: t("industries:Entidades financieras (Bancos, Brokers)"),
    },
    { value: "infrastructure", label: t("industries:Infraestructuras ") },
    { value: "others", label: t("industries:Otros") },
  ];

  return INDUSTIRES;
};
