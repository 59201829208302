import produce from "immer";
import {
  NotificationsActions,
  NotificationsActionsTypes,
  NotificationsState,
} from "./types";

export const initialState: NotificationsState = {
  notifications: [],
  requests: [],
  notificationsTotal: 0,
  requestsTotal: 0,
  notificationsHeader: [],
  requestsHeader: [],
};

const reducer = (
  state: NotificationsState = initialState,
  action: NotificationsActionsTypes
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case NotificationsActions.UPDATE_NOTIFICATIONS_TOTAL:
        draft.notificationsTotal = action.payload;
        break;

      case NotificationsActions.UPDATE_REQUESTS_TOTAL:
        draft.requestsTotal = action.payload;
        break;
      case NotificationsActions.UPDATE_NOTIFICATIONS:
        draft.notifications = [...action.payload];
        break;

      case NotificationsActions.UPDATE_REQUESTS:
        draft.requests = [...action.payload];
        break;
      case NotificationsActions.UPDATE_NOTIFICATIONS_HEADER:
        draft.notificationsHeader = [...action.payload];
        break;

      case NotificationsActions.UPDATE_REQUESTS_HEADER:
        draft.requestsHeader = [...action.payload];
        break;
      default:
    }
  });

export default reducer;
