import styled, { css } from "styled-components";

interface IFlexRowStyleProps {
  pattern?: string;
  uniquePattern?: string;
  uniquePatternResT?: string; // Responsive Tablet
  uniquePatternResM?: string; // Responsive Mobile
  rowGap?: string;
  rowGapRes?: string;
  colGap?: string;
  colGapRes?: string;
  justifyContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "initial";
  alignItems?: "normal" | "stretch" | "flex-start" | "flex-end" | "center";
}

export const FlexRowStyle = styled.div<IFlexRowStyleProps>`
  display: block;
  min-width: 100%;

  .flex-row-container {
    display: flex;
    flex-flow: wrap;

    ${({ rowGap }) =>
      rowGap &&
      css`
        margin-top: calc(${`-${rowGap}`} / 2);
        margin-bottom: calc(${`-${rowGap}`} / 2);
      `}

    ${({ colGap }) =>
      colGap &&
      css`
        margin-left: calc(${`-${colGap}`} / 2);
        margin-right: calc(${`-${colGap}`} / 2);
      `}

    ${({ justifyContent }) => css`
      justify-content: ${justifyContent};
    `}

    ${({ alignItems }) => css`
      align-items: ${alignItems};
    `}

    & > * {
      ${({ rowGap }) =>
        rowGap &&
        css`
          margin-top: calc(${rowGap} / 2);
          margin-bottom: calc(${rowGap} / 2);
        `}

      ${({ colGap }) =>
        colGap &&
        css`
          margin-left: calc(${colGap} / 2);
          margin-right: calc(${colGap} / 2);
        `}

      ${({ pattern, colGap, uniquePattern }) => {
        if (pattern) {
          const slicedPattern = pattern.split(" ");

          return slicedPattern.map(
            (item: string, index: number) =>
              css`
                &:nth-child(${index + 1}) {
                  flex-basis: calc(${item} - ${colGap ? colGap : 0});
                }
              `
          );
        }

        if (uniquePattern) {
          return css`
            flex-basis: calc(${uniquePattern} - ${colGap ? colGap : 0});
          `;
        }
      }}

    ${({ uniquePatternResT, colGap }) =>
        uniquePatternResT &&
        css`
          @media (max-width: 1100px) {
            flex-basis: calc(${uniquePatternResT} - ${colGap ? colGap : 0});
          }
        `}
        
    ${({ uniquePatternResM, colGap }) =>
        uniquePatternResM &&
        css`
          @media (max-width: 768px) {
            flex-basis: calc(${uniquePatternResM} - ${colGap ? colGap : 0});
          }
        `}
    }

    ${({ rowGapRes, colGapRes }) =>
      rowGapRes ||
      (colGapRes &&
        css`
          @media (max-width: 768px) {
            flex-flow: column;

            ${rowGapRes &&
            css`
              margin-top: calc(${`-${rowGapRes}`} / 2);
            `}

            ${colGapRes &&
            css`
              margin-left: calc(${`-${colGapRes}`} / 2);
              margin-right: calc(${`-${colGapRes}`} / 2);
            `}
          }
        `)}
  }
`;
