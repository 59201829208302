import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { icons } from "../../../assets/icons/icons";
import Button from "../../../components/button/button";
import Footer from "../../../components/cross/footer/footer";
import ProgressBar from "../../../components/progress-bar/progress-bar";
import useRecaptcha from "../../../hooks/useRecaptcha";
import { routes } from "../../../routes/routes";
import { DashboardApi } from "../../../services/rest-client";
import { loaderActions } from "../../../store/loader/actions";
import {
  USER_ANNUAL_ELECTRICAL_ENERGY_CONSUMPTION,
  USER_ANNUAL_EXPENDITURE_ON_ELECTRICITY,
  USER_COMPANY_NAME,
  USER_COUNTRY,
  USER_EMAIL,
  USER_ESTIMATED_DATE_PPA,
  USER_INDUSTRY,
  USER_INSTALLED_POWER_PPA,
  USER_JOB,
  USER_LASTNAME,
  USER_NAME,
  USER_PHONE,
  USER_ROLE,
  USER_TERMS,
} from "../../../types/users/user.constants";
import { RegisterStyle } from "./register-style";
import RegisterBuyer from "./steps/register-buyer/register-buyer";
import RegisterContact from "./steps/register-contact/register-contact";
import RegisterGenerator from "./steps/register-generator/register-generator";
import RegisterSuccess from "./steps/register-success/register-success";
import RegisterType from "./steps/register-type/register-type";

type STEPS = "type" | "contact" | "buyer" | "generator" | "success";

const Register: React.FC<any> = (props: any) => {
  const [step, setStep] = useState<STEPS>("type");
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation("auth");

  // Type
  const {
    control: controlType,
    handleSubmit: handleSubmitType,
    errors: errorsType,
    watch: watchType,
  } = useForm<any>({
    defaultValues: {
      [USER_ROLE]: "",
    },
    mode: "onBlur",
    criteriaMode: "all",
    shouldUnregister: false,
  });

  const fieldsType = watchType();

  // Contact
  const {
    control: controlContact,
    handleSubmit: handleSubmitContact,
    errors: errorsContact,
    watch: watchContact,
  } = useForm<any>({
    defaultValues: {
      [USER_NAME]: "",
      [USER_LASTNAME]: "",
      [USER_EMAIL]: "",
      [USER_PHONE]: "",
      [USER_JOB]: "",
    },
    mode: "onChange",
    criteriaMode: "all",
    shouldUnregister: false,
  });

  const fieldsContact = watchContact();

  // Buyer
  const {
    control: controlBuyer,
    handleSubmit: handleSubmitBuyer,
    errors: errorsBuyer,
    watch: watchBuyer,
  } = useForm<any>({
    defaultValues: {
      [USER_COMPANY_NAME]: "",
      [USER_COUNTRY]: "",
      [USER_INDUSTRY]: "",
      [USER_ANNUAL_ELECTRICAL_ENERGY_CONSUMPTION]: "",
      [USER_ANNUAL_EXPENDITURE_ON_ELECTRICITY]: "",
      [USER_TERMS]: "",
    },
    mode: "onChange",
    criteriaMode: "all",
    shouldUnregister: false,
  });

  const fieldsBuyer = watchBuyer();

  // Generator
  const {
    control: controlGenerator,
    handleSubmit: handleSubmitGenerator,
    errors: errorsGenerator,
    watch: watchGenerator,
  } = useForm<any>({
    defaultValues: {
      [USER_COMPANY_NAME]: "",
      [USER_COUNTRY]: "",
      [USER_ESTIMATED_DATE_PPA]: "",
      [USER_INSTALLED_POWER_PPA]: "",
      [USER_TERMS]: "",
    },
    mode: "onChange",
    criteriaMode: "all",
    shouldUnregister: false,
  });

  const fieldsGenerator = watchGenerator();

  const currentStep = useRef(step);
  const currentFieldsContact = useRef(fieldsContact);
  const currentFieldsBuyer = useRef(fieldsBuyer);
  const currentFieldsGenerator = useRef(fieldsGenerator);

  useEffect(() => {
    currentStep.current = step;
  }, [step]);
  useEffect(() => {
    currentFieldsContact.current = fieldsContact;
  }, [fieldsContact]);
  useEffect(() => {
    currentFieldsBuyer.current = fieldsBuyer;
  }, [fieldsBuyer]);
  useEffect(() => {
    currentFieldsGenerator.current = fieldsGenerator;
  }, [fieldsGenerator]);

  const handleStepNext = () => {
    switch (currentStep.current) {
      case "type":
        setStep("contact");
        break;
      case "contact":
        setStep(fieldsType[USER_ROLE] === "buyer" ? "buyer" : "generator");
        break;
      case "buyer":
        setStep("success");
        break;
      case "generator":
        setStep("success");
        break;
    }
  };

  const handleStep = (next: boolean) => {
    switch (step) {
      case "type":
        if (next) {
          handleSubmitType(handleStepNext)();
        } else {
          history.push(routes.root);
        }
        break;
      case "contact":
        if (next) {
          handleSubmitContact(handleStepNext)();
        } else {
          setStep("type");
        }
        break;
      case "buyer":
        if (next) {
          handleSubmitBuyer(validate)();
        } else {
          setStep("contact");
        }
        break;
      case "generator":
        if (next) {
          handleSubmitGenerator(validate)();
        } else {
          setStep("contact");
        }
        break;
      case "success":
        if (next) {
          history.push(routes.root);
        }
        break;
    }
  };

  const getPercentage = () => {
    switch (step) {
      case "type":
        return 25;
      case "contact":
        return 50;
      case "buyer":
      case "generator":
        return 75;
      case "success":
        return 100;
      default:
        return 0;
    }
  };

  const handleRegisterBuyer = async () => {
    try {
      dispatch(loaderActions.loading());
      await DashboardApi.post(`/register/buyer?locale=${i18n.language}`, {
        [USER_NAME]: currentFieldsContact.current[USER_NAME],
        [USER_LASTNAME]: currentFieldsContact.current[USER_LASTNAME],
        [USER_EMAIL]: currentFieldsContact.current[USER_EMAIL],
        [USER_PHONE]: currentFieldsContact.current[USER_PHONE],
        [USER_JOB]: currentFieldsContact.current[USER_JOB] || undefined,
        [USER_COMPANY_NAME]: currentFieldsBuyer.current[USER_COMPANY_NAME],
        [USER_COUNTRY]: currentFieldsBuyer.current[USER_COUNTRY]?.value,
        [USER_INDUSTRY]: currentFieldsBuyer.current[USER_INDUSTRY]?.value,
        [USER_ANNUAL_ELECTRICAL_ENERGY_CONSUMPTION]:
          currentFieldsBuyer.current[
            USER_ANNUAL_ELECTRICAL_ENERGY_CONSUMPTION
          ] || undefined,
        [USER_ANNUAL_EXPENDITURE_ON_ELECTRICITY]:
          currentFieldsBuyer.current[USER_ANNUAL_EXPENDITURE_ON_ELECTRICITY] ||
          undefined,
        [USER_TERMS]: currentFieldsBuyer.current[USER_TERMS],
      });

      handleStepNext();
    } catch (err) {
      const error = DashboardApi.errorHandler(err);
      toast.error(error.message);
    } finally {
      dispatch(loaderActions.loaded());
    }
  };

  const handleRegisterGenerator = async () => {
    try {
      dispatch(loaderActions.loading());
      await DashboardApi.post(`/register/generator?locale=${i18n.language}`, {
        [USER_NAME]: currentFieldsContact.current[USER_NAME],
        [USER_LASTNAME]: currentFieldsContact.current[USER_LASTNAME],
        [USER_EMAIL]: currentFieldsContact.current[USER_EMAIL],
        [USER_PHONE]: currentFieldsContact.current[USER_PHONE],
        [USER_JOB]: currentFieldsContact.current[USER_JOB] || undefined,
        [USER_COMPANY_NAME]: currentFieldsGenerator.current[USER_COMPANY_NAME],
        [USER_COUNTRY]: currentFieldsGenerator.current[USER_COUNTRY]?.value,
        [USER_ESTIMATED_DATE_PPA]:
          currentFieldsGenerator.current[USER_ESTIMATED_DATE_PPA]?.value,
        [USER_INSTALLED_POWER_PPA]:
          currentFieldsGenerator.current[USER_INSTALLED_POWER_PPA]?.value,
        [USER_TERMS]: currentFieldsGenerator.current[USER_TERMS],
      });
      handleStepNext();
    } catch (err) {
      const error = DashboardApi.errorHandler(err);
      toast.error(error.message);
    } finally {
      dispatch(loaderActions.loaded());
    }
  };

  const handleRegister = () => {
    if (currentStep.current === "buyer") {
      handleRegisterBuyer();
    }
    if (currentStep.current === "generator") {
      handleRegisterGenerator();
    }
  };

  const { validate } = useRecaptcha(handleRegister);

  return (
    <>
      <Helmet>
        <title>{t("common:app-title")} - Register</title>
      </Helmet>
      <RegisterStyle>
        <div className="register-main">
          <div className="register-main__logo">
            <img src={icons.fullLogo} alt="Sanza logo" />
          </div>
          <div className="register-main__title">
            <p>{t("auth:Registro")}</p>
          </div>
          <div className="register-main__progress">
            <ProgressBar percentage={getPercentage()} />
          </div>
          <div className="register-main__section">
            {step === "type" && (
              <RegisterType control={controlType} errors={errorsType} />
            )}
            {step === "contact" && (
              <RegisterContact
                control={controlContact}
                errors={errorsContact}
              />
            )}
            {step === "buyer" && (
              <RegisterBuyer control={controlBuyer} errors={errorsBuyer} />
            )}
            {step === "generator" && (
              <RegisterGenerator
                control={controlGenerator}
                errors={errorsGenerator}
              />
            )}
            {step === "success" && <RegisterSuccess />}
          </div>
          <div className="register-main__buttons">
            {step !== "success" ? (
              <>
                <Button
                  className="register-main__buttons__item"
                  type="secondary"
                  size="xxl"
                  text={t("auth:Volver")}
                  onClick={() => handleStep(false)}
                />
                <Button
                  className="register-main__buttons__item"
                  type="primary"
                  size="xxl"
                  text={
                    getPercentage() === 75
                      ? t("auth:Enviar")
                      : t("auth:Siguiente")
                  }
                  onClick={() => handleStep(true)}
                />
              </>
            ) : (
              <Button
                className="register-main__buttons__item"
                type="secondary"
                size="xxl"
                text={t("auth:Volver al inicio")}
                onClick={() => handleStep(true)}
              />
            )}
          </div>
        </div>
        <div className="register-side">
          <img src={icons.registerBg} alt="" />
        </div>
      </RegisterStyle>
      <Footer inAuth={true} />
    </>
  );
};

export default Register;
