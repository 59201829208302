import styled from 'styled-components';
import { colors } from '../../../assets/colors/colors';
import { Heading1Mixin } from '../../../components/text-components/heading-text';

export const PrivacyStyle = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  color: ${colors['neutrals-80']};
  .privacy-title {
    ${Heading1Mixin};
    color: ${colors['neutrals-100']};
    margin-bottom: 48px;
  }

  b {
    font-weight: 500;
  }

  span b {
    font-weight: 400;
  }

  h4 {
    font-size: 24px;
    line-height: 28px;

    span {
      font-weight: 500;
    }
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  } 

  li {
    box-sizing: border-box;
    display: list-item;
  }

  .privacy-text {
    & > p {

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }

  @media (max-width: 1100px) {
  }

  @media (max-width: 768px) {
  }
`;