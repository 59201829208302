import React from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../../../../assets/colors/colors";
import { icons } from "../../../../../assets/icons/icons";
import SVGIcon from "../../../../../components/svg-icon/svg-icon";
import useReactGa from "../../../../../hooks/useReactGa";

const RegisterSuccess: React.FC<any> = () => {
  const { t } = useTranslation("auth");
  useReactGa(window.location.pathname, "Registro - Éxito");

  return (
    <div className="card-register-success">
      <div className="card-register-success__label">
        <div className="card-register-success__label__icon">
          <SVGIcon
            icon={icons.check}
            size="35px"
            color={colors["brand-1-100"]}
          />
        </div>
        <div className="card-register-success__label__text">
          <p>
            {t(
              "auth:Te has registrado con éxito. En breves recibirás un correo de confirmación"
            )}
          </p>
        </div>
      </div>
      <div className="card-register-success__message">
        <p>
          {t(
            "auth:Sanza analizará tu solicitud y te enviará un correo para que puedas generar tus credenciales y acceder a la plataforma"
          )}
        </p>
      </div>
    </div>
  );
};

export default RegisterSuccess;
