import addFill from "./files/add-fill.svg";
import alert from "./files/alert.svg";
import arrowDownSmall from "./files/arrow-down-small.svg";
import arrowLeftSmall from "./files/arrow-left-small.svg";
import arrowLeft from "./files/arrow-left.svg";
import arrowOrderBack from "./files/arrow-order-back.svg";
import arrowOrderNeutral from "./files/arrow-order-neutral.svg";
import arrowOrderUp from "./files/arrow-order-up.svg";
import arrowRightSmall from "./files/arrow-right-small.svg";
import arrowRight from "./files/arrow-right.svg";
import arrowUpSmall from "./files/arrow-up-small.svg";
import attachment from "./files/attachment.svg";
import briefcase from "./files/briefcase.svg";
import building from "./files/building.svg";
import calendar from "./files/calendar.svg";
import chart from "./files/chart.svg";
import chat3 from "./files/chat-3.svg";
import checkCircle from "./files/check-circle.svg";
import checkUncheck from "./files/check-uncheck.svg";
import check from "./files/check.svg";
import circleCheck from "./files/circle-check.svg";
import circle from "./files/circle.svg";
import closeCircle from "./files/close-circle.svg";
import closeFill from "./files/close-fill.svg";
import closeX from "./files/close-x.svg";
import close from "./files/close.svg";
import credentialsBgBig from "./files/credentials-bg-big.png";
import credentialsBgSmall from "./files/credentials-bg-small.png";
import credentialsCheck from "./files/credentials-check.svg";
import credentialsRecoveryBgBig from "./files/credentials-recovery-bg-big.png";
import credentialsRecoveryBgSmall from "./files/credentials-recovery-bg-small.png";
import credentialsX from "./files/credentials-x.svg";
import deleteBin from "./files/delete-bin.svg";
import desert from "./files/desert.svg";
import dollarCoin from "./files/dollar-coins.svg";
import download from "./files/download.svg";
import edit from "./files/edit.svg";
import eyeOffLine from "./files/eye-off-line.svg";
import eye from "./files/eye.svg";
import file3 from "./files/file-3.svg";
import fileEdit from "./files/file-edit.svg";
import filePlus from "./files/file-plus.svg";
import fileShield from "./files/file-shield.svg";
import flag from "./files/flag.svg";
import flashlightLine from "./files/flashlight-line.svg";
import folder from "./files/folder.svg";
import fullLogoWhite from "./files/full-logo-white.svg";
import fullLogo from "./files/full-logo.svg";
import home from "./files/home.svg";
import inboxLine from "./files/inbox-line.svg";
import information from "./files/information.svg";
import instagram from "./files/instagram.svg";
import leaf from "./files/leaf.svg";
import lightbulb from "./files/lightbulb.svg";
import lineCircle from "./files/line-circle.svg";
import linkedin from "./files/linkedin.svg";
import loginBgBig from "./files/login-bg-big.png";
import loginBgSmall from "./files/login-bg-small.png";
import logo from "./files/logo.svg";
import logout from "./files/logout.svg";
import mail from "./files/mail.svg";
import map2 from "./files/map2.svg";
import menuClose from "./files/menu-close.svg";
import menuToggle from "./files/menu-toggle.svg";
import menu from "./files/menu.svg";
import more from "./files/more.svg";
import notification2 from "./files/notification-2.svg";
import passRecoveryBgBig from "./files/pass-recovery-bg-big.png";
import passRecoveryBgSmall from "./files/pass-recovery-bg-small.png";
import purchaseConfirmBg from "./files/purchase-confirm-bg.png";
import questionLine from "./files/question-line.svg";
import question from "./files/question.svg";
import registerBg from "./files/register-bg.png";
import screen from "./files/screen.svg";
import search2Line from "./files/search-2-line.svg";
import suitcase from "./files/suitcase.svg";
import time from "./files/time.svg";
import twitter from "./files/twitter.svg";
import upload from "./files/upload.svg";
import user3 from "./files/user-3.svg";
import users from "./files/users.svg";
import zap2 from "./files/zap-2.svg";
import zapCircle from "./files/zap-circle.svg";
import zoomIn from "./files/zoom-in.svg";
import zoomOut from "./files/zoom-out.svg";
interface IIcons {
  [key: string]: string;
  addFill: string;
  alert: string;
  arrowDownSmall: string;
  arrowLeft: string;
  arrowLeftSmall: string;
  arrowOrderBack: string;
  arrowOrderNeutral: string;
  arrowOrderUp: string;
  arrowRight: string;
  arrowRightSmall: string;
  arrowUpSmall: string;
  attachment: string;
  briefcase: string;
  building: string;
  calendar: string;
  chart: string;
  chat3: string;
  check: string;
  checkCircle: string;
  checkUncheck: string;
  circle: string;
  circleCheck: string;
  close: string;
  closeCircle: string;
  closeFill: string;
  closeX: string;
  credentialsBgBig: string;
  credentialsBgSmall: string;
  credentialsCheck: string;
  credentialsRecoveryBgBig: string;
  credentialsRecoveryBgSmall: string;
  credentialsX: string;
  deleteBin: string;
  desert: string;
  dollarCoin: string;
  download: string;
  edit: string;
  eye: string;
  eyeOffLine: string;
  file3: string;
  fileEdit: string;
  filePlus: string;
  fileShield: string;
  flag: string;
  flashlightLine: string;
  folder: string;
  fullLogo: string;
  fullLogoWhite: string;
  home: string;
  inboxLine: string;
  information: string;
  instagram: string;
  leaf: string;
  lightbulb: string;
  lineCircle: string;
  linkedin: string;
  loginBgBig: string;
  loginBgSmall: string;
  logo: string;
  logout: string;
  mail: string;
  map2: string;
  menu: string;
  menuClose: string;
  menuToggle: string;
  more: string;
  notification2: string;
  passRecoveryBgBig: string;
  passRecoveryBgSmall: string;
  purchaseConfirmBg: string;
  question: string;
  questionLine: string;
  registerBg: string;
  screen: string;
  search2Line: string;
  suitcase: string;
  time: string;
  twitter: string;
  upload: string;
  user3: string;
  users: string;
  zap2: string;
  zapCircle: string;
  zoomIn: string;
  zoomOut: string;
}

export const icons: IIcons = {
  addFill,
  alert,
  arrowDownSmall,
  arrowLeft,
  arrowLeftSmall,
  arrowOrderBack,
  arrowOrderNeutral,
  arrowOrderUp,
  arrowRight,
  arrowRightSmall,
  arrowUpSmall,
  attachment,
  briefcase,
  building,
  calendar,
  chart,
  chat3,
  check,
  checkCircle,
  checkUncheck,
  circle,
  circleCheck,
  close,
  closeCircle,
  closeFill,
  closeX,
  credentialsBgBig,
  credentialsBgSmall,
  credentialsCheck,
  credentialsRecoveryBgBig,
  credentialsRecoveryBgSmall,
  credentialsX,
  deleteBin,
  desert,
  dollarCoin,
  download,
  edit,
  eye,
  eyeOffLine,
  file3,
  fileEdit,
  filePlus,
  fileShield,
  flag,
  flashlightLine,
  folder,
  fullLogo,
  fullLogoWhite,
  home,
  inboxLine,
  information,
  instagram,
  leaf,
  lightbulb,
  lineCircle,
  linkedin,
  loginBgBig,
  loginBgSmall,
  logo,
  logout,
  mail,
  map2,
  menu,
  menuClose,
  menuToggle,
  more,
  notification2,
  passRecoveryBgBig,
  passRecoveryBgSmall,
  purchaseConfirmBg,
  question,
  questionLine,
  registerBg,
  screen,
  suitcase,
  search2Line,
  time,
  twitter,
  upload,
  user3,
  users,
  zap2,
  zapCircle,
  zoomIn,
  zoomOut,
};
