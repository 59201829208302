import classnames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../assets/colors/colors";
import { icons } from "../../assets/icons/icons";
import { PRIVATE_CONTACT_EMAIL } from "../../constants/contact";
import { routes } from "../../routes/routes";
import { authSelector } from "../../store/auth/selectors";
import { UserRolesEnum } from "../../types/users/users.enums";
import Button, { IButtonProps } from "../button/button";
import Tooltip from "../tooltip/tooltip";

interface IOptions extends IButtonProps {
  active?: boolean;
}

export type TYPE_TAB =
  | "scheduled"
  | "pending"
  | "rejected"
  | "approved"
  | "analyzing"
  | "auction"
  | "open"
  | "deserted"
  | "success"
  | "closed"
  | "needs-more-info";
export interface ITab {
  options: IOptions;
  headerTabs?: boolean;
  type?: TYPE_TAB | string;
}

interface IStyledTab {
  tabColor: string;
}

const StyledTab = styled.div<IStyledTab>`
  .tab {
    padding: 0 28px 9px;
  }

  .tab.auction {
    .button-text {
      color: ${(props: IStyledTab) => props.tabColor};
    }
  }
`;

const Tab: React.FC<ITab> = ({ type, options, headerTabs }) => {
  const { t } = useTranslation("projects");
  const getProps = (): { src: string; color: string } | undefined => {
    let item: { src: string; color: string } | undefined;

    switch (type) {
      case "scheduled":
        item = {
          src: icons.time,
          color: colors["neutrals-50"],
        };
        break;
      case "pending":
        item = {
          src: icons.checkUncheck,
          color: colors["neutrals-80"],
        };
        break;
      case "rejected":
        item = {
          src: icons.closeCircle,
          color: colors["error-100"],
        };
        break;
      case "canceled":
        item = {
          src: icons.closeCircle,
          color: colors["error-100"],
        };
        break;
      case "approved":
      case "success":
      case "closed":
        item = {
          src: icons.checkCircle,
          color: "#39996B",
        };
        break;
      case "needs-more-info":
        item = {
          src: icons.checkUncheck,
          color: colors["alert-100"],
        };
        break;
      case "analyzing":
        item = {
          src: icons.checkUncheck,
          color: "#134074",
        };
        break;
      case "auction":
        item = {
          src: icons.zapCircle,
          color: "#134074",
        };
        break;
      case "open":
        item = {
          src: icons.zapCircle,
          color: "#134074",
        };
        break;
      case "deserted":
        item = {
          src: icons.lineCircle,
          color: colors["error-100"],
        };
        break;
    }

    return item;
  };

  const props = getProps();
  const { role } = useSelector(authSelector.getUser());
  let tabColor: string;
  props != null ? (tabColor = props.color) : (tabColor = "#134074");

  return (
    <StyledTab tabColor={tabColor}>
      {type === "pending" &&
      options.disabled &&
      role === UserRolesEnum.GENERATOR ? (
        <Tooltip
          place="bottom"
          content={
            <p>
              {t(
                "projects:El acceso a esta fase todavía está bloqueado, necesitas obtener aprobación de sanza para las fases anteriores. Puedes obtener más información en"
              )}{" "}
              <NavLink to={routes.homeSellEnergy}>
                {t("projects:Cómo Vender Energía")}
              </NavLink>{" "}
              {t("projects:o contactando con Sanza")}{" "}
              <a onClick={() => window.open(`mailto:${PRIVATE_CONTACT_EMAIL}`)}>
                {PRIVATE_CONTACT_EMAIL}
              </a>
            </p>
          }
          className="tab-tooltip"
        >
          <Button
            {...options}
            className={classnames(options.className, type, {
              active: options.active,
              "full-width": headerTabs,
            })}
            type="tab"
            iconSize="16px"
            icon={props?.src}
          />
        </Tooltip>
      ) : (
        <Button
          {...options}
          className={classnames(options.className, type, {
            active: options.active,
            "full-width": headerTabs,
          })}
          type="tab"
          iconSize="16px"
          icon={props?.src}
        />
      )}
    </StyledTab>
  );
};

export default Tab;
