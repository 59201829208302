export const PREFIX_SELECT: any = [
  { label: "+34", value: "+34" },
  { label: "+44", value: "+44" },
  { label: "+49", value: "+49" },
  { label: "+33", value: "+33" },
  { label: "+351", value: "+351" },
  { label: "+1", value: "+1" },
  { label: "+93", value: "+93" },
  { label: "+355", value: "+355" },
  { label: "+213", value: "+213" },
  { label: "+376", value: "+376" },
  { label: "+244", value: "+244" },
  { label: "+1 264", value: "+1 264" },
  { label: "+672", value: "+672" },
  { label: "+1 268", value: "+1 268" },
  { label: "+599", value: "+599" },
  { label: "+966", value: "+966" },
  { label: "+54", value: "+54" },
  { label: "+374", value: "+374" },
  { label: "+297", value: "+297" },
  { label: "+61", value: "+61" },
  { label: "+43", value: "+43" },
  { label: "+994", value: "+994" },
  { label: "+32", value: "+32" },
  { label: "+1 242", value: "+1 242" },
  { label: "+973", value: "+973" },
  { label: "+880", value: "+880" },
  { label: "+1 246", value: "+1 246" },
  { label: "+501", value: "+501" },
  { label: "+229", value: "+229" },
  { label: "+975", value: "+975" },
  { label: "+375", value: "+375" },
  { label: "+95", value: "+95" },
  { label: "+591", value: "+591" },
  { label: "+387", value: "+387" },
  { label: "+267", value: "+267" },
  { label: "+55", value: "+55" },
  { label: "+673", value: "+673" },
  { label: "+359", value: "+359" },
  { label: "+226", value: "+226" },
  { label: "+257", value: "+257" },
  { label: "+238", value: "+238" },
  { label: "+855", value: "+855" },
  { label: "+237", value: "+237" },
  { label: "+235", value: "+235" },
  { label: "+56", value: "+56" },
  { label: "+86", value: "+86" },
  { label: "+357", value: "+357" },
  { label: "+57", value: "+57" },
  { label: "+269", value: "+269" },
  { label: "+243", value: "+243" },
  { label: "+850", value: "+850" },
  { label: "+82", value: "+82" },
  { label: "+225", value: "+225" },
  { label: "+506", value: "+506" },
  { label: "+385", value: "+385" },
  { label: "+53", value: "+53" },
  { label: "+45", value: "+45" },
  { label: "+1 767", value: "+1 767" },
  { label: "+593", value: "+593" },
  { label: "+20", value: "+20" },
  { label: "+503", value: "+503" },
  { label: "+971", value: "+971" },
  { label: "+291", value: "+291" },
  { label: "+421", value: "+421" },
  { label: "+386", value: "+386" },
  { label: "+372", value: "+372" },
  { label: "+251", value: "+251" },
  { label: "+63", value: "+63" },
  { label: "+358", value: "+358" },
  { label: "+679", value: "+679" },
  { label: "+241", value: "+241" },
  { label: "+220", value: "+220" },
  { label: "+995", value: "+995" },
  { label: "+233", value: "+233" },
  { label: "+350", value: "+350" },
  { label: "+1 473", value: "+1 473" },
  { label: "+30", value: "+30" },
  { label: "+299", value: "+299" },
  { label: "+", value: "+" },
  { label: "+1 671", value: "+1 671" },
  { label: "+502", value: "+502" },
  { label: "+224", value: "+224" },
  { label: "+240", value: "+240" },
  { label: "+245", value: "+245" },
  { label: "+592", value: "+592" },
  { label: "+509", value: "+509" },
  { label: "+504", value: "+504" },
  { label: "+852", value: "+852" },
  { label: "+36", value: "+36" },
  { label: "+91", value: "+91" },
  { label: "+62", value: "+62" },
  { label: "+98", value: "+98" },
  { label: "+964", value: "+964" },
  { label: "+353", value: "+353" },
  { label: "+354", value: "+354" },
  { label: "+1 441", value: "+1 441" },
  { label: "+1 345", value: "+1 345" },
  { label: "+682", value: "+682" },
  { label: "+298", value: "+298" },
  { label: "+960", value: "+960" },
  { label: "+500", value: "+500" },
  { label: "+1 670", value: "+1 670" },
  { label: "+692", value: "+692" },
  { label: "+870", value: "+870" },
  { label: "+677", value: "+677" },
  { label: "+1 649", value: "+1 649" },
  { label: "+1 284", value: "+1 284" },
  { label: "+1 340", value: "+1 340" },
  { label: "+972", value: "+972" },
  { label: "+39", value: "+39" },
  { label: "+1 876", value: "+1 876" },
  { label: "+81", value: "+81" },
  { label: "+962", value: "+962" },
  { label: "+254", value: "+254" },
  { label: "+996", value: "+996" },
  { label: "+686", value: "+686" },
  { label: "+965", value: "+965" },
  { label: "+961", value: "+961" },
  { label: "+856", value: "+856" },
  { label: "+266", value: "+266" },
  { label: "+371", value: "+371" },
  { label: "+231", value: "+231" },
  { label: "+218", value: "+218" },
  { label: "+423", value: "+423" },
  { label: "+370", value: "+370" },
  { label: "+352", value: "+352" },
  { label: "+52", value: "+52" },
  { label: "+377", value: "+377" },
  { label: "+853", value: "+853" },
  { label: "+389", value: "+389" },
  { label: "+261", value: "+261" },
  { label: "+60", value: "+60" },
  { label: "+265", value: "+265" },
  { label: "+223", value: "+223" },
  { label: "+356", value: "+356" },
  { label: "+212", value: "+212" },
  { label: "+230", value: "+230" },
  { label: "+222", value: "+222" },
  { label: "+262", value: "+262" },
  { label: "+691", value: "+691" },
  { label: "+373", value: "+373" },
  { label: "+976", value: "+976" },
  { label: "+382", value: "+382" },
  { label: "+1 664", value: "+1 664" },
  { label: "+258", value: "+258" },
  { label: "+264", value: "+264" },
  { label: "+674", value: "+674" },
  { label: "+977", value: "+977" },
  { label: "+505", value: "+505" },
  { label: "+227", value: "+227" },
  { label: "+234", value: "+234" },
  { label: "+683", value: "+683" },
  { label: "+47", value: "+47" },
  { label: "+687", value: "+687" },
  { label: "+64", value: "+64" },
  { label: "+968", value: "+968" },
  { label: "+31", value: "+31" },
  { label: "+92", value: "+92" },
  { label: "+680", value: "+680" },
  { label: "+507", value: "+507" },
  { label: "+675", value: "+675" },
  { label: "+595", value: "+595" },
  { label: "+51", value: "+51" },
  { label: "+689", value: "+689" },
  { label: "+48", value: "+48" },
  { label: "+974", value: "+974" },
  { label: "+236", value: "+236" },
  { label: "+420", value: "+420" },
  { label: "+1 809", value: "+1 809" },
  { label: "+250", value: "+250" },
  { label: "+40", value: "+40" },
  { label: "+7", value: "+7" },
  { label: "+685", value: "+685" },
  { label: "+1 684", value: "+1 684" },
  { label: "+590", value: "+590" },
  { label: "+1 869", value: "+1 869" },
  { label: "+378", value: "+378" },
  { label: "+1 599", value: "+1 599" },
  { label: "+508", value: "+508" },
  { label: "+1 784", value: "+1 784" },
  { label: "+290", value: "+290" },
  { label: "+1 758", value: "+1 758" },
  { label: "+239", value: "+239" },
  { label: "+221", value: "+221" },
  { label: "+381", value: "+381" },
  { label: "+248", value: "+248" },
  { label: "+232", value: "+232" },
  { label: "+65", value: "+65" },
  { label: "+963", value: "+963" },
  { label: "+252", value: "+252" },
  { label: "+94", value: "+94" },
  { label: "+27", value: "+27" },
  { label: "+249", value: "+249" },
  { label: "+46", value: "+46" },
  { label: "+41", value: "+41" },
  { label: "+597", value: "+597" },
  { label: "+268", value: "+268" },
  { label: "+992", value: "+992" },
  { label: "+66", value: "+66" },
  { label: "+886", value: "+886" },
  { label: "+255", value: "+255" },
  { label: "+670", value: "+670" },
  { label: "+228", value: "+228" },
  { label: "+690", value: "+690" },
  { label: "+676", value: "+676" },
  { label: "+1 868", value: "+1 868" },
  { label: "+216", value: "+216" },
  { label: "+993", value: "+993" },
  { label: "+90", value: "+90" },
  { label: "+688", value: "+688" },
  { label: "+380", value: "+380" },
  { label: "+256", value: "+256" },
  { label: "+598", value: "+598" },
  { label: "+998", value: "+998" },
  { label: "+678", value: "+678" },
  { label: "+58", value: "+58" },
  { label: "+84", value: "+84" },
  { label: "+681", value: "+681" },
  { label: "+967", value: "+967" },
  { label: "+253", value: "+253" },
  { label: "+260", value: "+260" },
  { label: "+263", value: "+263" },
];

/*---------------*/

export const PREFIX_COUNTRY: any = {
  Afghanistan: {
    iso2: "AF",
    iso3: "AFG",
    prefix: "+93",
  },
  Albania: {
    iso2: "AL",
    iso3: "ALB",
    prefix: "+355",
  },
  Germany: {
    iso2: "DE",
    iso3: "DEU",
    prefix: "+49",
  },
  Algeria: {
    iso2: "DZ",
    iso3: "DZA",
    prefix: "+213",
  },
  Andorra: {
    iso2: "AD",
    iso3: "AND",
    prefix: "+376",
  },
  Angola: {
    iso2: "AO",
    iso3: "AGO",
    prefix: "+244",
  },
  Anguilla: {
    iso2: "AI",
    iso3: "AIA",
    prefix: "+1 264",
  },
  Antarctica: {
    iso2: "AQ",
    iso3: "ATA",
    prefix: "+672",
  },
  "Antigua and Barbuda": {
    iso2: "AG",
    iso3: "ATG",
    prefix: "+1 268",
  },
  "Netherlands Antilles": {
    iso2: "AN",
    iso3: "ANT",
    prefix: "+599",
  },
  "Saudi Arabia": {
    iso2: "SA",
    iso3: "SAU",
    prefix: "+966",
  },
  Argentina: {
    iso2: "AR",
    iso3: "ARG",
    prefix: "+54",
  },
  Armenia: {
    iso2: "AM",
    iso3: "ARM",
    prefix: "+374",
  },
  Aruba: {
    iso2: "AW",
    iso3: "ABW",
    prefix: "+297",
  },
  Australia: {
    iso2: "AU",
    iso3: "AUS",
    prefix: "+61",
  },
  Austria: {
    iso2: "AT",
    iso3: "AUT",
    prefix: "+43",
  },
  Azerbaijan: {
    iso2: "AZ",
    iso3: "AZE",
    prefix: "+994",
  },
  Belgium: {
    iso2: "BE",
    iso3: "BEL",
    prefix: "+32",
  },
  Bahamas: {
    iso2: "BS",
    iso3: "BHS",
    prefix: "+1 242",
  },
  Bahrain: {
    iso2: "BH",
    iso3: "BHR",
    prefix: "+973",
  },
  Bangladesh: {
    iso2: "BD",
    iso3: "BGD",
    prefix: "+880",
  },
  Barbados: {
    iso2: "BB",
    iso3: "BRB",
    prefix: "+1 246",
  },
  Belize: {
    iso2: "BZ",
    iso3: "BLZ",
    prefix: "+501",
  },
  Benin: {
    iso2: "BJ",
    iso3: "BEN",
    prefix: "+229",
  },
  Bhutan: {
    iso2: "BT",
    iso3: "BTN",
    prefix: "+975",
  },
  Belarus: {
    iso2: "BY",
    iso3: "BLR",
    prefix: "+375",
  },
  Myanmar: {
    iso2: "MM",
    iso3: "MMR",
    prefix: "+95",
  },
  Bolivia: {
    iso2: "BO",
    iso3: "BOL",
    prefix: "+591",
  },
  "Bosnia and Herzegovina": {
    iso2: "BA",
    iso3: "BIH",
    prefix: "+387",
  },
  Botswana: {
    iso2: "BW",
    iso3: "BWA",
    prefix: "+267",
  },
  Brazil: {
    iso2: "BR",
    iso3: "BRA",
    prefix: "+55",
  },
  Brunei: {
    iso2: "BN",
    iso3: "BRN",
    prefix: "+673",
  },
  Bulgaria: {
    iso2: "BG",
    iso3: "BGR",
    prefix: "+359",
  },
  "Burkina Faso": {
    iso2: "BF",
    iso3: "BFA",
    prefix: "+226",
  },
  Burundi: {
    iso2: "BI",
    iso3: "BDI",
    prefix: "+257",
  },
  "Cape Verde": {
    iso2: "CV",
    iso3: "CPV",
    prefix: "+238",
  },
  Cambodia: {
    iso2: "KH",
    iso3: "KHM",
    prefix: "+855",
  },
  Cameroon: {
    iso2: "CM",
    iso3: "CMR",
    prefix: "+237",
  },
  Canada: {
    iso2: "CA",
    iso3: "CAN",
    prefix: "+1",
  },
  Chad: {
    iso2: "TD",
    iso3: "TCD",
    prefix: "+235",
  },
  Chile: {
    iso2: "CL",
    iso3: "CHL",
    prefix: "+56",
  },
  China: {
    iso2: "CN",
    iso3: "CHN",
    prefix: "+86",
  },
  Cyprus: {
    iso2: "CY",
    iso3: "CYP",
    prefix: "+357",
  },
  "Vatican City State": {
    iso2: "VA",
    iso3: "VAT",
    prefix: "+39",
  },
  Colombia: {
    iso2: "CO",
    iso3: "COL",
    prefix: "+57",
  },
  Comoros: {
    iso2: "KM",
    iso3: "COM",
    prefix: "+269",
  },
  Congo: {
    iso2: "CD",
    iso3: "COD",
    prefix: "+243",
  },
  "North Korea": {
    iso2: "KP",
    iso3: "PRK",
    prefix: "+850",
  },
  "South Korea": {
    iso2: "KR",
    iso3: "KOR",
    prefix: "+82",
  },
  "Ivory Coast": {
    iso2: "CI",
    iso3: "CIV",
    prefix: "+225",
  },
  "Costa Rica": {
    iso2: "CR",
    iso3: "CRI",
    prefix: "+506",
  },
  Croatia: {
    iso2: "HR",
    iso3: "HRV",
    prefix: "+385",
  },
  Cuba: {
    iso2: "CU",
    iso3: "CUB",
    prefix: "+53",
  },
  Denmark: {
    iso2: "DK",
    iso3: "DNK",
    prefix: "+45",
  },
  Dominica: {
    iso2: "DM",
    iso3: "DMA",
    prefix: "+1 767",
  },
  Ecuador: {
    iso2: "EC",
    iso3: "ECU",
    prefix: "+593",
  },
  Egypt: {
    iso2: "EG",
    iso3: "EGY",
    prefix: "+20",
  },
  "El Salvador": {
    iso2: "SV",
    iso3: "SLV",
    prefix: "+503",
  },
  "United Arab Emirates": {
    iso2: "AE",
    iso3: "ARE",
    prefix: "+971",
  },
  Eritrea: {
    iso2: "ER",
    iso3: "ERI",
    prefix: "+291",
  },
  Slovakia: {
    iso2: "SK",
    iso3: "SVK",
    prefix: "+421",
  },
  Slovenia: {
    iso2: "SI",
    iso3: "SVN",
    prefix: "+386",
  },
  Spain: {
    iso2: "ES",
    iso3: "ESP",
    prefix: "+34",
  },
  "United States of America": {
    iso2: "US",
    iso3: "USA",
    prefix: "+1",
  },
  Estonia: {
    iso2: "EE",
    iso3: "EST",
    prefix: "+372",
  },
  Ethiopia: {
    iso2: "ET",
    iso3: "ETH",
    prefix: "+251",
  },
  Philippines: {
    iso2: "PH",
    iso3: "PHL",
    prefix: "+63",
  },
  Finland: {
    iso2: "FI",
    iso3: "FIN",
    prefix: "+358",
  },
  Fiji: {
    iso2: "FJ",
    iso3: "FJI",
    prefix: "+679",
  },
  France: {
    iso2: "FR",
    iso3: "FRA",
    prefix: "+33",
  },
  Gabon: {
    iso2: "GA",
    iso3: "GAB",
    prefix: "+241",
  },
  Gambia: {
    iso2: "GM",
    iso3: "GMB",
    prefix: "+220",
  },
  Georgia: {
    iso2: "GE",
    iso3: "GEO",
    prefix: "+995",
  },
  Ghana: {
    iso2: "GH",
    iso3: "GHA",
    prefix: "+233",
  },
  Gibraltar: {
    iso2: "GI",
    iso3: "GIB",
    prefix: "+350",
  },
  Grenada: {
    iso2: "GD",
    iso3: "GRD",
    prefix: "+1 473",
  },
  Greece: {
    iso2: "GR",
    iso3: "GRC",
    prefix: "+30",
  },
  Greenland: {
    iso2: "GL",
    iso3: "GRL",
    prefix: "+299",
  },
  Guadeloupe: {
    iso2: "GP",
    iso3: "GLP",
    prefix: "+",
  },
  Guam: {
    iso2: "GU",
    iso3: "GUM",
    prefix: "+1 671",
  },
  Guatemala: {
    iso2: "GT",
    iso3: "GTM",
    prefix: "+502",
  },
  "French Guiana": {
    iso2: "GF",
    iso3: "GUF",
    prefix: "+",
  },
  Guernsey: {
    iso2: "GG",
    iso3: "GGY",
    prefix: "+",
  },
  Guinea: {
    iso2: "GN",
    iso3: "GIN",
    prefix: "+224",
  },
  "Equatorial Guinea": {
    iso2: "GQ",
    iso3: "GNQ",
    prefix: "+240",
  },
  "Guinea-Bissau": {
    iso2: "GW",
    iso3: "GNB",
    prefix: "+245",
  },
  Guyana: {
    iso2: "GY",
    iso3: "GUY",
    prefix: "+592",
  },
  Haiti: {
    iso2: "HT",
    iso3: "HTI",
    prefix: "+509",
  },
  Honduras: {
    iso2: "HN",
    iso3: "HND",
    prefix: "+504",
  },
  "Hong Kong": {
    iso2: "HK",
    iso3: "HKG",
    prefix: "+852",
  },
  Hungary: {
    iso2: "HU",
    iso3: "HUN",
    prefix: "+36",
  },
  India: {
    iso2: "IN",
    iso3: "IND",
    prefix: "+91",
  },
  Indonesia: {
    iso2: "ID",
    iso3: "IDN",
    prefix: "+62",
  },
  Iran: {
    iso2: "IR",
    iso3: "IRN",
    prefix: "+98",
  },
  Iraq: {
    iso2: "IQ",
    iso3: "IRQ",
    prefix: "+964",
  },
  Ireland: {
    iso2: "IE",
    iso3: "IRL",
    prefix: "+353",
  },
  "Bouvet Island": {
    iso2: "BV",
    iso3: "BVT",
    prefix: "+",
  },
  "Isle of Man": {
    iso2: "IM",
    iso3: "IMN",
    prefix: "+44",
  },
  "Christmas Island": {
    iso2: "CX",
    iso3: "CXR",
    prefix: "+61",
  },
  "Norfolk Island": {
    iso2: "NF",
    iso3: "NFK",
    prefix: "+",
  },
  Iceland: {
    iso2: "IS",
    iso3: "ISL",
    prefix: "+354",
  },
  "Bermuda Islands": {
    iso2: "BM",
    iso3: "BMU",
    prefix: "+1 441",
  },
  "Cayman Islands": {
    iso2: "KY",
    iso3: "CYM",
    prefix: "+1 345",
  },
  "Cocos (Keeling) Islands": {
    iso2: "CC",
    iso3: "CCK",
    prefix: "+61",
  },
  "Cook Islands": {
    iso2: "CK",
    iso3: "COK",
    prefix: "+682",
  },
  "Åland Islands": {
    iso2: "AX",
    iso3: "ALA",
    prefix: "+",
  },
  "Faroe Islands": {
    iso2: "FO",
    iso3: "FRO",
    prefix: "+298",
  },
  "South Georgia and the South Sandwich Islands": {
    iso2: "GS",
    iso3: "SGS",
    prefix: "+",
  },
  "Heard Island and McDonald Islands": {
    iso2: "HM",
    iso3: "HMD",
    prefix: "+",
  },
  Maldives: {
    iso2: "MV",
    iso3: "MDV",
    prefix: "+960",
  },
  "Falkland Islands (Malvinas)": {
    iso2: "FK",
    iso3: "FLK",
    prefix: "+500",
  },
  "Northern Mariana Islands": {
    iso2: "MP",
    iso3: "MNP",
    prefix: "+1 670",
  },
  "Marshall Islands": {
    iso2: "MH",
    iso3: "MHL",
    prefix: "+692",
  },
  "Pitcairn Islands": {
    iso2: "PN",
    iso3: "PCN",
    prefix: "+870",
  },
  "Solomon Islands": {
    iso2: "SB",
    iso3: "SLB",
    prefix: "+677",
  },
  "Turks and Caicos Islands": {
    iso2: "TC",
    iso3: "TCA",
    prefix: "+1 649",
  },
  "United States Minor Outlying Islands": {
    iso2: "UM",
    iso3: "UMI",
    prefix: "+",
  },
  "Virgin Islands": {
    iso2: "VG",
    iso3: "VG",
    prefix: "+1 284",
  },
  "United States Virgin Islands": {
    iso2: "VI",
    iso3: "VIR",
    prefix: "+1 340",
  },
  Israel: {
    iso2: "IL",
    iso3: "ISR",
    prefix: "+972",
  },
  Italy: {
    iso2: "IT",
    iso3: "ITA",
    prefix: "+39",
  },
  Jamaica: {
    iso2: "JM",
    iso3: "JAM",
    prefix: "+1 876",
  },
  Japan: {
    iso2: "JP",
    iso3: "JPN",
    prefix: "+81",
  },
  Jersey: {
    iso2: "JE",
    iso3: "JEY",
    prefix: "+",
  },
  Jordan: {
    iso2: "JO",
    iso3: "JOR",
    prefix: "+962",
  },
  Kazakhstan: {
    iso2: "KZ",
    iso3: "KAZ",
    prefix: "+7",
  },
  Kenya: {
    iso2: "KE",
    iso3: "KEN",
    prefix: "+254",
  },
  Kyrgyzstan: {
    iso2: "KG",
    iso3: "KGZ",
    prefix: "+996",
  },
  Kiribati: {
    iso2: "KI",
    iso3: "KIR",
    prefix: "+686",
  },
  Kuwait: {
    iso2: "KW",
    iso3: "KWT",
    prefix: "+965",
  },
  Lebanon: {
    iso2: "LB",
    iso3: "LBN",
    prefix: "+961",
  },
  Laos: {
    iso2: "LA",
    iso3: "LAO",
    prefix: "+856",
  },
  Lesotho: {
    iso2: "LS",
    iso3: "LSO",
    prefix: "+266",
  },
  Latvia: {
    iso2: "LV",
    iso3: "LVA",
    prefix: "+371",
  },
  Liberia: {
    iso2: "LR",
    iso3: "LBR",
    prefix: "+231",
  },
  Libya: {
    iso2: "LY",
    iso3: "LBY",
    prefix: "+218",
  },
  Liechtenstein: {
    iso2: "LI",
    iso3: "LIE",
    prefix: "+423",
  },
  Lithuania: {
    iso2: "LT",
    iso3: "LTU",
    prefix: "+370",
  },
  Luxembourg: {
    iso2: "LU",
    iso3: "LUX",
    prefix: "+352",
  },
  Mexico: {
    iso2: "MX",
    iso3: "MEX",
    prefix: "+52",
  },
  Monaco: {
    iso2: "MC",
    iso3: "MCO",
    prefix: "+377",
  },
  Macao: {
    iso2: "MO",
    iso3: "MAC",
    prefix: "+853",
  },
  Macedonia: {
    iso2: "MK",
    iso3: "MKD",
    prefix: "+389",
  },
  Madagascar: {
    iso2: "MG",
    iso3: "MDG",
    prefix: "+261",
  },
  Malaysia: {
    iso2: "MY",
    iso3: "MYS",
    prefix: "+60",
  },
  Malawi: {
    iso2: "MW",
    iso3: "MWI",
    prefix: "+265",
  },
  Mali: {
    iso2: "ML",
    iso3: "MLI",
    prefix: "+223",
  },
  Malta: {
    iso2: "MT",
    iso3: "MLT",
    prefix: "+356",
  },
  Morocco: {
    iso2: "MA",
    iso3: "MAR",
    prefix: "+212",
  },
  Martinique: {
    iso2: "MQ",
    iso3: "MTQ",
    prefix: "+",
  },
  Mauritius: {
    iso2: "MU",
    iso3: "MUS",
    prefix: "+230",
  },
  Mauritania: {
    iso2: "MR",
    iso3: "MRT",
    prefix: "+222",
  },
  Mayotte: {
    iso2: "YT",
    iso3: "MYT",
    prefix: "+262",
  },
  "Estados Federados de": {
    iso2: "FM",
    iso3: "FSM",
    prefix: "+691",
  },
  Moldova: {
    iso2: "MD",
    iso3: "MDA",
    prefix: "+373",
  },
  Mongolia: {
    iso2: "MN",
    iso3: "MNG",
    prefix: "+976",
  },
  Montenegro: {
    iso2: "ME",
    iso3: "MNE",
    prefix: "+382",
  },
  Montserrat: {
    iso2: "MS",
    iso3: "MSR",
    prefix: "+1 664",
  },
  Mozambique: {
    iso2: "MZ",
    iso3: "MOZ",
    prefix: "+258",
  },
  Namibia: {
    iso2: "NA",
    iso3: "NAM",
    prefix: "+264",
  },
  Nauru: {
    iso2: "NR",
    iso3: "NRU",
    prefix: "+674",
  },
  Nepal: {
    iso2: "NP",
    iso3: "NPL",
    prefix: "+977",
  },
  Nicaragua: {
    iso2: "NI",
    iso3: "NIC",
    prefix: "+505",
  },
  Niger: {
    iso2: "NE",
    iso3: "NER",
    prefix: "+227",
  },
  Nigeria: {
    iso2: "NG",
    iso3: "NGA",
    prefix: "+234",
  },
  Niue: {
    iso2: "NU",
    iso3: "NIU",
    prefix: "+683",
  },
  Norway: {
    iso2: "NO",
    iso3: "NOR",
    prefix: "+47",
  },
  "New Caledonia": {
    iso2: "NC",
    iso3: "NCL",
    prefix: "+687",
  },
  "New Zealand": {
    iso2: "NZ",
    iso3: "NZL",
    prefix: "+64",
  },
  Oman: {
    iso2: "OM",
    iso3: "OMN",
    prefix: "+968",
  },
  Netherlands: {
    iso2: "NL",
    iso3: "NLD",
    prefix: "+31",
  },
  Pakistan: {
    iso2: "PK",
    iso3: "PAK",
    prefix: "+92",
  },
  Palau: {
    iso2: "PW",
    iso3: "PLW",
    prefix: "+680",
  },
  Palestine: {
    iso2: "PS",
    iso3: "PSE",
    prefix: "+",
  },
  Panama: {
    iso2: "PA",
    iso3: "PAN",
    prefix: "+507",
  },
  "Papua New Guinea": {
    iso2: "PG",
    iso3: "PNG",
    prefix: "+675",
  },
  Paraguay: {
    iso2: "PY",
    iso3: "PRY",
    prefix: "+595",
  },
  Peru: {
    iso2: "PE",
    iso3: "PER",
    prefix: "+51",
  },
  "French Polynesia": {
    iso2: "PF",
    iso3: "PYF",
    prefix: "+689",
  },
  Poland: {
    iso2: "PL",
    iso3: "POL",
    prefix: "+48",
  },
  Portugal: {
    iso2: "PT",
    iso3: "PRT",
    prefix: "+351",
  },
  "Puerto Rico": {
    iso2: "PR",
    iso3: "PRI",
    prefix: "+1",
  },
  Qatar: {
    iso2: "QA",
    iso3: "QAT",
    prefix: "+974",
  },
  "United Kingdom": {
    iso2: "GB",
    iso3: "GBR",
    prefix: "+44",
  },
  "Central African Republic": {
    iso2: "CF",
    iso3: "CAF",
    prefix: "+236",
  },
  "Czech Republic": {
    iso2: "CZ",
    iso3: "CZE",
    prefix: "+420",
  },
  "Dominican Republic": {
    iso2: "DO",
    iso3: "DOM",
    prefix: "+1 809",
  },
  Réunion: {
    iso2: "RE",
    iso3: "REU",
    prefix: "+",
  },
  Rwanda: {
    iso2: "RW",
    iso3: "RWA",
    prefix: "+250",
  },
  Romania: {
    iso2: "RO",
    iso3: "ROU",
    prefix: "+40",
  },
  Russia: {
    iso2: "RU",
    iso3: "RUS",
    prefix: "+7",
  },
  "Western Sahara": {
    iso2: "EH",
    iso3: "ESH",
    prefix: "+",
  },
  Samoa: {
    iso2: "WS",
    iso3: "WSM",
    prefix: "+685",
  },
  "American Samoa": {
    iso2: "AS",
    iso3: "ASM",
    prefix: "+1 684",
  },
  "Saint Barthélemy": {
    iso2: "BL",
    iso3: "BLM",
    prefix: "+590",
  },
  "Saint Kitts and Nevis": {
    iso2: "KN",
    iso3: "KNA",
    prefix: "+1 869",
  },
  "San Marino": {
    iso2: "SM",
    iso3: "SMR",
    prefix: "+378",
  },
  "Saint Martin (French part)": {
    iso2: "MF",
    iso3: "MAF",
    prefix: "+1 599",
  },
  "Saint Pierre and Miquelon": {
    iso2: "PM",
    iso3: "SPM",
    prefix: "+508",
  },
  "Saint Vincent and the Grenadines": {
    iso2: "VC",
    iso3: "VCT",
    prefix: "+1 784",
  },
  "Ascensión y Tristán de Acuña": {
    iso2: "SH",
    iso3: "SHN",
    prefix: "+290",
  },
  "Saint Lucia": {
    iso2: "LC",
    iso3: "LCA",
    prefix: "+1 758",
  },
  "Sao Tome and Principe": {
    iso2: "ST",
    iso3: "STP",
    prefix: "+239",
  },
  Senegal: {
    iso2: "SN",
    iso3: "SEN",
    prefix: "+221",
  },
  Serbia: {
    iso2: "RS",
    iso3: "SRB",
    prefix: "+381",
  },
  Seychelles: {
    iso2: "SC",
    iso3: "SYC",
    prefix: "+248",
  },
  "Sierra Leone": {
    iso2: "SL",
    iso3: "SLE",
    prefix: "+232",
  },
  Singapore: {
    iso2: "SG",
    iso3: "SGP",
    prefix: "+65",
  },
  Syria: {
    iso2: "SY",
    iso3: "SYR",
    prefix: "+963",
  },
  Somalia: {
    iso2: "SO",
    iso3: "SOM",
    prefix: "+252",
  },
  "Sri Lanka": {
    iso2: "LK",
    iso3: "LKA",
    prefix: "+94",
  },
  "South Africa": {
    iso2: "ZA",
    iso3: "ZAF",
    prefix: "+27",
  },
  Sudan: {
    iso2: "SD",
    iso3: "SDN",
    prefix: "+249",
  },
  Sweden: {
    iso2: "SE",
    iso3: "SWE",
    prefix: "+46",
  },
  Switzerland: {
    iso2: "CH",
    iso3: "CHE",
    prefix: "+41",
  },
  Suriname: {
    iso2: "SR",
    iso3: "SUR",
    prefix: "+597",
  },
  "Svalbard and Jan Mayen": {
    iso2: "SJ",
    iso3: "SJM",
    prefix: "+",
  },
  Swaziland: {
    iso2: "SZ",
    iso3: "SWZ",
    prefix: "+268",
  },
  Tajikistan: {
    iso2: "TJ",
    iso3: "TJK",
    prefix: "+992",
  },
  Thailand: {
    iso2: "TH",
    iso3: "THA",
    prefix: "+66",
  },
  Taiwan: {
    iso2: "TW",
    iso3: "TWN",
    prefix: "+886",
  },
  Tanzania: {
    iso2: "TZ",
    iso3: "TZA",
    prefix: "+255",
  },
  "British Indian Ocean Territory": {
    iso2: "IO",
    iso3: "IOT",
    prefix: "+",
  },
  "French Southern Territories": {
    iso2: "TF",
    iso3: "ATF",
    prefix: "+",
  },
  "East Timor": {
    iso2: "TL",
    iso3: "TLS",
    prefix: "+670",
  },
  Togo: {
    iso2: "TG",
    iso3: "TGO",
    prefix: "+228",
  },
  Tokelau: {
    iso2: "TK",
    iso3: "TKL",
    prefix: "+690",
  },
  Tonga: {
    iso2: "TO",
    iso3: "TON",
    prefix: "+676",
  },
  "Trinidad and Tobago": {
    iso2: "TT",
    iso3: "TTO",
    prefix: "+1 868",
  },
  Tunisia: {
    iso2: "TN",
    iso3: "TUN",
    prefix: "+216",
  },
  Turkmenistan: {
    iso2: "TM",
    iso3: "TKM",
    prefix: "+993",
  },
  Turkey: {
    iso2: "TR",
    iso3: "TUR",
    prefix: "+90",
  },
  Tuvalu: {
    iso2: "TV",
    iso3: "TUV",
    prefix: "+688",
  },
  Ukraine: {
    iso2: "UA",
    iso3: "UKR",
    prefix: "+380",
  },
  Uganda: {
    iso2: "UG",
    iso3: "UGA",
    prefix: "+256",
  },
  Uruguay: {
    iso2: "UY",
    iso3: "URY",
    prefix: "+598",
  },
  Uzbekistan: {
    iso2: "UZ",
    iso3: "UZB",
    prefix: "+998",
  },
  Vanuatu: {
    iso2: "VU",
    iso3: "VUT",
    prefix: "+678",
  },
  Venezuela: {
    iso2: "VE",
    iso3: "VEN",
    prefix: "+58",
  },
  Vietnam: {
    iso2: "VN",
    iso3: "VNM",
    prefix: "+84",
  },
  "Wallis and Futuna": {
    iso2: "WF",
    iso3: "WLF",
    prefix: "+681",
  },
  Yemen: {
    iso2: "YE",
    iso3: "YEM",
    prefix: "+967",
  },
  Djibouti: {
    iso2: "DJ",
    iso3: "DJI",
    prefix: "+253",
  },
  Zambia: {
    iso2: "ZM",
    iso3: "ZMB",
    prefix: "+260",
  },
  Zimbabwe: {
    iso2: "ZW",
    iso3: "ZWE",
    prefix: "+263",
  },
};
