import React, { useEffect, useRef, useState } from "react";

type UseSafeState = <S>(
  initialValue: S
) => [S, React.Dispatch<React.SetStateAction<S>>];

const useSafeState: UseSafeState = (initialState: any) => {
  const mountedRef = useRef(false);

  const [state, setState] = useState<any>(initialState);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const isMounted = () => mountedRef.current;

  const setSafeState = (newState: any) => {
    return isMounted() ? setState(newState) : null;
  };

  return [state, setSafeState];
};

export default useSafeState;
