import { useEffect, useRef } from "react";
import { RECAPTCHA_KEY } from "../constants/env";

interface ReCaptchaInstance {
  ready: (cb: () => any) => any;
  reset: () => Promise<string>;
  execute: (idCaptcha: number) => Promise<string>;
  render: (id: string, options: ReCaptchaRenderOptions) => any;
}

// Interfaz param execuete V3
// interface ReCaptchaExecuteOptions {
//   action: string;
// }

interface ReCaptchaRenderOptions {
  sitekey: string;
  size: "invisible";
  callback: any;
}

declare global {
  interface Window {
    captchaOnLoad: () => void;
    grecaptcha: ReCaptchaInstance;
  }
}

const useRecaptcha = (callback: any) => {
  const script = useRef<any>();
  const widget = useRef<any>();
  const ready = useRef<boolean>(false);
  const idRecaptcha = useRef<number>(-1);

  useEffect(() => {
    loadScript();
    return () => {
      document.body.removeChild(widget.current);
      document.body.removeChild(script.current);
    };
  }, []);

  const loadScript = (): void => {
    window.captchaOnLoad = onLoad;

    const url = "https://www.google.com/recaptcha/api.js";
    const queryString = "?onload=captchaOnLoad&render=explicit";
    const scriptAux = document.createElement("script");
    scriptAux.type = "text/javascript";
    scriptAux.src = url + queryString;
    scriptAux.async = true;
    scriptAux.defer = true;

    script.current = document.body.appendChild(scriptAux);
  };

  const onLoad = (): void => {
    const div = document.createElement("div");
    div.id = "g-recaptcha";
    widget.current = document.body.appendChild(div);

    idRecaptcha.current = window.grecaptcha.render("g-recaptcha", {
      callback,
      sitekey: RECAPTCHA_KEY,
      size: "invisible",
    });

    window.grecaptcha.ready(() => {
      ready.current = true;
    });
  };

  const validate = (): void => {
    window.grecaptcha.reset();
    window.grecaptcha.execute(idRecaptcha.current);
  };

  return {
    script,
    widget,
    ready,
    idRecaptcha,
    validate,
  };
};

export default useRecaptcha;
