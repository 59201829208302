import classNames from "classnames";
import Cookie from "js-cookie";
import React, { useCallback, useEffect, useState } from "react";
import Button, { IButtonProps } from "../button/button";
import Toggle from "../toggle/toggle";
import iconChevronDown from "./assets/icon-chevron-down.svg";
import { ModalContainerStyle } from "./modal-cookies-style";

interface IOptionItem {
  text: string;
  cookieKey: string;
  options: string[];
  required: boolean;
  setCookies?: () => void;
  clearCookies?: () => void;
  [key: string]: any;
}

interface ModalContainerProps {
  cookiesText: string;
  cookiesText2: string;
  cookiesButtonText?: IButtonProps;
  cookiesButtonConfig?: IButtonProps;
  cookiesButtonTextSettings: string;
  cookiesButtonTextAccept: string;
  titleSettings: string;
  textSettings: string[];
  titleOptions: string;
  optionsItems: IOptionItem[];
  optionRequiredText: string;
  optionAcceptText: string;
  optionRejectText: string;
  buttonAccept: string;
  buttonCancel: string;
  textFinal: string;
  showConfig: boolean;
  setShowConfig: (value: boolean) => void;
}

const initCookies = () => {
  const cookieConfig = Cookie.get("cookie-config");
  return cookieConfig ? !JSON.parse(cookieConfig).cookiesAccepted : true;
};

const ModalCookies: React.FC<ModalContainerProps> = ({
  cookiesText,
  cookiesText2,
  cookiesButtonText,
  cookiesButtonConfig,
  cookiesButtonTextSettings,
  cookiesButtonTextAccept,
  textSettings,
  titleOptions,
  optionsItems,
  optionRequiredText,
  optionAcceptText,
  optionRejectText,
  buttonAccept,
  buttonCancel,
  textFinal,
  showConfig,
  setShowConfig,
}) => {
  const [active, setActive] = useState<boolean>(initCookies());
  const [showOptions, setShowOptions] = useState<any>({});
  const [options, setOptions] = useState<any[]>([]);

  const setCookieOptions = useCallback(() => {
    const cookieConfig = Cookie.get("cookie-config");
    const cookieOptions = cookieConfig
      ? JSON.parse(cookieConfig).cookieOptions
      : false;
    const newOptions = [...optionsItems];
    for (const option of optionsItems) {
      option.value = cookieOptions?.[option.cookieKey] || option.required;
    }
    setOptions(newOptions);
  }, [optionsItems]);

  useEffect(() => {
    setCookieOptions();
  }, [setCookieOptions]);

  useEffect(() => {
    if (showConfig) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }

    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, [showConfig]);

  const acceptModal = (acceptAll?: boolean) => {
    const cookieConfig: any = { cookiesAccepted: true, cookieOptions: {} };
    options.forEach((option: any) => {
      cookieConfig.cookieOptions[option.cookieKey] = acceptAll
        ? true
        : option.value;
    });

    options.forEach((option: any) => {
      if (cookieConfig.cookieOptions[option.cookieKey] && option.setCookies) {
        option.setCookies();
      } else if (
        !cookieConfig.cookieOptions[option.cookieKey] &&
        option.clearCookies
      ) {
        option.clearCookies();
      }
    });

    Cookie.set("cookie-config", JSON.stringify(cookieConfig), { expires: 7 });
    setShowConfig(false);
    setActive(false);
  };

  const handleShowOptions = (key: string) => {
    showOptions[key] = !showOptions[key];
    setShowOptions({ ...showOptions });
  };

  const handleAccept = (index: number) => {
    if (!options[index].required) {
      const newOptions = [...options];
      newOptions[index].value = true;
      setOptions(newOptions);
    }
  };

  const handleRefuse = (index: number) => {
    if (!options[index].required) {
      const newOptions = [...options];
      newOptions[index].value = false;
      setOptions(newOptions);
    }
  };

  return (
    <ModalContainerStyle>
      <div className={classNames("box-modal-cookies", { off: !active })}>
        <p className="modal-description">
          {cookiesText}
          {!!cookiesButtonConfig && <Button text={cookiesButtonConfig.text} type={cookiesButtonConfig.type}
            onClick={() => {
              setActive(false);
              setShowConfig(true);
            }} />}
          {cookiesText2}
          {!!cookiesButtonText && <Button {...cookiesButtonText} />}
        </p>
        <div className="box-modal-cookies-buttons">
          <div className="box-modal-cookies-buttons__item">
            <button
              onClick={() => acceptModal(true)}
              className="btn-ok-cookies"
            >
              {cookiesButtonTextAccept}
            </button>
          </div>
          <div className="box-modal-cookies-buttons__item">
            <button
              onClick={() => {
                setActive(false);
                setShowConfig(true);
              }}
              className="btn-options-cookies"
            >
              {cookiesButtonTextSettings}
            </button>
          </div>
        </div>
      </div>

      <div
        className={classNames("modal-cookies-settings", {
          active: showConfig,
        })}
      >
        <div className="modal-cookies-settings-container">
          <div className="modal-cookies-settings-header">
            <div
              className="modal-cookies-settings-close"
              onClick={() => {
                setActive(initCookies());
                setShowConfig(false);
              }}
            >
              <div className="modal-cookies-settings-close-top" />
              <div className="modal-cookies-settings-close-bottom" />
            </div>
          </div>
          {textSettings.map((text: string, index: number) => (
            <div
              key={`setting-text-${index}`}
              className="modal-cookies-settings-text"
            >
              <p>{text}</p>
            </div>
          ))}

          {/* <div className="modal-cookies-settings-options-title">
            <p>{titleOptions}</p>
          </div> */}

          <div className="modal-cookies-settings-options-container">
            {options.map((option, index) => (
              <div
                key={`modal-cookies-settings-options-item-${index}`}
                className={classNames("modal-cookies-settings-options", {
                  open: showOptions[`option-item-${index}`],
                })}
              >
                <div className="modal-cookies-settings-options-info">
                  <div
                    className="modal-cookies-settings-options-item"
                    onClick={() => handleShowOptions(`option-item-${index}`)}
                  >
                    <div className="modal-cookies-settings-options-more-info">
                      <div className="modal-cookies-settings-options-more-info-icon">
                        <img src={iconChevronDown} alt="cerrar modal cookies" />
                      </div>
                    </div>
                    <div className="modal-cookies-settings-drop-title">
                      <p>{option.text}</p>
                    </div>
                  </div>
                  <div
                    className={classNames(
                      "modal-cookies-settings-options-text",
                      {
                        active: showOptions[`option-item-${index}`],
                      }
                    )}
                  >
                    {option.options.map((op: any, indexOp: any) => (
                      <p key={`op-${indexOp}`}>{op}</p>
                    ))}
                  </div>
                </div>
                <p id={`toggle-input-option-text-${index}`}>
                  {option.required
                    ? optionRequiredText
                    : option.value
                      ? optionAcceptText
                      : optionRejectText}
                </p>
                {option.required ? null : (
                  <div style={{ alignSelf: "flex-start" }}>
                    <Toggle
                      checked={option.value}
                      onChange={() => {
                        if (!option.value) {
                          handleAccept(index);
                        } else {
                          handleRefuse(index);
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
{/* 
          <div className="modal-cookies-settings-final-text">
            <p>{textFinal}</p>
          </div> */}
          <div className="modal-cookies-settings-save">
            <div
              onClick={() => acceptModal()}
              className="modal-cookies-settings-save__success-button"
            >
              <p>{buttonAccept}</p>
            </div>
            <div
              onClick={() => {
                setCookieOptions();
                setActive(initCookies());
                setShowConfig(false);
              }}
              className="modal-cookies-settings-save__reject-button"
            >
              <p>{buttonCancel}</p>
            </div>
          </div>
        </div>
      </div>
    </ModalContainerStyle>
  );
};

export default ModalCookies;
