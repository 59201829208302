import styled from "styled-components";
import { colors } from "../../assets/colors/colors";

export const MainContainerStyle = styled.div`
  background: ${colors["neutrals-5"]};

  .main-container {
    display: flex;
    flex-flow: column;
    min-height: calc(100vh - 64px);
    padding-left: 76px;
    width: 100%;
    background: ${colors["neutrals-5"]};
    margin-top: 64px;
    justify-content: space-between;

    &__content {
      height: 100%;
      padding: 36px 102px;

      &.full {
        display: flex;
        flex-flow: column;
        flex: 1;
      }
    }
  }
  
  &.is-legal {
    .main-container {
      background: ${colors["neutrals-0"]};
    }
  }

  @media (max-width: 1100px) {
    .main-container {
      min-height: calc(100vh - 56px);
      margin-top: 56px;
      padding-left: 0;

      &__content {
        padding: 24px 56px;
      }
    }

    .footer-container {
      padding-left: 0;
    }
  }

  @media (max-width: 768px) {
    .main-container {
      &__content {
        padding: 20px;
      }
    }
  }
`;
