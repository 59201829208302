import produce from "immer";
import {
  RecaptchaActions,
  RecaptchaActionsTypes,
  RecaptchaState,
} from "./types";

export const initialState: RecaptchaState = {
  id: -1,
};

const reducer = (
  state: RecaptchaState = initialState,
  action: RecaptchaActionsTypes
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case RecaptchaActions.SET_ID:
        draft.id = action.payload;
        break;
      default:
    }
  });

export default reducer;
