import styled from "styled-components";
import { colors } from "../../assets/colors/colors";
import { BodyDefaultMixin } from "../text-components/body-text";

export const ValidationsPasswordStyle = styled.div`
  &.validation-password {
    background: #FFFFFF;
    box-shadow: 0px 40px 80px rgba(37, 40, 43, 0.05);
    border-radius: 12px;
    padding: 24px;
    width: 306px;
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    left: 620px;
  }

  .validation-password-item {
    display: flex;
    align-items: center;
    max-width: 100%;

    &__icon {
      margin-right: 12px;
    }

    &__text {
      ${BodyDefaultMixin};
      color: ${colors['neutrals-80']};
    }

    &:not(:first-child) {
      margin-top: 24px;
    }
  }

  @media (max-width: 1100px) {
    &.validation-password {
      position: relative;
      top: initial;
      left: initial;
      transform: translateY(0);
      width: 100%;
    }
  }

  &.in-profile {
    position: relative;
    top: initial;
    left: initial;
    transform: translateY(0);
    width: 100%;
  }
`;
