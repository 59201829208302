import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { icons } from "../../../assets/icons/icons";
import Button from "../../../components/button/button";
import Footer from "../../../components/cross/footer/footer";
import InputController from "../../../components/input/input-controller";
import useReactGa from "../../../hooks/useReactGa";
import useRecaptcha from "../../../hooks/useRecaptcha";
import { routes } from "../../../routes/routes";
import { DashboardApi } from "../../../services/rest-client";
import { loaderActions } from "../../../store/loader/actions";
import { getErrorMessage } from "../../../utils/errorMessage";
import { isValidEmail } from "../../../utils/validation";
import { PassRecoveryStyle } from "./pass-recovery-style";

const PassRecovery: React.FC = () => {
  const { t, i18n } = useTranslation(["auth", "validations"]);
  const dispatch = useDispatch();
  const history = useHistory();
  useReactGa(window.location.pathname, "Contraseña Olvidada");

  const { control, handleSubmit, errors, watch } = useForm<any>({
    defaultValues: {
      email: "",
    },
    mode: "onBlur",
    criteriaMode: "all",
  });

  const fields = watch();

  const fieldsRef = useRef<any>(fields);

  useEffect(() => {
    fieldsRef.current = fields;
  }, [fields]);

  const handleForgotPassword = async () => {
    try {
      dispatch(loaderActions.loading());
      await DashboardApi.post(
        "/request-recover-password",
        {
          ...fieldsRef.current,
        },
        {
          params: {
            locale: i18n.language,
          },
        }
      );
      toast.success(t("auth:Comprueba el correo"));
    } catch (err) {
      const error = DashboardApi.errorHandler(err);
      toast.error(error.message);
      // setError(t("auth:Usuario o contraseña incorrectos"));
    } finally {
      dispatch(loaderActions.loaded());
    }
  };

  const { validate } = useRecaptcha(handleForgotPassword);

  return (
    <>
      <Helmet>
        <title>{t("common:app-title")} - Forgot Password</title>
      </Helmet>
      <PassRecoveryStyle>
        <div className="pass-recovery-main">
          <div className="pass-recovery-main__logo">
            <NavLink to="/">
              <img
                className="tablet"
                src={icons.fullLogoWhite}
                alt="Sanza logo"
              />
              <img className="other" src={icons.fullLogo} alt="Sanza logo" />
            </NavLink>
          </div>

          <div className="pass-recovery-main__container">
            <div className="pass-recovery-main__title">
              <p>{t("auth:Recuperar contraseña")}</p>
            </div>
            <div className="pass-recovery-main__text">
              <p>
                {t(
                  "auth:Introduce tu dirección de correo y te enviaremos un mail para restablecer tu contraseña."
                )}
              </p>
            </div>
            <form onSubmit={handleSubmit(handleForgotPassword)}>
              <div className="pass-recovery-form">
                <div className="form-row small">
                  <InputController
                    inputProps={{
                      required: true,
                      placeholder: t("auth:Introduce tu correo"),
                      type: "text",
                      label: t("auth:Correo electrónico"),
                      error: getErrorMessage(t, errors.email?.type),
                    }}
                    controllerProps={{
                      control,
                      name: "email",
                      rules: {
                        required: true,
                        validate: {
                          isEmail: isValidEmail,
                        },
                      },
                    }}
                  />
                </div>
                <div className="pass-recovery-main__buttons">
                  <Button
                    className="pass-recovery-main__buttons__item"
                    type="secondary"
                    size="xxl"
                    text={t("auth:Atrás")}
                    onClick={() => history.push(routes.root)}
                  />
                  <Button
                    className="pass-recovery-main__buttons__item"
                    type="primary"
                    size="xxl"
                    text={t("auth:Enviar")}
                    onClick={handleSubmit(validate)}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="pass-recovery-side">
          <picture>
            <source
              srcSet={icons.passRecoveryBgBig}
              media="(min-width: 1100px)"
            />
            <img src={icons.passRecoveryBgSmall} alt="" />
          </picture>
        </div>
      </PassRecoveryStyle>
      <Footer />
    </>
  );
};

export default PassRecovery;
