import styled from "styled-components";
import { colors } from "../../../../assets/colors/colors";
import {
  BodyDefaultMixin,
  BodySmallMixin,
} from "../../../../components/text-components/body-text";

export const CardNotificationStyle = styled.div`
  cursor: pointer;
  .card-notification {
    background: ${colors["neutrals-0"]};
    padding: 16px;
    border-radius: 12px;
    border: 1px solid ${colors["neutrals-10"]};
    min-width: 302px;
    user-select: none;

    &__date {
      display: flex;
      align-items: center;
      color: ${colors["neutrals-80"]};
      margin-bottom: 6px;

      &__icon {
        margin-right: 8px;
      }

      &__text {
        ${BodySmallMixin};
      }
    }

    &__text,
    &__detail {
      ${BodyDefaultMixin};
      color: ${colors["neutrals-100"]};

      span {
        font-weight: 400;
      }

      &:not(:last-child) {
        margin-bottom: 2px;
      }
    }

    @media (max-width: 768px) {
      min-width: 0;
    }
  }

  &.in-header {
    .card-notification {
      &__detail {
        display: none;
      }
    }
  }
`;
