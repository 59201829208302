import React, { ReactElement } from "react";
import ReactTooltip from "react-tooltip";
import { TooltipStyle } from "./tooltip-style";

interface ITooltip {
  place: "top" | "left" | "right" | "bottom";
  content: string | ReactElement;
  className?: string;
  children: ReactElement;
}

const Tooltip: React.FC<ITooltip> = ({
  place,
  content,
  className,
  children,
}) => {
  const id = `tooltip-${Math.floor(Math.random() * 100)}`;

  return (
    <TooltipStyle>
      <ReactTooltip
        id={id}
        className="tooltip"
        place={place}
        effect="solid"
        delayHide={100}
        delayShow={100}
      >
        {content}
      </ReactTooltip>
      <div data-tip={content} data-for={id} className={className}>
        {children}
      </div>
    </TooltipStyle>
  );
};

export default Tooltip;
