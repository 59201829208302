import { useEffect } from "react";
import { logPageView } from "../utils/analytics";

declare global {
  interface Window {
    GA_INITIALIZED: boolean;
  }
}

const useReactGa = (url: string, title: string) => {
  useEffect(() => {
    logPageView(title);
  }, []);
};

export default useReactGa;
