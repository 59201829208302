import styled from 'styled-components';
import { colors } from '../../../assets/colors/colors';
import { FormRowMixin } from '../../../components/style-components/form-row';
import { BodyDefaultMixin } from '../../../components/text-components/body-text';
import { Heading1Mixin, Heading4Mixin } from '../../../components/text-components/heading-text';

export const CredentialsStyle = styled.div`
  display: flex;
  align-items: stretch;
  min-height: calc(100vh - 60px);
  width: 100%;
  position: relative;

  .credentials-main {
    flex: 1;
    padding: 44px 20px;
    padding-left: 130px;
    display: flex;
    flex-flow: column;
    /* align-items: center;
    align-self: center; */

    &__logo {
      width: 182px;
      margin-bottom: 62px;
      /* top: 48px;
      left: 120px; */
      position: relative;

      .other {
        display: block;
      }

      .tablet {
        display: none;
      }
    }

    &__container {
      display: flex;
      flex-flow: column;
      max-width: 426px;
      position: relative;
    }

    &__title {
      ${Heading1Mixin};
      color: ${colors['neutrals-100']};
      margin-bottom: 8px;
      user-select: none;
    }

    &__text {
      ${BodyDefaultMixin};
      color: ${colors['neutrals-50']};
      margin-bottom: 48px;
      user-select: none;
    }

    &__buttons {
      margin-top: 48px;
      display: flex;
      justify-content: flex-start;

      &__item {

        &:not(:first-child) {
          margin-left: 12px;
        }
      }
    }
  }

  ${FormRowMixin};

  .credentials-side {
    /* max-height: 100vh; */
    max-width: 38%;
    position: fixed;
    right: 0px;
    height: 100vh;
    flex: 1;
    z-index: -1;

    picture {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 150px 0px 0px 0px;
    }
  }

  @media (max-width: 1100px) {
    flex-flow: column;
    align-items: stretch;
    min-height: 0;

    .credentials-main {
      padding: 0 20px 60px;
      align-items: center;
      margin-top: 300px;

      &__container {
        padding-top: 60px;
      }

      &__logo {
        position: absolute;
        left: 54px;
        top: 60px;

        .other {
          display: none;
        }

        .tablet {
          display: block;
        }
      }

      &__container {
        max-width: 540px;
      }

    }

    .credentials-side {
      max-height: 300px;
      max-width: none;
      position:absolute;
      width: 100%;
      flex: none;
      order: -1;

      img {
        max-height: 300px;
        border-radius: 0px 0px 150px 0px;
      }
    }
  }

  @media (max-width: 768px) {
    .credentials-main {
      justify-content: flex-start;
      padding: 44px 20px 26px;
      align-self: stretch;
      align-items: stretch;
      margin-top: 0px;

      &__container {
        padding-top: 0px;
        max-width: none;
      }

      &__title {
        ${Heading4Mixin};
      }

      &__text {
        margin-bottom: 36px;
      }

      &__logo {
        left: initial;
        top: initial;
        position: relative;
        margin-bottom: 32px;
        width: 130px;

        .other {
          display: block;
        }

        .tablet {
          display: none;
        }
      }
    }

    .credentials-side {
      display: none;
    }
  }
`;