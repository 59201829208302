import i18n from "i18next";
import React from "react";
import {Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {LEGAL_LINKS_REGISTER} from "../../../constants/legal-links";
import useReactGa from "../../../hooks/useReactGa";
import { LegalStyle } from "./legal-style";

const Legal: React.FC<any> = (props: any) => {
  const { t } = useTranslation("legal");
  useReactGa(window.location.pathname, "Legal");

  return (
    <LegalStyle>
      <main className="main-container__content">
        <div className="legal-title">
          <p>{t("legal:Condiciones de Compra")}</p>
          <p>{t("legal:Sanza Market")}</p>
        </div>
        <div className="legal-text">
          <p>
            {t("legal:Sanza Energy, S.L. (en adelante “Sanza”) es la entidad titular del portal www.sanzaenergy.com (el “Portal”) y de esta plataforma informática de contratación y gestión de PPAs a la que usted ha accedido como Usuario introduciendo su contraseña (la “Plataforma”).")}
          </p>
          <Trans i18nKey={"legal:Al dar click en aceptar las presentes Condiciones de Compra, usted acepta incondicionalmente quedar vinculado a las mismas respecto de las operaciones de compraventa (contratación de PPAs) que realice en esta Plataforma. Las presentes Condiciones de Compra habrán de leerse en conjunto con las Condiciones Legales de uso de la Plataforma y nuestra Política de privacidad.  En caso de existir discrepancia entre lo establecido en las presentes Condiciones de Compra y las Condiciones Legales, prevalecerá lo dispuesto en estas Condiciones de Compra"}>
          Al dar click en aceptar las presentes Condiciones de Compra, usted acepta incondicionalmente quedar vinculado a las mismas respecto de las operaciones de compraventa (contratación de PPAs) que realice en esta Plataforma. Las presentes Condiciones de Compra habrán de leerse en conjunto con las <a href={`${LEGAL_LINKS_REGISTER[i18n.language]}`} style={{textDecoration: "underline"}}>Condiciones Legales de uso de la Plataforma</a> y nuestra <a href="/privacy" style={{textDecoration: "underline"}}>Política de privacidad</a>.  En caso de existir discrepancia entre lo establecido en las presentes Condiciones de Compra y las Condiciones Legales, prevalecerá lo dispuesto en estas Condiciones de Compra.
          </Trans>
          <p>
            {t("legal:Al realizar cualquier compra a través de esta Plataforma, usted reconoce y acepta libre, irrevocable e incondicionalmente que:")}
          </p>
          <ol>
            <li>
              {t("legal:Las operaciones que realice en esta Plataforma, las realiza realizan en nombre y representación de la empresa compradora cuyos datos se consignan en el apartado “Mi Perfil” de la Plataforma (el “Comprador”).")}
            </li>
            <li>
              {t("legal:Dentro de la sección “Condiciones de subasta y contrato” relativa a los Proyectos publicados en Sanza Market encontrará:")}
              <ol type="a">
                <li>
                  {t("legal:Un enlace para descargar las Condiciones Generales y Particulares del contrato PPA ofertado por el proyecto (el “PPA”).")}
                </li>
                <li>
                  {t("legal:Las reglas de la subasta en cuestión, y")}
                </li>
                <li>
                  {t("legal:Un resumen de las Condiciones Particulares del Contrato")}
                </li>

                <p>
                  {t("legal:El apartado de Condiciones Particulares del Contrato (referido en el apartado (c) anterior), es un resumen de disposiciones comerciales incluidas en el PPA, teniendo dicho resumen un carácter meramente orientativo y en ningún caso es vinculante, por lo que no releva al Comprador ni a sus Garantes de su obligación de leer detenidamente los términos y condiciones del PPA (tanto las condiciones generales como las Particulares). Las operaciones de compra dentro de la Plataforma se hacen respecto del PPA (que sí será vinculante) y en ningún caso respecto de dicho resumen")}
                </p>
              </ol>
            </li>

            <li>
              {t("legal:El Vendedor (y en su caso su(s) Garantes) que se describe(n) en las Condiciones Particulares del PPA (en adelante, la “Parte Vendedora”) ha emitido una oferta vinculante para celebrar el PPA con los usuarios de la Plataforma que válidamente emitan Órdenes de Compra respecto de volúmenes de energía disponibles conforme a las reglas de la subasta (la “Oferta del Vendedor”)")}
            </li>

            <li>
              {t("legal:Al pulsar “comprar” se enviará una comunicación a la/las dirección(es) de correo(s) electrónico(s) del/los apoderado(s) del Comprador (y en su caso de su(s) Garante(s)) dado(s) de alta en la Plataforma, adjuntándoles el PPA e informándoles el proceso que deberán seguir para validar la orden de compra. Dicho proceso consiste en lo siguiente")}
              <ol type="a">
                <li>
                  {t("legal:Deberán pulsar el botón “Aprobar orden de compra” dentro del correo electrónico que reciban.")}
                </li>
                <li>
                  {t("legal:Recibirán un SMS en el teléfono dado de alta para cada apoderado, con un código único de verificación")}
                </li>
                <li>
                  {t("legal:El apoderado deberá de introducir en el espacio correspondiente dentro de la Plataforma su código único de verificación en señal de aceptación")}
                </li>

                <p>
                  {t("legal:Hecho lo anterior, se entenderá que en ese momento se emite una orden de compra vinculante e irrevocable (tano para el Comprador como para sus Garantes dados de alta en la Plataforma) respecto del volumen de Energía Cubierta que el Comprador hubiese solicitado al hacer clic en “comprar” (la “Orden de Compra”)")}
                </p>

                <p>{t("legal:En caso de que el Comprador y/o su(s) Garante(s) haya(n) dado de alta a más de un apoderado y haya(n) elegido la opción de exigir firmas mancomunadas, la Orden de Compra no será válidamente emitida hasta que todos los apoderados necesarios aprueben la orden (es decir, será válida al momento en el que se reciba la confirmación del último apoderado requerido para la valida emisión de la orden)")}</p>
              </ol>
            </li>

            <li>
              {t("legal:La emisión de una Orden de Compra de conformidad con el punto anterior equivale a firmar el PPA en nombre y representación del Comprador y, en su caso, de su(s) Garante(s). Por lo Tanto, al emitir la Orden de Compra, el Comprador y su(s) Garante(s) declara(n) conocer y aceptar libre, irrevocable e incondicionalmente los términos del PPA, lo cual será inmediatamente comunicado a la Parte Vendedora a través de la propia Plataforma.")}
            </li>

            <li>
              {t("legal:Al concurrir oferta y aceptación en términos del artículo 1262 del Código Civil, desde la emisión de la Orden de Compra, y sujeto únicamente a que la subasta sea exitosa conforme a sus propias reglas, quedará perfeccionado el contrato PPA (respecto del volumen de Energía Cubierta que el Comprador hubiese establecido en su Orden de Compra), por lo que desde ese momento el Comprador y, en su caso, su(s) Garante(s) (al igual que la Parte Vendedora) se compromete(n) a cumplir el PPA sin reservas, restricciones ni condicionamientos")}
            </li>

            <li>
              {t("legal:Mientras el/los apoderado(s) del Comprador (y, en su caso, de su(s) Garante(s) dado(s) de alta en la Plataforma) no confirmen la orden, ésta no será válidamente emitida, por lo que otros Usuarios con perfil comprador estarán en libertad de emitir sus respectivas Órdenes de Compra respecto del mismo proyecto, teniendo preferencia para la adjudicación del PPA los Usuarios cuyos apoderados hubiesen validado antes sus Órdenes de Compra")}
            </li>

            <li>
              {t("legal:El Comprador (y en su caso su(s) Garante(s)) conoce(n) las reglas de la subasta publicadas en Sanza Market. En particular, reconoce(n) y acepta(n) lo siguiente:")}

              <ol type="a">
                <li>
                  <Trans i18nKey="legal:No es posible emitir Órdenes de Compra por volúmenes de energía que sean inferiores a volumen que se establece en el apartado de “Volumen mínimo de compra. Como excepción a esta regla, sí se podrán emitir Órdenes de Compra inferiores a éste umbra siempre y cuando sean segundas o ulteriores Órdenes de Compra realizadas por el Comprador respecto del mismo proyecto para el cual ya ha realizado una primer Orden de Compra por un volumen no inferior al Volumen mínimo de compra.">
                    No es posible emitir Órdenes de Compra por volúmenes de energía que sean inferiores a volumen que se establece en el apartado de “Volumen mínimo de compra. Como excepción a esta regla, sí se podrán emitir Órdenes de Compra inferiores a éste umbra siempre y cuando sean segundas o ulteriores Órdenes de Compra realizadas por el Comprador respecto del mismo proyecto para el cual ya ha realizado una primer Orden de Compra por un volumen no inferior al <span style={{ fontWeight: "bold" }}>Volumen</span> mínimo de compra.
                  </Trans>
                </li>
                <li>
                  {t("legal:Solo podrá emitir Órdenes de Compra para un proyecto, dentro del plazo comprendido entre las fechas indicadas en los apartados “Fecha Comienzo Subasta” y “Fecha Fin Subasta”.")}
                </li>
                <li>
                  {t("legal:No es posible emitir Órdenes de Compra por volúmenes de energía que hagan que se supere el volumen indicado en el apartado “Volumen disponible en Venta”.")}
                </li>
                <li>
                  {t("legal:La subasta será exitosa únicamente:")}

                  <ol>
                    <li>
                      {t("legal:En la fecha indicada como “Fecha Fin Subasta”, siempre que a esa fecha se hubiesen confirmado Órdenes de Compra que, de forma agregada, cubran al menos el volumen mínimo para considerar la subasta exitosa que se establece en el apartado de “Condiciones de Subasta y Contrato” dentro del proyecto publicado en la Plataforma de que se trate, o")}
                    </li>

                    <li>
                      {t("legal:en la fecha en la que se reciba una Orden de Compra que agote el volumen total de energía disponible en la subasta que se establece en el apartado de “Condiciones de Subasta y Contrato” dentro del proyecto publicado en la Plataforma")}
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li>
              {t("legal:Que tanto el propio Comprador como, en su caso, su(s) Garante(s) es/son una(s) parte(s) sofisticada(s) que han llevado a cabo su propio análisis (con sus respectivos asesores) y entiende perfectamente los posibles riesgos e implicaciones de la subasta y el PPA y los términos relativos a su contratación a través de la Plataforma, por lo que releva a Sanza de cualquier responsabilidad al respecto.")}
            </li>

            <li>
              {t("legal:Qué la Parte Vendedora se ha adherido incondicionalmente a estas Condiciones de Compra y es parte contractual de las mismas, por lo que los compromisos aquí previstos son asumidos por el Comprador (y su(s) Garante(s)) no solo frente a Sanza, sino que también frente a la Parte Vendedora, del mismo modo que al publicar la Subasta en Sanza Market la Parte Vendedora ha asumido obligaciones o responsabilidades no solo frente a Sanza, sino también frente al Comprador y sus Garantes, particularmente respecto de mantener su oferta vinculante referida en los términos indicados en el apartado 3 anterior.")}
            </li>

            <li>
              {t("legal:Estas Condiciones de Compra se regirán por la legislación española. Los tribunales de Madrid tendrán jurisdicción exclusiva para dirimir cualquier controversia derivada o relacionada con las mismas o con las operaciones que se realicen al amparo de éstas.")}
            </li>
          </ol>
        </div>
      </main>
    </LegalStyle>
  );
};

export default Legal;
