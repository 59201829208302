import React from "react";
import { Control, DeepMap, FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import RadioController from "../../../../../components/radio/radio-controller";
import useReactGa from "../../../../../hooks/useReactGa";
import { USER_ROLE } from "../../../../../types/users/user.constants";
import { getErrorMessage } from "../../../../../utils/errorMessage";
interface IRegisterType {
  control: Control<any>;
  errors: DeepMap<any, FieldError>;
}

const RegisterType: React.FC<IRegisterType> = ({ control, errors }) => {
  const { t } = useTranslation(["auth", "validation"]);
  useReactGa(window.location.pathname, "Registro - Tipo de Usuario");

  return (
    <div className="card-register-choose">
      <RadioController
        radioProps={{
          required: true,
          className: "card",
          label: t("auth:Selecciona el tipo de perfil que quieres registrar."),
          options: [
            {
              value: "buyer",
              label: t("auth:Soy Comprador"),
              name: USER_ROLE,
            },
            {
              value: "generator",
              label: t("auth:Soy Generador"),
              name: USER_ROLE,
            },
          ],
          error: getErrorMessage(t, errors[USER_ROLE]?.type),
        }}
        controllerProps={{
          control,
          name: USER_ROLE,
          rules: {
            required: true,
          },
        }}
      />
    </div>
  );
};

export default RegisterType;
