import { useSelector } from "react-redux";
import { ProjectStatusEnum } from "../constants/options/projects";
import { IActions, rules } from "../constants/roles";
import {
  RENDER,
  RenderEnum,
} from "../containers/internal/projects/constants-project/project-detail.constants";
import {
  RENDER as RENDER_USER,
  RenderEnum as RenderUserEnum,
} from "../containers/internal/users/user-detail/user-detail.constants";
import { authSelector } from "../store/auth/selectors";
import { projectsSelector } from "../store/projects/selectors";
import { usersSelector } from "../store/users/selectors";
import { STATUS } from "../types/projects/project.constant";
import { QualificationStatusEnum } from "../types/qualifications/qualification.entity";
import { USER_IDENTITY_STATUS } from "../types/users/user.constants";
import {
  UserIdentityStatusEnum,
  UserRolesEnum,
} from "../types/users/users.enums";

// TODO:
// 1. Recuperar info de proyectos
// 2. Revisar rol para ver pantallas y tabs
// 3. Bloquear Tabs según estado del proyecto
// 4. Validaciones Formularios
// 5. Guardar cada form

// TODO:
// Vistas Totales
// Gestor Sanza - Alta Proyecto, Contratación Sanza, Crear Subasta y Subasta
// Generador - Alta Proyecto, Contratación Sanza, Crear Subasta, Subasta, Proyecto en Desarrollo y PPA en Vigor
// Comprador - Info del proyecto, Subasta, Proyecto en desarrollo, PPA en Vigor
// Asesor - Alta Proyecto, Crear Subasta y Subasta

const useCan = () => {
  const me = useSelector(authSelector.getUser());
  const project = useSelector(projectsSelector.getProject());
  const qualification: any = useSelector(usersSelector.getQualification());
  const user: any = useSelector(usersSelector.getUser());

  const canEditProject = (tab: RENDER) => {
    const notCreation =
      project?.status.indexOf("creation_") === -1 &&
      project?.status !== "draft";
    const notHiring = project?.status.indexOf("hiring_") === -1;
    const notCreateAuction = project?.status.indexOf("auction_") === -1;
    if (project?.isDeleted) {
      return false;
    }
    switch (tab) {
      case RenderEnum.REGISTER_PROJECT:
        if (me.role === UserRolesEnum.MANAGER) {
          return (
            project?.status !== ProjectStatusEnum.DRAFT &&
            project?.status !== ProjectStatusEnum.CREATION_NEEDS_MORE_INFO &&
            project?.status !== ProjectStatusEnum.CREATION_REJECTED
          );
        }
        if (me.role === UserRolesEnum.GENERATOR) {
          return (
            project?.status === ProjectStatusEnum.DRAFT ||
            project?.status === ProjectStatusEnum.CREATION_NEEDS_MORE_INFO
          );
        }
        break;
      case RenderEnum.HIRING_SANZA:
        if (me.role === UserRolesEnum.MANAGER) {
          return (
            notCreation && project?.status !== ProjectStatusEnum.HIRING_REJECTED
          );
        }
        break;
      case RenderEnum.CREATE_AUCTION:
        if (me.role === UserRolesEnum.MANAGER) {
          return (
            notCreation &&
            notHiring &&
            project?.status !== ProjectStatusEnum.AUCTION_PENDING &&
            project?.status !== ProjectStatusEnum.AUCTION_NEEDS_MORE_INFO &&
            project?.status !== ProjectStatusEnum.AUCTION_REJECTED
          );
        }
        if (me.role === UserRolesEnum.GENERATOR) {
          return (
            project?.status === ProjectStatusEnum.AUCTION_PENDING ||
            project?.status === ProjectStatusEnum.AUCTION_NEEDS_MORE_INFO
          );
        }
        break;
      case RenderEnum.DETAIL_TICKET:
        if (me.role === UserRolesEnum.MANAGER) {
          // TODO: Desierta y finalizada a false
          return true;
        }
        break;
    }
    return false;
  };

  const canEditUser = (tab?: RENDER_USER) => {
    if (me.role === UserRolesEnum.MANAGER) {
      return true;
    }
    if (me.role === UserRolesEnum.BUYER) {
      if (
        tab === RenderUserEnum.EDIT_IDENTITY &&
        [
          undefined,
          UserIdentityStatusEnum.DRAFT,
          UserIdentityStatusEnum.NEEDS_MORE_INFO,
        ].indexOf(user?.[USER_IDENTITY_STATUS]) > -1
      ) {
        return true;
      }
      if (
        tab === RenderUserEnum.EDIT_VALIDATION &&
        [
          undefined,
          QualificationStatusEnum.DRAFT,
          QualificationStatusEnum.NEEDS_MORE_INFO,
        ].indexOf(qualification?.[STATUS]) > -1
      ) {
        return true;
      }
    }
    return false;
  };

  const check = (action: string) => {
    const role: UserRolesEnum = me.role;
    const actions: IActions = rules[role];

    if (!actions) {
      return false;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { static: staticActions, dynamic: dynamicActions } = actions;

    if (staticActions && staticActions.includes(action)) {
      return true;
    }

    return false;
  };

  return {
    canEditProject,
    canEditUser,
    check,
  };
};

export default useCan;
