import styled from 'styled-components';
import { colors } from '../../assets/colors/colors';
import { BodyDefaultMixin } from '../text-components/body-text';

export const MainMenuStyle = styled.div`
  height: calc(100vh - 64px);
  position: fixed;
  top: 64px;
  left: 0;
  right: calc(100% - 76px);
  transition: all 0.3s ease-in-out;
  /* width: 100%; */
  /* max-width: 76px; */
  z-index: 100;

  .main-menu-container {
    background: ${colors['neutrals-0']};
    display: flex;
    flex-flow: column;
    left: 0;
    right: calc(100% - 76px);
    transition: all 0.3s ease-in-out;
    position: relative;
    padding: 84px 12px 60px;
    height: 100%;
    border-right: 1px solid ${colors['neutrals-5']};
  }

  .main-menu-toggle {
    background: ${colors['neutrals-0']};
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 20px;
    transition: all 0.3s ease-in-out;
    border: 1px solid ${colors['secondary-2-10']};
  }

  .main-menu-links {
    & > .main-menu-link {
      &:not(:first-child) {
        margin-top: 8px;
      }
    }
  }

  .main-menu-spacer {
    flex: 1;
  }

  .main-menu-footer {
    & > .main-menu-link {
      &:not(:first-child) {
        margin-top: 8px;
      }
    }
  }

  .main-menu-link {
    border-radius: 12px;
    padding: 12px 13px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: 1px solid transparent;

    &__icon {
      svg * {
        transition: all 0.3s ease-in-out;
        fill: ${colors['neutrals-50']};
      }

      &.icon-stroke {
        svg * {
          fill: transparent;
          stroke: ${colors['neutrals-50']};
        }
      }
    }

    &__text {
      position: absolute;
      left: 46px;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0s ease-in-out;
      user-select: none;
      opacity: 0;
      color: ${colors['neutrals-50']};
      ${BodyDefaultMixin};
    }

    &:hover:not(:active), &:focus {
      background: ${colors['brand-1-10']};

      .main-menu-link__icon {
        svg * {
          fill: ${colors['brand-1-100']};
        }

        &.icon-stroke {
          svg * {
            fill: transparent;
            stroke: ${colors['brand-1-100']};
          }
        }
      }

      .main-menu-link__text {
        color: ${colors['brand-1-100']};
      }
    }

    &.active {
      background: transparent;
      border-color: transparent;

      .main-menu-link__icon {
        svg * {
          fill: ${colors['brand-1-100']};
        }

        &.icon-stroke {
          svg * {
            fill: transparent;
            stroke: ${colors['brand-1-100']};
          }
        }
      }

      .main-menu-link__text {
        color: ${colors['brand-1-100']};
      }
    }

    &:active {
      border-color: ${colors['brand-1-100']};
      background: ${colors['brand-1-10']};

      .main-menu-link__icon {
        svg * {
          fill: ${colors['brand-1-100']};
        }

        &.icon-stroke {
          svg * {
            fill: transparent;
            stroke: ${colors['brand-1-100']};
          }
        }
      }

      .main-menu-link__text {
        color: ${colors['brand-1-100']};
      }
    }
  }

  &.open {
    right: calc(100% - 240px);

    .main-menu-container {
      right: calc(100% - 240px);
    }

    .main-menu-toggle {
      transform: rotate(180deg);
      right: 12px;
    }

    .main-menu-link {
      &__text {
        transition: opacity 0.3s 0.3s ease-in-out, color 0.3s ease-in-out;
        opacity: 1;
      }
    }
  }

  @media (max-width: 1100px) {
    display: none;
  }
`;