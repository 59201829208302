import React, { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import Loader from "../components/loader/loader";
import MainContainer from "../components/main-container/main-container";
import { authSelector } from "../store/auth/selectors";
import { UserRolesEnum } from "../types/users/users.enums";
import { CLASSNAMES } from "./internal.routes-constants";
import { commonRoutes, internalRoutes } from "./routes";

const UserDetail = lazy(
  () => import("../containers/internal/users/user-detail/user-detail")
);
const Cookies = lazy(() => import("../containers/internal/cookies/cookies"));
const Faqs = lazy(() => import("../containers/internal/faqs/faqs"));
const BuyEnergy = lazy(() => import("../containers/internal/home/buy-energy"));
const Contracts = lazy(() => import("../containers/internal/home/contracts"));
const DigitalContracts = lazy(
  () => import("../containers/internal/home/digital-contracts")
);
const Home = lazy(() => import("../containers/internal/home/home"));
const SellEnergy = lazy(
  () => import("../containers/internal/home/sell-energy")
);
const Services = lazy(() => import("../containers/internal/home/services"));
const Legal = lazy(() => import("../containers/internal/legal/legal"));
const CreateNotification = lazy(
  () =>
    import(
      "../containers/internal/notifications/create-notification/create-notification"
    )
);
const Notifications = lazy(
  () => import("../containers/internal/notifications/notifications")
);
const Privacy = lazy(() => import("../containers/internal/privacy/privacy"));
const Profile = lazy(() => import("../containers/internal/profile/profile"));
const EditPassword = lazy(
  () =>
    import(
      "../containers/internal/profile/profile-edit/edit-password/edit-password"
    )
);
const CreateProject = lazy(
  () => import("../containers/internal/projects/create-project/create-project")
);
const ProjectDetail = lazy(
  () => import("../containers/internal/projects/detail-project/project-detail")
);
const Projects = lazy(() => import("../containers/internal/projects/projects"));
const CreateUser = lazy(
  () => import("../containers/internal/users/create-user/create-user")
);

const Users = lazy(() => import("../containers/internal/users/users"));
const SanzaMarket = lazy(
  () => import("../containers/internal/sanza-market/sanza-market")
);
const SanzaMarketDetail = lazy(
  () =>
    import(
      "../containers/internal/sanza-market/sanza-market-containers/sanza-market-detail/sanza-market-detail"
    )
);

const InternalRoutes: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const { role } = useSelector(authSelector.getUser());

  history.listen(() => window.scrollTo(0, 0));

  return (
    <Suspense fallback={() => <Loader />}>
      <MainContainer className={CLASSNAMES[location.pathname]}>
        <Switch>
          <Route
            exact
            path={internalRoutes.createProject}
            component={CreateProject}
          />
          <Route
            exact
            path={internalRoutes.editProject}
            component={ProjectDetail}
          />
          <Route exact path={internalRoutes.projects} component={Projects} />
          <Route
            exact
            path={internalRoutes.createNotification}
            component={CreateNotification}
          />
          <Route
            exact
            path={internalRoutes.notifications}
            component={Notifications}
          />
          <Route
            exact
            path={internalRoutes.profilePassword}
            component={EditPassword}
          />
          <Route exact path={internalRoutes.profile} component={Profile} />
          <Route
            exact
            path={internalRoutes.createUser}
            component={CreateUser}
          />
          <Route exact path={internalRoutes.editUser} component={UserDetail} />
          <Route exact path={internalRoutes.users} component={Users} />
          <Route exact path={commonRoutes.privacy} component={Privacy} />
          <Route exact path={commonRoutes.cookies} component={Cookies} />
          <Route exact path={commonRoutes.legal} component={Legal} />
          <Route
            exact
            path={internalRoutes.homeDigitalContracts}
            component={DigitalContracts}
          />
          <Route
            exact
            path={internalRoutes.homeServices}
            component={Services}
          />
          <Route
            exact
            path={internalRoutes.homeContracts}
            component={Contracts}
          />
          <Route
            exact
            path={internalRoutes.homeBuyEnergy}
            component={BuyEnergy}
          />
          <Route
            exact
            path={internalRoutes.homeSellEnergy}
            component={SellEnergy}
          />
          <Route
            exact
            path={internalRoutes.sanzaMarket}
            component={SanzaMarket}
          />
          <Route
            exact
            path={internalRoutes.detailSanzaMarket}
            component={SanzaMarketDetail}
          />
          <Route exact path={internalRoutes.faqs} component={Faqs} />
          <Route exact path={internalRoutes.home} component={Home} />
          <Redirect
            to={
              role === UserRolesEnum.ADVISER
                ? internalRoutes.users
                : internalRoutes.home
            }
          />
        </Switch>
      </MainContainer>
    </Suspense>
  );
};

export default InternalRoutes;
