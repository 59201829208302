import React from "react";
import { Controller } from "react-hook-form";
import { IController } from "../../types/controller-interface";
import Select, { SelectProps } from "./select";

interface ISelectControllerProps {
  selectProps: SelectProps;
  controllerProps: IController;
}

const SelectController: React.FC<ISelectControllerProps> = ({
  selectProps,
  controllerProps,
}) => {
  return (
    <Controller
      render={({ value, onChange, onBlur, name }) => (
        <Select
          {...selectProps}
          onChange={(option: any) => {
            if (controllerProps.onChange) {
              controllerProps.onChange(option);
            }
            onChange(option);
            onBlur();
          }}
          onBlur={(option: any) => {
            if (controllerProps.onBlur) {
              controllerProps.onBlur(option);
            }
            onBlur();
          }}
          value={value}
          name={name}
        />
      )}
      {...controllerProps}
    />
  );
};

export default SelectController;
