import styled from "styled-components";
import { colors } from "../../../../assets/colors/colors";
import { BodyDefaultMixin } from "../../../../components/text-components/body-text";

export const CardRequestStyle = styled.div`
  cursor: pointer;
  .card-request {
    background: ${colors["neutrals-0"]};
    padding: 16px;
    border-radius: 12px;
    border: 1px solid ${colors["neutrals-10"]};
    min-width: 302px;
    user-select: none;

    &__user {
      display: flex;
      align-items: center;
      color: ${colors["neutrals-80"]};
      margin-bottom: 2px;

      &__icon {
        margin-right: 8px;
      }

      &__text {
        ${BodyDefaultMixin};
      }
    }

    &__type {
      ${BodyDefaultMixin};
      color: ${colors["neutrals-100"]};
      margin-bottom: 2px;
    }

    &__user {
      ${BodyDefaultMixin};
      color: ${colors["neutrals-80"]};
      margin-bottom: 2px;
    }

    @media (max-width: 768px) {
      min-width: 0;
    }
  }
`;
