interface IColors {
  [key: string]: string;
  "brand-1-120": string;
  "brand-1-100": string;
  "brand-1-80": string;
  "brand-1-50": string;
  "brand-1-30": string;
  "brand-1-10": string;
  "secondary-1-100": string;
  "secondary-1-80": string;
  "secondary-1-50": string;
  "secondary-1-30": string;
  "secondary-1-10": string;
  "secondary-2-100": string;
  "secondary-2-80": string;
  "secondary-2-50": string;
  "secondary-2-30": string;
  "secondary-2-10": string;
  "secondary-3-100": string;
  "secondary-3-80": string;
  "secondary-3-50": string;
  "secondary-3-30": string;
  "secondary-3-10": string;
  "neutrals-100": string;
  "neutrals-80": string;
  "neutrals-50": string;
  "neutrals-30": string;
  "neutrals-10": string;
  "neutrals-5": string;
  "neutrals-0": string;
  "success-100": string;
  "success-80": string;
  "success-50": string;
  "success-30": string;
  "success-10": string;
  "alert-100": string;
  "alert-80": string;
  "alert-50": string;
  "alert-30": string;
  "alert-10": string;
  "error-100": string;
  "error-80": string;
  "error-50": string;
  "error-30": string;
  "error-10": string;
}

export const colors: IColors = {
  "brand-1-120": "#39996B",
  "brand-1-100": "#45BE84",
  "brand-1-80": "#6ACB9D",
  "brand-1-50": "#A2DFC1",
  "brand-1-30": "#C7ECDA",
  "brand-1-10": "#ECF9F3",
  "secondary-1-100": "#C9F2C7",
  "secondary-1-80": "#D4F5D2",
  "secondary-1-50": "#E4F8E3",
  "secondary-1-30": "#EFFBEE",
  "secondary-1-10": "#FAFEF9",
  "secondary-2-100": "#1A4330",
  "secondary-2-90": "#536B60",
  "secondary-2-80": "#486959",
  "secondary-2-50": "#8CA197",
  "secondary-2-30": "#BAC7C1",
  "secondary-2-10": "#E8ECEA",
  "secondary-3-100": "#134074",
  "secondary-3-80": "#426690",
  "secondary-3-50": "#899FB9",
  "secondary-3-30": "#B8C6D5",
  "secondary-3-10": "#E7ECF1",
  "secondary-3-5": "#F3F5F8",
  "neutrals-100": "#222222",
  "neutrals-80": "#4E4E4E",
  "neutrals-50": "#909090",
  "neutrals-30": "#BDBDBD",
  "neutrals-10": "#E9E9E9",
  "neutrals-5": "#F4F4F4",
  "neutrals-0": "#FFFFFF",
  "success-100": "#2F964F",
  "success-80": "#59AB72",
  "success-50": "#97CBA7",
  "success-30": "#C1DFCA",
  "success-10": "#EAF4ED",
  "alert-100": "#EC952F",
  "alert-80": "#F0AA59",
  "alert-50": "#F5CA97",
  "alert-30": "#F9DFC1",
  "alert-10": "#FDF4EA",
  "error-100": "#ED2A36",
  "error-80": "#F1555E",
  "error-50": "#F6959B",
  "error-30": "#FABFC3",
  "error-10": "#FDEAEB",
};
