import classnames from "classnames";
import _ from "lodash";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { icons } from "../../assets/icons/icons";
import Checkbox from "../checkbox/checkbox";
import SVGIcon from "../svg-icon/svg-icon";
import Tooltip from "../tooltip/tooltip";
import { InputStyle, SelectContainerStyle, SelectStyle } from "./input-style";

const useOutsideClick = (ref: any, callback: any) => {
  const handleClick = (e: any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

export interface SelectProps {
  className?: string;
  disableFocus?: boolean;
  disabled?: boolean;
  editorVariant?: boolean;
  error?: boolean | string;
  label?: string;
  menuIsOpen?: boolean;
  message?: string;
  multiple?: boolean;
  name?: string;
  onBlur?: any;
  onChange?: any;
  onChangeInput?: any;
  onClear?: any;
  onClose?: any;
  onFocus?: any;
  onKeyDown?: any;
  options: { value: string; label: string; icon?: any; [key: string]: any }[];
  placeholder?: string;
  readonly?: any;
  required?: boolean;
  success?: boolean | string;
  tooltip?: string;
  value?: any;
  messageEmpty?: string;
  notClear?: boolean;
}

const Select: React.FC<SelectProps> = (props: SelectProps) => {
  const { t } = useTranslation("common");

  const [open, setOpen] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<any>(props.multiple ? [] : {});
  const [options, setOptions] = React.useState<any[]>(
    props.options && props.options.length > 0 ? props.options : []
  );
  const [focus, setFocus] = React.useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const svgRef = useRef<any>(null);
  const toggleContainer = useRef(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onClose, name } = props;
  const didMountRef = useRef(false);

  useEffect(() => {
    if (Array.isArray(props.options)) {
      setOptions(props.options);
    }
  }, [props.options]);

  useEffect(() => {
    if (!open && didMountRef.current) {
      if (!_.isEqual(props.value, value)) {
        // tslint:disable-next-line:no-unused-expression
        onClose && onClose(value, name);
      }
    }
    didMountRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onClickOutsideHandler = () => {
    if (open) {
      handleOpen();
    }
  };

  const handleOpen = (event?: any) => {
    if (event && svgRef.current.contains(event.target)) {
      return;
    }
    if (inputRef && inputRef.current) {
      inputRef.current.value = "";
    }
    if (!open) {
      setOptions(props.options.map((o: any) => o));
    }
    if (!props.disabled) {
      setOpen(!open);
    }
  };

  const handleChange = (ev: any) => {
    if (!open) {
      setOpen(true);
    }
    if (props.onChangeInput) {
      props.onChangeInput(ev);
    } else {
      const val = ev.target.value
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      const optionsNew = props.options.filter(
        (item: any) =>
          item.label
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .indexOf(val) > -1
      );

      setOptions(optionsNew);
    }
  };

  const onClear = () => {
    setValue([]);
    if (!open) {
      if (props.onChange) {
        props.onChange(props.multiple ? [] : "");
      }
      if (props.onClear) {
        props.onClear(props.multiple ? [] : "", props.name);
      }
    }
  };

  const handleSelectedItem = (option: any) => {
    let auxValue: any = JSON.parse(JSON.stringify(props.value || value));
    if (inputRef && inputRef.current) {
      inputRef.current.value = "";
    }
    setOptions(props.options.map((o: any) => o));

    if (props.multiple) {
      auxValue = JSON.parse(JSON.stringify(value));

      const optionIndex = auxValue.findIndex(
        (opt: any) => opt.value === option.value
      );

      if (optionIndex !== -1) {
        auxValue.splice(optionIndex, 1);
      } else {
        auxValue.push(option);
      }

      setValue(auxValue);
    } else {
      setOpen(false);
      setValue(option);
    }
    if (props.onChange) {
      props.onChange(props.multiple ? auxValue : option);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const removeItem = (index: number) => {
    const val: any = JSON.parse(JSON.stringify(props.value || value));

    val.splice(index, 1);

    if (props.onChange) {
      props.onChange(val);
    }
    setValue(val);
  };

  const renderSelectedItems = () => {
    let val = props.value ?? value;

    if (!props.multiple) {
      if (Object.keys(val).length === 0) return;

      return <div className={classnames("selected-item")}>{val.label}</div>;
    }
    val = value;

    return (
      <div className={classnames("selected-item", "multiple")}>
        {val.length > 0 && `Total: ${val.length}`}
      </div>
    );
  };

  const isSelected = (option: any) => {
    let val = props.value || value;

    if (props.multiple) {
      val = value;
      return (
        val.findIndex(
          (op: any) => JSON.stringify(option) === JSON.stringify(op)
        ) > -1
      );
    }

    return JSON.stringify(option) === JSON.stringify(val);
  };

  useOutsideClick(toggleContainer, onClickOutsideHandler);

  return (
    <SelectContainerStyle>
      <InputStyle
        ref={toggleContainer}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!open);
        }}
        className={classnames(props.className || "", {
          dropdown: !props.editorVariant,
          "dropdown-variant": props.editorVariant,
          error: !!props.error,
          success: !!props.success,
          disabled: !!props.disabled,
          required: !!props.required,
          "on-focus": !!focus,
          complete:
            Object.keys(props.value || value).length > 0 ||
            (!!focus && inputRef.current?.value !== ""),
          readonly: !!props.readonly,
          "disable-focus": !!props.disableFocus,
        })}
      >
        <div className="input-top">
          {props.label && (
            <label className="input-top__label" htmlFor={props.name}>
              <p>
                {props.label}
                {props.required && <span>*</span>}
              </p>
            </label>
          )}
          {/* {props.error && typeof props.error === 'string' && (
            <div className="input-top__error">
              <p>{props.error}</p>
            </div>
          )} */}
          {props.tooltip && (
            <Tooltip place="left" content={props.tooltip}>
              <SVGIcon icon={icons.question} size={"16px"} />
            </Tooltip>
          )}
        </div>
        <div className="input-body">
          <div
            className="input-body-wrapper"
            onClick={(ev) => {
              ev.stopPropagation();
              handleOpen();
            }}
          >
            <div className={`input-body__selected`}>
              {renderSelectedItems()}
            </div>

            <input
              id={props.name}
              autoComplete={"off"}
              ref={inputRef}
              type="text"
              name={props.name}
              placeholder={
                Object.keys(props.multiple ? value : props.value ?? value)
                  .length !== 0
                  ? ""
                  : props.placeholder
              }
              disabled={props.disabled || props.readonly}
              required={props.required}
              onChange={(ev: any) =>
                !props.disabled && !props.readonly && handleChange(ev)
              }
              onFocus={(ev: any) => {
                if (props.disabled || props.readonly) {
                  ev.preventDefault();
                  return;
                }
                setFocus(true);
                return props.onFocus && props.onFocus(ev);
              }}
              onBlur={(ev: any) => {
                if (props.disabled || props.readonly) {
                  ev.preventDefault();
                  return;
                }
                setFocus(false);
                if (!props.multiple) {
                  setTimeout(() => {
                    handleOpen();
                  }, 200);
                }

                return props.onBlur && props.onBlur(ev);
              }}
              onKeyDown={(ev: any) =>
                !props.disabled &&
                !props.readonly &&
                props.onKeyDown &&
                props.onKeyDown(ev)
              }
            />

            {(props.multiple &&
              (value?.length > 0 || props.value?.length > 0)) ||
              (!props.notClear &&
                !props.multiple &&
                (!!value?.value || !!props.value?.value) && (
                  <div
                    ref={svgRef}
                    onClick={(ev: any) => {
                      if (!props.disabled) {
                        ev.stopPropagation();
                        onClear();
                      }
                    }}
                    className={classnames("input-body__icon", "clear-button")}
                  >
                    <SVGIcon icon={icons.close} size="20px" />
                  </div>
                ))}

            <div
              className="input-body__icon select"
              onClick={(ev: any) => {
                ev.stopPropagation();
                setOpen(!open);
              }}
            >
              <SVGIcon
                icon={open ? icons.arrowUpSmall : icons.arrowDownSmall}
                size="20px"
              />
            </div>
          </div>
          <SelectStyle
            className={classnames({ open: open || props.menuIsOpen })}
          >
            {options.length > 0 ? (
              options.map((option: any) => {
                return (
                  <div
                    key={`list-${option.value}`}
                    className={classnames("select-item", {
                      multiple: props.multiple,
                      selected: isSelected(option),
                    })}
                    onClick={() => handleSelectedItem(option)}
                  >
                    {props.multiple && (
                      <div className="input-body-option-checkbox">
                        <Checkbox
                          inSelect={true}
                          checked={isSelected(option)}
                        />
                      </div>
                    )}
                    <p>{option.label}</p>
                    {option.icon && <SVGIcon icon={option.icon} size="16px" />}
                  </div>
                );
              })
            ) : (
              <div className="select-item disable">
                <p>{props.messageEmpty || t("common:No hay opciones")}</p>
              </div>
            )}
          </SelectStyle>
        </div>
        <div className="input-bottom">
          {props.error && typeof props.error === "string" ? (
            <div className="input-bottom__error">
              <p>{props.error}</p>
            </div>
          ) : (
            props.success &&
            typeof props.success === "string" && (
              <div className="input-bottom__success">
                <p>{props.success}</p>
              </div>
            )
          )}
          {props.message && (
            <div className="input-bottom__message">
              <p>{props.message}</p>
            </div>
          )}
        </div>
      </InputStyle>
    </SelectContainerStyle>
  );
};

export default Select;
