export const IDENTITY_GENERATOR = "identityGenerator";
export const IDENTITY_GUARANTOR = "identityGuarantor";
export const IDENTITY_BUYER_ACT = "valueAsAConsumerCompany";

export const IDENTITY_COMPANY_NAME = "companyName";
export const IDENTITY_PROJECT_SOCIETY = "projectSociety";
export const IDENTITY_GUARANTOR_SOCIETY = "guarantorSociety";
export const IDENTITY_SEIZURE = "seizure";
export const IDENTITY_KYC = "kyc";
export const IDENTITY_POWER_COPY = "powerOfAttorneyDocument";
export const IDENTITY_FIRST_PROXY = "firstProxy";
export const IDENTITY_SECOND_PROXY = "secondProxy";

export const IDENTITY_NAME = "name";
export const IDENTITY_LASTNAME = "lastname";
export const IDENTITY_PHONE = "phone";
export const IDENTITY_JOB = "job";
export const IDENTITY_EMAIL = "email";
export const IDENTITY_DNI = "dni";

// TODO: Sanza no ha confirmado
export const IDENTITY_REQUEST = "launchRequest";
export const IDENTITY_HAS_GUARANTOR = "hasGuarantor";
