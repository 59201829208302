import styled from "styled-components";
import { colors } from "../../assets/colors/colors";
import { BodyDefaultMixin } from "../text-components/body-text";

export const MainHeaderStyle = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  /* box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05); */
  z-index: 100;

  .main-header-bar {
    height: 64px;
    background: ${colors["neutrals-0"]};
    display: flex;
    align-items: center;
    padding: 6px 16px;
    padding-right: 38px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 75px;
      right: 0;
      height: 1px;
      bottom: -1px;
      background: ${colors["neutrals-5"]};
      z-index: 99;
    }

    &__small-logo,
    &__logo {
      transition: all 0.3s ease-in-out;
    }

    &__logo {
      margin-left: 12px;
    }

    &__small-logo {
      display: none;
      margin-left: 5px;
    }

    &__notifications {
      margin-left: 8px;
      transition: all 0.3s ease-in-out;
      position: relative;

      .button-icon {
        margin-right: 0;
      }
    }

    &__spacer {
      flex: 1;
    }

    &__toggle {
      display: none;
    }

    &__toggle-close {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: all 0.3s ease-in-out;
    }
  }

  .main-header-menu {
    position: fixed;
    box-shadow: 0px 20px 40px rgba(34, 34, 34, 0.05);
    padding: 20px;
    top: 56px;
    bottom: 0;
    left: calc(100% - 332px);
    right: 0;
    display: flex;
    flex-flow: column;
    background: ${colors["neutrals-0"]};
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
  }

  .main-header-links {
    & > .main-header-link {
      &:not(:first-child) {
        margin-top: 8px;
      }
    }
  }

  .main-header-spacer {
    flex: 1;
    min-height: 8px;
  }

  .main-header-link {
    border-radius: 12px;
    padding: 12px 15px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &__icon {
      svg * {
        transition: all 0.3s ease-in-out;
        fill: ${colors["neutrals-50"]};
      }

      &.icon-stroke {
        svg * {
          fill: transparent;
          stroke: ${colors["neutrals-50"]};
        }
      }
    }

    &__text {
      position: absolute;
      left: 46px;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.3s ease-in-out;
      user-select: none;
      color: ${colors["neutrals-50"]};
      ${BodyDefaultMixin};
    }

    &:hover:not(:active),
    &:focus {
      background: ${colors["brand-1-10"]};

      .main-header-link__icon {
        svg * {
          fill: ${colors["brand-1-100"]};
        }

        &.icon-stroke {
          svg * {
            fill: transparent;
            stroke: ${colors["brand-1-100"]};
          }
        }
      }

      .main-header-link__text {
        color: ${colors["brand-1-100"]};
      }
    }

    &.active {
      background: transparent;
      border-color: transparent;

      .main-header-link__icon {
        svg * {
          fill: ${colors["brand-1-100"]};
        }

        &.icon-stroke {
          svg * {
            fill: transparent;
            stroke: ${colors["brand-1-100"]};
          }
        }
      }

      .main-header-link__text {
        color: ${colors["brand-1-100"]};
      }
    }

    &:active {
      border-color: ${colors["brand-1-100"]};
      background: ${colors["brand-1-10"]};

      .main-header-link__icon {
        svg * {
          fill: ${colors["brand-1-100"]};
        }

        &.icon-stroke {
          svg * {
            fill: transparent;
            stroke: ${colors["brand-1-100"]};
          }
        }
      }

      .main-header-link__text {
        color: ${colors["brand-1-100"]};
      }
    }
  }

  @media (max-width: 1100px) {
    &.open {
      .main-header-bar {
        /* &__small-logo, &__notifications {
          opacity: 0;
        } */

        &__toggle-close {
          opacity: 1;
        }
      }

      .main-header-menu {
        pointer-events: all;
        opacity: 1;
      }
    }

    .main-header-bar {
      height: 56px;
      padding: 6px 54px;
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);

      &::after {
        left: 0;
      }

      &__toggle {
        display: flex;
      }

      /* &__language {
        display: none;
      } */

      &__logo {
        display: none;
      }

      &__small-logo {
        display: block;
      }

      &__notifications {
        margin-right: 16px;
      }
    }
  }

  @media (max-width: 768px) {
    .main-header-bar {
      padding: 6px 20px;
    }
  }
`;
