import { createGlobalStyle, css } from "styled-components";
import {
  Heading1Mixin,
  Heading2Mixin,
  Heading3Mixin,
  Heading4Mixin,
  Heading5Mixin,
  Heading6Mixin,
} from "../../components/text-components/heading-text";
import { LinkMixin } from "../../components/text-components/other-text";

const EditorMixin = css`
  h1 {
    ${Heading1Mixin}
  }
  h2 {
    ${Heading2Mixin}
  }
  h3 {
    ${Heading3Mixin}
  }
  h4 {
    ${Heading4Mixin}
  }
  h5 {
    ${Heading5Mixin}
  }
  h6 {
    ${Heading6Mixin}
  }

  strong {
    font-weight: 600;
  }

  em {
    font-style: inherit;
  }

  ul,
  ol {
    list-style: initial;
  }

  a {
    color: #45BE84;
    ${LinkMixin}
  }
`;

export const ResetStyles = createGlobalStyle`
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
input,
textarea,
select,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  word-break: break-word;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

*, *::after, *::before {
  box-sizing: border-box;
}

body {
  font-family: "Prompt Font", Arial, Helvetica, sans-serif;
  /*background: #F7F7F7;*/
  background: #ffffff;
  color: #333333;
}


a {
  color: inherit;
  text-decoration: none;
}

img {
  height: auto;
  width: 100%;
}

input:not([type="range"]) {
  -webkit-appearance: none;
  border: none;
  background: none;
}

input,
button {
  &:focus {
    outline: unset;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: none !important;
  color: inherit;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

button {
  margin: 0;
}

.ckeditor {
  a {
      ${LinkMixin}
    }
  h2 {
      font-size: 26px;
    }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 18px;
  }
  i {
    font-style: italic;
  }

  ul {
    list-style: circle;
    ul,
    ol {
      padding-left: 24px;
    }
  }
  ol {
    list-style: lower-alpha;
    ul,
    ol {
      padding-left: 24px;
    }
  }
  blockquote {
    list-style: initial;
    font-style: italic;
    padding-left: 24px;
    border-left: 2px solid #248489;
  }
   
  p {
    a {
      color: #248489;
      text-decoration: underline;
      ${LinkMixin}
    }
  }
}

.sb-show-main {
  padding: 0 !important;
}

.prismic-wrapper {
  ${EditorMixin}
}

`;

export default ResetStyles;
