import produce from "immer";
import { ProjectsActions, ProjectsActionsTypes, ProjectsState } from "./types";

export const initialState: ProjectsState = {
  project: null,
  // project: {
  //   status: "auction_approved",
  //   technicalInformationName: "Prueba",
  //   technicalInformationTechnology: "photovoltaicSolar",
  //   technicalInformationInstalledPower: "12",
  //   technicalInformationEstimatedTotalAnnualProduction: "12",
  //   technicalInformationProjectStatus: "development",
  //   technicalInformationEstimatedProductionStartDate:
  //     "2020-12-30T07:59:03.602Z",
  //   technicalInformationEstimatedConstructionStartDate:
  //     "2020-12-30T07:59:03.602Z",
  //   financialInformationInstalledPower: "12",
  //   financialInformationTotalAnnualProduction: "12",
  //   financialInformationParentCompany: "Prueba Matriz",
  // },
  contracts: null,
  invoices: null,
  auction: null,
  tickets: null,
  user: null,
  // auction: {
  //   description: "Prueba",
  //   productionVolumeToAuction: "111",
  //   price: "222",
  //   ppaDuration: 3,
  //   estimatedPpaStartDate: "2020-12-30T09:44:06.663Z",
  //   estimatedConstructionStartDate: "2020-12-30T09:44:06.663Z",
  //   minimumPurchaseVolume: "200",
  //   minimumVolumeRequiredForAuction: "1500",
  //   auctionStartDate: "2020-12-30T09:44:06.663Z",
  //   auctionEndDate: "2020-12-30T09:44:06.663Z",
  //   identityGenerator: {
  //     hasGuarantor: 1,
  //     seizure: "join",
  //     firstProxy: {
  //       name: "Fernando",
  //       lastname: "Garcia",
  //       phone: "+34623123123",
  //       job: "",
  //       email: "asd@asd.es",
  //       dni: "",
  //     },
  //     secondProxy: {
  //       name: "Pedro",
  //       lastname: "Rodriguez",
  //       phone: "+34623123123",
  //       job: "Director",
  //       email: "bb@bb.es",
  //       dni: "",
  //     },
  //   },
  //   identityGuarantor: {
  //     seizure: "join",
  //     firstProxy: {
  //       name: "Lucas",
  //       lastname: "Vázquez",
  //       phone: "+34623123123",
  //       job: "",
  //       email: "cc@cc.es",
  //       dni: "",
  //     },
  //     secondProxy: {
  //       name: "Daniel",
  //       lastname: "Perez",
  //       phone: "+34623123123",
  //       job: "",
  //       email: "hh@hh.es",
  //     },
  //   },
  //   status: "open",
  //   soldVolume: "200",
  //   availableVolume: "200",
  //   percentSoldVolume: 50,
  //   purchaseOrder: [
  //     {
  //       buyer: {
  //         company: "Prueba",
  //       },
  //       totalVolumePurchased: 200,
  //       status: "accept",
  //     },
  //   ],
  // },
  documentEdit: null,
  ticket: null,
  // ticket: {
  //   id: 1,
  //   purchaseOrder: [
  //     {
  //       createdAt: "2021-01-04T09:44:06.663Z",
  //       volumePurchased: "300",
  //       status: "accept",
  //       buyer: {
  //         identityBuyer: {
  //           firstProxy: {
  //             name: "Juan",
  //             lastname: "López",
  //           },
  //           secondProxy: {
  //             name: "Pedro",
  //             lastname: "Mendieta",
  //           },
  //         },
  //       },
  //       firstProxyStatus: "accept",
  //       firstProxyStatusConfirm: "2021-01-05T09:44:06.663Z",
  //       secondProxyStatus: "accept",
  //       secondProxyStatusConfirm: "2021-01-05T10:30:00.663Z",
  //     },
  //   ],
  // },
  purchaseOrderEdit: null,
};

const reducer = (
  state: ProjectsState = initialState,
  action: ProjectsActionsTypes
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ProjectsActions.SET_PROJECT:
        draft.project = action.payload;
        break;

      case ProjectsActions.CLEAN_PROJECT:
        draft.project = null;
        break;
      case ProjectsActions.SET_INVOICES:
        draft.invoices = action.payload;
        break;

      case ProjectsActions.CLEAN_INVOICES:
        draft.invoices = null;
        break;
      case ProjectsActions.SET_CONTRACTS:
        draft.contracts = action.payload;
        break;

      case ProjectsActions.CLEAN_CONTRACTS:
        draft.contracts = null;
        break;
      case ProjectsActions.SET_AUCTION:
        draft.auction = action.payload;
        break;

      case ProjectsActions.CLEAN_AUCTION:
        draft.auction = null;
        break;
      case ProjectsActions.SET_TICKETS:
        draft.tickets = action.payload;
        break;

      case ProjectsActions.CLEAN_TICKETS:
        draft.tickets = null;
        break;
      case ProjectsActions.SET_USER:
        draft.user = action.payload;
        break;

      case ProjectsActions.CLEAN_USER:
        draft.user = null;
        break;
      case ProjectsActions.SET_DOCUMENT:
        draft.documentEdit = action.payload;
        break;

      case ProjectsActions.CLEAN_DOCUMENT:
        draft.documentEdit = null;
        break;
      case ProjectsActions.SET_TICKET:
        draft.ticket = action.payload;
        break;

      case ProjectsActions.CLEAN_TICKET:
        draft.ticket = null;
        break;
      case ProjectsActions.SET_PURCHASE_ORDER_EDIT:
        draft.purchaseOrderEdit = action.payload;
        break;

      case ProjectsActions.CLEAN_PURCHASE_ORDER_EDIT:
        draft.purchaseOrderEdit = null;
        break;

      default:
    }
  });

export default reducer;
