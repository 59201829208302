import styled, { css } from "styled-components";

interface ITypography {
  weight?: 300 | 400 | 500;
  color?: string;
}

export const BodyLargeMixin = css`
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
`;

export const BodyLargeBoldMixin = css`
  ${BodyLargeMixin}
  font-weight: 500;
`;

export const BodyLargeText = styled.p<ITypography>`
  ${BodyLargeMixin};
`;

export const BodyLargeBoldText = styled.p<ITypography>`
  ${BodyLargeBoldMixin};
`;

export const BodyDefaultMixin = css`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
`;

export const BodyDefaultBoldMixin = css`
  ${BodyDefaultMixin};
  font-weight: 400;
`;

export const BodyDefaultText = styled.p<ITypography>`
  ${BodyDefaultMixin};
`;

export const BodyDefaultBoldText = styled.p<ITypography>`
  ${BodyDefaultBoldMixin};
`;

export const BodySmallMixin = css`
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
`;

export const BodySmallBoldMixin = css`
  ${BodySmallMixin};
  font-weight: 500;
`;

export const BodySmallText = styled.p<ITypography>`
  ${BodySmallMixin};
`;

export const BodySmallBoldText = styled.p<ITypography>`
  ${BodySmallBoldMixin};
`;

export const BodyXSmallMixin = css`
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
`;

export const BodyXSmallBoldMixin = css`
  ${BodyXSmallMixin};
  font-weight: 400;
`;

export const BodyXSmallText = styled.p<ITypography>`
  ${BodyXSmallMixin};
`;

export const BodyXSmallBoldText = styled.p<ITypography>`
  ${BodyXSmallBoldMixin};
`;