import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { icons } from "../../../assets/icons/icons";
import Button from "../../../components/button/button";
import Footer from "../../../components/cross/footer/footer";
import InputController from "../../../components/input/input-controller";
import Modal from "../../../components/modal/modal";
import { BodyDefaultText } from "../../../components/text-components/body-text";
import useReactGa from "../../../hooks/useReactGa";
import { routes } from "../../../routes/routes";
import { DashboardApi } from "../../../services/rest-client";
import { authActions } from "../../../store/auth/actions";
import { loaderActions } from "../../../store/loader/actions";
import { getErrorMessage } from "../../../utils/errorMessage";
import { isValidEmail } from "../../../utils/validation";
import { LoginStyle } from "./login-style";

const Login: React.FC = () => {
  const { t } = useTranslation(["auth", "validations"]);
  const dispatch = useDispatch();
  const history = useHistory();
  useReactGa(window.location.pathname, "Contraseña Olvidada");

  const [token, setToken] = useState("");
  const [renderModal, setRenderModal] = useState("");

  const { control, handleSubmit, errors } = useForm<any>({
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "onBlur",
    criteriaMode: "all",
  });

  const handleLogin = async (data: any) => {
    try {
      dispatch(loaderActions.loading());
      const res: any = await DashboardApi.post("/login", {
        ...data,
      });
      dispatch(authActions.logging({ ...res.data.user }));
      localStorage.setItem("jwt", res.data.token);
    } catch (err) {
      if (err.response?.data?.value) {
        setToken(err.response.data.value);
        setRenderModal("password-expired");
      } else {
        toast.error(t("auth:Usuario o contraseña incorrectos"));
      }
    } finally {
      dispatch(loaderActions.loaded());
    }
  };

  return (
    <>
      <Helmet>
        <title>{t("common:app-title")} - Login</title>
      </Helmet>
      <LoginStyle>
        <div className="login-side">
          <picture>
            <source srcSet={icons.loginBgBig} media="(min-width:768px)" />
            <img src={icons.loginBgSmall} alt="" />
          </picture>
        </div>
        <div className="login-main">
          <div className="login-main__logo">
            <img className="mobile" src={icons.fullLogo} alt="Sanza logo" />
            <img className="other" src={icons.fullLogo} alt="Sanza logo" />
          </div>

          <div className="login-main__container">
            <div className="login-main__title">
              <p>{t("auth:Iniciar Sesión")}</p>
            </div>
            <div className="login-main__text">
              <p>
                {t(
                  "auth:Introduce tus credenciales para acceder a la plataforma"
                )}
              </p>
            </div>
            <form onSubmit={handleSubmit(handleLogin)}>
              <div className="login-form">
                <div className="form-row">
                  <InputController
                    inputProps={{
                      required: true,
                      placeholder: t("auth:Introduce tu correo"),
                      type: "text",
                      label: t("auth:Correo electrónico"),
                      error: getErrorMessage(t, errors.email?.type),
                    }}
                    controllerProps={{
                      control,
                      name: "email",
                      rules: {
                        required: true,
                        validate: {
                          isEmail: isValidEmail,
                        },
                      },
                    }}
                  />
                </div>
                <div className="form-row">
                  <InputController
                    inputProps={{
                      required: true,
                      label: t("auth:Contraseña"),
                      type: "password",
                      icon: {
                        inputFn: "showPassword",
                      },
                      placeholder: t("auth:Introduce tu contraseña"),
                      error: getErrorMessage(t, errors.password?.type),
                    }}
                    controllerProps={{
                      control,
                      name: "password",
                      rules: {
                        required: true,
                      },
                    }}
                  />
                </div>
                <div className="login-recover">
                  <Button
                    className="login-recover__button"
                    type="ghost"
                    size="xxl"
                    text={t("auth:Recuperar contraseña")}
                    onClick={() => history.push(routes.forgotPassword)}
                  />
                </div>
                <div className="login-main__buttons">
                  <Button
                    className="login-main__buttons__item"
                    type="primary"
                    size="xxl"
                    text={t("auth:Acceder")}
                    onClick={handleSubmit(handleLogin)}
                  />
                  <div className="login-main__buttons__item text">
                    <BodyDefaultText>
                      {t("auth:¿Aún no te has registrado? Empieza")}{" "}
                      <Button
                        type="ghost"
                        text={t("auth:ahora")}
                        onClick={() => history.push(routes.register)}
                      />
                    </BodyDefaultText>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </LoginStyle>
      <Footer inAuth={true} />
      <Modal
        onClose={() => setRenderModal("")}
        active={renderModal === "password-expired"}
        title={t("auth:Actualizar Credenciales")}
        text={t(
          "auth:Tu contraseña ha expirado, es necesario que la actualices para poder acceder a la plataforma."
        )}
        buttons={[
          {
            type: "secondary",
            text: t("users:Cancelar"),
            onClick: () => setRenderModal(""),
          },
          {
            type: "primary",
            text: t("users:Aceptar"),
            onClick: () => {
              history.push(`${routes.generatePassword}?token=${token}`);
            },
          },
        ]}
      />
    </>
  );
};

export default Login;
