import styled from "styled-components";
import { colors } from "../../assets/colors/colors";

export const TooltipStyle = styled.div`
  .tooltip {
    background: #426690;
    padding: 12px !important;
    color: ${colors["neutrals-0"]} !important;
    font-weight: 300 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    border-radius: 12px !important;
    opacity: 1 !important;
    max-width: 433px !important;
    width: 433px !important;
    pointer-events: auto !important;
    &:hover {
      visibility: visible !important;
      opacity: 1 !important;
    }

    a {
      text-decoration: underline;
      cursor: pointer;
    }

    &.place-top {
      &:after {
        border-top-color: #426690 !important;
        border-top-style: solid !important;
        border-top-width: 6px !important;
      }
    }

    &.place-bottom {
      &:after {
        border-bottom-color: #426690 !important;
        border-bottom-style: solid !important;
        border-bottom-width: 6px !important;
      }
    }

    &.place-left {
      &:after {
        top: 20px;
        border-left-color: #426690 !important;
        border-left-style: solid !important;
        border-left-width: 6px !important;
      }
    }

    &.place-right {
      &:after {
        border-right-color: #426690 !important;
        border-right-style: solid !important;
        border-right-width: 6px !important;
      }
    }
  }

  @media (max-width: 520px) {
    .tooltip {
      max-width: 233px !important;
      width: 233px !important;
    }
  }
`;
