export const domain = process.env.REACT_APP_ENV;
// export const COUNTRY = process.env.REACT_APP_COUNTRY || "br";

interface IServices {
  [key: string]: any;
}

export const SERVICES: IServices = {
  local: "https://sanza-energy-api-develop.azurewebsites.net",
  develop: "https://sanza-energy-api-develop.azurewebsites.net",
  staging: "https://sanza-energy-api-staging.azurefd.net",
  master: "https://app.sanzaenergy.com/api",
};

export const SERVICE_URL = SERVICES[domain as string] || SERVICES.local;

const RECAPTCHA_KEY_DEVELOP = "6LdCkQAaAAAAAKakH4aI0ESx_ZRYw_-rE4VL5uo4";

const RECAPTCHA_KEY_PRODUCTION = "6LeBdv8ZAAAAAGZUeQCAABJ1T62ZqsKqYG1bwUtg";

export const RECAPTCHA_KEY = domain === "master" ? RECAPTCHA_KEY_PRODUCTION : RECAPTCHA_KEY_DEVELOP;
