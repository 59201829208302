import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import auctionsEn from "./locales/en/auctions/translation.json";
import authEn from "./locales/en/auth/translation.json";
import commonEn from "./locales/en/common/translation.json";
import contractsEn from "./locales/en/contracts/translation.json";
import cookiesEn from "./locales/en/cookies/translation.json";
import countryEn from "./locales/en/country/translation.json";
import faqsEn from "./locales/en/faqs/translation.json";
import footerEn from "./locales/en/footer/translation.json";
import generatorEn from "./locales/en/generator/translation.json";
import headerEn from "./locales/en/header/translation.json";
import homeEn from "./locales/en/home/translation.json";
import industriesEn from "./locales/en/industries/translation.json";
import legalEn from "./locales/en/legal/translation.json";
import loginEn from "./locales/en/login/translation.json";
import marketEn from "./locales/en/market/translation.json";
import notificationsEn from "./locales/en/notifications/translation.json";
import profileEn from "./locales/en/profile/translation.json";
import projectsEn from "./locales/en/projects/translation.json";
import usersEn from "./locales/en/users/translation.json";
import validationEn from "./locales/en/validation/translation.json";

// es
import auctionsES from "./locales/es/auctions/translation.json";
import authES from "./locales/es/auth/translation.json";
import commonES from "./locales/es/common/translation.json";
import contractsES from "./locales/es/contracts/translation.json";
import cookiesES from "./locales/es/cookies/translation.json";
import countryES from "./locales/es/country/translation.json";
import faqsES from "./locales/es/faqs/translation.json";
import footerES from "./locales/es/footer/translation.json";
import headerES from "./locales/es/header/translation.json";
import homeES from "./locales/es/home/translation.json";
import industriesES from "./locales/es/industries/translation.json";
import legalES from "./locales/es/legal/translation.json";
import marketES from "./locales/es/market/translation.json";
import notificationsES from "./locales/es/notifications/translation.json";
import profileES from "./locales/es/profile/translation.json";
import projectsES from "./locales/es/projects/translation.json";
import usersES from "./locales/es/users/translation.json";
import validationES from "./locales/es/validation/translation.json";

i18n.use(initReactI18next).init({
  ns: [
    // "common",
  ],
  defaultNS: "common",
  resources: {
    en: {
      auctions: auctionsEn,
      auth: authEn,
      common: commonEn,
      contracts: contractsEn,
      cookies: cookiesEn,
      country: countryEn,
      faqs: faqsEn,
      footer: footerEn,
      generator: generatorEn,
      header: headerEn,
      home: homeEn,
      industries: industriesEn,
      legal: legalEn,
      login: loginEn,
      notifications: notificationsEn,
      profile: profileEn,
      projects: projectsEn,
      market: marketEn,
      users: usersEn,
      validation: validationEn,
    },
    es: {
      auctions: auctionsES,
      auth: authES,
      common: commonES,
      contracts: contractsES,
      cookies: cookiesES,
      country: countryES,
      faqs: faqsES,
      footer: footerES,
      header: headerES,
      home: homeES,
      industries: industriesES,
      legal: legalES,
      market: marketES,
      notifications: notificationsES,
      profile: profileES,
      projects: projectsES,
      users: usersES,
      validation: validationES,
    },
  },
  lng: "es",
  keySeparator: false,
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["span"],
  },
});

export const onLanguageChange = (callback: () => void): void | undefined => {
  i18n.on("languageChanged", () => {
    return callback();
  });
};

export default i18n;
