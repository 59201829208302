import React from "react";
import { Controller } from "react-hook-form";
import { IController } from "../../types/controller-interface";
import InputPhone, { InputPhoneProps } from "./input-phone";

interface IInputPhoneControllerProps {
  inputPhoneProps: InputPhoneProps;
  controllerProps: IController;
}

const InputPhoneController: React.FC<IInputPhoneControllerProps> = ({
  inputPhoneProps,
  controllerProps,
}) => {
  return (
    <Controller
      render={({ value, onChange, onBlur, name }) => (
        <InputPhone
          {...inputPhoneProps}
          onChange={(option: any) => {
            if (controllerProps.onChange) {
              controllerProps.onChange(option);
            }
            onChange(option);
          }}
          onBlur={(option: any) => {
            if (controllerProps.onBlur) {
              controllerProps.onBlur(option);
            }
            onBlur();
          }}
          value={value}
          name={name}
        />
      )}
      {...controllerProps}
    />
  );
};

export default InputPhoneController;
