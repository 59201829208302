import produce from "immer";
import { AuthActions, AuthActionsTypes, AuthState } from "./types";

const query = new URLSearchParams(window.location.search);

export const initialState: AuthState = {
  isAuth: !!localStorage.getItem("jwt") && !query.get("token"),
  user: {},
};

const reducer = (state: AuthState = initialState, action: AuthActionsTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case AuthActions.LOGGING:
        draft.isAuth = true;
        draft.user = { ...action.payload };
        break;

      case AuthActions.LOGOUT:
      case AuthActions.EXPIRED_SESSION:
        draft.isAuth = false;
        draft.user = {};
        localStorage.removeItem("jwt");
        break;
      default:
    }
  });

export default reducer;
