import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
// @ts-ignore
import io from "socket.io-client";
import { SERVICE_URL } from "../constants/env";
import { DashboardApi } from "../services/rest-client";
import { authSelector } from "../store/auth/selectors";
import { loaderActions } from "../store/loader/actions";
import { notificationsActions } from "../store/notifications/actions";
import { notificationsSelector } from "../store/notifications/selectors";
import { IFetchOptions } from "../types/common/interfaces/common-interfaces";
import { INotification } from "../types/common/interfaces/notifications-interfaces";
import { UserRolesEnum } from "../types/users/users.enums";
import useSafeState from "./useSafeState";

const useNotification = (activeSocket: boolean, getFiltersFromQuery?: any) => {
  const LIMIT_REQUESTS = 8;
  const LIMIT_NOTIFICATIONS = 3;

  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { t } = useTranslation("notifications");
  const { role, id: idUser } = useSelector(authSelector.getUser());
  const notifications = useSelector(notificationsSelector.getNotifciations());
  const requests = useSelector(notificationsSelector.getRequests());
  const notificationsHeader = useSelector(
    notificationsSelector.getNotifciationsHeaders()
  );
  const requestsHeader = useSelector(
    notificationsSelector.getRequestsHeaders()
  );

  const socket = useRef<any>(null);

  const endpointNotifications =
    role === UserRolesEnum.MANAGER
      ? "/notifications"
      : `/users/${idUser}/notifications`;

  const [hasNotifications, setHasNotifications] = useSafeState(false);

  const [notificationsSent, setNotificationsSent] = useSafeState<
    INotification[]
  >([]);
  const [totalNotificationsSent, setTotalNotificationsSent] = useSafeState<
    number
  >(0);

  useEffect(() => {
    if (role) {
      getNotificationsHeader();
      getRequestsHeader();
      getHasNotifications();
    }
  }, [role]);

  useEffect(() => {
    if (activeSocket && role) {
      socket.current = io(`${SERVICE_URL}/notifications`, {
        transportOptions: {
          polling: {
            extraHeaders: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          },
        },
      });
      socket.current.on("connect", (data: any) => {
        socket.current.emit(
          role === UserRolesEnum.MANAGER
            ? "join-notifications-admin"
            : "join-notifications"
        );
      });
      socket.current.on("notifications", (data: any) => {
        getNotificationsHeader();
        getHasNotifications();
      });
    }
    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
    };
  }, [role]);

  const getHasNotifications = async () => {
    try {
      dispatch(loaderActions.loading());
      const res: any = await DashboardApi.get(
        `/users/${idUser}/has-not-read-notifications`
      );

      setHasNotifications(res.data.hasNotReadNotifications);
    } catch (err) {
      toast.error(t("auth:Error en el servidor"));
    } finally {
      dispatch(loaderActions.loaded());
    }
  };

  const getNotificationsHeader = async () => {
    try {
      dispatch(loaderActions.loading());
      const res: any = await DashboardApi.get(endpointNotifications, {
        params: {
          take: LIMIT_REQUESTS,
        },
      });
      dispatch(notificationsActions.setNotificationsHeader(res.data.items));
    } catch (err) {
      toast.error(t("auth:Error en el servidor"));
    } finally {
      dispatch(loaderActions.loaded());
    }
  };

  const getRequestsHeader = async () => {
    if (role === UserRolesEnum.MANAGER) {
      try {
        dispatch(loaderActions.loading());
        const res: any = await DashboardApi.get(`/requests`, {
          params: {
            take: LIMIT_REQUESTS,
          },
        });

        dispatch(notificationsActions.setRequestsHeader(res.data.items));
      } catch (err) {
        toast.error(t("auth:Error en el servidor"));
      } finally {
        dispatch(loaderActions.loaded());
      }
    }
  };

  const fetchRequests = async (options?: IFetchOptions) => {
    const page = options?.page || 0;

    try {
      dispatch(loaderActions.loading());
      const {
        data: { items, totalItems },
      }: any = await DashboardApi.get("/requests", {
        params: {
          take: LIMIT_REQUESTS,
          skip: LIMIT_REQUESTS * page,
        },
      });
      dispatch(notificationsActions.setRequests([...items]));
      dispatch(notificationsActions.setRequestsTotal(totalItems));
      dispatch(loaderActions.loaded());
    } catch (err) {
      const error = DashboardApi.errorHandler(err);
      toast.error(error.message);
    } finally {
      dispatch(loaderActions.loaded());
    }
  };

  const fetchNotifications = async (options?: IFetchOptions) => {
    const page = options?.page || 0;

    try {
      dispatch(loaderActions.loading());
      const filtersFromQuery = getFiltersFromQuery ? getFiltersFromQuery() : "";
      const endpoint = filtersFromQuery
        ? `${endpointNotifications}?${encodeURI(filtersFromQuery)}`
        : endpointNotifications;
      const {
        data: { items, totalItems },
      }: any = await DashboardApi.get(endpoint, {
        params: {
          take: LIMIT_NOTIFICATIONS,
          skip: LIMIT_NOTIFICATIONS * page,
        },
      });
      dispatch(notificationsActions.setNotifications(items));
      dispatch(notificationsActions.setNotificationsTotal(totalItems));
      dispatch(loaderActions.loaded());
    } catch (err) {
      const error = DashboardApi.errorHandler(err);
      toast.error(error.message);
    } finally {
      dispatch(loaderActions.loaded());
    }
  };

  const fetchNotificationsSent = async (options?: IFetchOptions) => {
    const page = options?.page || 0;

    try {
      dispatch(loaderActions.loading());
      const filtersFromQuery = getFiltersFromQuery ? getFiltersFromQuery() : "";
      const endpoint = filtersFromQuery
        ? `/notifications/manually-generated?${encodeURI(filtersFromQuery)}`
        : "/notifications/manually-generated";
      const {
        data: { items, totalItems },
      }: any = await DashboardApi.get(endpoint, {
        params: {
          take: LIMIT_NOTIFICATIONS,
          skip: LIMIT_NOTIFICATIONS * page,
        },
      });
      setNotificationsSent(items);
      setTotalNotificationsSent(totalItems);
      dispatch(loaderActions.loaded());
    } catch (err) {
      const error = DashboardApi.errorHandler(err);
      toast.error(error.message);
    } finally {
      dispatch(loaderActions.loaded());
    }
  };

  const markedReadNotification = async (id: string, isHeader?: boolean) => {
    try {
      await DashboardApi.patch(
        `/notifications/${id}/mark-as-read?locale=${i18n.language}`,
        {}
      );
      await getHasNotifications();

      const notificationsAux = notifications.map((item: any) => ({ ...item }));
      const notificationsHeaderAux = notificationsHeader.map((item: any) => ({
        ...item,
      }));
      const index = notifications.findIndex((item: any) => item.id === id);
      const indexHeader = notificationsHeader.findIndex(
        (item: any) => item.id === id
      );

      if (index > -1) {
        notificationsAux[index].wasRead = true;
        dispatch(notificationsActions.setNotifications([...notificationsAux]));
      }
      if (indexHeader > -1) {
        notificationsHeaderAux[indexHeader].wasRead = true;
        dispatch(
          notificationsActions.setNotificationsHeader([
            ...notificationsHeaderAux,
          ])
        );
      }
    } catch (err) {
      const error = DashboardApi.errorHandler(err);
      toast.error(error.message);
      throw err;
    }
  };

  const markedReadRequest = async (id: string, isHeader?: boolean) => {
    try {
      await DashboardApi.patch(
        `/requests/${id}/mark-as-read?locale=${i18n.language}`,
        {}
      );
      await getHasNotifications();

      const requestsAux = requests.map((item: any) => ({ ...item }));
      const requestsHeaderAux = requestsHeader.map((item: any) => ({
        ...item,
      }));
      const index = requests.findIndex((item: any) => item.id === id);
      const indexHeader = requestsHeader.findIndex(
        (item: any) => item.id === id
      );

      if (index > -1) {
        requestsAux[index].wasRead = true;
        dispatch(notificationsActions.setRequests([...requestsAux]));
      }
      if (indexHeader > -1) {
        requestsHeaderAux[indexHeader].wasRead = true;
        dispatch(
          notificationsActions.setRequestsHeader([...requestsHeaderAux])
        );
      }
    } catch (err) {
      const error = DashboardApi.errorHandler(err);
      toast.error(error.message);
      throw err;
    }
  };

  return {
    markedReadNotification,
    markedReadRequest,
    hasNotifications,
    LIMIT_REQUESTS,
    notificationsSent,
    LIMIT_NOTIFICATIONS,
    fetchNotificationsSent,
    fetchNotifications,
    fetchRequests,
    totalNotificationsSent,
  };
};

export default useNotification;
