import { UsersState } from "./types";

export const usersSelector = {
  getUser: () => (state: { users: UsersState }) => state.users.user,
  getQualification: () => (state: { users: UsersState }) =>
    state.users.qualification,
  getIdentities: () => (state: { users: UsersState }) => state.users.identities,
  getDocuments: () => (state: { users: UsersState }) => state.users.documents,
  getMultipoint: () => (state: { users: UsersState }) => state.users.multipoint,
};
