export const INSTAGRAM_URL = "";
export const LINKEDIN_URL = "https://www.linkedin.com/company/sanza-energy ";
export const TWITTER_UL = "https://twitter.com/sanzaenergy";

export const PRIVATE_CONTACT_EMAIL = "help@sanzaenergy.com";
export const CONTACT_EMAIL = "info@sanzaenergy.com";

export const PRISMIC_URL = "https://sanza.prismic.io/";

export const FORM_URL = "https://forms.gle/3xUNQcFyzg1CTEjM6";

export const TIMEZONE = "Europe/Madrid";
