import styled, { css } from "styled-components";

interface ITypography {
  weight?: 300 | 400 | 500;
  color?: string;
}

export const Heading1Mixin = css`
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
`;

export const Heading1Text = styled.h1<ITypography>`
  ${Heading1Mixin};
`;

export const Heading2Mixin = css`
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
`;

export const Heading2Text = styled.h2<ITypography>`
  ${Heading2Mixin};
`;

export const Heading3Mixin = css`
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
`;

export const Heading3Text = styled.h3<ITypography>`
  ${Heading3Mixin};
`;

export const Heading4Mixin = css`
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
`;

export const Heading4Text = styled.h4<ITypography>`
  ${Heading4Mixin};
`;

export const Heading5Mixin = css`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
`;

export const Heading5Text = styled.h5<ITypography>`
  ${Heading5Mixin};
`;

export const Heading6Mixin = css`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
`;

export const Heading6Text = styled.h6<ITypography>`
  ${Heading6Mixin};
`;