import styled from 'styled-components';
import { colors } from '../../../../assets/colors/colors';
import { BodyDefaultMixin } from '../../../../components/text-components/body-text';
import { Heading6Mixin } from '../../../../components/text-components/heading-text';

export const CardNotificationDetailStyle = styled.div`
  .card-notification-detail {
    width: 100%;

    &__from, 
    &__to {
      ${BodyDefaultMixin};
      color: ${colors['neutrals-100']};
      margin-bottom: 12px;

      span {
        font-weight: 400;
      }
    }

    &__title {
      ${Heading6Mixin};
      color: ${colors['neutrals-100']};
      margin-bottom: 8px;
    }

    &__message {
      ${BodyDefaultMixin};
      color: ${colors['neutrals-80']};
      margin-bottom: 8px;

      span {
        color: #426690;  
      }
    }

    &__extra {
      ${BodyDefaultMixin};
      color: #426690;

      &:not(:first-child) {
        margin-top: 12px;
      }
    }

    &__more {
      display: flex;
      justify-content: flex-end;
    }
  }

  &.to-user {
    .card-notification-detail {
      &__title {
        margin-bottom: 24px;
        font-weight: 500;

        @media (max-width: 768px) {
          margin-bottom: 12px;  
        }
      }
    }
  }
`;