import styled from 'styled-components';
import { colors } from '../../../assets/colors/colors';
import { LinkMixin } from '../../text-components/other-text';

export const FooterStyle = styled.div`
  height: 60px;
  padding: 6px 38px;
  display: flex;
  align-items: center;
  background: ${colors['neutrals-0']};
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;

  .footer-links {
    &__item {
      cursor: pointer;
      ${LinkMixin};
      font-weight: 300;
      text-decoration: underline;
      color: ${colors['brand-1-100']};

      &:not(:first-child) {
        margin-left: 24px;
      }
    }
  }

  .footer-spacer {
    flex: 1;
  }

  .footer-other {
    display: flex;
    align-items: center;

    &__item {
      &:not(:first-child) {
        margin-left: 24px;
      }
    }

    .button-icon {
      margin-right: 0px;
    }

    .language-btn {
      margin: 0 11px;
    }
  }

  @media (max-width: 1100px) {
    height: auto;
    flex-flow: column;
    align-items: center;
    padding: 36px 38px;

    .footer-other {
      order: -1;
      margin-bottom: 26px;
    }  
  }

  @media (max-width: 768px) {
    padding: 24px 34px 40px;

    .footer-links {
      display: flex;
      align-items: center;
      flex-flow: column;

      &__item {
        &:not(:first-child) {
          margin-top: 4px;
          margin-left: 0;
        }
      }
    }  
  }
`;