import { IBaseAction } from "../base-action.interface";

export enum ProjectsActions {
  GET_PROJECT = "GET_PROJECT",
  SET_PROJECT = "SET_PROJECT",
  CLEAN_PROJECT = "CLEAN_PROJECT",
  SET_CONTRACTS = "SET_CONTRACTS",
  CLEAN_CONTRACTS = "CLEAN_CONTRACTS",
  SET_INVOICES = "SET_INVOICES",
  CLEAN_INVOICES = "CLEAN_INVOICES",
  SET_AUCTION = "SET_AUCTION",
  CLEAN_AUCTION = "CLEAN_AUCTION",
  SET_TICKETS = "SET_TICKETS",
  CLEAN_TICKETS = "CLEAN_TICKETS",
  SET_USER = "SET_USER_PROJECT",
  CLEAN_USER = "CLEAN_USER_PROJECT",
  SET_DOCUMENT = "SET_DOCUMENT",
  CLEAN_DOCUMENT = "CLEAN_DOCUMENT",
  SET_TICKET = "SET_TICKET",
  CLEAN_TICKET = "CLEAN_TICKET",
  SET_PURCHASE_ORDER_EDIT = "SET_PURCHASE_ORDER_EDIT",
  CLEAN_PURCHASE_ORDER_EDIT = "CLEAN_PURCHASE_ORDER_EDIT",
}

export interface ProjectsState {
  readonly project: any;
  readonly contracts: any;
  readonly invoices: any;
  readonly auction: any;
  readonly tickets: any;
  readonly user: any;
  readonly documentEdit: any;
  readonly ticket: any;
  readonly purchaseOrderEdit: any;
}

interface GetProjectAction extends IBaseAction {
  type: ProjectsActions.SET_PROJECT;
  payload: any;
}
interface SetProjectAction extends IBaseAction {
  type: ProjectsActions.SET_PROJECT;
  payload: any;
}

interface CleanProjectAction extends IBaseAction {
  type: ProjectsActions.CLEAN_PROJECT;
}

interface SetContractsAction extends IBaseAction {
  type: ProjectsActions.SET_CONTRACTS;
  payload: any;
}

interface CleanContractsAction extends IBaseAction {
  type: ProjectsActions.CLEAN_CONTRACTS;
}

interface SetInvoicesAction extends IBaseAction {
  type: ProjectsActions.SET_INVOICES;
  payload: any;
}

interface CleanInvoicesAction extends IBaseAction {
  type: ProjectsActions.CLEAN_INVOICES;
}

interface SetAuctionAction extends IBaseAction {
  type: ProjectsActions.SET_AUCTION;
  payload: any;
}

interface CleanAuctionAction extends IBaseAction {
  type: ProjectsActions.CLEAN_AUCTION;
}

interface SetTicketsAction extends IBaseAction {
  type: ProjectsActions.SET_TICKETS;
  payload: any;
}

interface CleanTicketsAction extends IBaseAction {
  type: ProjectsActions.CLEAN_TICKETS;
}

interface SetUserAction extends IBaseAction {
  type: ProjectsActions.SET_USER;
  payload: any;
}

interface CleanUserAction extends IBaseAction {
  type: ProjectsActions.CLEAN_USER;
}

interface SetDocumentAction extends IBaseAction {
  type: ProjectsActions.SET_DOCUMENT;
  payload: any;
}

interface CleanDocumentAction extends IBaseAction {
  type: ProjectsActions.CLEAN_DOCUMENT;
}

interface SetTicketAction extends IBaseAction {
  type: ProjectsActions.SET_TICKET;
  payload: any;
}

interface CleanTicketAction extends IBaseAction {
  type: ProjectsActions.CLEAN_TICKET;
}

interface SetPurchaseOrderEditActiion extends IBaseAction {
  type: ProjectsActions.SET_PURCHASE_ORDER_EDIT;
  payload: any;
}

interface CleanPurchaseOrderEditActiion extends IBaseAction {
  type: ProjectsActions.CLEAN_PURCHASE_ORDER_EDIT;
}

export type ProjectsActionsTypes =
  | GetProjectAction
  | SetProjectAction
  | CleanProjectAction
  | SetContractsAction
  | CleanContractsAction
  | SetInvoicesAction
  | CleanInvoicesAction
  | SetAuctionAction
  | CleanAuctionAction
  | SetTicketsAction
  | CleanTicketsAction
  | SetUserAction
  | CleanUserAction
  | SetDocumentAction
  | CleanDocumentAction
  | SetTicketAction
  | CleanTicketAction
  | SetPurchaseOrderEditActiion
  | CleanPurchaseOrderEditActiion;
