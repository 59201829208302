import { icons } from "../../assets/icons/icons";

const PATHS = {
  home: "/home",
  profile: "/my-profile",
  users: "/users",
  projects: "/projects",
  sanzaMarket: "/sanza-market",
  faqs: "/faqs",
};

export const getSanzaManagerLinks = (t: any) => [
  {
    icon: icons.home,
    src: t("header:Home"),
    text: t("header:Home"),
    path: PATHS.home,
  },
  {
    icon: icons.user3,
    src: t("header:Mi perfil"),
    text: t("header:Mi perfil"),
    path: PATHS.profile,
  },
  {
    icon: icons.users,
    src: t("header:Usuarios"),
    text: t("header:Usuarios"),
    path: PATHS.users,
  },
  {
    icon: icons.suitcase,
    src: t("header:Proyectos"),
    text: t("header:Proyectos"),
    path: PATHS.projects,
  },
  {
    icon: icons.zap2,
    src: t("header:Sanza Market"),
    text: t("header:Sanza Market"),
    path: PATHS.sanzaMarket,
  },
  {
    icon: icons.question,
    src: t("header:FAQs"),
    text: t("header:FAQs"),
    path: PATHS.faqs,
  },
];

export const getBuyerAndGeneratorLinks = (t: any) => [
  {
    icon: icons.home,
    src: t("header:Home"),
    text: t("header:Home"),
    path: PATHS.home,
  },
  {
    icon: icons.user3,
    src: t("header:Mi perfil"),
    text: t("header:Mi perfil"),
    path: PATHS.profile,
  },
  {
    icon: icons.suitcase,
    src: t("header:Proyectos"),
    text: t("header:Proyectos"),
    path: PATHS.projects,
  },
  {
    icon: icons.zap2,
    src: t("header:Sanza Market"),
    text: t("header:Sanza Market"),
    path: PATHS.sanzaMarket,
  },
  {
    icon: icons.question,
    src: t("header:FAQs"),
    text: t("header:FAQs"),
    path: PATHS.faqs,
  },
];

export const getAdiviserLinks = (t: any) => [
  {
    icon: icons.users,
    src: t("header:Usuarios"),
    text: t("header:Usuarios"),
    path: PATHS.users,
  },
  {
    icon: icons.zap2,
    src: t("header:Sanza Market"),
    text: t("header:Sanza Market"),
    path: PATHS.sanzaMarket,
  },
  {
    icon: icons.user3,
    src: t("header:Mi perfil"),
    text: t("header:Mi perfil"),
    path: PATHS.profile,
  },
];
