import { generatePath } from "react-router-dom";

interface CommonRoutes {
  root: string;
  privacy: string;
  cookies: string;
  legal: string;
}

export const commonRoutes: CommonRoutes = {
  root: "/",
  privacy: "/privacy",
  cookies: "/cookies",
  legal: "/legal",
};

interface AuthRoutes {
  generatePassword: string;
  changePassword: string;
  forgotPassword: string;
  register: string;
  orderConfirm: string;
}

export const authRoutes: AuthRoutes = {
  generatePassword: "/generate-password",
  changePassword: "/change-password",
  forgotPassword: "/forgot-password",
  register: "/register",
  orderConfirm: "/order-confirm",
};

interface InternalRoutes {
  createProject: string;
  editProject: string;
  projects: string;
  detailSanzaMarket: string;
  sanzaMarket: string;
  createNotification: string;
  notifications: string;
  profileContact: string;
  profilePassword: string;
  profile: string;
  users: string;
  createUser: string;
  editUser: string;
  homeDigitalContracts: string;
  homeServices: string;
  homeContracts: string;
  homeBuyEnergy: string;
  homeSellEnergy: string;
  faqs: string;
  home: string;
}

export const internalRoutes: InternalRoutes = {
  createProject: "/projects/create",
  editProject: "/projects/detail/:id",
  projects: "/projects",
  detailSanzaMarket: "/sanza-market/detail/:id",
  sanzaMarket: "/sanza-market",
  createNotification: "/notifications/create",
  notifications: "/notifications",
  profileContact: "/my-profile/contact",
  profilePassword: "/my-profile/password",
  profile: "/my-profile",
  createUser: "/users/create",
  editUser: "/users/detail/:id",
  users: "/users",
  homeDigitalContracts: "/home/contracts-digital",
  homeServices: "/home/services",
  homeContracts: "/home/contracts",
  homeBuyEnergy: "/home/buy-energy",
  homeSellEnergy: "/home/sell-energy",
  faqs: "/faqs",
  home: "/home",
};

interface Routes
  extends CommonRoutes,
    AuthRoutes,
    Omit<InternalRoutes, "editProject" | "detailSanzaMarket" | "editUser"> {
  editProject: (id: string) => string;
  detailSanzaMarket: (id: string) => string;
  editUser: (id: string) => string;
}

export const routes: Routes = {
  ...commonRoutes,
  ...authRoutes,
  ...internalRoutes,
  editProject: (id) => generatePath(internalRoutes.editProject, { id }),
  detailSanzaMarket: (id) =>
    generatePath(internalRoutes.detailSanzaMarket, { id }),
  editUser: (id) => generatePath(internalRoutes.editUser, { id }),
};
